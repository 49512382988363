import React, { useState } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import {
  RiLinkedinFill,
  RiFacebookFill,
  RiTwitterFill,
  RiWhatsappLine,
} from "react-icons/ri";
import Lady from "../../assets/images/footer-bg.png";
import Copy from "../../assets/images/copyright.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FooterMain,
  FooterSectionTitle,
  FooterLinksGroup,
  FooterImage,
  FooterBottom,
  FooterCopyRight,
  FooterBottomWrap,
  FooterSocialMedia,
  BookTuitionHeader,
  BookModalBody,
  ModalFooter,
} from "./footerStyle";

const Footer: Function = () => {
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const submitForm = (values: any, { resetForm }: any) => {
    localStorage.setItem("name", JSON.stringify(values));
    setTimeout(() => {
      setShow(false);
      resetForm();
    }, 5000);

    toast.success("Our team will updates you");
  };

  return (
    <>
      <FooterMain>
        <Container>
          <Row>
            <Col sm={3} lg={2}>
              <FooterSectionTitle>Quick Links</FooterSectionTitle>
              <FooterLinksGroup>
                <li>
                  <Link to="/">Find a Tuition</Link>
                </li>
                <li>
                  <Link to="#">Book a Demo</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
              </FooterLinksGroup>
            </Col>
            <Col  sm={3} lg={2}>
              <FooterSectionTitle>Terms of Service</FooterSectionTitle>
              <FooterLinksGroup>
                <li>
                  <Link to="/cookie-policy">Cookie policy</Link>
                </li>
                <li>
                  <Link to="/cookie-setting">Cookie settings</Link>
                </li>
                
                <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/refund-policy">Refund & Cancellation Policy</Link>
                </li>
                <li>
                  <Link to="/tc-for-student">T&Cs for students</Link>
                </li>
              </FooterLinksGroup>
            </Col>
            <Col  sm={3} lg={2}>
              <FooterSectionTitle>We are here to help</FooterSectionTitle>
              <FooterLinksGroup>
                <li>
                  <Link to="#" onClick={handleShow}>
                    Help
                  </Link>
                </li>
                <li>
                  <Link to="#">Tutor Area</Link>
                </li>
                <li>
                  <Link to="#">Join Us</Link>
                </li>
                <li>
                  <Link to="#">Become a private tutor</Link>
                </li>
                <li>
                  <Link to="#">Questions and answers</Link>
                </li>
              </FooterLinksGroup>
            </Col>
            <Col  sm={3} lg={3}>
            <FooterSectionTitle>Contact Us</FooterSectionTitle>
              <FooterLinksGroup>
                <li>
                  <strong>Office Address:</strong> <br/>
                  <span style={{ fontFamily: "mono" }}>
                    E 12, Ashiyana Nagar, Phase 2, <br/>
                    Patna - 800025
                  </span>
                </li>
                <li style={{ fontFamily: "mono" }}>
                  ethosity.solutions@gmail.com <br/>
                  +91 98225 07475 <br/>
                  +91 96659 87151
                </li>
              
              </FooterLinksGroup>
            </Col>
            <Col xs={6} lg={2} className="d-xs-none d-sm-none d-md-none d-lg-flex">
              <FooterImage>
                <img src={Lady} alt="Lady" />
              </FooterImage>
            </Col>
          </Row>
        </Container>
        <FooterBottom>
          <Container>
            <Row>
              <Col sm={12}>
                <FooterBottomWrap>
                  <FooterCopyRight>
                    <img src={Copy} alt="Copy" />
                    Copyright 2024 EthosITy Solutions. | All Rights Reserved.
                  </FooterCopyRight>
                  <FooterSocialMedia>
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=100090586877040"
                        target="_blank"
                      >
                        <RiFacebookFill />
                        {/* </Link> */}
                      </a>
                    </li>
                    <li>
                      <Link to="">
                        <RiLinkedinFill />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <RiTwitterFill />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <RiWhatsappLine />
                      </Link>
                    </li>
                  </FooterSocialMedia>
                </FooterBottomWrap>
              </Col>
            </Row>
          </Container>
        </FooterBottom>
      </FooterMain>
      <Formik
        initialValues={{
          name: "",
          emailId: "",
          productOrService: "",
          subject: "",
          comment: "",
        }}
        validate={(values: any) => {
          let errors: any = {};

          if (!values.name) {
            errors.name = "Please enter your name";
          }

          if (!values.emailId) {
            errors.emailId = "Please enter your email Id";
          }

          if (!values.subject) {
            errors.subject = "Please enter subject";
          }

          if (!values.productOrService) {
            errors.productOrService = "Please select product / service";
          }

          if (!values.comment) {
            errors.comment = "Please enter comment";
          }

          return errors;
        }}
        onSubmit={submitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldError,
          dirty,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Modal
                show={show}
                onHide={handleClose}
                className="bookDemo"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <style>
                  {`
          .bookDemo .modal-dialog {
            max-width: calc(100% - 40px);
            width: 782px;
            letter-spacing: 0;
          }
          .bookDemo .modal-content {
            background: #FFFFFF;
            border-radius: 30px;
            border: none;
            box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
          }
        
        `}
                </style>
                <BookTuitionHeader>
                  <Modal.Header closeButton onClick={() => resetForm()}>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Contact Support
                    </Modal.Title>
                  </Modal.Header>
                </BookTuitionHeader>
                <BookModalBody>
                  <Modal.Body>
                    <form>
                      <Row>
                        <Col lg={6}>
                          <Form.Group className="form-group CloseBtn">
                            <Form.Label>Name *</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Name"
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div className="error">
                              {touched.name && errors.name && (
                                <p>Please enter your name</p>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Email ID *</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Email ID"
                              name="emailId"
                              id="emailId"
                              value={values.emailId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div className="error">
                              {touched.emailId && errors.emailId && (
                                <p>{errors.emailId}</p>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Product / Services *</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              id="productOrService"
                              name="productOrService"
                              value={values.productOrService}
                              onChange={(e: any) =>
                                setFieldValue(
                                  "productOrService",
                                  e.target.value
                                )
                              }
                            >
                              <option>Select</option>
                              <option value="student-mitr">
                                Tution Mitra'
                              </option>
                            </Form.Select>
                            <div className="error">
                              {errors.productOrService &&
                                touched.productOrService && (
                                  <p>{errors.productOrService}</p>
                                )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Subject *</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Here"
                              name="subject"
                              id="subject"
                              value={values.subject}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div className="error">
                              {touched.subject && errors.subject && (
                                <p>Please enter subject</p>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group className="form-group">
                            <Form.Label>Comment *</Form.Label>
                            <Form.Control
                              as="textarea"
                              type="text"
                              placeholder="Enter Here"
                              id="comment"
                              name="comment"
                              value={values.comment}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div className="error">
                              {touched.comment && errors.comment && (
                                <p>{errors.comment}</p>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <ModalFooter>
                        <Modal.Footer>
                          <Button
                            type="reset"
                            variant="secondary"
                            onClick={() => {
                              handleClose();
                              resetForm();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            variant="secondary"
                            // onClick={() => resetForm()}
                          >
                            Submit
                          </Button>
                        </Modal.Footer>
                      </ModalFooter>
                      {/* <ToastContainer autoClose={5000} /> */}
                    </form>
                  </Modal.Body>
                </BookModalBody>
              </Modal>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default Footer;
