import React, { useState } from "react";
import "./checkStyle.css";

// function ColorCheckboxGroup({ colorOptions }) {
//   const [colors, setColors] = useState( 
//     colorOptions.map((color) => ({ ...color, checked: false }))
//   );

//   const handleCheckboxChange = (id) => {
//     const updatedColors = colors.map((color) =>
//       color.id === id ? { ...color, checked: !color.checked } : color
//     );
//     setColors(updatedColors);
//   };

//   return (
//     <div>
//       {colors.map((color) => (
//         <label key={color.id} className="checkbox" htmlFor={color.id}>
//           <input
//             type="checkbox"
//             name="color"
//             checked={color.checked}
//             value={color.label}
//             id={color.id}
//             onChange={() => handleCheckboxChange(color.id)}
//           />
//           <span className="checkmark"></span>
//           <div className="ms-4 check-label" > {color.label}</div>
//         </label>
//       ))}
//     </div>
//   );
// }

// export default ColorCheckboxGroup;




function ColorCheckboxGroup({
  accommodation = [],
  budget = [],
  amenities = [],
  selectedValues = {
    accommodation_type: [],
    budget: [],
    amenities: [],
  },
  onCheckboxChange,
}) {



  

  const renderCheckbox = (items, type) => {
    return items.map((item) => (
      <label key={item.id} className="checkbox" htmlFor={item.id}>
        <input
          type="checkbox"
          name={type} 
          id={item.id}
          value={item.value} 
          checked={selectedValues[type].includes(item.id)}
          onChange={() => onCheckboxChange(type, item.id, !selectedValues[type].includes(item.id))}
        />
        <span className="checkmark"></span>
        <div className="ms-4 check-label"> {item.label}</div>
      </label>
    ));
  };

  return (
    <>
      {renderCheckbox(accommodation, "accommodation_type")}
      {renderCheckbox(budget, "budget")}
      {renderCheckbox(amenities, "amenities")}
    </>
  );
}

export default ColorCheckboxGroup;
