import React, { useState } from "react";
import Nodata from "../../assets/images/no-data.png";
import moment from "moment";
import LoginModal from "../../components/LoginModal";
import { hostConfig } from "../../config";
import Carousal from "../../components/Carousal";

const ServiceList = ({ rentalList }) => {
  const [loginModal, setLoginModal] = useState(false);

  const getData = JSON.parse(localStorage.getItem("loggedUsers") || "null");

  console.log(rentalList, "rentalList");
  console.log(getData, "getData");

  return (
    <div>
      <div className="card" style={{ marginBottom: "30px" }}>
        <h5
          className="card-header"
          style={{ background: "#940E34", height: 40 }}
        >
          {/* <p style={{ color: "#940E34" }}>n</p> */}
        </h5>
        {rentalList?.map((item) => {
          return (
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Carousal item={item} hostConfig={hostConfig}/>
                  {/* <div className="card mb-3" style={{ borderRadius: "0px" }}>
                    {item?.image_file_name?.length
                      ? item?.image_file_name.map((imageUrl) => (
                          <img
                            className="card-img-top"
                            // src={
                            //     hostConfig.S3_BUCKET}${item?.image_filename}
                            // }
                            src={`${hostConfig.S3_BUCKET}${imageUrl}`}
                            alt="Card image cap"
                            style={{ width: "70%" }}
                          />
                        ))
                      : null}
                  </div> */}
                  <h5
                    className="card-header p-2"
                    style={{ background: "#940E34" }}
                  >
                    <p className="mb-0" style={{ color: "white" }}>
                      Rs. {item?.budget ? item?.budget : ""} / month
                    </p>
                    <p className="mb-0" style={{ color: "white" }}>
                      per person
                    </p>
                  </h5>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">
                        {item?.address ? item?.address : ""}
                      </h5>
                      <p className="card-text">
                        {item?.description ? item?.description : ""}
                      </p>
                      <div style={{ marginTop: "10px" }}>
                        <p className="card-text p-0 m-0">
                          Type: {item?.property_type ? item?.property_type : ""}
                        </p>
                        <p className="card-text p-0 m-0">
                          Bedrooms: {item?.bedroom ? item?.bedroom : ""}
                        </p>
                        <p className="card-text p-0 m-0">
                          Security Deposit:{" "}
                          {item?.security_deposit ? item?.security_deposit : ""}
                        </p>
                        <p className="card-text p-0 m-0">
                          Contract Length:{" "}
                          {item?.contract_length ? item?.contract_length : ""}
                        </p>
                        {getData !== null ? (
                          <>
                            <h5 className="card-title mt-3">
                              Mr. {item?.name ? item?.name : ""}
                            </h5>
                            <h5 className="card-title">
                              Tel:{" "}
                              {item?.mobile_number ? item?.mobile_number : ""}
                            </h5>
                          </>
                        ) : null}
                      </div>

                      <div className="row d-flex justify-content-center align-items-center mt-2">
                        <div className="col-md-7">
                          <button
                            style={{
                              borderRadius: "10px",
                              background: "#940E34",
                              color: "white",
                              fontSize: 12,
                              fontWeight: "600",
                            }}
                            onClick={() => {
                              if (getData === null) {
                                setLoginModal(true);
                              }
                            }}
                          >
                            Click to view contact Info
                          </button>
                        </div>
                        <div className="col-md-5">
                          <div style={{ fontSize: 12 }}>
                            Added on{" "}
                            {moment(item?.created_at).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {getData === null && loginModal && (
        <LoginModal loginModal={loginModal} setLoginModal={setLoginModal} />
      )}
    </div>
  );
};

export default ServiceList;
