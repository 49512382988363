import styled from "styled-components";

export const FooterMain = styled.div`
  background: #f7f7f7;
  width:100%;
`;
export const FooterSectionTitle = styled.h3`
  padding-top: 100px;
  color: #012834;
  font-size: 18px;
  line-height: 120%;
  font-weight: 800;
  margin-bottom: 30px;
  @media (max-width: 1600px) {
    padding-top: 80px;
  }
  @media (max-width: 1440px) {
    padding-top: 60px;
    margin-bottom: 30px;
  }
  @media (max-width: 1366px) {
    font-size: 22px;
    padding-top: 50px;
  }
  @media (max-width: 820px) {
    font-size: 14px;
  }
`;
export const FooterLinksGroup = styled.ul`
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    & + li {
      margin-top: 20px;
      @media (max-width: 1440px) {
        margin-top: 20px;
      }
    }
    a {
      color: #012834;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      text-decoration: none;
      &:hover {
        color: #940e34;
      }
      @media (max-width: 800px) {
        font-size: 12px;
        line-height: 90%;
      }
    }
  }
`;
export const FooterImage = styled.div`
  text-align: right;
  margin-top: 60px;
  margin-right: -90px;
  @media (max-width: 1600px) {
    margin-right: -50px;
  }
  @media (max-width: 1366px) {
    margin-right: -40px;
  }
  @media (max-width: 820px) {
    margin-top: 30px;
    margin-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;
export const FooterBottom = styled.div`
  background: #940e34;
  margin-top: 40px;
`;
export const FooterBottomWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;
export const FooterCopyRight = styled.div`
  img {
    vertical-align: -6px;
    margin-right: 6px;
  }
  color: #ffffff;
  font-size: 16px;
  line-height: 120%;
  font-weight: 400;
  @media (max-width: 900px) {
    text-align: center;
    font-size: 15px;
    line-height: 190%;
    margin-top: 10%;
  }
`;
export const FooterSocialMedia = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  li {
    width: 36px;
    height: 36px;
    & + li {
      margin-left: 15px;
    }
    a {
      width: 36px;
      height: 36px;
      background: #ffffff;
      border-radius: 50%;
      font-size: 29px;
      line-height: 36px;
      svg {
        height: 23px;
        width: 36px;
        vertical-align: -1px;
        fill: #940e34;
      }
      &:hover {
        background: #f7f7f7;
      }
    }
  }
`;

export const BookTuitionHeader = styled.div`
  max-width: 782px;
  .btn-close {
    height: 20px;
    width: 20px;
    &:focus {
      box-shadow: none;
    }
  }
  .modal-header {
    background: #f7f7f7;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 32px 40px;
    border-bottom: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    @media (max-width: 1440px) {
      padding: 20px 32px;
    }
    @media (max-width: 1366px) {
      padding: 18px 30px;
    }
    .modal-title {
      font-size: 30px;
      line-height: 120%;
      font-weight: 800;
      @media (max-width: 1440px) {
        font-size: 28px;
      }
      @media (max-width: 1366px) {
        font-size: 26px;
      }
      @media (max-width: 1280px) {
        font-size: 24px;
      }
    }
  }
`;

export const BookModalBody = styled.div`
  .modal-body {
    padding: 40px 40px;
    @media (max-width: 1440px) {
      padding: 30px 20px 35px;
    }
    @media (max-width: 1280px) {
      padding: 20px 20px 35px;
    }
    .form-label {
      font-size: 14px;
      line-height: 130%;
      color: #707070;
      margin-bottom: 15px;
    }
    .form-control {
      font-size: 16px;
      line-height: 130%;
      font-weight: 500;
      padding: 19px;
      color: #012834;
      @media (max-width: 1440px) {
        padding: 14px 20px;
        font-size: 14px;
      }
      &::placeholder {
        color: #707070;
        &:focus {
          background-color: #d3d3d3;
        }
      }
    }
    .form-select {
      font-size: 16px;
      font-weight: 500;
    }
    textarea {
      height: 160px;
      resize: none;
      overflow-y: auto;
    }
    .CloseBtn {
      position: relative;
      .resetbtn {
        position: absolute;
        top: 45px;
        right: 10px;
        height: 20px;
        width: 20px;
        color: #940e34;
        opacity: 0.6;
        @media (max-width: 1440px) {
          top: 48px;
        }
      }
    }
  }
`;

export const ModalFooter = styled.div`
  .modal-footer {
    border-top: 0px;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0px 20px;
    @media (max-width: 1440px) {
      padding: 0;
    }
    .btn {
      width: 214px;
      padding: 19px;
      margin: 0;
      @media (max-width: 1440px) {
        padding: 15px;
      }
    }
  }
`;
