import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Carousal = ({ item, hostConfig }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  let imageUrls = item?.image_file_name || []; // Handle empty array

  if(typeof imageUrls === 'string') {
    imageUrls = imageUrls?.replace(/{|}/g, '')
    imageUrls = imageUrls?.split(",")
  }

  console.log("imageUrls",imageUrls);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (currentIndex + 1) % imageUrls.length;
      setCurrentIndex(nextIndex);
    }, 3000); // Change interval as needed (in milliseconds)

    return () => clearInterval(intervalId); // Cleanup function
  }, [imageUrls, currentIndex]);

  return (
    <div className="card mb-3" style={{ borderRadius: "0px" }}>
      {imageUrls && Array.isArray(imageUrls) && imageUrls.length  ? (
        <div id="imageCarousel" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            {imageUrls && imageUrls?.map((imageUrl, index) => (
              <li
                key={index}
                data-target="#imageCarousel"
                data-slide-to={index}
                className={index === currentIndex ? 'active' : ''}
              />
            ))}
          </ol>
          <div className="carousel-inner">
            {imageUrls && imageUrls?.map((imageUrl, index) => (
              <div
                key={index}
                className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
              >
                <img
                  className="d-block w-100" // Adjust width as needed
                  src={`${hostConfig.S3_BUCKET}${imageUrl}`}
                  alt="Card image cap"
                  style={{ maxHeight: "300px" }}
                />
              </div>
            ))}
          </div>
          {/* <a
            className="carousel-control-prev carousel-control-dark"
            href="#imageCarousel"
            role="button"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next carousel-control-dark"
            href="#imageCarousel"
            role="button"
            data-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a> */}
        </div>
      ) : (
        <img
          className="card-img-top"
          src={`${hostConfig.S3_BUCKET}${imageUrls}`} // Use first image if only one
          alt="Card image cap"
          style={{ width: "70%" }}
        />
      )}
    </div>
  );
};

export default Carousal;
