/** ****************************** Import libs *********************************** */
import {
  getAllListByIdApi,
  getListByApi,
  getListByIdApi,
  getListPathByApi,
  viewDataByApi,
} from "./actions";
import { URL_CONSTANTS } from "./urls";

export const getUserList = (params) =>
  getListByApi(URL_CONSTANTS.users, params);

export const getAboutusById = (params, id) =>
  getListByApi(URL_CONSTANTS.aboutus, params, id);

//Batches

export const getBatchesList = (params) =>
  getListByApi(URL_CONSTANTS.batches, params);
export const getBatchesById = (params, id) =>
  getListByApi(URL_CONSTANTS.batches, params, id);

export const getSpecificTuitionDetails = (params, id) =>
  getListByApi(URL_CONSTANTS.tlist, params, id);
export const getSpecifictutiondetails = (params, id) =>
  getListByApi(URL_CONSTANTS.tlist, params, id); // TODO: remove
export const getBookingValidation = (params, id) =>
  getListByApi(URL_CONSTANTS.bookingValidation, params, id);

export const getTeacherById = (id) =>
  viewDataByApi(URL_CONSTANTS.teacherUsers, id);

export const getBatchByBatchId = (batchId) =>
  viewDataByApi(URL_CONSTANTS.batches, batchId);

export const getStudyMaterialsForBatch = (batchId) =>
  viewDataByApi(URL_CONSTANTS.studyMaterials, batchId);

export const getStudyMaterialById = (itemId) =>
  viewDataByApi(URL_CONSTANTS.studyMaterialsItem, itemId);

//Students Profile

export const getStudentProfileList = (params) =>
  getListByApi(URL_CONSTANTS.studentsProfile, params);

export const getStudentProfileById = (id) =>
  viewDataByApi(URL_CONSTANTS.studentRegistration, id);

export const getMyBookingId = (id) =>
  viewDataByApi(URL_CONSTANTS.myBooking, id);

//Profile ID GET
export const getTeacherProfileById = (id) =>
  getListPathByApi(URL_CONSTANTS.teacherRegistration, id);

//Review  GET
export const getReviewById = (params) =>
  getListByApi(URL_CONSTANTS.review, params);
export const getStudentReviews = (params) =>
  getListByApi(URL_CONSTANTS.studentReviews, params);
//Review  GET
export const getDashboardById = (params, data) =>
  getListPathByApi(URL_CONSTANTS.dashboard, params, data);
export const getReview = (params) => getListByApi(URL_CONSTANTS.review, params);

// export const getAllDataByTeacherId = (teacherId) =>
// getListByApi(URL_CONSTANTS.review, { teacher_id: teacherId });

export const getAllDataByTeacherId = (params, id) =>
  getAllListByIdApi(URL_CONSTANTS.review, params, id);

//Review  Advertisement
export const getAdvertisementById = (id, params) =>
  getListByIdApi(URL_CONSTANTS.advertisement, id, params);
export const getAdvertisement = (params) =>
  getListByApi(URL_CONSTANTS.advertisement, params);

export const getallreviews = (params) =>
  getListByApi(URL_CONSTANTS.listReview, params);

export const getallposts = (params) =>
  getListByApi(URL_CONSTANTS.listAds, params);
export const gettopposts = (params) =>
  getListByApi(URL_CONSTANTS.topAds, params);
//get all levels
export const getLevels = (params) => getListByApi(URL_CONSTANTS.level, params);
export const getSubject = (params) =>
  getListByApi(URL_CONSTANTS.subjects, params);

// get all subjects
export const getallSubjects = (params) =>
  getListByApi(URL_CONSTANTS.subjects, params);
export const gettopSubjects = (params) =>
  getListByApi(URL_CONSTANTS.topSubjects, params);

// get all tutions
export const getallTutions = (params) =>
  getListByApi(URL_CONSTANTS.popularTution, params);
// get all locations
export const getLocationList = (params) =>
  getListByApi(URL_CONSTANTS.location, params);
// get all areas
export const getAreasList = (params) =>
  getListByApi(URL_CONSTANTS.areas, params);

// get area by city
export const getareaById = (id) => viewDataByApi(URL_CONSTANTS.location, id);

// get blog list
export const getBlogs = (params) => getListByApi(URL_CONSTANTS.blogs, params);
export const getSpecificBlogs = (params) =>
  getListPathByApi(URL_CONSTANTS.blogs, params);

export const getSpecificRentals = (params, id) =>
  getListByApi(URL_CONSTANTS.rental, params, id);
