import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TeamImage from "../../assets/images/team.svg";
import {
  MeetTheTeamWrap,
  Title,
  Description,
  Image,
  TeamRight,
} from "../../pages/AboutUs/aboutusStyle";
import data from "./aboutUsText";

const MeetTheTeam: Function = () => {
  return (
    <>
      <MeetTheTeamWrap>
        <Container>
          <Row>
            <Col md={6} >
              <Image>
                <img src={TeamImage} alt="" />
              </Image>
            </Col>
            <Col md= {6} style={{marginTop: 35}}>
              <TeamRight>
                <Title>Meet the Team</Title>
                <Description>{data.meetTheTeam}</Description>
              </TeamRight>
            </Col>
          </Row>
        </Container>
      </MeetTheTeamWrap>
    </>
  );
};

export default MeetTheTeam;
