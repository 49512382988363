import React, { useState, useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";

import LoginModal from "../../components/LoginModal";
import ModalBookBatch from "./ModalBookBatch";

import { convertRailwayTimeRangeToStandardTimeRange } from "../../utils/helper";

import {
  BatchContainer,
  FieldsContainer,
  BatchField,
  BatchFieldLabel,
  BatchFieldValue,
  ActionContainer
} from "./styledViews";


const BatchItem: React.FC<any> = ({ batch, teacherData, subject }) => {
  const [user, setUser] = useState<any>();
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showBookingModal, setShowBookingModal] = useState<boolean>(false);
  const [isDemo, setIsDemo] = useState<boolean>(false);

  const timing = useMemo(() => convertRailwayTimeRangeToStandardTimeRange(batch.timing), [batch.timing]);

  useEffect(() => {
    const item: any = JSON.parse(localStorage.getItem("loggedUsers") || "{}");
    console.log("user", item);
    if (item.email_id) {
      setUser(item);
    } else {
      setUser(null);
    }
  }, []);

  const loadBookingModal = (isDemo: boolean) => {
    if (!user) {
      setShowLoginModal(true);
    }
    else {
      setIsDemo(isDemo);
      setShowBookingModal(true);
    }
  };

  return (
    <BatchContainer>
      <FieldsContainer>
        <BatchField>
          <BatchFieldLabel>Timing</BatchFieldLabel>
          <BatchFieldValue>{timing}</BatchFieldValue>
        </BatchField>

        <BatchField>
          <BatchFieldLabel>Class</BatchFieldLabel>
          <BatchFieldValue>{batch.level}</BatchFieldValue>
        </BatchField>

        <div style={{ flexGrow: 1 }} />

        <BatchField>
          <BatchFieldLabel>Seats</BatchFieldLabel>
          <BatchFieldValue>{batch.available_seats}</BatchFieldValue>
        </BatchField>

        <BatchField>
          <BatchFieldLabel>Available</BatchFieldLabel>
          <BatchFieldValue>{batch.seats_remaining}</BatchFieldValue>
        </BatchField>
      </FieldsContainer>

      <ActionContainer>
        <Button variant="secondary" onClick={() => loadBookingModal(true)}>
          Attend a Demo Class
        </Button>

        <Button variant="primary" onClick={() => loadBookingModal(false)}>
          Reserve a Seat
        </Button>
      </ActionContainer>

      {showLoginModal && <LoginModal show={true} onHide={() => setShowLoginModal(false)} />}
      {showBookingModal && <ModalBookBatch
        show={true} onHide={() => setShowBookingModal(false)}
        isDemo={isDemo} user={user} teacherData={teacherData} subject={subject} batch={batch} timing={timing}
      />}
    </BatchContainer>
  );
};

export default BatchItem;