import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { LoginUser } from "../../redux/action/loginAction";

import BaseModal from "../Modal/BaseModal";
import Form, { dataType } from "../../pages/Auth/Login/Form";


export const Footer = styled.div`
  margin-top: 30px;

  .sign-up {
    color: #940e34;
    font-weight: 600;
    &:hover {
      color: #012834;
    }
  }
`;

type Props = {
  show: boolean
  onHide: () => void
}

const LoginModal: React.FC<Props> = ({ show, onHide }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const dispatch: any = useDispatch();

  const handleSubmit = (values: dataType) => {
    setErrorMsg("");
    setLoading(true);

    const payload = {
      email_address: values.email,
      password: values.password,
    };

    dispatch(LoginUser(payload, true)).then((res: any) => {
      setLoading(false);

      if (!res) {
        setErrorMsg("Something went wrong, please try again later.");
        return;
      }
      if (res.detail?.credential_verified === false) {
        setErrorMsg("Incorrect Email Id or Password.");
      }
      else if (res.detail?.email_verified === false) {
        setErrorMsg("Please verify your email to get started.");
      }
      else if (res.success) {
        window.location.reload();
      }
      else {
        setErrorMsg("Something went wrong, please try again later.");
      }
    });
  };

  return (
    <BaseModal show={show} onHide={onHide} title="Student Login">
      <Form onFormSubmit={handleSubmit} isLoading={isLoading} submitError={errorMsg} />

      <Footer>
        Don't have an account? &nbsp;
        <Link className="sign-up" to="/auth/register">
          Sign Up
        </Link>
      </Footer>
    </BaseModal>
  );
};

export default LoginModal;