import React from "react";
import { Result } from "antd";
import { ResultStatusType } from "antd/es/result";


type Props = {
  status?: ResultStatusType | undefined
  title?: string | false
  subTitle?: string
}

const PageError: React.FC<Props> = ({ status, title, subTitle }) => {
  return (
    <Result
      style={{ margin: "100px 0 200px" }}
      status={status || "warning"}
      title={title || "Something went wrong."}
      subTitle={subTitle || "Maybe try again later."}
    />
  );
};

export default PageError;