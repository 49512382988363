import React from "react";
import { Tabs, TabsProps } from "react-bootstrap";
import styled from "styled-components";


const Wrapper = styled.div`
  .nav-tabs {
    border: none;
    gap: 24px;

    .nav-item {
      flex-grow: 1;

      .nav-link {
        width: 100%;
        color: #012834;
        font-size: 18px;
        border: none;
        border-radius: 0;
        border-bottom: 3px solid #DCDCDC;
        @media (max-width: 1400px) {
          font-size: 16px;
        }
        @media (max-width: 1400px) {
          font-size: 14px;
        }

        &.active {
          background: transparent;
          color: #940e34;
          border-bottom: 3px solid #940e34;
          font-weight: 600;
        }
      }
    }
  }
`;

const StyledTabs: React.FC<TabsProps> = (props) => {
  return (
    <Wrapper>
      <Tabs {...props} />
    </Wrapper>
  );
};

export default StyledTabs;