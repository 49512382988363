import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import Accordion from "../../../components/TuorPanel/Accordion";
import RoundedButton from "../../../components/TuorPanel/Button";
import { RiFilter2Fill } from "react-icons/ri";
import { GetReviewList } from "../../../redux/action/reviewAction";
import { useDispatch, useSelector } from "react-redux";
import { get } from 'lodash';
import nodata from "../../../assets/images/no-data.png";
import "./review.css";
import { Cookies } from "react-cookie";
import StarRating from "../../../components/TuorPanel/Accordion/starRatings";
import Header from "../../../components/TuorPanel/Header";
import CustomPagination from "../../../components/TuorPanel/Pagination";
import CustomPreloader from "../../../components/TuorPanel/Preloader";
import "./review.css";

const CheckboxGroup = Checkbox.Group;
const plainOptions = [
  {
    label: (
      <>
        <StarRating rating={5} totalStars={5} starSize={12} /> &nbsp; 5 Star
      </>
    ),
    value: 5,
  },
  {
    label: (
      <>
        <StarRating rating={4} totalStars={5} starSize={12} /> &nbsp; 4 Stars
      </>
    ),
    value: 4,
  },
  {
    label: (
      <>
        <StarRating rating={3} totalStars={5} starSize={12} /> &nbsp; 3 Star
      </>
    ),
    value: 3,
  },
  {
    label: (
      <>
        <StarRating rating={2} totalStars={5} starSize={12} /> &nbsp; 2 Star
      </>
    ),
    value: 2,
  },
  {
    label: (
      <>
        <StarRating rating={1} totalStars={5} starSize={12} /> &nbsp; 1 Star
      </>
    ),
    value: 1,
  },
];
const Reviews = () => {
  const [review, setReview] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTooltip, setActiveTooltip] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true); // Initialize loading state
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [searchQuery, setSearchQuery] = useState();
  const currentKey = window.location.href.split("/")[3];

  const search = useSelector(state => state.searchTution);
  const searchValue = get(search, 'data', '');

  if(searchValue?.trim().length){
    if(currentKey === 'reviews') {
      setSearchQuery(searchValue)
    }
  }

  useEffect(() => {
    dispatch({ type: 'SEARCH_HANDLER', data: null });
  }, [])

  const handleFilter = () => {
    setActiveTooltip(!activeTooltip);
  };
  const handlePageChange = (page, pageSize) => {
    console.log(page, pageSize, "pagesize");
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };
  const [selectedRating, setSelectedRating] = useState(6);
  const isAllChecked = plainOptions.length === selectedRating.length;

  const handleCheckAllChange = (e) => {
    setSelectedRating(e.target.checked ? 6 : 0); // Set to 6 when "All Ratings" is checked, otherwise 0
  };

  const handleChange = (values) => {
    setSelectedRating(values);
  };
  console.log(review, "start_count");
  const initialUserData = async () => {
    const userData = cookies.get("TeacherloggedUser");
    const userID = userData;

    dispatch(
      GetReviewList(
        userID?.id,
        searchQuery,
        selectedRating,
        currentPage,
        itemsPerPage
      )
    ).then((res) => {
      setReview(res?.records);
      setTotalItems(res?.total_records || 0);
      setLoading(false); // Set loading to false once data is fetched

    });
  };

  const calculateAverageRating = (reviews) => {
    if (reviews?.reviews?.length === 0) return 0; // Return 0 if there are no reviews

    // Calculate the total sum of star ratings and divide by the number of reviews
    const totalStars = reviews?.reviews?.reduce(
      (acc, review) => acc + review?.start_count,
      0
    );
    const averageRating = totalStars / reviews?.length;

    if (isNaN(averageRating)) return 0; // Handle the case when the result is NaN

    // Round the average to a specific number of decimal places, e.g., 2
    return averageRating.toFixed(2);
  };

  const averageRating = calculateAverageRating(review);
  // console.log(+averageRating, "averageRating");
  useEffect(() => {
    setLoading(true); // Set loading to true before fetching data
    initialUserData();
  }, [searchQuery, currentPage, itemsPerPage, selectedRating]);

  useEffect(() => {
    // Check if averageRating is not null and not NaN
    if (averageRating !== null && !isNaN(averageRating)) {
      // Store the updated averageRating in local storage
      localStorage.setItem("Average Rating", +averageRating);
    }
  }, [averageRating]);

  return (
    <div className="w-100">
      <div className="review-page">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="prime-text fw-bolder mb-0" style={{ fontSize: '20px' }}>My Reviews</h6>
          <RoundedButton
            variant="secondary"
            icon={<RiFilter2Fill />}
            onClick={handleFilter}
          >
            Filter
          </RoundedButton>
          {activeTooltip && (
            <div className="filter-tooltip">
              <Checkbox
                indeterminate={!isAllChecked && selectedRating !== 6}
                checked={selectedRating === 6}
                onChange={handleCheckAllChange}
              >
                All Ratings
              </Checkbox>
              <div className="d-grid">
                {plainOptions.map((option) => (
                  <Checkbox
                    key={option.value}
                    value={option.value}
                    checked={selectedRating === option.value}
                    onChange={() => handleChange(option.value)}
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </div>
            </div>
          )}
        </div>
        {loading ? (
          <CustomPreloader />
        ) : (
          <div>
            {review?.reviews?.length > 0 ? (
              <Accordion items={review.reviews} />
            ) : (
              <div className="d-flex justify-content-center">
                <img src={nodata} alt="no-data" width={350} />
              </div>
            )}
          </div>
        )}
      </div>
        <div className="review-pagination">
          <CustomPagination
            total={totalItems}
            currentPage={currentPage}
            onPageChange={handlePageChange} // Pass handlePageChange here
            itemsPerPage={itemsPerPage}
          />
        </div>
    </div>
  );
};

export default Reviews;
