import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

export default function PostRentalModel({ open, handleClose ,setActive, state}) {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate("/view-service",{ state: { ...state.state,postYourRental:false }});
        setActive(false)
        // window.location.reload()
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ color: "#940E34" }}
                    >
                        Thank you for posting your Rental
                        <br />
                        Click OK to navigate to your listing page
                    </Typography>
                    <button
                        type="button"
                        style={{
                            width: "40%",
                            marginTop: "30px",
                            padding: "10px",
                            borderRadius: "20px",
                            color: "white",
                            background: "#940E34",
                            marginLeft: "6rem",
                            fontWeight: "bold",
                        }}
                        onClick={() => {
                            handleNavigate();
                        }}
                    >
                        OK
                    </button>
                </Box>
            </Modal>
        </div>
    );
}
