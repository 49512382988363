/** ****************************** Import libs *********************************** */
import { deleteDataByIdApi, deleteByIdApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const deleteSubject = (id) =>
  deleteDataByIdApi(URL_CONSTANTS.subjectlist, id);

export const deleteQuestion = (id, dataId) =>
  deleteByIdApi(URL_CONSTANTS.question, id, dataId);

// export const deleteBadches = (id, dataId) =>
//   deleteByIdApi(URL_CONSTANTS.batches, id, dataId);

//Batches
export const deleteBadches = (id) => deleteDataByIdApi(URL_CONSTANTS.batches, id);
//Students Profile
export const deleteStudentProfile = (id) => deleteDataByIdApi(URL_CONSTANTS.studentsProfile, id);
//Teachers Profile
export const deleteProfile = (id) => deleteDataByIdApi(URL_CONSTANTS.teacherRegistration, id);
//Teachers Profile
export const deleteReview = (id) => deleteDataByIdApi(URL_CONSTANTS.review, id);
//Advertisement POST
export const deleteAdvertisement = (id) => deleteDataByIdApi(URL_CONSTANTS.advertisement, id);
