import React, { useState, useEffect } from "react";
import _ from "lodash";

import { getSpecificTuitionDetails } from "../../api/list";
import { hostConfig } from "../../config";

import Header from "../../components/Header";
import PageBreadcrumb from "../../components/TuitionDetails/tuitionBreadCrumbs";
import PageSpinner from "../../components/PageSpinner";
import PageError from "../../components/PageError";
import ProfileSummary from "./ProfileSummary";
import ProfileSubjects from "./ProfileSubjects";
import Footer from "../../components/Footer";

import {
  MainContainer
} from "./styledViews";


const TuitionProfile: React.FC<any> = () => {
  const [isPageLoading, setPageLoading] = useState<boolean>(true);
  const [pageError, setPageError] = useState<string|boolean>(false);
  const [profile, setProfile] = useState<any>(null);

  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  console.log("urlSearchParams", urlParams);

  const getTuitionDetails = () => {
    let params = {
      teacher_id: parseInt(urlParams.TEACHER_ID),
      subject: urlParams.SUBJECT_NAME,
    };
    getSpecificTuitionDetails(params).then((res) => {
      if(res.error || !res.success) {
        setPageLoading(false);
        setPageError("Failed to fetch details.");
        return;
      }
      setProfile(res.records);
      setPageLoading(false);
      console.log("##### res", res);
    });
  };

  useEffect(() => {
    if(!urlParams || !urlParams.TEACHER_ID) {
      setPageLoading(false);
      setPageError(true);
      return;
    }

    getTuitionDetails();
  }, []);

  const calculateOverallReviewCount = (
    totalReviewStarCount: any,
    reviewStudentCount: any
  ) => {
    if (reviewStudentCount === 0) {
      return 0; // Return 0 when dividing by 0
    }
    if (totalReviewStarCount === 0) {
      return 0; // Return 0 when dividing by 0
    }
    if (isNaN(totalReviewStarCount) || isNaN(reviewStudentCount)) {
      return 0; // Return an error message for non-numeric inputs
    }
    const result = totalReviewStarCount / reviewStudentCount; // Calculate the result as a number
    const isInteger = result % 1 === 0;
    if (isInteger) {
      return result.toFixed(0);
    } else {
      return result.toFixed(1);
    }
  };

  const getRatingsText = () => {
    const rating = calculateOverallReviewCount(profile.overall_review_stats.total_start_count, profile.overall_review_stats.student_count);
    return <span>{rating} / 5 &nbsp; | &nbsp; {profile.overall_review_stats.student_count} review(s)</span>;
  };

  return (
    <MainContainer>
      <Header />
      <PageBreadcrumb />

      {(isPageLoading && <PageSpinner />) ||
      (pageError && <PageError title={_.isString(pageError) && pageError} />) ||
      (<>
        <ProfileSummary
          logoUrl={`${hostConfig.S3_BUCKET}${profile.teacher_details.profile_filename}`}
          title={profile.teacher_details.tuition_name}
          city={profile.teacher_details.city}
          phone={profile.teacher_details.mobile_number}
          email={profile.teacher_details.email_id}
          website={profile.teacher_details.website}
          description={profile.teacher_details.discription}
          ratings={getRatingsText()}
          education={profile.teacher_details.education}
          availability={"6:00 PM"}
          averageFees={profile.teacher_details.fee_per_month}
          videoUrl={profile.teacher_details.intro_video_url}
          thumbUrl={profile.teacher_details.intro_thumb_url}
        />

        <ProfileSubjects
          teacherData={profile.teacher_details}
          subjects={profile.available_subject}
          batches={profile.batch}
          ratings={profile.overall_review_stats}
        />

        <Footer />
      </>)}
    </MainContainer>
  );
};

export default TuitionProfile;