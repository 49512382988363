import { FaBell } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

const StyledBellIcon = styled(FaBell)`
  font-size: 24px; /* Adjust the size as needed */
  position: relative;
`;

const NotificationBadge = styled.span`
background-color: #74c5d7;
color: white;
border-radius: 50%;
padding: 1px 5px;
position: absolute;
font-size: 9px;
left: 12px;
border: 2px solid white;
top: -4px;
`;

const NotificationContainer = styled.div`
width:15%;
display:flex;
justify-content: space-evenly;
align-items:center;
margin:0.86rem 0rem;
`;




export {
    StyledBellIcon,
    NotificationBadge,
    NotificationContainer
}