import React from "react";
import "./preloader.css";
const CustomPreloader = ({ className }) => {
  return (
    <div className={className}>
      <div class="circles-preloader">
        <div class="circles circle-1"></div>
        <div class="circles circle-2"></div>
        <div class="circles circle-3"></div>
        <div class="circles circle-4"></div>
        <div class="circles circle-5"></div>
        <div class="circles circle-6"></div>
      </div>
    </div>
  );
};

export default CustomPreloader;
