import Image1 from "../../assets/images/Teacher Sample Image 1.jpg";
import Image2 from "../../assets/images/Teacher Sample Image 2.jpg";
import Image3 from "../../assets/images/teacherImage.jpg";
import Image4 from "../../assets/images/profile1.png";
import Image5 from "../../assets/images/profile1.png";
import Image6 from "../../assets/images/profile1.png";

const TeacherInfo: any = [
  {
    searchList:
      "The Viks Coaching Institute is a progressive, independent Institute located in Biharsharif. Since its establishment in 2015. Our aim to give our children a complete educational experience, with outstanding academic teaching in an excellent pastoral setting. VMPA provides classroom coaching; correspondence courses; online testing; test series; admission guidance; campus coaching; etc. These services are available to students of Classes 5th 6th 7th 8th 9th 10th 11th and 12th for subjects like Math, Science, English,social science, Accountancy, Physics, Chemistry and Biology to prepare them for board exams as well as for the School Exams",
    instituteName: "Viks Coaching Institute",
    located:
      "10+ years in business · MG Road, Behind Aashirwad Vastralaya, Naaz Cinema Rd, near SBI Bazar Branch",
    onlineOrOfflineClass: "Onsite services · Online classes",
    contactDetails: "+91 78089 00900",
    teacherId: "100",
    teacherName: "AAAA",
    subjectName: "CHEMISTRY",
    cityName: "BIHAR SHARIF",
    locationName: "BHARAOPAR",
    cost: "2000",
    className: "1ST",
    phoneNumber: "9408152104",
    email: "shreyash.cmarix@gmail.com",
    // website: "www.cmarix.com",
    rating: "4.1",
    review: "115",
    photo: Image1,
  },
  {
    searchList:
      "Real Chemistry Coaching Institute is premier coaching institute for IIT Jee Preparation. We have highly qualifed teacher to mentor students.",
    instituteName: "Real Chemistry Coaching Institute",
    located: "8+ years in business · Palika Market, Ranchi Rd",
    onlineOrOfflineClass: "Offline classes",
    contactDetails: "+91 7016566323",
    teacherId: 101,
    teacherName: "Real Chemistry teacher",
    subjectName: "Chemistry",
    cityName: "ahmedabad",
    cost: "4000",
    locationName: "gujarat",
    className: "1ST",
    phoneNumber: "+91 9402225524",
    email: "realchemistry@gmail.com",
    website: "www.realchemistry.com",
    rating: "5",
    review: "500",
    photo: Image5,
  },
  {
    searchList:
      "Unacademy Centre Institute is premier coaching institute for IIT Jee Preparation.Real Chemistry Coaching Institute is premier coaching institute for IIT Jee Preparation. We have highly qualifed teacher to mentor students.Real Chemistry Coaching Institute is premier coaching institute for IIT Jee Preparation. We have highly qualifed teacher to mentor students.Real Chemistry Coaching Institute is premier coaching institute for IIT Jee Preparation. We have highly qualifed teacher to mentor students.",
    instituteName: "Unacademy Centre",
    located: "5+ years in business · Palika Market, Ranchi Rd",
    onlineOrOfflineClass: "Online classes",
    contactDetails: "+91 8320335525",
    teacherId: 101,
    teacherName: "Unacademy Maths teacher",
    subjectName: "Maths",
    cityName: "ahmedabad",
    cost: "12000",
    locationName: "gujarat",
    className: "2ND",
    phoneNumber: "+91 8336254125",
    email: "unacademy@gmail.com",
    website: "www.unacademy.com",
    rating: "5",
    review: "550",
    photo: Image6,
  },
  {
    searchList:
      "Adhyayan Coaching Institute is premier coaching institute for IIT Jee Preparation. We have highly qualifed teacher to mentor students.",
    instituteName: "Adhyayan Coaching Institute",
    located: "15+ years in business · Palika Market, Ranchi Rd",
    onlineOrOfflineClass: "Online classes",
    contactDetails: "+91 8320361177",
    teacherId: 101,
    teacherName: "Adhyayan teacher",
    subjectName: "English",
    cityName: "ahmedabad",
    cost: "5000",
    locationName: "gujarat",
    className: "1ST",
    phoneNumber: "+91 9408152104",
    email: "adhyayan@gmail.com",
    rating: "5",
    review: "500",
    photo: Image4,
  },
  {
    searchList:
      "Ahmedabad Coaching Institute is premier coaching institute for IIT Jee Preparation. We have highly qualifed teacher to mentor students.",
    instituteName: "Ahmedabad Coaching Institute",
    located: "14+ years in business · Palika Market, Ranchi Rd",
    onlineOrOfflineClass: "Offline classes",
    contactDetails: "+91 9408152104",
    teacherId: 101,
    teacherName: "Ahmedabad teacher",
    subjectName: "English",
    cityName: "ahmedabad",
    cost: "5000",
    locationName: "gujarat",
    className: "1ST",
    phoneNumber: "+91 8320361177",
    email: "ahmedabad@gmail.com",
    rating: "5",
    review: "500",
    photo: Image4,
  },
  {
    searchList:
      "Indian Coaching Institute is premier coaching institute for IIT Jee Preparation. We have highly qualifed teacher to mentor students.",
    instituteName: "Indian Coaching Institute",
    located: "1+ years in business ·  Ranchi Rd",
    onlineOrOfflineClass: "Online classes",
    contactDetails: "+91 9408152104",
    teacherId: 101,
    teacherName: "Ahmedabad teacher",
    subjectName: "Marathi",
    cityName: "India",
    cost: "2000",
    locationName: "India",
    className: "1ST",
    phoneNumber: "+91 8320361177",
    email: "india@gmail.com",
    rating: "5",
    review: "500",
    photo: Image4,
  },
  {
    searchList:
      "latest Coaching Institute is premier coaching institute for IIT Jee Preparation. We have highly qualifed teacher to mentor students.",
    instituteName: "latest Coaching Institute",
    located: "14+ years in business , Ranchi Rd",
    onlineOrOfflineClass: "Offline classes",
    contactDetails: "+91 9408152104",
    teacherId: 101,
    teacherName: "latest teacher",
    subjectName: "English",
    cityName: "Pune",
    cost: "4000",
    locationName: "gujarat",
    className: "1ST",
    phoneNumber: "+91 8320361177",
    email: "latest@gmail.com",
    rating: "4",
    review: "400",
    photo: Image4,
  },
];

export default TeacherInfo;
