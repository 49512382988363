import { Formik } from "formik";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CloseEye from "../../assets/images/eye-off-icon.svg";
import OpenEye from "../../assets/images/eye-open-icon.svg";
import EmailVerifyModal from "../../components/EmailverifyModal";
import {
  BookModalBody,
  BookTuitionHeader,
  ModalFooter,
  SignUpText,
} from "../../pages/tuitionList/tuitionlistStyle";
import { LoginUser } from "../../redux/action/loginAction";
// import { loginUserAction } from "../../redux/action/login/login-slice";

const LoginModal = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const register = JSON.parse(localStorage.getItem("register") || "{}");

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const navigateToRegister = () => {
    navigate("/register");
  };

  const submitForm = (values) => {
    // const tempData = register.filter((item) => {
    //   return (
    //     item.emailId === values.emailInfo &&
    //     item.createPassword === values.passwordInfo
    //   );
    // });
    // console.log(tempData, "tempDtaaaaaaaa");
    const data = {
      email_address: values?.emailInfo,
      password: values?.passwordInfo,
    };
    dispatch(LoginUser(data, false)).then((res) => {
      setError("");
      console.log(res, "returnedresponce true");
      if (res) {
        window.location.reload();
        // toast.success("Logged in sucessfully");
      } else if (res === undefined) {
        setError("Incorrect email Id or password");
        handleOpen();
      } else if (!res?.detail?.success) {
        console.log("====================================");
        console.log(res);
        console.log("====================================");
        setError("Please Verify your email");
        handleOpen();
      }
    });

    // if (tempData.length === 1) {
    //   props.setLoginModal(false);
    //   if (props.test) {
    //     props.setModalShow(false);
    //   } else {
    //     props.setModalShow(true);
    //   }
    //   if (props.popUpValue.date) {
    //     // props.setPopUpValue({ date: "", radio: "" });
    //     props.setModalShow(true);
    //   } else {
    //     props.setModalShow(false);
    //   }
    // } else {
    //   toast.error("Please enter valid email id or password");
    // }
  };

  const isValidInfo = (values) => {
    let isValid = false;

    if (values.emailInfo.length > 0) {
      isValid = true;
    } else if (values.passwordInfo.length > 0) {
      isValid = true;
    }
    return isValid;
  };

  return (
    <>
      <Modal
        show={props.loginModal}
        onHide={() => props.setLoginModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <style>
          {`.modal-dialog{max-width:calc(100% - 40px);width:782px;letter-spacing:0;  @media (max-width: 575px) {
              max-width: calc(100% );
              margin: auto;
              padding: 10px;
            }}.modal-content{background:#FFFFFF;border-radius:30px;border:none;box-shadow:0px 3px 8px rgba(0,0,41,0.16);}
            @media(max-width:1440px){.modal-dialog{width:582px;}}`}
        </style>
        <BookTuitionHeader>
          <Modal.Header closeButton>
            <Modal.Title>Student Login</Modal.Title>
          </Modal.Header>
        </BookTuitionHeader>
        <BookModalBody>
          <Modal.Body>
            <Formik
              initialValues={{
                emailInfo: "",
                passwordInfo: "",
              }}
              validate={(values) => {
                let errors = {};

                if (!values.emailInfo) {
                  errors.emailInfo =
                    "Enter your Email Id or your Mobile number.";
                }

                if (!values.passwordInfo) {
                  errors.passwordInfo = "Please enter your password";
                }

                return errors;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="form-group">
                          <Form.Label>Email Id / Mobile</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Here"
                            id="emailInfo"
                            name="emailInfo"
                            autoComplete="off"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={values.emailInfo}
                            onBlur={handleBlur}
                          />
                          <div className="error">
                            {touched.emailInfo && errors.emailInfo && (
                              <p>{errors.emailInfo}</p>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group">
                          <Form.Label>Password</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              placeholder="********"
                              id="passwordInfo"
                              name="passwordInfo"
                              autoComplete="off"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={values.passwordInfo}
                              onBlur={handleBlur}
                            />
                            <Button
                              className="icon-wrap"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <img
                                  src={CloseEye}
                                  alt="close eye"
                                  className="close-eye"
                                />
                              ) : (
                                <img
                                  src={OpenEye}
                                  alt="Open eye"
                                  className="open-eye"
                                />
                              )}
                            </Button>
                          </InputGroup>
                          <div className="error">
                            {touched.passwordInfo && errors.passwordInfo && (
                              <p>{errors.passwordInfo}</p>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <ModalFooter>
                      <Modal.Footer>
                        <Button
                          type="submit"
                          disabled={
                            values.emailInfo === "" ||
                            values?.passwordInfo === ""
                          }
                          variant={
                            isValidInfo(values)
                              ? "secondary active"
                              : "secondary"
                          }
                        >
                          Login
                        </Button>
                      </Modal.Footer>
                      <SignUpText>
                        Don’t have an account?{" "}
                        <Link
                          to="/register"
                          className="signup"
                          onClick={navigateToRegister}
                        >
                          Sign up
                        </Link>
                      </SignUpText>
                    </ModalFooter>
                  </form>
                </>
              )}
            </Formik>
          </Modal.Body>
        </BookModalBody>
      </Modal>
      {open ? (
        <EmailVerifyModal open={open} handleClose={handleClose} error={error} />
      ) : null}
    </>
  );
};
export default LoginModal;
