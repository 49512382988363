/** **************************** Import Types ****************************** */
import "react-toastify/dist/ReactToastify.css";
import {
  DASHBOARD_REQUEST,
  DASHBOARD_FAILURE,
  DASHBOARD_SUCCESS,
} from "../types/dashboardType.js";
import { getDashboardById } from "../../api/list";

export const dashboardRequest = () => ({
  type: DASHBOARD_REQUEST,
});
export const dashboardSuccess = (users) => ({
  type: DASHBOARD_SUCCESS,
  payload: users,
});
export const dashboardFailure = (error) => ({
  type: DASHBOARD_FAILURE,
  payload: error,
});

export const GetDashboardById = (userId, data) =>
  async function (dispatch) {
    dispatch(dashboardRequest());
    const params = {
      selected_date: data,
    };
    return getDashboardById(userId, params)
      .then((res) => {
        if (res) {
          dispatch(dashboardSuccess(res.records));
          return res;
        }
        dispatch(dashboardFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
