import React from "react";
import { Flex, Spin } from "antd";


type Props = {
  fullHeight?: boolean
  height?: number
}

const PageSpinner: React.FC<Props> = ({ fullHeight, height = 400 }) => {
  return (
    <Flex justify="center" align="center" style={{ height: fullHeight ? "100%" : height }}>
      <Spin size="large" />
    </Flex>
  );
};

export default PageSpinner;