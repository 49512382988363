// ********import Libs ******//
import { combineReducers } from "redux";

// ******* Reducers  *****//
import { loginReducer } from "./reducer/loginReducer";
import { teacherLoginReducer } from "./reducer/tloginReducer";
import badgeReducer from "./reducer/badgeReducer";
import studentProfileReducer from "./reducer/studentProfile";
import studentProfilePageReducer from "./reducer/studentProfilePageReducer";
import bookDemoReducer from "./reducer/bookDemoReducer";
import profileReducer from "./reducer/profileReducer";
import reviewReducer from "./reducer/reviewReducer";
import advertisementPostReducer from "./reducer/advertisementReducer";
import { searchTutionReducer } from "./reducer/searchTution";
import otpReducer from "./reducer/otpReducer";
import myBookingReducer from "./reducer/myBookingReducer";
import paymentReducer from "./reducer/paymentReducer";
import rentalReducer from "./reducer/rentalReducer";
const rootReducer = combineReducers({
  Login: loginReducer,
  teacher: teacherLoginReducer,
  badge: badgeReducer,
  studentProfile: studentProfileReducer,
  studentProfilePage: studentProfilePageReducer,
  bookDemo: bookDemoReducer,
  profile: profileReducer,
  review: reviewReducer,
  advertisement: advertisementPostReducer,
  searchTution: searchTutionReducer,
  otp: otpReducer,
  myBooking: myBookingReducer,
  payment: paymentReducer,
  rental: rentalReducer,
});

export default rootReducer;
