import React, { FC, ReactNode } from "react";
import ReactDOM from "react-dom";
import { GrClose } from "react-icons/gr";
import {
  ModalOverlay,
  ModalWrapper,
  ModalContent,
  Card,
  CardHeader,
  ModalHeader,
  ModalCloseButton,
  UserName,
  TitleHeader,
  ModalBody,
  DeleteWrapper,
  CloseBtn,
} from "../Modal/modalStyle"; // Adjust the import path based on your project structure
import RoundedButton from "../Button";
import { MdDelete } from "react-icons/md";

interface ModalProps {
  isShowing: boolean;
  hide: () => void;
  title: string;
  width?: string;
  handleDelete: () => void; // Define the type of handleDelete
  info?: string;
  marginTop?: string;
  marginBottom?: string;
  userName?: string;
  backgroundColor?: string;
  color?: string;
  iconColor?: string;
  children?: ReactNode;
}

const DeleteModal: FC<ModalProps> = ({
  isShowing,
  hide,
  title,
  width,
  marginTop,
  info,
  marginBottom,
  handleDelete,
  userName,
  backgroundColor,
  color,
  iconColor,
  children,
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <ModalOverlay>
            <ModalWrapper>
              <ModalContent
                style={{
                  width: width,
                }}
              >
                <Card>
                  <CardHeader
                    style={{
                      marginTop: marginTop,
                      marginBottom: marginBottom,
                      backgroundColor: "#fff",
                      color: color,
                    }}
                  >
                    <ModalHeader>
                      <div></div>
                      <ModalCloseButton onClick={hide}>
                        <CloseBtn>
                          <GrClose />
                        </CloseBtn>
                      </ModalCloseButton>
                    </ModalHeader>
                  </CardHeader>
                  <ModalBody>
                    <div className="d-flex justify-content-center my-4">
                      {/* <DeleteWrapper>
                        <MdDelete className="fs-1 prime-text" />
                      </DeleteWrapper> */}
                    </div>
                    <strong className="text-center d-block h6">{info}</strong>
                    <div className="m-4 text-left d-flex justify-content-center">
                      <RoundedButton
                        variant="secondary"
                        className="w-25"
                        onClick={hide}
                      >
                        Cancel
                      </RoundedButton>
                      <RoundedButton
                        variant="primary"
                        className="w-25"
                        onClick={handleDelete}
                      >
                        Yes
                      </RoundedButton>
                    </div>
                  </ModalBody>
                </Card>
              </ModalContent>
            </ModalWrapper>
          </ModalOverlay>
        </>,
        document.body
      )
    : null;

export default DeleteModal;
