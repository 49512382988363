import React from "react";
import "./searchBar.css";
import { BiSearch } from "react-icons/bi";
import store from "../../../redux/store";
const Searchbar = () => {  
  const dispatch = store.dispatch;

  const handleSearchInputChange = (e) => {
    console.log("MMMM");
  };

  return (
    <div class="search-box mx-4">
      <input
        type="text"
        class="search-input"
        placeholder="Search"
        onChange={handleSearchInputChange}
      />
      <button class="search-button">
        {/* <i class="fas fa-search"></i> */}
        <BiSearch />
      </button>
    </div>
  );
};

export default Searchbar;
