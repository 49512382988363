import React from "react";
import TheCompanySection from "../../components/AboutUs/theCompanySection";
import VissionMission from "../../components/AboutUs/vissionMission";
import MeetTheTeam from "../../components/AboutUs/meetTheTeam";
import Difference from "../../components/AboutUs/difference";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Banner from "../../components/AboutUs/banner";
import ContactUs from "../../components/AboutUs/contactus";
import {} from "./aboutusStyle";

const AboutUs: Function = () => {
  return (
    <>
      <Header />
      <Banner />
      <TheCompanySection />
      <VissionMission />
      <MeetTheTeam />
      <ContactUs/>
      <Difference />
      <Footer />
    </>
  );
};

export default AboutUs;
