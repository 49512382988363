import React, { useState } from "react";
import "./table.css"; // You can define your own CSS for styling
import { FaSort } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import nodata from "../../../assets/images/no-data.png";

const Table = ({
  data,
  columns,
  handleEdit,
  handleMessage,
  handleDelete,
  totalItems,
  itemsPerPage,
  handlePageChange,
  currentPage,
  handleDeactive,
  handleActive,
  id,
  setId,
  setdisableData,
}) => {
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [activeTooltips, setActiveTooltips] = useState(
    new Array(data?.length).fill(false)
  );
  const [checkall, setCheckAll] = useState(false);
  const [singleCheck, setSingleCheck] = useState(false);
  const [activeTooltipIndex, setActiveTooltipIndex] = useState(-1);
  const [checkData, setCheckData] = useState(
    data?.map((ev) => ({
      ...ev,
      checked: false,
    }))
  );

  // let checkData = data?.map((ev) => ({
  //   ...ev,
  //   checked: false,
  // }));
  console.log("checkData for id", id);

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortDirection("asc");
    }
  };

  const toggleTooltip = (rowIndex) => {
    const updatedTooltips = [...activeTooltips];
    updatedTooltips[rowIndex] = !updatedTooltips[rowIndex];
    setActiveTooltips(updatedTooltips);
    if (activeTooltipIndex === rowIndex) {
      // Clicking the same row's three-dot icon again, so close the tooltip
      setActiveTooltipIndex(-1);
    } else {
      // Clicking a different row's three-dot icon, so open its tooltip
      setActiveTooltipIndex(rowIndex);
    }
  };

  const sortedData = sortedColumn
    ? [...checkData].sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];

        // Handle different data types
        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortDirection === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        } else {
          return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
        }
      })
    : checkData;

  const showOverflow = sortedData?.length > 10;

  const handleCheck = (e) => {
    setCheckAll(e.target.checked);
    if (e.target.checked) {
      let chkArr = checkData?.map((ev) => ({
        ...ev,
        checked: e.target.checked,
      }));
      console.log(chkArr, "checkedArr");
      let val = chkArr?.map((ev) => ev.id);
      setCheckData(chkArr);
      setId(val.toString());
      setdisableData(true);
    } else {
      let chkArr = checkData?.map((ev) => ({
        ...ev,
        checked: e.target.checked,
      }));
      console.log(chkArr, "checkedArr");
      setCheckData(chkArr);
      setId([]);
      setdisableData(false);
    }
  };

  const handleSingleCheck = (e, item) => {
    let check = checkData.map((d) => {
      const newModulesDataByRow = { ...d };
      if (newModulesDataByRow.id === item?.id) {
        newModulesDataByRow.checked = e.target.checked;
      }
      return newModulesDataByRow;
    });
    console.log(check, "chekccccc");
    setCheckData(check);
    setId([...id, item?.id.toString()]);
    if (e.target.checked) {
      setdisableData(true);
    } else {
      setdisableData(false);
    }
  };

  console.log(data, "datadatadatadata");

  return (
    <div
      className={`table-body-container table-container ${
        showOverflow ? "overflow-y" : ""
      }`}
    >
      <table className="responsive-table">
        <thead>
          <tr>
            <th className="text-center">
              {" "}
              <input type="checkbox" onChange={(e) => handleCheck(e)}></input>
            </th>
            {columns.map((column, index) => (
              <th
                className="table-datas"
                key={index}
                onClick={() => handleSort(column.field)}
              >
                {column.header} &nbsp;
                <FaSort className="prime-text" />
              </th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="responsive-table-body">
          {sortedData?.length === 0 ? (
            // <tr>
            //   <td colSpan={columns.length + 1} className="text-center border-0">
            //     <img src={nodata} alt="no-data" width={350} />
            //   </td>
            // </tr>
            ''
          ) : (
            sortedData?.map(
              (item, rowIndex) => (
                console.log(item, "itemmmmmmmmm"),
                (
                  <tr
                    key={rowIndex}
                    className={rowIndex % 2 === 0 ? "even-row" : "odd-row"}
                  >
                    <td className="actions table-datas text-center">
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={(e) => handleSingleCheck(e, item)}
                      ></input>
                    </td>
                    {columns.map(
                      (column, colIndex) => (
                        console.log(column, colIndex, "12132435455"),
                        (
                          <td
                            key={colIndex}
                            // className="table-datas"
                            className={`${
                              item?.is_active ? "active" : "in-active"
                            } table-datas`}
                          >
                            {item[column.field]?.toString()}
                          </td>
                        )
                      )
                    )}
                    <td className="actions table-datas text-center">
                      <div
                        className={
                          rowIndex % 2 === 0
                            ? "action-btn odd-row rounded-circle prime-text"
                            : "action-btn rounded-circle even-row prime-text"
                        }
                        onClick={() => toggleTooltip(rowIndex)}
                      >
                        <BsThreeDotsVertical />
                      </div>
                      {activeTooltipIndex === rowIndex && item?.is_active ? (
                        <div className="tooltip-box">
                          <p
                            className="border-bottom text-left m-0 p-2"
                            onClick={() => handleEdit(item, rowIndex)}
                            role="button"
                          >
                            Edit
                          </p>
                          <p
                            className="border-bottom text-left m-0 p-2"
                            onClick={() => handleDelete(item)}
                            role="button"
                          >
                            Delete
                          </p>
                          <p
                            className="border-bottom text-left m-0 p-2"
                            onClick={() => {
                              setActiveTooltipIndex(-1);
                              handleMessage(item, rowIndex)}}
                            role="button"
                          >
                            Message
                          </p>
                          <p
                            className="border-bottom text-left m-0 p-2"
                            onClick={() => handleDeactive(item, rowIndex)}
                            role="button"
                          >
                            De-Active
                          </p>
                          
                        </div>
                      ) : (
                        activeTooltipIndex === rowIndex &&
                        !item.is_active && (
                          <div className="tooltip-box">
                            <p
                              className="border-bottom text-left m-0 p-2"
                              onClick={() => handleActive(item, rowIndex)}
                              role="button"
                            >
                              Active
                            </p>
                          </div>
                        )
                      )}
                    </td>
                  </tr>
                )
              )
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
