import styled from "styled-components";


export const MainContainer = styled.div`
  margin-left: 300px;
  padding: 30px;

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

export const MainTitle = styled.h2`
  margin-top: 10px;
`;

export const ItemListContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const ItemAddContainer = styled.div`
  width: 350px;
  height: 309px;
  border: 2px dashed rgba(0,0,0,0.15);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-size: 40px;
  color: rgba(0,0,0,0.2);

  &:hover {
    border: 2px dashed rgba(0,0,0,0.3);
    color: rgba(0,0,0,0.4);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ItemContainer = styled.div`
  position: relative;
  width: 350px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ItemIconContainer = styled.div`
  height: 80px;
  width: 80px;
  min-width: 80px;
  border: 2px solid #940E34;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 35px;
  color: #940E34;
`;

export const ItemTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
`;

export const ItemInfoContainer = styled.div`
  display: flex;
  margin-top: 30px;
  font-size: 12px;
  color: rgba(0,0,0,0.6);
`;

export const ItemEditButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  .ant-btn:hover {
    border-color: #940E34 !important;
    color: #d2144a !important;
  }
`;

// Form Components
export const InputLabel = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: rgba(0,0,0,0.4);
  margin-bottom: 8px;
  margin-left: 10px;
`;

export const SubmitContainer = styled.div`
  width: 200px;

  .ant-btn {
    height: 48px;
    background: #940E34;

    &:hover {
      background: #d2144a !important;
    }
    &:disabled {
      background: rgba(0,0,0,0.1);
      &:hover {
        background: rgba(0,0,0,0.1) !important;
      }
    }
  }
`;