import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { Title } from "../AboutUs/aboutusStyle";



const RefundPolicy: React.FC = () => {
    useEffect(() => {
        document.getElementById('root')?.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-white">
            <Header />

            <div className="container">
        
                <div style={{ marginTop: 100, marginBottom: 200, fontSize: 20 }}>
            
                    <Title style={{ textAlign:"center", marginBottom: 40 }}>Refund & Cancellation Policy</Title>

                    <ul>
                        <li>Our policy is valid for a period of 7 calendar days from the date of the purchase. If you are not satisfied with the service, you can ask for a refund. If the period of 7 days has lapsed since the purchase, we can’t, unfortunately, offer you a refund.</li>

                        <li>To be eligible for refund, you must NOT have used the service.</li>

                        <li>If the conditions listed above are not met, we reserve the right not to issue a refund.</li>

                        <li>If eligible for refund, the booking amount paid via our website - <a href="https://www.tuitionmitra.com">www.tuitionmitra.com</a> - will be refunded in 7 working days.</li>
                    </ul>

                    <p style={{ marginTop: 60 }}>The owner of Tuition Mitra is <em>Sumit Singh</em>, founder of EthosITy Solutions Pvt Ltd, who owns this website.</p>

                    <p>If you have any questions, concerns or complaints regarding this refund policy, we encourage you to contact us at <a href="mailto:ethosity.solutions@gmail.com">ethosity.solutions@gmail.com</a>.</p>
                </div>
            </div>
            
            <Footer />
        </div>
    );
}

export default RefundPolicy;