import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  CompanyWrap,
  Title,
  Description,
} from "../../pages/AboutUs/aboutusStyle";
import data from "./aboutUsText";

const TheComapny: Function = () => {
  return (
    <>
      <CompanyWrap>
        <Container>
          <Row>
            <Col sm={12}>
              <Title>The Company</Title>
              <Description>{data.theCompany}</Description>
            </Col>
          </Row>
        </Container>
      </CompanyWrap>
    </>
  );
};

export default TheComapny;
