import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import ViewService from "../../components/ViewService";
import Grid from "@mui/material/Grid";
import ServiceList from "./serviceList";
import ServiceLocations from "./serviceLocations";
import { FaChevronDown, FaList } from "react-icons/fa";
import { postRental } from "../../api/create";
import { FaMapMarker } from "react-icons/fa";
import { AutoComplete, Select } from "antd";
import "./availableservice.css";
import DynamicTabs from "../../components/Tab";
import Footer from "../../components/Footer";
import {
  getLocationList,
  getSpecificRentals,
  getareaById,
} from "../../api/list";
import { Form, Formik, useFormik } from "formik";
import { toast } from "react-toastify";
import { AiOutlineCloseCircle } from "react-icons/ai";
import PostYourService from "./postYourService";

const AvailableServices = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const [active, setActive] = useState("list");
  const [activeTab, setActiveTab] = useState(0);
  const [options, setOptions] = useState([]);
  const [rentalList, setRentalList] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [citysearch, setCitysearch] = useState("");
  const [cityID, setCityID] = useState("");
  const [areasearch, setAreasearch] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [maxPrice, setMaxPrice] = useState([]);
  const [genderType, setGenderType] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [filteredArea, setFilteredarea] = useState([]);
  const [autoCompleteLableValue, setAutoCompleteLableValue] = useState({
    city: "",
    subject: "",
  });
  const [activePage, setActivePage] = useState(0);
  console.log(state, "stateeeeeee");

  const tabs = [
    {
      label: "Rental",
    },
    {
      label: "Meal",
    },
    {
      label: "Books",
    },
    {
      label: "Career Counselling",
    },
    {
      label: "Others",
    },
  ];


  const property_type = [
    { value: "Land", label: "Land" },
    { value: "residence", label: "residence" }
    // Add more color options as needed
  ];
  const max_price = [
    { value: "Less than 2000", label: "Less than 2000" },
    { value: "2000 to  3000", label: "2000 to  3000" },
    { value: "3000 to 5000", label: "3000 to 5000" },
    { value: "5000 and above", label: "5000 and above" },
    // Add more color options as needed
  ];
  const gender = [
    { value: "male", label: "male" },
    { value: "female", label: "female" }
    // Add more color options as needed
  ];

  const handlePostService = () => {
    // navigate("/post-service");
    console.log("onclick")
    navigate("/view-service", { state: {...autoCompleteLableValue, postYourRental:true }});
    setActivePage(1);
  };

  const getPanelValue = (searchText) =>
    !searchText
      ? []
      : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)];

  const mockVal = (str, repeat = 1) => ({
    value: str.repeat(repeat),
  });
  const [selectedCity, setSelectedCity] = useState("");
  const [areas, setAreas] = useState([]);
  console.log(areas, 889889);
  const handleCityChange = (value) => {
    formik.setFieldValue("city", value);
    // Update the selected city
    setSelectedCity(value);
    // Fetch areas based on the selected city
    if (value.toLowerCase() === "chennai") {
      // Fetch areas for city with ID 1
      fetchAreasByCity("1");
    } else {
      // Fetch areas for other cities
      fetchAreasByCity(value);
    }
  };

  const fetchAreasByCity = (city) => {
    // Make API call to fetch areas based on the selected city
    // Replace 'yourApiEndpoint' with your actual API endpoint
    getareaById(city).then((response) => {
      console.log(response, 8998989);

      // Check if response is defined and not empty
      if (response && response.records) {
        // Update the areas state with the fetched areas
        setAreas(response.records);
      } else {
        // If response is undefined or empty, set areas to an empty array
        setAreas([]);
      }
    });
  };
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  console.log(latitude, longitude, 77999);
  useEffect(() => {
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error("Error getting geolocation:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    fetchLocation();
  }, []);


  const handleSubmit = async (values) => {
    console.log("Form values submitted:", values);
          const data = {
        accomdation_type: "",
        address: "address",
        amenities: [],
        area: values?.area,
        base64_image: "",
        bathroom: values?.bathrooms,
        bedroom: values?.bedrooms.toString(),
        budget: "16000",
        city: values?.city,
        contract_length: "10",
        description: "description",
        email_id: "",
        gender: values.gender,
        i_want_to: "",
        iam: "",
        latitude: latitude?.toString(),
        longitude: longitude?.toString(),
        mobile_number: "",
        name: "sam",
        property_type: values?.propertyType,
        security_deposit: "36000",
      };
      console.log(data,"dataaa")
      console.log("filter data:", data);
      const response = await postRental(data);
      console.log("availabe services from rental:", response);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      city: "",
      area: "",
      bedrooms: 0,
      bathrooms: "",
      propertyType: "",
      gender: "",
      maxPrice: "",
      i_want_to: "",
      iam: "",
      mobile_number: "",
      email_id: "",
    },
    onSubmit: handleSubmit
    // (values) => {
    //   alert("Click")
    //   console.log(values,"OnSubmit")
    //   const data = {
    //     accomdation_type: "",
    //     address: "address",
    //     amenities: "",
    //     area: values?.area,
    //     base64_image: "",
    //     bathroom: values?.bathrooms,
    //     bedroom: values?.bedrooms.toString(),
    //     budget: "16000",
    //     city: values?.city,
    //     contract_length: "10",
    //     description: "description",
    //     email_id: "",
    //     gender: values.gender,
    //     i_want_to: "",
    //     iam: "",
    //     latitude: latitude.toString(),
    //     longitude: longitude.toString(),
    //     mobile_number: "",
    //     name: "sam",
    //     property_type: values?.propertyType,
    //     security_deposit: "36000",
    //   };
    //   console.log(values, 8989889);
      // postRental(data).then((res) => {
      //   console.log(res, 87789898);
      //   if (res?.success === true) {
      //     toast.success(res?.message.toUpperCase());
      //   } else {
      //     toast.error("error");
      //   }
      // });
    
  });

  useEffect(() => {
    getLocationList().then((res) => {
      if (res?.records) {
        const cityData = res?.records?.map((item) => ({
          value: item?.location,
          id: item?.id,
          label: item?.location,
        }));
        setCityList(cityData);
      }
    });
  }, []);

  useEffect(() => {
    console.log("!state.postYourRental, ",state)
    console.log("!state, ",state?.state?.postYourRental)
    if(state?.state?.postYourRental) {
      setActivePage(1)
    } else {
      setActivePage(0)
    }
    if (state) {
      const getRentals = () => {
        let params = {
          city: state?.state?.city ? state?.state?.city : "chennai",
          area: state?.state?.area,
        };
        if (params?.area === "") {
          delete params.area;
        }
        getSpecificRentals(params).then((res) => {
          console.log(res?.records, "response from rentals");
          setRentalList(res?.records);
        });
      };
      getRentals();
    }
  }, [state]);

  const handleSearchCity = (value) => {
    if (!value) {
      setOptionsList([]);
    } else {
      const filteredOptions = cityList?.filter((option) =>
        option?.label?.toLowerCase()?.includes(value.toLowerCase())
      );
      setOptionsList(filteredOptions);
    }
  };

  const getCertainArea = (data) => {
    setCityID(data);
    getareaById(data).then((res) => {
      if (res?.records) {
        const areaData = res?.records?.map((item) => ({
          value: item?.area,
          id: item?.id,
          label: item?.area,
        }));
        setAreaList(areaData);
      }
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    filterAreas();
  }, [citysearch]);
  const filterAreas = () => {
    if (citysearch?.length > 0) {
      const cityid = cityList?.find((c) => c?.label === citysearch)?.id;
      const belongedareas = areaList?.filter((a) => a?.locationid == cityid);
      setFilteredarea(belongedareas);
    }
  };

  // const [postRentalData, setPostRentalData] = useState({
  //   accomdation_type: "Double Sharing",
  //   address: "address",
  //   amenities: "Table Chair",
  //   area: "TS Nagar",
  //   base64_image: "base64 string",
  //   bathroom: "bathroom",
  //   bedroom: "1",
  //   budget: "16000",
  //   city: "Erode",
  //   contract_length: "10",
  //   description: "description",
  //   email_id: "sam@gmail.com",
  //   gender: "male",
  //   i_want_to: "Rent",
  //   iam: "Owner",
  //   latitude: "latitude",
  //   longitude: "longitude",
  //   mobile_number: "9878765654",
  //   name: "sam",
  //   property_type: "property_type",
  //   security_deposit: "36000",
  // });

  return (
    <>
      <Header />
      {activePage === 0 ? (
        <div>
          <DynamicTabs
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
          {/* <div className="dynamic-tab-content"> */}
          {activeTab === 0 && (
            <>
              <div
                class="card m-5 pt-2 pb-2"
                style={{ background: "#940E34", borderRadius: "25px" }}
              >
                <div class="card-body">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={2.5} lg={1.5}>
                      <AutoComplete
                        style={{ width: "100%", height: "45px" }}
                        onSearch={handleSearchCity}
                        options={optionsList}
                        // {cityList?.map((sub: any) => (
                        //   <option value={sub?.label}>{sub?.label}</option>
                        // ))}
                        // options = {
                        //   cityList.map((value)=>(value?.label) )
                        // }
                        value={citysearch}
                        onChange={(e, value) => {
                          setCitysearch(e);
                          setAreasearch([]);
                          // errors.location = "";
                          formik.setFieldValue("city", String(e));
                          getCertainArea(value?.id);
                          setAutoCompleteLableValue({
                            ...autoCompleteLableValue,
                            city: e,
                          });
                        }}
                        placeholder="Enter City"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.5} lg={1.5}>
                      <div>
                        <Select
                          style={{
                            width: "100%",
                            height: "45px",
                            background: "white",
                            borderRadius: "20px",
                          }}
                          showSearch
                          placeholder="Select Area"
                          optionFilterProp="children"
                          disabled={
                            citysearch === "" || citysearch.length === 0
                          }
                          onChange={(e) => {
                            setAreasearch(e);
                            formik.setFieldValue("area", String(e));
                          }}
                          filterOption={filterOption}
                          value={areasearch}
                          options={areaList}
                        ></Select>
                        {areasearch?.length > 0 && (
                          <>
                            <AiOutlineCloseCircle
                              type="reset"
                              className="resetbtn"
                              onClick={() => {
                                setAreasearch([]);
                              }}
                            >
                              close
                            </AiOutlineCloseCircle>
                          </>
                        )}
                      </div>
                    </Grid>{" "}
                    <Grid item xs={12} sm={6} md={2.5} lg={1.5}>
                      <div style={{ position: "relative" }}>
                        <Select
                          name="bedrooms"
                          onChange={(value) => {
                            formik.setFieldValue("bedrooms", String(value));
                          }}
                          value={formik.values.bedrooms}
                          options={options}
                          style={{ width: "100%", height: "45px" }}
                          // onSelect={onSelect}
                          // onSearch={(text) => setOptions(getPanelValue(text))}
                          placeholder="Bedrooms"
                        ></Select>
                        <div
                          style={{ position: "absolute", top: 15, right: 4 }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <button
                              type="button"
                              style={{
                                width: "18px",
                                height: "18px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                const currentBedrooms =
                                  parseInt(formik.values.bedrooms) || 0;
                                if (currentBedrooms > 0) {
                                  formik.setFieldValue(
                                    "bedrooms",
                                    currentBedrooms - 1
                                  );
                                }
                              }}
                            >
                              -
                            </button>
                            {/* <input
                            value={formik.values.bedrooms}
                            style={{
                              width: "18px",
                              height: "18px",
                              fontSize: 13,
                              marginLeft: 2,
                              marginRight: 2,
                            }}
                          /> */}
                            <button
                              type="button"
                              style={{
                                width: "18px",
                                height: "18px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                const currentBedrooms =
                                  parseInt(formik.values.bedrooms) || 0;
                                formik.setFieldValue(
                                  "bedrooms",
                                  currentBedrooms + 1
                                );
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </Grid>{" "}
                    <Grid item xs={12} sm={6} md={2.5} lg={1.5}>
                      <AutoComplete
                        name="bathrooms"
                        onChange={(value) => {
                          formik.setFieldValue("bathrooms", value);
                        }}
                        value={formik.values.bathrooms}
                        options={options}
                        style={{ width: "100%", height: "45px" }}
                        // onSelect={onSelect}
                        // onSearch={(text) => setOptions(getPanelValue(text))}
                        placeholder="Bathrooms"
                      />
                    </Grid>{" "}
                    {/* const [propertyType, setPropertyType] = useState([]);
  const [maxPrice, setMaxPrice] = useState([]);
  const [genderType, setGenderType] = useState([]); */}
                    <Grid item xs={12} sm={6} md={2.5} lg={1.5}>
                      <Select
                        
                        placeholder="Property Type"
                        name="propertyType"
                        options={property_type}
                        onSelect={(value) => {
                          console.log("Selected value:", value);
                          formik.setFieldValue("propertyType", value);
                        }}
                        // value={formik.values.propertyType}
                        
                        style={{ width: "100%", height: "45px" }}
                        // onSelect={onSelect}
                        // onSearch={(text) => setOptions(getPanelValue(text))}
                        
                      />



                    </Grid>{" "}
                    <Grid item xs={12} sm={6} md={2.5} lg={1.5}>
                      <Select
                        // value={formik.values.gender}
                        onSelect={(value) => {
                          console.log("Selected value gender:", value);
                          formik.setFieldValue("gender", value);
                        }}
                        name="gender"
                        options={gender}
                        style={{ width: "100%", height: "45px" }}
                        // onSelect={onSelect}
                        // onSearch={(text) => setOptions(getPanelValue(text))}
                        placeholder="Gender"
                      />
                    </Grid>{" "}
                    <Grid item xs={12} sm={6} md={2.5} lg={1.5}>
                      <Select
                        // value={formik.values.maxPrice}
                        onSelect={(value) => {
                          console.log("Selected value maxPrice:", value);
                          formik.setFieldValue("maxPrice", value);
                        }}
                        name="maxPrice"
                        type="number"
                        options={max_price}
                        style={{ width: "100%", height: "45px" }}
                        // onSelect={onSelect}
                        // onSearch={(text) => setOptions(getPanelValue(text))}
                        placeholder="Max Price"
                      />
                    </Grid>{" "}
                    <Grid item xs={12} sm={6} md={2.5} lg={1.5}>
                      {/* <AutoComplete
                        options={options}
                        style={{ width: "100%", height: "45px" }}
                        // onSelect={onSelect}
                        // onSearch={(text) => setOptions(getPanelValue(text))}
                        placeholder="More Filters"
                      /> */}
                      <button
                        style={{
                          borderRadius: "20px",
                          background: "#ffffff",
                          // color: "",
                          fontSize: 16,
                          fontWeight: "600",
                          height: 45,
                          padding: "10px 20px 10px 20px",
                        }}
                        type="submit"
                        onClick={formik.handleSubmit}
                      >
                        Search
                      </button>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div className="mx-5">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <div>
                          <h6>{rentalList?.length ? rentalList?.length : 0 }&nbsp;&nbsp;results</h6>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div>
                          <span>Sort</span>&nbsp;
                          <span>
                            <select name="cars" id="cars">
                              <option value="volvo">Newest Listed</option>
                            </select>
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginTop: "30px" }}>
                          <ServiceList rentalList={rentalList} />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <div className="d-flex">
                          <span>
                            <button
                              className={`${
                                active === "list" ? "active-btn" : ""
                              } py-1 admin-button`}
                              onClick={() => setActive("list")}
                              style={{
                                fontSize: 12,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 22,
                              }}
                            >
                              <FaList />
                              &nbsp; List
                            </button>
                          </span>
                          <span>
                            <button
                              className={`${
                                active === "map" ? "active-btn " : ""
                              } py-1 mobile-button`}
                              onClick={() => setActive("map")}
                              style={{
                                fontSize: 12,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 22,
                              }}
                            >
                              <FaMapMarker />
                              &nbsp; Map
                            </button>
                          </span>
                        </div>
                      </Grid>
                      <Grid xs={12} sm={6}>
                        <div className="d-flex justify-content-end">
                          <button
                            style={{
                              borderRadius: "20px",
                              background: "#940E34",
                              color: "white",
                              fontSize: 12,
                              fontWeight: "600",
                              height: 22,
                              cursor:"pointer"
                            }}
                            type="submit"
                            onClick={() => handlePostService()}
                            // disabled={
                            //   formik.values.area === "" ||
                            //   formik.values.city == "" ||
                            //   formik.values.bedrooms === "" ||
                            //   formik.values.bathrooms === "" ||
                            //   formik.values.gender === "" ||
                            //   formik.values.propertyType === "" ||
                            //   formik.values.maxPrice === ""
                            // }
                          >
                            POST YOUR RENTAL
                          </button>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <ServiceLocations rentalList={rentalList} />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </>
            // <form onSubmit={formik.handleSubmit}>
            // </form>
          )}
          {activeTab === 1 && (
            <div>
              <h2>Meal Services</h2>
              <p>This is the content for Meal Services.</p>
              {/* Add your HTML code here */}
              <div className="pt-5" style={{ background: "#f7f7f7" }}>
                <Footer />
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div>
              <h2>Books Services</h2>
              <p>This is the content for Books Services.</p>
              {/* Add your HTML code here */}
              <div className="pt-5" style={{ background: "#f7f7f7" }}>
                <Footer />
              </div>
            </div>
          )}
          {activeTab === 3 && (
            <div>
              <h2>Career Counselling Services</h2>
              <p>This is the content for Career Counselling Services.</p>
              {/* Add your HTML code here */}
              <div className="pt-5" style={{ background: "#f7f7f7" }}>
                <Footer />
              </div>
            </div>
          )}
          {activeTab === 4 && (
            <div>
              <h2>Other Services</h2>
              <p>This is the content for Other Services.</p>
              {/* Add your HTML code here */}
              <div className="pt-5" style={{ background: "#f7f7f7" }}>
                <Footer />
              </div>
            </div>
          )}
        </div>
      ) : (
        <PostYourService
          formik={formik}
          active={activePage}
          setActive={setActivePage}
          state={state}
        />
      )}
    </>
  );
};

export default AvailableServices;
