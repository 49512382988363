/** **************************** Import Types ****************************** */
import {
  MYBOOKING_REQUEST,
  MYBOOKING_SUCCESS,
  MYBOOKING_FAILURE,
} from "../types/myBookingTypes";

const initialState = {
  loading: false,
  myBookingResponse: "",
  error: "",
};

const myBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case MYBOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        myBookingResponse: "",
      };
    case MYBOOKING_SUCCESS:
      return {
        loading: false,
        myBookingResponse: action.payload.responseStatus,
        error: "",
      };
    case MYBOOKING_FAILURE:
      return {
        loading: false,
        myBookingResponse: "error",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default myBookingReducer;
