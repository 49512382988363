import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  CompanyWrap,
  Title,
  Description,
} from "../../pages/AboutUs/aboutusStyle";
import data from "./aboutUsText";

const ContactUs: Function = () => {
  return (
    <>
        <Container>
          <Row>
            <Col sm={12}>
              <Title style={{textAlign:"center"}}>Refund Policy</Title>
              {/* <Description>{data.contactUs}</Description> */}
              <p>Our Refund policy is valid for a period of 7 calendar days from the date of the purchase. If you are not satisfied with the service, you can ask for a refund. If the period of 7 days has lapsed since the purchase, we can’t, unfortunately, offer you a refund.</p>

<h2>Refund Requirements</h2>

<p>The following criteria must be met to qualify for a refund:</p>

<ul>
  <li>Service must not be used</li>
</ul>

<p>If the conditions listed above are not met, we reserve the right not to issue a refund.</p>

<h2>Contacting Us</h2>

<p>If you have any questions, concerns, or complaints regarding this refund policy, we encourage you to contact us using the details below:</p>

<ul>
  <li>Email: <a href="mailto:ethosity.solutions@gmail.com">ethosity.solutions@gmail.com</a></li>
  <li>Phone: +91 98225 07475</li>
  <li>Phone: +91 96659 87151</li>
</ul>
            </Col>
          </Row>
        </Container>
      
     
  
    </>
  );
};

export default ContactUs;
