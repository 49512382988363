const environmentList = [
  "http://34.238.40.108:5002",
  "http://192.168.43.68:5002",
  // "http://localhost:5002",
  "https://api.studentsathi.com",
  "https://api.tuitionmitra.com",
];

export const env = 2; // Place your environment number heres

export const hostConfig = {
  CURRENT_URL: environmentList[env],
  WEB_URL: process.env.url,
  API_URL: `${environmentList[env]}/api/v1/`,
  S3_BUCKET: "https://tuition-mitra-assets.s3.ap-south-1.amazonaws.com/",
};
