/** **************************** Import Packages ****************************** */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const SelectBoxStyled = styled.select`
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "6px"};
  background-color: #f7f7f7;
  width: ${(props) => (props.width ? props.width : "40%")};
  height: ${(props) => (props.height ? props.height : "2.5rem")};
  border: none;
  padding-left: 10px;
  box-shadow: ${(props) =>
    props.shadow ? "0px 4px 20px rgba(5, 31, 50, 0.14) " : ""};
  //   &:focus {
  //     border: 1px solid rgba(5, 31, 50, 0.3);
  //   }
`;

function SelectBox({ name, onChange, width, value, children, ...others }) {
  return (
    <SelectBoxStyled
      width={width}
      name={name}
      className="dropdown-btn"
      value={value}
      onChange={onChange}
      {...others}
    >
      {children || <option>Options...</option>}
    </SelectBoxStyled>
  );
}
export default SelectBox;
