/** **************************** Import Types ****************************** */
import {
    ADVERTISEMENT_POST_REQUEST,
    ADVERTISEMENT_POST_SUCCESS,
    ADVERTISEMENT_POST_FAILURE,
} from "../types/advertisementType";

const initialState = {
    loading: false,
    advertisementPostResponse: "",
    error: "",
};

const advertisementPostReducer = (state = initialState, action) => {
    console.log("form review api reducer", action.payload);
    switch (action.type) {
        case ADVERTISEMENT_POST_REQUEST:
            return {
                ...state,
                loading: true,
                advertisementPostResponse: "",
            };
        case ADVERTISEMENT_POST_SUCCESS:
            return {
                loading: false,
                advertisementPostResponse: action.payload.responseStatus,
                error: "",
            };
        case ADVERTISEMENT_POST_FAILURE:
            return {
                loading: false,
                advertisementPostResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default advertisementPostReducer;
