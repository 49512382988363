import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
// import { StarRating } from "src/components/TuorPanel/Accordion/starRatings";
import UserPlaceholder from "../../assets/images/user-placeholder-small.png";
import StarRating from "../../components/TuorPanel/Accordion/starRatings";
import { hostConfig } from "../../config";
import {
  ReviewCard,
  ReviewSection,
  ReviewTitle,
  StudentsCard,
  StudentsCardWrap,
  StudentsDetails,
  StudentsImg,
  StudentsName,
  StudentsReview,
  StudentsStar,
} from "../../pages/home/homeStyle";
import { getallreviewsData } from "../../redux/action/reviewAction";

export const ReviewSectionCard = () => {
  const dispatch = useDispatch();
  const [reviewData, setReviewData] = useState([]);
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Large desktop
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablet
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getAllReviews = () => {
    dispatch(getallreviewsData()).then((res) => {
      console.log(res?.records, "ressssssss form reviewwwwww");
      setReviewData(res?.records);
    });
  };

  useEffect(() => {
    getAllReviews();
  }, []);

  return (
    <>
      <ReviewSection>
        <Container>
          <Row>
            <Col xs={12} sm={12} >
              
              <ReviewTitle>Recent Reviews From Students</ReviewTitle>
              </Col>
              <Col sm={12}>
                <ReviewCard>
                  <Slider {...settings}>
                    {reviewData?.map((item) => {
                      return (
                        <StudentsCard key={item.id}>
                          <StudentsCardWrap>
                            <StudentsName>
                              <StudentsImg>
                                <img
                                  src={
                                    item?.student_details?.is_profile === true
                                      ? `${hostConfig.S3_BUCKET}${item?.student_details?.profile_name}`
                                      : UserPlaceholder
                                  }
                                  alt={item?.student_details?.student_first_name}
                                />
                              </StudentsImg>
                              <StudentsReview>
                                <h6>
                                  Reviewed for {item?.teacher_details?.tuition_name}
                                </h6>
                                <h4>{`${item?.student_details?.student_first_name} ${item?.student_details?.student_last_name}`}</h4>
                              </StudentsReview>
                            </StudentsName>
                            <StudentsDetails>
                              {item?.review?.reviewed_data}
                            </StudentsDetails>
                            <StudentsStar>
                              <span>
                                {moment(
                                  item?.review?.created_at,
                                  "YYYY-MM-DDTHH:mm:ss.SSSSSS"
                                ).format("DD-MMM-YYYY")}
                              </span>
                              <ul>

                            
                              </ul>
                                <StarRating
                                rating={item.review.start_count}
                                totalStars={5}
                                starSize={28}
                              />
                              {/* <ul>
                                <li className="active">
                                  <img src={DarkStar} alt="Star" />
                                </li>
                                <li className="active">
                                  <img src={DarkStar} alt="Star" />
                                </li>
                                <li className="active">
                                  <img src={DarkStar} alt="Star" />
                                </li>
                                <li>
                                  <img src={DarkStar} alt="Star" />
                                </li>
                                <li>
                                  <img src={DarkStar} alt="Star" />
                                </li>
                              </ul> */}
                              {/* <Rating
                                name="simple-controlled"
                                value={item?.review?.batch_star_count}
                                // onChange={(event, newValue) => {
                                //   console.log(newValue, "valyeeee");
                                //   setValue(newValue);
                                // }}
                              /> */}
                            </StudentsStar>
                          </StudentsCardWrap>
                        </StudentsCard>
                      );
                    })}
                  </Slider>
                </ReviewCard>
            </Col>
          </Row>
        </Container>
      </ReviewSection>
    </>
  );
};
export default ReviewSectionCard;
