import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const UserName = styled.h6`
  font-size: 26px;
  padding-left: 0.4rem;
  text-transform: capitalize;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
  padding: 30px;
`;
export const DeleteWrapper = styled.div`
width: 75px;
height: 75px;
background-color: #f7f7f7;
display: flex;
justify-content: center;
align-items:center;
border-radius: 50%;
`;
export const ModalContent = styled.div`
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
  width: 100%;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
  border-radius: 50px;
  background-color: #fff;
`;

export const CardHeader = styled.div`
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  background-color:#f7f7f7;
  border-top-left-radius:15px;
  border-top-right-radius:15px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const ModalCloseButton = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  color: #e8e8e8;
  cursor: pointer;
  border: none;
  background: transparent;
`;

export const TitleHeader = styled.h6`
  font-family: 'Montserrat';
  font-weight: bold;
  display: flex;
  font-size: 16px;
  color:#940e34;
  margin-bottom: 0;
  vertical-align: middle;
  padding-left: 0.4rem;
  align-items: center !important;
`;

export const ModalBody = styled.div`
  font-family: 'Montserrat';
`;

export const CloseBtn = styled.span`
  font-size: 15px;
  margin-right: 10px;
`;

export const OutlineDangerButton = styled.button`
  margin-right: 1rem;
  height: 40px;
  width: 86px;
`;
