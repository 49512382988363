import styled from "styled-components";

import DropArrow from "../../assets/images/arrow-down.svg";

export const SubbannerMain = styled.div`
  background-color: #f7f7f7;
  padding: 30px 0px;
`;
export const BannerWrap = styled.div``;
export const BannerSearchBox = styled.div`
  box-shadow: 0px 0px 50px #0128341a;
  background: #940e34;
  padding: 30px 30px 10px;
  border-radius: 30px;
  .CloseBtn {
    position: relative;
    .resetbtn {
      position: absolute;
      top: 22px;
      right: 10px;
      height: 20px;
      width: 20px;
      color: #940e34;
      opacity: 0.6;
      @media (max-width: 1440px) {
        top: 14px;
      }
    }
  }
  @media (max-width: 1440px) {
    padding: 20px 20px 5px;
    border-radius: 30px;
  }
  .form-select {
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    @media (max-width: 1366px) {
      font-size: 14px;
    }
  }
  .form-control {
    /* border-radius: 30px; */
    background: #f7f7f7;
    border: none;
    padding: 20px;
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    color: #012834;
    opacity: 1;
    border: 1px solid #f7f7f7;
    @media (max-width: 1440px) {
      padding: 14px 20px;
    }
    @media (max-width: 1366px) {
      font-size: 14px;
    }
    ::placeholder {
      color: #012834;
      opacity: 1; /* Firefox */
    }
  }
  .btn-wrap {
    text-align: center;
    .btn-primary {
      width: 100%;
      padding: 20px 20px;
      font-weight: 600;
      background: #ffffff;
      color: #5e0b22;
      &:hover {
        background: #ffffff;
        color: #000000;
      }
      &.active {
        &:hover {
          background: #ffffff !important;
          color: #000000;
          border-color: #ffffff;
        }
        background: #ffffff !important;
        color: #000000 !important;
        border-color: #ffffff;
      }
      @media (max-width: 1440px) {
        padding: 14px 20px;
      }
      @media (max-width: 1366px) {
        font-size: 14px;
      }
    }
  }
  .ant-select-selector {
    /* border-radius: 30px; */
    background-color: #f7f7f7 !important;
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    color: #012834;
    border: 1px solid #f7f7f7 !important;
    min-height: 60px;
    padding: 5px 36px 5px 20px;
    opacity: 1;
    @media (max-width: 1440px) {
      min-height: 48px;
    }
    .ant-select-selection-placeholder {
      inset-inline-start: 20px;
      inset-inline-end: 20px;
      color: #012834;
      @media (max-width: 1366px) {
        font-size: 14px;
      }
    }
    .ant-select-selection-item {
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 20px;
      padding: 3px 5px;
      height: 26px;
      font-size: 16px;
      line-height: 120%;
      font-weight: 500;
      color: #012834;
      .anticon {
        vertical-align: middle;
        svg {
          fill: #940e34;
        }
      }
    }
  }
`;
export const PageTitle = styled.h2`
  color: #012834;
  font-size: 42px;
  line-height: 130%;
  font-weight: 800;
  margin-bottom: 60px;
  @media (max-width: 1440px) {
    font-size: 38px;
    margin-bottom: 45px;
  }
  @media (max-width: 1366px) {
    font-size: 34px;
    margin-bottom: 35px;
  }
`;

export const TutionListMain = styled.div`
  padding: 100px 0px 140px;
  @media (max-width: 1680px) {
    padding: 90px 0 130px;
  }
  @media (max-width: 1440px) {
    padding: 70px 0 120px;
  }
  @media (max-width: 1366px) {
    padding: 60px 0 90px;
  }
  @media (max-width: 1280px) {
    padding: 50px 0 80px;
  }
`;
export const TutionListWrap = styled.div`
  background: #f7f7f7;
  border-radius: 30px;
  padding: 40px;
  margin-bottom: 30px;
  @media (max-width: 1440px) {
    padding: 30px;
  }
  @media (max-width: 1366px) {
    padding: 25px;
  }
`;
export const TutionDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const TutionImg = styled.div`
  flex: 100px;
  max-width: 100px;
  height: 100px;
  width: 100%;
  @media (max-width: 1366px) {
    flex: 90px;
    max-width: 90px;
    height: 90px;
  }
  img {
    height: 100%;
    width: 100%;
    border: 4px solid #ffffff;
    border-radius: 58px;
    box-shadow: 0px 10px 10px #7070700d;
    object-fit: cover;
  }
`;
export const TutionName = styled.div`
  flex: calc(100% - 100px);
  max-width: calc(100% - 100px);
  padding-left: 30px;
  @media (max-width: 1366px) {
    padding-left: 20px;
    flex: calc(100% - 90px);
    max-width: calc(100% - 90px);
  }
  h4 {
    font-size: 20px;
    line-height: 120%;
    color: #940e34;
    font-weight: 700;
    margin: 14px 0px 10px;
    @media (max-width: 1366px) {
      font-size: 18px;
      margin: 10px 0px 5px;
    }
  }
  span {
    font-size: 14px;
    line-height: 130%;
    color: #012834;
    // opacity: 0.5;
    font-weight: 400;
  }
  P {
    font-size: 14px;
    line-height: 190%;
    color: #012834;
    // opacity: 0.8;
    font-weight: 400;
    margin: 30px 0px;
    @media (max-width: 1440px) {
      margin: 20px 0px;
    }
    @media (max-width: 1366px) {
      margin: 15px 0px;
    }
  }
`;
export const TutionProfile = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -15px;
  @media (max-width: 1366px) {
    margin: 0 -10px;
  }
`;
export const TutionProData = styled.div`
  flex: calc(25% - 30px);
  max-width: calc(25% - 30px);
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  padding: 30px 0px;
  margin: 0 15px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1440px) {
    padding: 20px 0px;
    margin: 0 10px;
  }
  @media (max-width: 1366px) {
    padding: 15px 0px;
    margin: 0 10px;
  }
`;
export const TutionIcon = styled.div`
  padding: 10px;
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TutionData = styled.div`
  padding-left: 10px;
  flex: calc(100% - 40px);
  max-width: calc(100% - 40px);
  span {
    font-size: 14px;
    line-height: 130%;
    color: #940e34;
    font-weight: 400;
  }
  h6 {
    font-size: 16px;
    line-height: 120%;
    color: #012834;
    font-weight: 400;
    margin-top: 10px;
    max-width: 240px;
    margin-bottom: 0px;
    @media (max-width: 1366px) {
      font-size: 15px;
    }
    @media (max-width: 1280px) {
      font-size: 14px;
      margin-top: 5px;
    }
  }
`;
export const LoadMore = styled.div`
  margin: 60px 0px 0px;
  padding: 28px 0px;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  text-align: center;
  @media (max-width: 1680px) {
    margin: 50px 0px 0px;
    padding: 25px 0px;
  }
  @media (max-width: 1440px) {
    margin: 40px 0px 0px;
    padding: 20px 0px;
  }
  @media (max-width: 1366px) {
    margin: 35px 0px 0px;
    padding: 15px 0px;
  }
  span {
    font-size: 16px;
    line-height: 120%;
    font-weight: 600;
    color: #940e34;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: #012834;
    }
  }
`;
export const TutionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 10px;
  @media (max-width: 1366px) {
    gap: 20px;
  }
  .btn-primary {
    font-size: 16px;
    line-height: 120%;
    font-weight: 600;
    padding: 20px 46px 20px;
    min-width: 310px;
    @media (max-width: 1440px) {
      font-size: 15px;
      padding: 15px 40px 15px;
      min-width: 290px;
    }
    @media (max-width: 1366px) {
      font-size: 15px;
      padding: 15px 46px 15px;
      min-width: 270px;
    }
  }
  .btn-secondary {
    font-size: 16px;
    line-height: 120%;
    font-weight: 600;
    padding: 20px 46px 19px;
    min-width: 310px;
    background-color: #ffffff;
    &:hover {
      background-color: #940e34;
    }
    @media (max-width: 1440px) {
      font-size: 15px;
      padding: 15px 40px 15px;
      min-width: 290px;
    }
    @media (max-width: 1366px) {
      font-size: 15px;
      padding: 15px 35px 15px;
      min-width: 270px;
    }
  }
`;
export const ModalFooter = styled.div`
  .modal-footer {
    border-top: 0px;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 20px;
    @media (max-width: 1440px) {
      padding: 0 0 15px;
    }
    .btn {
      width: 214px;
      padding: 20px;
      @media (max-width: 1440px) {
        padding: 15px;
      }
    }
  }
`;
export const BookModalBody = styled.div`
  .modal-body {
    padding: 40px 40px;
    @media (max-width: 1440px) {
      padding: 30px 20px;
    }
    @media (max-width: 1280px) {
      padding: 20px 20px 25px;
    }
    .form-label {
      font-size: 14px;
      line-height: 130%;
      color: #707070;
      margin-bottom: 15px;
    }
    .form-control {
      font-size: 16px;
      line-height: 130%;
      font-weight: 500;
      padding: 19px;
      @media (max-width: 1440px) {
        padding: 14px 20px;
        font-size: 14px;
      }
      &::placeholder {
        color: #012834;
        &:focus {
          background-color: #d3d3d3;
        }
      }
    }
    .ant-select-selector {
      /* border-radius: 30px; */
      background: #f7f7f7 !important;
      font-size: 16px;
      line-height: 120%;
      font-weight: 500;
      color: #012834;
      border: 1px solid #f7f7f7 !important;
      min-height: 60px;
      padding: 5px 20px;
      opacity: 0.8;
      @media (max-width: 1440px) {
        min-height: 48px;
      }

      .ant-select-selection-placeholder {
        inset-inline-start: 20px;
        inset-inline-end: 20px;
        color: #707070;
        font-family: "Montserrat", sans-serif;
        @media (max-width: 1366px) {
          font-size: 14px;
        }
      }
      .ant-select-selection-item {
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 20px;
        padding: 3px 5px;
        height: 26px;
        font-size: 16px;
        line-height: 120%;
        font-weight: 500;
        color: #012834;
        .anticon {
          vertical-align: middle;
          svg {
            fill: #940e34;
          }
        }
      }
    }
    .input-group {
      .form-control {
        /* border-radius: 30px !important; */
        padding-right: 46px;
      }
      .icon-wrap {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        padding: 0;
        border: 0;
        background: border-box;
        &:active {
          background: transparent !important;
        }
        img {
          width: 20px;
          &.close-eye {
            width: 18px;
          }
        }
      }
    }
  }
`;
export const SignUpText = styled.div`
  margin-bottom: 0;
  color: #012834;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  .signup {
    color: #940e34;
    font-size: 14px;
    line-height: 120%;
    font-weight: 600;
    text-decoration: none;
    margin-left: 5px;
    &:hover {
      color: #012834;
    }
  }
`;
export const BookTuitionHeader = styled.div`
  max-width: 782px;
  @media (max-width: 1440px) {
    max-width: 582px;
  }
  .btn-close {
    height: 20px;
    width: 20px;
  }
  .btn-close:focus {
    box-shadow: none;
  }
  .modal-header {
    background: #f7f7f7;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 32px 40px;
    border-bottom: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    @media (max-width: 1440px) {
      padding: 20px 32px;
    }
    @media (max-width: 1366px) {
      padding: 18px 30px;
    }
    .modal-title {
      font-size: 30px;
      line-height: 120%;
      font-weight: 800;
      @media (max-width: 1440px) {
        font-size: 28px;
      }
      @media (max-width: 1366px) {
        font-size: 26px;
      }
      @media (max-width: 1280px) {
        font-size: 24px;
      }
    }
  }
`;
export const TutionBookedDetails = styled.div`
  padding: 20px 30px;
  background-color: #f7f7f7;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const BookTitle = styled.div`
  font-size: 24px;
  line-height: 125%;
  font-weight: 800;
  margin-bottom: 30px;
  @media (max-width: 1440px) {
    font-size: 22px;
    line-height: 125%;
    margin-bottom: 20px;
  }
  @media (max-width: 1366px) {
    font-size: 20px;
    line-height: 125%;
    margin-bottom: 15px;
  }
`;
export const TutionBookedRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 50%;
  width: 50%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
`;
export const TeacherDate = styled.div`
  flex: 0 0 50%;
  width: 50%;
  font-size: 16px;
  line-height: 120%;
  font-weight: 800;
  padding-top: 20px;
  color: #012834;
  p {
    margin-bottom: 12px;
  }
  span {
    color: #940e34;
  }
`;
export const RattingIcon = styled.div`
  height: 50px;
  width: 50px;
  background-color: #ffffff;
  padding: 14px 15px 15px;
  border-radius: 50%;
`;
export const TutionBookedLeft = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex: 0 0 50%;
  width: 50%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
`;
export const TeacherImg = styled.div`
  height: 50px;
  width: 50px;
  img {
    height: 100%;
    width: 100%;
    border: 4px solid #ffffff;
    border-radius: 50%;
    object-fit: cover;
  }
`;
export const TeacherName = styled.h4`
  font-size: 16px;
  line-height: 120%;
  font-weight: 800;
  padding-left: 15px;
  color: #012834;
`;
export const TutionBooked = styled.div`
  .btn-close {
    height: 20px;
    width: 20px;
    &:focus {
      box-shadow: none;
    }
  }
  .modalTitle {
    font-size: 30px;
    line-height: 165%;
    font-weight: 800;
    @media (max-width: 1440px) {
      font-size: 28px;
    }
    @media (max-width: 1366px) {
      font-size: 26px;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
    }
  }
  .modal-header {
    background: #f7f7f7;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 28px 40px;
    border-bottom: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    @media (max-width: 1440px) {
      padding: 20px 32px;
    }
    @media (max-width: 1366px) {
      padding: 18px 30px;
    }
  }
  .modal-body {
    padding: 40px 25px 55px;
    @media (max-width: 1440px) {
      padding: 30px 20px;
    }
    @media (max-width: 1280px) {
      padding: 20px 20px 25px;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: center;
    border-top: 0px;
    padding: 15px 0px 35px;
    @media (max-width: 1440px) {
      padding: 15px 0px 30px;
    }
    .btn {
      width: 214px;
    }
  }
  ${TeacherName} {
    margin-bottom: 0;
  }
  ${TutionBookedRight} {
    justify-content: flex-start;
  }
  ${TutionData} {
    padding-left: 15px;
    span {
      color: #707070;
    }
    h6 {
      font-weight: 700;
      margin-top: 8px;
    }
  }
`;
