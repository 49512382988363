/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  PROFILE_REQUEST,
} from "../types/profileType";
import { updateProfile } from "../../api/update";
import { deleteProfile } from "../../api/delete";
import { getStudentProfileById, getTeacherProfileById } from "../../api/list";

export const profileRequest = () => ({
  type: PROFILE_REQUEST,
});
export const profileSuccess = (users) => ({
  type: PROFILE_SUCCESS,
  payload: users,
});
export const profileFailure = (error) => ({
  type: PROFILE_FAILURE,
  payload: error,
});

export const GetTeacherProfileById = (userId, data) =>
  async function (dispatch) {
    dispatch(profileRequest());
    return getTeacherProfileById(userId, data)
      .then((res) => {
        if (res) {
          dispatch(profileSuccess(res.records));
          return res;
        }
        dispatch(profileFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
//teacher profile
export const updatedProfile = (data, userId, token) => async (dispatch) => {
  dispatch(profileRequest());
  try {
    const response = await updateProfile(data, userId, token);

    if (!response.code && !response.error) {
      dispatch(profileSuccess({ responseStatus: "success" }));
      //  toast.success("Profile Updated Successfully");
      toast.success("Profile Updated Successfully", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return response;
    } else {
      dispatch(profileFailure(response.error));
      return response.message || response.error;
    }
  } catch (error) {
    dispatch(profileFailure(error.message));
    toast.error("Profile not Updated", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
    //  toast.error("Profile not Updated");
    return error.message;
  }
};

// delete action remains unchanged

export const DeleteProfile = (profileID) =>
  async function (dispatch) {
    dispatch(profileRequest());
    return deleteProfile(profileID)
      .then((res) => {
        if (!res.error) {
          dispatch(profileSuccess({ responseStatus: "success" }));
          //toast.success("Profile Deleted Successfully");
          toast.error("Profile Deleted Successfully", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          return profileID; // Return the deleted badge's ID
        }
        dispatch(profileFailure(res.error));
        return res.error;
      })
      .catch((err) => {
        console.log("Profile not Deleted");
        toast.error("Profile not Deleted", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        return err;
      });
  };

// get particular student

export const GetStudentProfileById = (userId, data) =>
  async function (dispatch) {
    dispatch(profileRequest());
    return getStudentProfileById(userId, data)
      .then((res) => {
        if (res) {
          dispatch(profileSuccess(res.records));
          return res;
        }
        dispatch(profileFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
