import React from "react";
import { FileAddOutlined } from "@ant-design/icons";

import { ItemAddContainer } from "./styledViews";


type Props = {
  onAdd: () => void
}

const AddItem: React.FC<Props> = ({ onAdd }) => {
  return (
    <ItemAddContainer onClick={onAdd}>
      <FileAddOutlined />
    </ItemAddContainer>
  );
};

export default AddItem;