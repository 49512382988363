import React, { useState } from "react";
import _ from "lodash";
import { Input, Rate, Alert } from 'antd';
import { Button, Container, Row, Col } from "react-bootstrap";
import { SyncOutlined } from "@ant-design/icons";

import { PostReview } from "../../redux/action";

import BaseModal from "../../components/Modal/BaseModal";

import {
  Logo,
  TuitionName,
  TextAreaWrapper,
  RatingsWrapper,
  RatingsCard,
  SubmitWrapper,
} from "./styledViews";


type Props = {
  show: boolean
  onHide: () => void
  logoUrl: string
  name: string
  data: any
  updateReviews: () => void
}

const RatingModal: React.FC<Props> = ({ show, onHide, logoUrl, name, data, updateReviews }) => {
  const [review, setReview] = useState<string>("");
  const [ratingTeaching, setRatingTeaching] = useState<number>(0);
  const [ratingEnv, setRatingEnv] = useState<number>(0);
  const [ratingOverall, setRatingOverall] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleHide = () => {
    onHide();
    if (success) updateReviews();
  };

  const handleSubmit = () => {
    if (!review || !ratingTeaching || !ratingEnv || !ratingOverall) return;

    setError(null);
    setLoading(true);

    const { student_id, teacher_id, batch_id } = data;
    const payload = {
      batch_star_count: ratingTeaching,
      timing_star_count: ratingEnv,
      start_count: ratingOverall,
      reviewed_data: review,
      student_id,
      teacher_id,
      batch_id,
    };

    PostReview(payload).then(res => {
      if (!res.success) {
        setError(res.message || "Failed to post review");
        setLoading(false);
        return;
      }

      setSuccess(true);
      setLoading(false);
    });
  };

  return (
    <BaseModal show={show} onHide={handleHide} title="Write a Review">
      <div style={{ display: "flex", alignItems: "center", gap: 30 }}>
        <Logo><img src={logoUrl} alt="Tuition Logo" /></Logo>
        <TuitionName>{name}</TuitionName>
      </div>
      
      <TextAreaWrapper>
        <Input.TextArea rows={5} value={review} onChange={({ target: { value } }) => setReview(value)} />
      </TextAreaWrapper>

      <RatingsWrapper>
        <Container fluid>
          <Row>
            <Col md={4}>
              <RatingItem label="Teaching Style" value={ratingTeaching} onChange={(v: number) => setRatingTeaching(v)} />
            </Col>
            <Col md={4}>
              <RatingItem label="Classroom Environment" value={ratingEnv} onChange={(v: number) => setRatingEnv(v)} />
            </Col>
            <Col md={4}>
              <RatingItem label="Overall Experience" value={ratingOverall} onChange={(v: number) => setRatingOverall(v)} />
            </Col>
          </Row>
        </Container>
      </RatingsWrapper>

      {!success && <SubmitWrapper>
        <Button
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <SyncOutlined spin /> : "Submit"}
        </Button>
      </SubmitWrapper>}

      {error && <Alert
        style={{ marginTop: 10 }}
        type="error"
        showIcon
        message={error}
      />}

      {success && <Alert
        style={{ marginTop: 30 }}
        type="success"
        showIcon
        message="Review Submitted"
      />}
    </BaseModal>
  );
};

const RatingItem: React.FC<any> = ({ label, value, onChange }) => {
  return (
    <RatingsCard>
      <div className="label">{label}</div>
      <Rate value={value} onChange={onChange} />
    </RatingsCard>
  );
}

export default RatingModal;