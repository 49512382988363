// import "react-toastify/dist/ReactToastify.css";
import { Cookies } from "react-cookie";
import { postTeacherLoginRequestData } from "../../api/create";
import {
  TLOGIN_USERS_FALIURE,
  TLOGIN_USERS_REQUEST,
  TLOGIN_USERS_SUCCESS,
} from "../types/tlogingTypes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const cookies = new Cookies(); // Create an instance of Cookies

export const LoginUsersRequest = () => ({
  type: TLOGIN_USERS_REQUEST,
});
export const LoginUsersSuccess = (users) => ({
  type: TLOGIN_USERS_SUCCESS,
  payload: users,
});

export const LoginUsersFailure = (error) => ({
  type: TLOGIN_USERS_FALIURE,
  payload: error,
});

export const TeacherLoginUser = (data) =>
  async function (dispatch) {
    dispatch(LoginUsersRequest());
    return (
      postTeacherLoginRequestData(data)
        // ============================
        .then((res) => {
          if (res?.success) {
            const TeacherloggedUser = res?.records;
            // if (TeacherloggedUser.is_email_verified === true) {
            const token = res.token.access_token;
            localStorage.setItem(
              "loggedUsers",
              JSON.stringify(TeacherloggedUser)
            );
            cookies.set(
              "TeacherloggedUser",
              JSON.stringify(TeacherloggedUser),
              {
                path: "/",
              }
            );
            localStorage.setItem("token", token);

            dispatch(LoginUsersSuccess({ responseStatus: res }));

            //toast.success("Login Successfully!", {
            //   position: "top-center",
            //   autoClose: 1000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: false,
            //   progress: undefined,
            //   theme: "light",
            // });
            // setTimeout(() => {
            //   window.location.href = "/dashboard";
            // }, 3000);
            return res;
            // } else {
            //   console.log("Email not Verified");
            //   console.log(TeacherloggedUser.is_email_verified === true);
            //   console.log(TeacherloggedUser);
            //   console.log("Email not Verified");
            //   dispatch(LoginUsersFailure("Email not Verified"));
            //   // toast.error("Email not verified");
            //   return res;
            // }
          } else {
            dispatch(LoginUsersFailure(res.message));
            // toast.error("check your email or password", {
            //   position: "top-center",
            //   autoClose: 1000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: false,
            //   progress: undefined,
            //   theme: "light",
            // });
            return res;
          }
        })
        .catch((err) => console.log("Email Or Password incorrect"))
    );
  };
