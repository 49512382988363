import React from "react";
import { Outlet, Link } from "react-router-dom";

import {
  AuthContainer,
  ContentContainer,
  SideImage,
  Header,
  Content,
  Logo
} from "./styledViews";

import sideImage from "../../assets/images/login.png";
import headerLogo from "../../assets/images/TuitionMitraLogo.svg";


const Auth: React.FC<any> = () => {
  return (
    <AuthContainer>
      <ContentContainer>
        <Header>
          <div><Link to="/">
            <Logo src={headerLogo} />
          </Link></div>
        </Header>

        <Content>
          <Outlet />
        </Content>
      </ContentContainer>
      
      <SideImage src={sideImage} />
    </AuthContainer>
  );
};

export default Auth;