import React, { useState } from "react";
import moment from "moment";
import { Flex, Button, Tooltip, message } from "antd";
import { VideoCameraOutlined, FileTextOutlined, FormOutlined } from "@ant-design/icons";

import { updateStudyMaterial } from "../../../api/update";
import { openInNewTab, formatFileSize } from "../../../utils/helper";

import FileUploader from "../../../components/FileUploader/Input";
import BlockSpinner from "../../../components/PageSpinner/BlockSpinner";
import FormModal from "./FormModal";
import {
  ItemContainer,
  ItemIconContainer,
  ItemTitle,
  ItemInfoContainer,
  ItemEditButton,
} from "./styledViews";

import { FileType, SMFile } from "./types";


type Props = {
  data: SMFile
  type: FileType
  fetchData: () => void
}

const ContentItem: React.FC<Props> = ({ data, type, fetchData }) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmitEdit = (formData: any) => {
    setShowEditModal(false);
    setIsLoading(true);

    const { title, file_url, thumb_url, file_size } = formData;
    const payload = {
      id: data.id,
      title,
      file_url,
      thumb_url,
      file_size,
    };
    updateStudyMaterial(payload).then(res => {
      if (!res.success) {
        setIsLoading(false);
        message.error("Failed to update.");
      }

      message.success("Updated successfully.");
      setIsLoading(false);
      fetchData();
    });
  };

  return (
    <ItemContainer>
      {(isLoading && <BlockSpinner height={267} />) || (
        <>
          <Flex align="center" gap="middle" style={{ margin: "10px 0 30px" }}>
            <ItemIconContainer>
              {(type === FileType.VIDEO && <VideoCameraOutlined />) ||
              (type === FileType.NOTE && <FileTextOutlined />)}
            </ItemIconContainer>

            <ItemTitle>{data.title}</ItemTitle>
          </Flex>

          <FileUploader
            disableUpload
            bucket={type === FileType.VIDEO ? "teacher_video" : "teacher_notes"}
            name="file_url"
            value={data.file_url}
            label={type === FileType.VIDEO ? "Video" : "Notes"}
            onChange={() => {}}
            onView={type === FileType.VIDEO ? (url => openInNewTab(url)) : undefined}
            fileTypes={type === FileType.VIDEO ? ["video/mp4"] : ["application/pdf"]}
            maxFileSize={25}
          />

          {type === FileType.VIDEO && (
            <>
              <div style={{ height: 20 }} />

              <FileUploader
                disableUpload
                bucket="teacher_video"
                name="thumb_url"
                value={data.thumb_url || ""}
                label="Thumbnail"
                onChange={() => {}}
                onView={(url => openInNewTab(url))}
                fileTypes={["image/jpeg", "image/png"]}
                maxFileSize={0.5}
              />
            </>
          )}

          <ItemInfoContainer>
            <div>{moment(data.updated_at).format("hh:mm a, MMM DD, YYYY")}</div>
            <div style={{ flexGrow: 1 }} />
            {!!data.file_size && <div>{formatFileSize(data.file_size)}</div>}
          </ItemInfoContainer>

          <ItemEditButton>
            <Tooltip title="Edit">
              <Button shape="circle" icon={<FormOutlined />} onClick={() => setShowEditModal(true)} />
            </Tooltip>
          </ItemEditButton>
        </>
      )}

      {showEditModal && <FormModal
        show onHide={() => setShowEditModal(false)}
        type={type}
        data={data}
        onSubmit={handleSubmitEdit}
      />}
    </ItemContainer>
  );
};

export default ContentItem;