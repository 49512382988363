/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  REVIEW_SUCCESS,
  REVIEW_FAILURE,
  REVIEW_REQUEST,
} from "../types/reviewType";
import { postReviewData } from "../../api/create";
import { updateReview } from "../../api/update";
import {
  createReviewWithTeacherId,
  getAllDataByTeacherId,
  getReview,
  getReviewById,
  getallposts,
  gettopposts,
  getallreviews,
  getStudentReviews,
} from "../../api/list";
import { deleteReview } from "../../api/delete";
import { useDispatch } from "react-redux";
export const reviewRequest = () => ({
  type: REVIEW_REQUEST,
});
export const reviewSuccess = (users) => ({
  type: REVIEW_SUCCESS,
  payload: users,
});
export const reviewFailure = (error) => ({
  type: REVIEW_FAILURE,
  payload: error,
});

export const CreateReview = (data) =>
  async function (dispatch) {
    dispatch(reviewRequest());
    return postReviewData(data)
      .then((res) => {
        if (res) {
          console.log("badge post response", res);
          dispatch(reviewSuccess({ responseStatus: res }));
          // toast.success("Review created successfully");
          return res;
        }
        dispatch(reviewFailure(res.message));
        // toast.success("Review created successfully");
        return res.message;
      })
      .catch((err) => console.log("Review not Created "));
  };

export const PostReview = (payload) => {
  return postReviewData(payload).then(res => {
    return res;
  }).catch(err => err);
};

export const UpdateReview = (data, userId) =>
  async function (dispatch) {
    dispatch(reviewRequest());
    return updateReview(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(reviewSuccess({ responseStatus: "success" }));
          //toast.success("Review Updated Successfully");
          // setTimeout(() => {
          //   window.location.href = "/banner";
          // }, 3000);
          return "success";
        }
        dispatch(reviewFailure(res.error));
        return res.message || res.error;
      })
      .catch((err) => console.log("Badge not Updated "));
  };
export const GetReviewList = (teacherId, searchValue, starCount, page, limit) =>
  async function (dispatch) {
    dispatch(reviewRequest()); // Dispatch the request action
    try {
      const params = {
        teacher_id: teacherId,
        search_value: searchValue,
        start_count: starCount,
        page: page, // Include the page parameter
        rowsPerPage: limit, // Include the limit parameter as rowsPerPage
      };
      const res = await getReviewById(params);
      if (res) {
        console.log("badge action=========", res);
        dispatch(reviewSuccess({ responseStatus: res })); // Dispatch the success action
        return res;
      } else {
        dispatch(reviewFailure("Error message")); // Dispatch the failure action with an error message
        return "Error message";
      }
    } catch (err) {
      console.log("Catch Error:", err);
      dispatch(reviewFailure(err.message)); // Dispatch the failure action with the error message from the catch block
      return err.message;
    }
  };

export const GetStudentReviews = (student_id) => {
  return getStudentReviews({ student_id }).then(res => {
    return res;
  }).catch(err => err);
};

export const GetReviewById = (params) =>
  async function (dispatch) {
    dispatch(reviewRequest());
    return getReviewById(params)
      .then((res) => {
        if (res.results) {
          dispatch(reviewSuccess(res.results));
          return res;
        }

        dispatch(reviewFailure(res.message));

        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
// UpdateReview action remains unchanged

export const DeleteReview = (ReviewId) =>
  async function (dispatch) {
    dispatch(reviewRequest());
    return deleteReview(ReviewId)
      .then((res) => {
        if (!res.error) {
          dispatch(reviewSuccess({ responseStatus: "success" }));
          //toast.success("Review Deleted Successfully");
          return ReviewId; // Return the deleted Review's ID
        }
        dispatch(reviewFailure(res.error));
        return res.error;
      })
      .catch((err) => {
        console.log("Review not Deleted");
        return err;
      });
  };

//get all reviews
export const getallreviewsData = (params) =>
  async function (dispatch) {
    dispatch(reviewRequest());
    return getallreviews(params)
      .then((res) => {
        if (res) {
          dispatch(reviewSuccess(res));
          return res;
        }
        dispatch(reviewFailure(res?.detail?.message));
        return res?.detail?.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

//get all Post Datas
export const getallpostData = (params) =>
  async function (dispatch) {
    dispatch(reviewRequest());
    return getallposts(params)
      .then((res) => {
        if (res) {
          dispatch(reviewSuccess(res));
          return res;
        }
        dispatch(reviewFailure(res?.detail?.message));
        return res?.detail?.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

//get all Post Datas
export const gettoppostData = (params) =>
  async function (dispatch) {
    dispatch(reviewRequest());
    return gettopposts(params)
      .then((res) => {
        if (res) {
          dispatch(reviewSuccess(res));
          return res;
        }
        dispatch(reviewFailure(res?.detail?.message));
        return res?.detail?.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
