const onlyNumberHandler = (e: any) => {
    const re = /[0-9 () +:]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleNumberPaste = (e: any) => {
    // Get the pasted text
    const pastedText = e.clipboardData.getData('text/plain');
    
    // Check if the pasted text contains only allowed characters
    const re = /^[0-9 ()+:]+$/g;
    if (!re.test(pastedText)) {
      e.preventDefault();
    }
  };
export  {onlyNumberHandler,handleNumberPaste};