import React from "react";
import BlogHeading from "../../components/Blog/theHeadingSection";
// import VissionMission from "../../components/AboutUs/vissionMission";
// import MeetTheTeam from "../../components/AboutUs/meetTheTeam";
// import Difference from "../../components/AboutUs/difference";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Banner from "../../components/Blog/banner";
import { getBlogs,  } from "../../api/list";
import { useEffect, useState } from "react";
import BlogCard from "../../components/Blog/blog_card";  // Adjust the path accordingly
// import {} from "./aboutusStyle";
import { hostConfig } from "../../config";


const Blogs: Function = () => {
  const [blogData, setBlogData] = useState([]);
  const getBlogList = () => {
    getBlogs().then((res) => {
      console.log(res, "res from popular subjects");
      setBlogData(res?.records[0] || []);
    });
  };
  useEffect(() => {
    getBlogList();
   
  }, []);
 
  return (
    <>
   
      <Header />
       <Banner />
       <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
          gap: "20px",
          margin: "28px 20px 60px 20px"
        }}
      >
     {blogData.map((item) => (
        <BlogCard
          key={item.id}
          id={item.id}
          title={item.title}
          subtitle={item.sub_title}
          body={item.body}
          postedAt={item.updated_at}
          coverImage={item.is_image?`${hostConfig.S3_BUCKET}${item?.image_file_name}?lastdate=` +
          Date():"https://cdn4.wpbeginner.com/wp-content/uploads/2018/07/whatisblog.png"}// Replace with the actual property name for the cover image
        />
      ))}

      
      </div>
       {/* <BlogHeading/> */}
      {/* <TheCompanySection />
      <VissionMission />
      <MeetTheTeam />
      <Difference /> */} 
      <Footer />
    </>
  );
};

export default Blogs;
