const faqTextData = {
  heading: "Frequently asked questions",
  faqContent:
    "Tution Mitra is committed to help you in your tuition finding journey. Please connect with customer service if these questions do not serve your purpose.",
  accordianHeading1: "Why should you choose Tution Mitra?",
  accordianHeadingBody1:
    "Tution Mitra is committed to help the students find a quality and affordable education in your vicinity. We believe that finding a good teacher is one of the most critical steps in students’ career. Our applications will not just help you in saving time in finding the coaching institute but also allow you to book the class online in advance for the coaching institutes where seats are filled up quickly.",
  accordiaaHeading2: "How can you search for the teachers?",
  accordianHeadingBody2:
    "Tution Mitra is committed to help the students find a  quality and affordable education in your vicinity. We believe that finding a good teacher is one of the most  critical steps in students’ career. Our applications will not just help you in saving time in finding the coaching institute but also allow you to book the class online in advance for the coaching institutes where seats are filled  up quickly.",
  accordiaaHeading3: "How can you book a demo class?",
  accordianHeadingBody3:
    "Tution Mitra is committed to help the students find a quality and affordable education in your vicinity. We believe that finding a good teacher is one of the most critical steps in students’ career. Our applications will not just help you in saving time in finding the coaching institute but also allow you to book the class online in advance for the coaching institutes where seats are filled up quickly.",
  accordiaaHeading4:
    "How can you confirm your enrollment for the coaching institutes?",
  accordianHeadingBody4:
    "Tution Mitra is committed to help the students find a quality and affordable education in your vicinity. We believe that finding a good teacher is one of the most critical steps in students’ career. Our applications will not just help you in saving time in finding the coaching institute but also allow you to book the class online in advance for the coaching institutes where seats are filled up quickly.",
};
export default faqTextData;
