/* eslint-disable no-dupe-keys */
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Avaibility from "../../assets/images/avaibility.svg";
import Education from "../../assets/images/education.svg";
import Location from "../../assets/images/location.svg";
import Email from "../../assets/images/mail.svg";
import Phone from "../../assets/images/phone.svg";
import Ratting from "../../assets/images/rattings.svg";
import Rupees from "../../assets/images/rupee.svg";
import Website from "../../assets/images/website.svg";
import BookADemo from "../../components/Book a demo/index";
import CongratulationModal from "../../components/CongratulationModal/index";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LoginModal from "../../components/LoginModal/index_old";
import SubBanner from "../../components/SubBanner";

import nodata from "../../assets/images/no-data.png";
import { hostConfig } from "../../config";
import {
  TuitionSearchTitle,
  TutionButton,
  TutionData,
  TutionDetails,
  TutionIcon,
  TutionImg,
  TutionListMain,
  TutionListWrap,
  TutionName,
  TutionProData,
  TutionProfile,
} from "./tuitionlistStyle";

const TutionList: Function = () => {
  const linkStyle = {
    color: "black",
    textDecoration: "none",
  };
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [loginModal, setLoginModal] = useState(false);
  let dateInfo = moment(new Date()).format("MM-DD-YYYY");
  const [popUpValue, setPopUpValue] = useState({
    date: dateInfo,
    radio: "",
  });
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreValue, setLoadMoreValue] = useState(5);
  const [validation, setValidation] = useState("");
  const calculateOverallReviewCount = (
    totalReviewStarCount: any,
    reviewStudentCount: any
  ) => {
    if (reviewStudentCount === 0) {
      return 0; // Return 0 when dividing by 0
    }
    if (totalReviewStarCount === 0) {
      return 0; // Return 0 when dividing by 0
    }
    if (isNaN(totalReviewStarCount) || isNaN(reviewStudentCount)) {
      return 0; // Return an error message for non-numeric inputs
    }
    const result = totalReviewStarCount / reviewStudentCount; // Calculate the result as a number
    const isInteger = result % 1 === 0;
    if (isInteger) {
      return result.toFixed(0);
    } else {
      return result.toFixed(1);
    }
  };
  const handleShow = () => {
    setShow(true);
    setPopUpValue({ date: dateInfo, radio: "" });
  };
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  console.log(params, "iotemsadsdsgffh");
  const navigateToUserProfilePage = (item: any) => {
    navigate(
      `${"/tuition-details"}?SUBJECT_NAME=${item?.subjectName}&TIME=${
        params.TIME
      }&LOCATION_NAME=${params?.LOCATION_NAME}&DAYS=${
        params.DAYS_OF_WEEK
      }&CLASS_NAME=${item.className}&CITY_NAME=${item.cityName}&PHONE_NUMBER=${
        item.phoneNumber
      }&WEBSITE=${item.website}&EMAIL=${item.email}&COST=${item.cost}&RATING=${
        item.rating
      }&REVIEW=${item.review}&SEARCH_LIST=${item.searchList}&INSTITUTE_NAME=${
        item.instituteName
      }&PHONE_NUMBER=${item?.contactDetails}&PHOTO=${item?.photo}&SEATS=${
        item?.availableSeats
      }&TEACHER_ID=${item?.teacherId}&BATCH_ID=${item.batchId}&BATCH_TIME=${
        item.batchTime
      }`
    );
    localStorage.setItem("tuition-list", params?.LOCATION_NAME);
  };
  const handleChangeForRadio = (e: any) => {
    setPopUpValue({ ...popUpValue, [e.target.name]: e.target.value });
    setValidation("");
  };

  const onChangePopUp = (value: any) => {
    setPopUpValue({ ...popUpValue, date: moment(value).format("MM-DD-YYYY") });
  };
  const getLoginInfo = useSelector((state: any) => state?.login);
  const searchTutionResult = useSelector(
    (state: any) => state?.searchTution?.data
  );
  const resultCount = useSelector(
    (state: any) => state?.searchTution?.count
  );
  // setLoading(true);
  const modifiedResult =
    typeof searchTutionResult == "object"
      ? searchTutionResult?.[1]?.tuition_details?.map((item: any) => {
          // console.log(item, "searchTutionResult");
          return {
            cityName: item?.teacher?.city,
            className: "",
            batchId: item?.batch?.batch_id,
            batchTime: item?.batch?.timing,
            contactDetails: item?.teacher?.mobile_number,
            cost: item?.teacher?.fee_per_month,
            email: item?.teacher?.email_id,
            instituteName: item?.teacher?.tuition_name,
            located: item?.teacher?.address,
            locationName: item?.teacher?.location,
            onlineOrOfflineClass: "-",
            phoneNumber: item?.teacher?.mobile_number,
            photo:
              item.teacher?.is_profile == true
                ? `${hostConfig.S3_BUCKET}${item?.teacher?.profile_filename}`
                : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAREAAAC4CAMAAADzLiguAAAANlBMVEXp7vG6vsHs8fS2ur3c4eTU2dzm6u3P1Ne4vL/u8/a4vL67v8G0ubzDx8rY3eDEyMvh5unKz9Izr04MAAADb0lEQVR4nO2c63KrIBRGFY1CY4x5/5c93nKiICZGGOvuWj86adowYc0HWxgxSQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOC3oiJwdJ/2oJr6Epy6Sc4qxeTXKtNPfoYfP9NXDj//f0xfv97oX2X6cU4l5pGl6TWNgdbF0b37AnPXUWwMVEd37wvqLKKQNnzm6A5uxcRMSEuWH93DrTRV/8XbaVBnQdFj9u4nm13Vpc+ILk3wy5FCn3LYqHL43hG+9ti0PqmRCNGO2HRMVJlGNqIx8mpakpEQyzRBRlSSd+u0vT0RY8Tkt6rq1mnXcl9fpBjp130DOt2Vk8HI9exG1G16VV81u5qWkBF7Ibxn6SrDSF5ZC7UdqxIRRoyzcZR9P25EGCnsiLRLwK87JMGIqt3NkjdL15VdQxFGSkfIm+v7Irt7jUmovm0f3B3o1Q7pVHuViMjIZeOo6aYdffP8hwQjSePuQq+U33Ee9ikRYcQ4tSar/Z996vMoEWHkue31wTSiJpV6WYkII4myjFS5rz/FdIAtKpFhxJpJqod3Xp3POEtKJFTf7vdGv2KSeYU4F7cLSoRkJFHJvRqcZDr3CnFrkntdIsVIW3CK8tam/ZEbb1+ckrSUEjlG2jeNUsbvw10PjimZf0KSkfVPLAyZxYHzV4woT0LcgSOk1rylWLu7YpaSv5KR9ftvpin5G0ZWhoyjRKIRU1tvF9XbO5JeSgQaMXU1nyrfJmSmRJ6RVkia3iZ/+CAhaVdcRiXijPRCpoPAex3iSYm06qvq+Q7ZZ0NmVDIxIiYjTyGdkv5vG4SINGIm9/32Kfl4yAg1YuppIlolWxIi0Yip7R2ybTdGizNiC9mMFlZr1O6zA8Iysjsh0oy0ZXf36SNRRsxlU1WRb8RcQpw/EmSkuw4JcGJPkJE6wJBJJVXfxXuMdho5d0YwkmDEBSM2GLGJboRaYxs5d0YSjNgZeVRBjoNXYowkTR6GsWkBRgI3jRG7aYzYTWPEbvqkRqI97sCc1MiwaaYfSRGa/JzPH3k+oyYNciEyZ2j4dE8Ac49vhmXHYdCjyOM+68p3QusXY8owm6uL6LPNqz0RlWTXozv3Haq5R5hXW66XMyakxwRb400p/IcNAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4FD+AZS0NBe99dfKAAAAAElFTkSuQmCC",
            rating: "",
            review: "",
            searchList: item?.teacher?.discription,
            subjectName: item?.batch?.subject,
            teacherId: item?.teacher?.id,
            teacherName:
              item?.teacher?.first_name + " " + item?.teacher?.last_name,
            website: item?.teacher?.website,
            education: item?.teacher?.education,
            availability: item?.teacher?.avaibility,
            availableSeats:
              item?.batch?.available_seats - item?.batch?.seats_occupied,
          };
        })
      : [];
      // setLoading(false);
  console.log(searchTutionResult);
  const getData = localStorage.getItem("email-password");

  const handleClose1 = () => setModalShow(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <SubBanner />
      <TutionListMain>
       {loading===true?<></>: <Container>
         {modifiedResult?.length !== 0? <TuitionSearchTitle>
          {resultCount} {resultCount === 1 ? "tuition": "tuitions"} available in {params?.SELECT_AREA && params?.SELECT_AREA + (params?.LOCATION_NAME ? ',' : '')} {params?.LOCATION_NAME}
            </TuitionSearchTitle>:<>
            <TuitionSearchTitle>Sorry, no tuitions matched with your query</TuitionSearchTitle>
            <h3 style={{ fontSize: 36 }}>You can check out our <a href="/#popular-tuitions">Popular Tuitions.</a></h3>
          </>}
          <>
            {modifiedResult?.length === 0 ? (<></>
              
            ) : (
              modifiedResult?.map((item: any) => {
               
                return (
                  <TutionListWrap>
                    <TutionDetails>
                      <TutionImg>
                        <img src={item.photo} alt="Profile photo" />
                      </TutionImg>
                      <TutionName>
                        <h4>{item.instituteName}</h4>
                        <span>Tuition Address - {item.located}</span>
                        {/* <p style={{ padding: 0 }}>
                          Subject - {item.subjectName}
                        </p> */}
                        <p>{item.searchList}</p>
                        <p>{item.onlineOrOfflineClass}</p>
                      </TutionName>
                    </TutionDetails>
                    <TutionProfile>
                      <TutionProData>
                        <TutionIcon>
                          <img src={Ratting} alt="Ratting" />
                        </TutionIcon>
                        <TutionData>
                          <span>Ratings</span>
                          <h6>
                            {calculateOverallReviewCount(
                              searchTutionResult?.[0]?.teacher_reviews?.[
                                item?.teacherId
                              ]?.overall_star_count,
                              searchTutionResult?.[0]?.teacher_reviews?.[
                                item?.teacherId
                              ]?.student_count
                            )}{" "}
                            / 5 /{" "}
                            {
                              searchTutionResult?.[0]?.teacher_reviews?.[
                                item?.teacherId
                              ]?.student_count
                            }{" "}
                            reviews
                          </h6>
                        </TutionData>
                      </TutionProData>
                      <TutionProData>
                        <TutionIcon>
                          <img src={Education} alt="Education" />
                        </TutionIcon>
                        <TutionData>
                          <span>Education</span>
                          <h6>{item?.education}</h6>
                        </TutionData>
                      </TutionProData>
                      <TutionProData>
                        <TutionIcon>
                          <img src={Avaibility} alt="Avaibility" />
                        </TutionIcon>
                        <TutionData>
                          <span>Availability</span>
                          <h6>{item?.availability}</h6>
                        </TutionData>
                      </TutionProData>
                      <TutionProData>
                        <TutionIcon>
                          <img src={Rupees} alt="Rupees" />
                        </TutionIcon>
                        <TutionData>
                          <span>Average fee per subject</span>
                          <p>Rs. {item.cost} / -</p>
                        </TutionData>
                      </TutionProData>
                      <TutionProData>
                        <TutionIcon>
                          <img src={Location} alt="Location" />
                        </TutionIcon>
                        <TutionData>
                          <span>Location</span>
                          <h6>{item.cityName}</h6>
                        </TutionData>
                      </TutionProData>
                      <TutionProData>
                        <TutionIcon>
                          <img src={Phone} alt="Phone" />
                        </TutionIcon>
                        <TutionData>
                          <span>Phone</span>
                          <p>{item.contactDetails}</p>
                        </TutionData>
                      </TutionProData>
                      <TutionProData>
                        <TutionIcon>
                          <img src={Email} alt="Email" />
                        </TutionIcon>
                        <TutionData>
                          <span>Email</span>
                          <p>
                            
                            
                            <a href={`mailto:${item.email}`} style={linkStyle}>{item.email}</a>
                            </p>
                        </TutionData>
                      </TutionProData>
                      {item.website && (
                        <TutionProData>
                          <TutionIcon>
                            <img src={Website} alt="Website" />
                          </TutionIcon>
                          <TutionData>
                            <span>Website</span>
                            <p>
                            <a href={`http://${item.website}`} target="_blank" style={linkStyle}>{item.website}</a>
                            </p>
                          </TutionData>
                        </TutionProData>
                      )}
                    </TutionProfile>
                    <TutionButton>
                      <Button
                        variant="secondary"
                        type="submit"
                        onClick={() => navigateToUserProfilePage(item)}
                      >
                        View Profile
                      </Button>
                      {item?.bookNow === true && (
                        <Button
                          type="submit"
                          variant="secondary"
                          onClick={handleShow}
                        >
                          Book a Demo Class
                        </Button>
                      )}
                    </TutionButton>
                  </TutionListWrap>
                );
              })
            )}
          </>
          {/* {loadMoreValue < tuitionListText.length && (
            <LoadMore
              onClick={() => {
                setLoadMore(!loadMore);
                setLoadMoreValue(loadMoreValue + 1);
              }}
            >
              <span>Load More</span>
            </LoadMore>
          )} */}

          <BookADemo
            onChangePopUp={onChangePopUp}
            popUpValue={popUpValue}
            handleChangeForRadio={handleChangeForRadio}
            setModalShow={setModalShow}
            setLoginModal={setLoginModal}
            getLoginInfo={getLoginInfo}
            show={show}
            setShow={setShow}
            validation={validation}
            setValidation={setValidation}
          />

          {(!getLoginInfo?.data || getData) && (
            <LoginModal
              loginModal={loginModal}
              setLoginModal={setLoginModal}
              modalShow={modalShow}
              setModalShow={setModalShow}
            />
          )}

          <CongratulationModal
            modalShow={modalShow}
            onHide={handleClose1}
            popUpValue={popUpValue}
            setModalShow={setModalShow}
          />
        </Container>}
      </TutionListMain>
      <Footer />
    </>
  );
};

export default TutionList;
