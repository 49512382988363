import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SEARCH_FAILURE,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
} from "../types/searchType";
import store from "../store";
import axios from "axios";
import { URL_CONSTANTS } from "../../api/urls";
import { hostConfig } from "../../config";

export const SearchRequest = () => ({
  type: SEARCH_REQUEST,
});
export const searchSuccess = (results, count) => ({
  type: SEARCH_SUCCESS,
  payload: results,
  count
});

export const searchFailure = (error) => ({
  type: SEARCH_FAILURE,
  payload: error,
});

export const SearchTution = async (data) => {
  const token = localStorage.getItem("token");
  const dispatch = store.dispatch;
  try {
    dispatch(SearchRequest());
    const url = `${hostConfig.API_URL}${URL_CONSTANTS.tutionSearch}`;
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data) {
      dispatch(searchSuccess(response.data.records, response.data.total_records));
    }
  } catch (error) {
    dispatch(searchFailure(error));
    console.log(error);
  }
};
