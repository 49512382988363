import styled from "styled-components";


export const NavLogo = styled.img`
  height: 28px;
  transition: all 0.2s ease-in-out;
  @media (max-width: 1199px) {
    height: 22px;
  }
`;

export const HeaderMain = styled.div`
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0px 10px 0px 60px;
  @media (max-width: 991px) {
    padding: 0px 20px;
  }

  .navbar {
    font-family: "Montserrat", sans-serif;
    padding: 40px 0;
    &.user-active {
      padding: 30px 0;
    }
    @media (max-width: 1199px) {
      padding: 20px 0;
      &.user-active {
        padding: 15px 0;
      }
    }
  }
  
  .navbar-collapse {
    justify-content: center;
  }

  .nav-item {
    margin: 0 30px;
    @media (max-width: 1600px) {
      margin: 0 20px;
    }
    @media (max-width: 1400px) {
      margin: 0 15px;
    }
    @media (max-width: 1199px) {
      margin: 0 10px;
    }
    @media (max-width: 991px) {
      margin: 0 0px;
    }
    
    .btn-primary {
      padding: 0px;
      font-size: 16px;
      line-height: 140%;
      position: relative;
      transition: all 0.2s ease-in-out;
      background-color: transparent;
      border: 0px;
    }

    .nav-link {
      padding: 0px;
      color: rgba(0,0,0,0.6);
      font-size: 16px;
      line-height: 120%;
      position: relative;
      transition: all 0.2s ease-in-out;
      @media (max-width: 1199px) {
        font-size: 14px;
      }
      @media (max-width: 991px) {
        padding: 12px;
      }
      &.active {
        color: black;
      }

      &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 3px;
        background: #940e34;
        border-radius: 10px;
        left: 0;
        right: 0;
        bottom: -10px;
        margin: 0 auto;
        transition: all 0.2s ease-out;
        @media (max-width: 991px) {
          position: absolute;
          left: 12px;
          right: 0px;
          bottom: 5px;
          margin: 0 0;
        }
      }
      &:hover {
        &::before {
          width: 30px;
        }
      }
      &.active {
        &::before {
          width: 40%;
        }
      }
      &.no-hand-icon {
        pointer-events: none !important;
        cursor: default !important;
        &:hover {
          &::before {
            width: 0; /* Set the width to 0 on hover */
          }
        }
      }
    }
  }
`;
export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const RightHeader = styled.div`
  margin-right: 20px;
  .btn {
    width: 130px;
    padding: 10px 0;
    margin-left: 20px;
    @media (max-width: 1400px) {
      width: 100px;
    }
    @media (max-width: 1199px) {
      margin-left: 10px;
      font-size: 14px;
    }
    @media (max-width: 600px) {
      width: 80px;
      font-size: 12px;
    }
    &:hover {
      background: #820c2e;
      color: #ffffff;
    }
    &:active {
      background: #940e34;
      color: #ffffff;
      box-shadow: none;
    }
  }
`;
export const AfterLogin = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 600px) {
    margin-left: 20px;
  }
  .dropdown {
    .dropdown-toggle {
      background: transparent;
      border: none;
      padding: 0;
      height: 32px;
      width: 32px;
      text-align: center;
      margin-left: 8px;
      &::after {
        display: none;
      }
      &:active {
        background: transparent !important;
      }
    }
    .dropdown-menu {
      box-shadow: 0px 30px 30px #7070700d;
      border: 1px solid #7070701a;
      border-radius: 10px;
      background: #ffffff;
      padding: 5px 15px;
      top: 45px !important;
      .dropdown-item {
        color: #012834;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        border-bottom: 1px solid rgba(112, 112, 112, 0.2);
        padding: 10px 0;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: transparent;
          color: #940e34;
        }
        &:active {
          background: transparent;
          color: #012834;
        }
      }
    }
  }
`;
export const UserImageWrap = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  img {
    border: 2px solid #ffffff;
    border-radius: 30px;
    box-shadow: 0px 10px 10px #7070700d;
  
  // @media (max-width: 1366px) {
  //   width: 48px;
  //   height: 48px;
  // }
  
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
export const UserInfo = styled.div``;
export const UserName = styled.h3`
  color: #940e34;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 5px;
  font-weight: 700;
`;
export const UserCategory = styled.span`
  color: #707070;
  display: block;
  font-size: 12px;
  line-height: 120%;
  font-weight: 400;
`;
export const BookTuitionHeader = styled.div`
  max-width: 782px;
  .btn-close {
    height: 20px;
    width: 20px;
    &:focus {
      box-shadow: none;
    }
  }
  .modal-header {
    background: #f7f7f7;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 32px 40px;
    border-bottom: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    @media (max-width: 1440px) {
      padding: 20px 32px;
    }
    @media (max-width: 1366px) {
      padding: 18px 30px;
    }
    .modal-title {
      font-size: 30px;
      line-height: 120%;
      font-weight: 800;
      @media (max-width: 1440px) {
        font-size: 28px;
      }
      @media (max-width: 1366px) {
        font-size: 26px;
      }
      @media (max-width: 1280px) {
        font-size: 24px;
      }
    }
  }
`;

export const BookModalBody = styled.div`
  .modal-body {
    padding: 40px 40px;
    @media (max-width: 1440px) {
      padding: 30px 20px 35px;
    }
    @media (max-width: 1280px) {
      padding: 20px 20px 35px;
    }
    .form-label {
      font-size: 14px;
      line-height: 130%;
      color: #707070;
      margin-bottom: 15px;
    }
    .form-control {
      font-size: 16px;
      line-height: 130%;
      font-weight: 500;
      padding: 19px;
      @media (max-width: 1440px) {
        padding: 14px 20px;
        font-size: 14px;
      }
      &::placeholder {
        color: #707070;
        &:focus {
          background-color: #d3d3d3;
        }
      }
    }
    .ant-select-selector {
      /* border-radius: 30px; */
      background: #f7f7f7 !important;
      font-size: 16px;
      line-height: 120%;
      font-weight: 500;
      color: #012834;
      border: 1px solid #f7f7f7 !important;
      min-height: 60px;
      padding: 5px 20px;
      opacity: 1;
      @media (max-width: 1440px) {
        min-height: 48px;
      }

      .ant-select-selection-placeholder {
        inset-inline-start: 20px;
        inset-inline-end: 20px;
        color: #707070;
        font-family: "Montserrat", sans-serif;
        @media (max-width: 1440px) {
          font-size: 14px;
        }
      }
      .ant-select-selection-item {
        // background: #ffffff;
        // border: 1px solid #ffffff;
        border-radius: 20px;
        padding: 3px 5px;
        height: 26px;
        font-size: 16px;
        line-height: 120%;
        font-weight: 500;
        color: #012834;
        .anticon {
          vertical-align: middle;
          svg {
            fill: #940e34;
          }
        }
      }
    }
    .CloseBtn {
      position: relative;
      .resetbtn {
        position: absolute;
        top: 45px;
        right: 10px;
        height: 20px;
        width: 20px;
        color: #940e34;
       // opacity: 0.6;
        background:#ffffff;
        @media (max-width: 1440px) {
          top: 48px;
        }
      }
    }
  }
`;

export const ModalFooter = styled.div`
  .modal-footer {
    border-top: 0px;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 40px;
    @media (max-width: 1440px) {
      padding: 0;
    }
    .btn {
      width: 214px;
      padding: 20px;
      &:focus-visible {
        background: #940e34;
        color: #ffffff;
        border-color: #940e34;
        box-shadow: none;
      }
      &:active {
        background: #940e34;
        color: #ffffff;
        box-shadow: none;
      }
      @media (max-width: 1440px) {
        padding: 15px;
      }
    }
  }
`;
export const MyVerticallyCenteredModal = styled.div``;
