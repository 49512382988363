import React, { useState } from "react";
import Header from "../../components/Header";
import StyledInput from "../../components/TuorPanel/Input/input";
import { useNavigate } from "react-router-dom";
import "./availableservice.css";
import PropertyDetails from "./propertyDetails";

const PostYourService = ({ formik, active, setActive,state }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    type1: "",
    type2: "",
    contact: {
      mobileNumber: "",
      email: "",
    },
  });
  const [selectedType, setSelectedType] = useState("");
  const [selectedType1, setSelectedType1] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  // const handleNavigateToPropertyDetails = () => {
  //   navigate("/property-details", { state: formik });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContactInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      contact: {
        ...formData.contact,
        [name]: value,
      },
    });
  };

  return (
    <>
      {/* <Header /> */}
      {active === 1 ? (
        <div className="container">
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-11">
              <div
                style={{
                  textAlign: "start",
                  marginTop: "20px",
                  marginBottom: "40px",
                }}
              >
                <h1>Glad, you decided to post your rental</h1>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "30px" }}>
            {/* //  Card 1 */}
            <div className="col-md-1" />
            <div className="col-md-10">
              <div className="card" style={{ border: "none" }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4 grid-post">
                      <h2 style={{ color: "#940E34" }}>I am</h2>
                    </div>
                    <div className="col-md-4 grid-post">
                      <button
                        className={`btn-post-service ${
                          selectedType === "Owner" ? "selected" : ""
                        }`}
                        onClick={() => {
                          setFormData({ ...formData, type1: "Owner" });
                          setSelectedType("Owner");
                          formik.setFieldValue("iam", "Owner");
                        }}
                      >
                        <p className="mb-0" style={{ fontWeight: 700 }}>
                          Owner
                        </p>
                      </button>
                    </div>{" "}
                    <div className="col-md-4 grid-post">
                      <button
                        className={`btn-post-service ${
                          selectedType === "Rental" ? "selected" : ""
                        }`}
                        onClick={() => {
                          setFormData({ ...formData, type1: "Rental" });
                          setSelectedType("Rental");
                          formik.setFieldValue("iam", "Rental");
                        }}
                      >
                        <p className="mb-0" style={{ fontWeight: 700 }}>
                          Rental
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1" />
            {/* // card 2 */}
            <div className="col-md-1" />
            <div className="col-md-10">
              <div className="card" style={{ border: "none" }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4 grid-post">
                      <h2 style={{ color: "#940E34" }}>I want to </h2>
                    </div>
                    <div className="col-md-4 grid-post">
                      <button
                        className={`btn-post-service ${
                          selectedType1 === "Rent/Lease" ? "selected" : ""
                        }`}
                        onClick={() => {
                          setFormData({ ...formData, type2: "Rent/Lease" });
                          setSelectedType1("Rent/Lease");
                          formik.setFieldValue("i_want_to", "Rent/Lease");
                        }}
                      >
                        <p className="mb-0" style={{ fontWeight: 700 }}>
                          Rent/Lease
                        </p>
                      </button>
                    </div>{" "}
                    <div className="col-md-4 grid-post">
                      <button
                        className={`btn-post-service ${
                          selectedType1 === "List as Paying Guest"
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            type2: "List as Paying Guest",
                          });
                          setSelectedType1("List as Paying Guest");
                          formik.setFieldValue(
                            "i_want_to",
                            "List as Paying Guest"
                          );
                        }}
                      >
                        <p className="mb-0" style={{ fontWeight: 700 }}>
                          List as Paying Guest
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1" />
            {/* // card 3 */}
            <div className="col-md-1" />
            <div className="col-md-10">
              <div className="card" style={{ border: "none" }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4 grid-post">
                      <h2 style={{ color: "#940E34" }}>My Contact</h2>
                    </div>
                    <div className="col-md-4 grid-post">
                      <StyledInput
                        type="number"
                        placeholder="+91"
                        width="100%"
                        name="mobile_number"
                        value={formik.values.mobile_number}
                        onChange={(e) => {
                          console.log(e?.target?.value, "sadfgh");
                          formik.setFieldValue(
                            "mobile_number",
                            e?.target?.value
                          );
                        }}
                        className={
                          selectedContact === "mobileNumber" ? "selected" : ""
                        }
                      />
                    </div>{" "}
                    <div className="col-md-4 grid-post">
                      <StyledInput
                        type="text"
                        placeholder="Email"
                        width="100%"
                        name="email_id"
                        value={formik.values.email_id}
                        onChange={(e) => {
                          formik.setFieldValue("email_id", e?.target?.value);
                        }}
                        className={
                          selectedContact === "email" ? "selected" : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1" />
          </div>
          <div className="row">
            <div className="col-md-5" />
            <div className="col-md-5 grid-post">
              <button
                className="btn-begin pt-3 pb-3"
                onClick={() => {
                  setActive(2);
                }}
                disabled={
                  selectedType1 === "" ||
                  selectedType === "" ||
                  formik.values.email_id === "" ||
                  formik.values.mobile_number === ""
                }
              >
                <p
                  className="mb-0"
                  style={{ fontWeight: 600, fontSize: "18px" }}
                >
                  Let's begin
                </p>
              </button>
            </div>
            {/* <div className="col-md-4" /> */}
          </div>
        </div>
      ) : (
        active === 2 && <PropertyDetails data={formik?.values} setActive={setActive} state={state}/>
      )}
    </>
  );
};

export default PostYourService;
