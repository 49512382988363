/** **************************** Import Types ****************************** */
import {
  STUDENT_PROFILE_REQUEST,
  STUDENT_PROFILE_SUCCESS,
  STUDENT_PROFILE_FAILURE,
} from "../types/studentProfilePageType";

const initialState = {
  loading: false,
  studentProfileResponse: "",
  error: "",
};

const studentProfilePageReducer = (state = initialState, action) => {
  console.log("form student Profile api reducer", action.payload);
  switch (action.type) {
    case STUDENT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        studentProfileResponse: "",
      };
    case STUDENT_PROFILE_SUCCESS:
      return {
        loading: false,
        studentProfileResponse: action.payload.responseStatus,
        error: "",
      };
    case STUDENT_PROFILE_FAILURE:
      return {
        loading: false,
        studentProfileResponse: "error",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default studentProfilePageReducer;
