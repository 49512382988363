import React, { useState, useMemo } from "react";
import _ from "lodash";
import { Container, Row, Col } from "react-bootstrap";

import BatchItem from "./ProfileBatch";
import ProfileRatings from "./ProfileRatings";

import {
  SubjectsContainer,
  ListHeader,
  SubjectsList,
  SubjectButton,
  BatchesList
} from "./styledViews";


type Props = {
  teacherData: any
  subjects: any[]
  batches: any[]
  ratings: any
}

const ProfileSubjects: React.FC<Props> = ({ teacherData, subjects, batches, ratings }) => {
  const [activeSubject, setActiveSubject] = useState<string>(subjects[0].subject);
  const batchList = useMemo(
    () => _.filter(batches, b => b.subject === activeSubject),
    [batches, activeSubject]
  );

 return (
    <SubjectsContainer>
      <Container>
        <Row>
          <Col md={5} lg={4}>
            <ListHeader>Subjects Offered</ListHeader>

            <SubjectsList>
              {_.map(subjects, (item) => (
                <SubjectButton
                  key={item.subject}
                  onClick={() => setActiveSubject(item.subject)}
                  className={activeSubject === item.subject ? "active" : ""}
                >
                  {item.subject}
                </SubjectButton>
              ))}
            </SubjectsList>
          </Col>

          <Col md={7} lg={8} className="content-container">
            <ListHeader>Batches</ListHeader>

            <BatchesList>
              {_.map(batchList, (item) => (
                <BatchItem key={item.batch_id} batch={item} teacherData={teacherData} subject={activeSubject} />
              ))}
            </BatchesList>

            <ProfileRatings reviewCount={ratings} />
          </Col>
        </Row>
      </Container>
    </SubjectsContainer>
 );
};

export default ProfileSubjects;