const data = {
  theCompany:
    "EthoSIty solutions is a new entrant into the tuition platform service providing service. However, we are here to make a difference. The tuition finding application – StudentMitr – is a  culmination of long research. The philosophy of the name StudentMitr is to be a friend of every student.",
  visionAndMission:
    "Our objective is to bridge the gap between students and teachers and engage with the students across their career.",
  meetTheTeam:
    " Tution Mitra is the proprietary software of EthosITy solutions. We are a technology company run by experienced IT  professionals. The average years of experience of company executives is more than 15 years.",
  makeADifferenceFirst:
    "We provide real time updates of the batch availability in the  coaching institutes.",
  makeADifferenceSecond:
    "We allow you to book the classes in advance for the popular teachers.",
  makeADifferenceThird:
    " We provide verified information about the teachers and allow current students to share the review about the teacher.",
  makeADifferenceFourth:
    "We provide you the updated list of demo classes based on your search criteria.",
    contactUs:
    "Our policy is valid for a period of 7 calendar days from the date of the purchase. If you are not satisfied with the service, you can ask for a refund. If the period of 7 days has lapsed since the purchase, we can’t, unfortunately, offer you a refund. "
    
};

export default data;
