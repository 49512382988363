// PasswordInput.tsx

import React, { useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import CloseEye from "../../assets/images/eye-off-icon.svg";
import OpenEye from "../../assets/images/eye-open-icon.svg";



interface PasswordInputProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void; // TODO: remove
  error?: string; 
  placeholder?: string;
  capsLockWarning?: boolean // TODO: remove
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  onKeyDown, // TODO: remove
  error,
  placeholder = "********",
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false);
  const [capsLockWarning, setCapsLockWarning] = useState<boolean>(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handlePasswordBlur = (e: any) => {
    setIsPasswordFocused(false);
    if(onBlur) onBlur(e);
  };

  const handlePasswordFocus = (e: any) => {
    setIsPasswordFocused(true);
  };

  const handleKeyUp = (keyEvent: any) => {
    if (keyEvent.getModifierState("CapsLock") && (keyEvent.keyCode !== 20 || !capsLockWarning)) {
      setCapsLockWarning(true);
    } else {
      setCapsLockWarning(false);
    }
  };

  return (
    <Form.Group className="form-group">
      <Form.Label style={{ color: 'black' }}>
        {label}
        {capsLockWarning && (
          <span style={{ color: "red", fontSize: 12 }}>
            &nbsp;&nbsp;&nbsp;Caps Lock Enabled
          </span>
        )}
      </Form.Label>
      <InputGroup>
        <Form.Control
          type={showPassword ? "text" : "password"}
          placeholder={isPasswordFocused ? "" : placeholder}
          value={value}
          onChange={onChange}
          onKeyUp={handleKeyUp}
          onBlur={handlePasswordBlur}
          autoComplete="off"
          onFocus={handlePasswordFocus}
          name={name}
        />
        <Button className="icon-wrap" onClick={togglePasswordVisibility}>
          {showPassword ? (
            <img src={CloseEye} alt="close eye" className="close-eye" />
          ) : (
            <img src={OpenEye} alt="Open eye" className="open-eye" />
          )}
        </Button>
      </InputGroup>
      {error && <div className="error">{error}</div>}
    </Form.Group>
  );
};

export default PasswordInput;