import React, { ButtonHTMLAttributes, ReactNode } from "react";
import styled from "styled-components";

interface RoundedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary"|"secondary-disabled";
  icon?: ReactNode;
  width?: string; // Add a width prop
}

const StyledButton = styled.button<RoundedButtonProps>`
  padding: 10px 10px;
  border: 1px solid #940e34;
  border-radius: 20px;
  font-weight: 500;
  margin: 10px 0px 10px 10px;
  text-align: center !important;
  align-items: center;
  cursor: pointer;

  ${({ variant }: RoundedButtonProps) =>
    variant === "primary"
      ? `
        background-color: #940e34;
        color: white;`
      :variant === "secondary-disabled"?
      `
        background-color: #fff;
        color: #940e34;
        pointer-events: none;
      `
      : `
        background-color: #fff;
        color: #940e34;
      `};

  svg {
    margin-right: 8px;
  }

  ${({ width }: RoundedButtonProps) => (width ? `width: ${width};` : `width: auto;`)}; // Use user-provided width or default width
`;

const RoundedButton: React.FC<RoundedButtonProps> = ({
  children,
  icon,
  ...rest
}) => {
  return (
    <StyledButton {...rest}>
      {icon && icon}
      {children}
    </StyledButton>
  );
};

export default RoundedButton;
