import React, { ReactNode, useState } from "react";
import _ from "lodash";
import { Container, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

import Location from "../../assets/images/location.svg";
import Phone from "../../assets/images/phone.svg";
import Email from "../../assets/images/mail.svg";
import Website from "../../assets/images/website.svg";

import Rating from "../../assets/images/rattings.svg";
import Education from "../../assets/images/education.svg";
import { ClockCircleFilled } from "@ant-design/icons";
import Rupees from "../../assets/images/rupee.svg";

import { PlayCircleFilled, CloseOutlined } from "@ant-design/icons";

import {
  SummaryContainer,
  SummaryHeader,
  SummaryLogo,
  HeaderContent,
  HeaderTitle,
  HeaderDetails,
  DetailItem,
  Line,
  SummaryDescription,
  SummaryCards,
  SummaryCard,
  VideoCard,
  CardImage,
  CardTitle,
  CardInfo
} from "./styledViews";


const Card: React.FC<any> = ({ icon, imgAlt, title, info }) => {
  return (
    <SummaryCard>
      {_.isString(icon) ? <CardImage src={icon} alt={imgAlt} /> : icon}
      <CardTitle>{title}</CardTitle>
      <CardInfo>{info}</CardInfo>
    </SummaryCard>
  );
};

const VideoModal: React.FC<any> = ({ url, onHide }) => {
  return (
    <Modal
      show={true}
      onHide={onHide}
      centered
      dialogClassName="video-modal"
    >
      <Modal.Body><ReactPlayer controls playing url={url} /></Modal.Body>

      <div style={{ position: "absolute", top: 0, right: -40, fontSize: 25, color: "white", cursor: "pointer" }}>
        <CloseOutlined onClick={onHide} />
      </div>
    </Modal>
  );
};

type Props = {
  logoUrl: string
  title: string
  city: string
  phone: string
  email: string
  website: string
  description: string
  ratings: string | ReactNode
  education: string
  availability: string
  averageFees: string
  videoUrl: string
  thumbUrl: string
}

const ProfileSummary: React.FC<Props> = ({
  logoUrl, title, city, phone, email, website, description, ratings, education, availability, averageFees, videoUrl, thumbUrl
}) => {
  const [showVideo, setShowVideo] = useState<boolean>(false);

  return (
    <Container>
      <SummaryContainer>
        <SummaryHeader>
          <SummaryLogo><img src={logoUrl} alt="Tuition Logo" /></SummaryLogo>

          <HeaderContent>
            <HeaderTitle>{title}</HeaderTitle>

            <HeaderDetails>
              <DetailItem>
                <img src={Location} alt="Location" style={{width:"22px"}} />
                <span>{city}</span>
              </DetailItem>
              <Line />
              <DetailItem>
                <img src={Phone} alt="Phone" style={{width:"15px"}} />
                <span>{phone}</span>
              </DetailItem>
              <Line />
              <DetailItem>
                <img src={Email} alt="Email" style={{width:"18px"}} />
                <a href={`mailto:${email}`}>{email}</a>
              </DetailItem>
              <Line />
              <DetailItem>
                <img src={Website} alt="Website" style={{width:"18px"}} />
                <a href={`http://${website}`} target="_blank" rel="noreferrer">{website}</a>
              </DetailItem>
            </HeaderDetails>
          </HeaderContent>
        </SummaryHeader>

        <SummaryDescription>
          {description}
        </SummaryDescription>

        <SummaryCards>
          {!!videoUrl && !!thumbUrl && <VideoCard>
            <img src={thumbUrl} alt="video" className="video-thumb" />
            <div className="video-thumb-overlay" onClick={() => setShowVideo(!showVideo)}><PlayCircleFilled /></div>
          </VideoCard>}

          <Card
            icon={Rating}
            imgAlt="Ratings"
            title="Overall Ratings"
            info={ratings}
          />

          <Card
            icon={Education}
            imgAlt="Qualification"
            title="Qualification"
            info={education}
          />

          <Card
            icon={<ClockCircleFilled className="icon" />}
            imgAlt="Availability"
            title="Availability"
            info={availability}
          />

          <Card
            icon={Rupees}
            imgAlt="Average Fee"
            title="Average fee per subject per month"
            info={`Rs. ${averageFees} / -`}
          />
        </SummaryCards>
      </SummaryContainer>

      {showVideo && <VideoModal controls url={videoUrl} onHide={() => setShowVideo(false)} />}
    </Container>
  );
};

export default ProfileSummary;