/** ****************************** Import libs *********************************** */
import { postDataApi, postFormDataApi, putDataApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const postLoginRequestData = (params) =>
  postDataApi(URL_CONSTANTS.login, params);
export const postStudentRegRequestData = (params) =>
  postDataApi(URL_CONSTANTS.studentRegistration, params);
export const postTeacherLoginRequestData = (params) =>
  postDataApi(URL_CONSTANTS.teacherLogin, params);
export const postTeacherRegisterRequestData = (params) =>
  postDataApi(URL_CONSTANTS.teacherRegistration, params);
export const postTutionSearch = (params) => {
  postDataApi(URL_CONSTANTS.tutionSearch, params);
};

//Batches
export const postBatchesData = (params) =>
  postDataApi(URL_CONSTANTS.batches, params);

export const postStudyMaterial = (params) =>
  postDataApi(URL_CONSTANTS.studyMaterials, params);

//OTP
export const postOTPData = (params) =>
  postDataApi(URL_CONSTANTS.studentOTP, params);

  export const validateOTPData = (params) =>
  postDataApi(URL_CONSTANTS.studentValidateOTP, params);
//OTP
export const postTeacherOTPData = (params) =>
  postDataApi(URL_CONSTANTS.teacherOTP, params);
export const validateTeacherOTPData = (params) =>
  postDataApi(URL_CONSTANTS.teacherValidateOTP, params);
//Password
export const postPasswordData = (params) =>
  putDataApi(URL_CONSTANTS.studentPassword, params);
export const postTeacherPasswordData = (params) =>
  putDataApi(URL_CONSTANTS.teacherPassword, params);
//Batches
export const postFilterData = (params) =>
  postDataApi(URL_CONSTANTS.filter, params);
//Batches
export const postValidationMail = (params) =>
  postDataApi(URL_CONSTANTS.mailValidation, params);
//Review
export const postReviewData = (params) =>
  postDataApi(URL_CONSTANTS.review, params);

//Student Profile
export const postStudentProfileData = (params) =>
  postDataApi(URL_CONSTANTS.studentsProfile, params);

//Student Profile
export const postAdvertisementData = (params) =>
  postFormDataApi(URL_CONSTANTS.advertisement, params);

//Class Booking
export const postClassBooking = (params) =>
  postDataApi(URL_CONSTANTS.bookingPayment, params);

export const postBookingPayment = (params) =>
  postDataApi(URL_CONSTANTS.bookingPayment, params);

export const postVerifyPayment = (params) =>
  postDataApi(URL_CONSTANTS.verifyPayment, params);

// class Booking student
export const postBookingClass = (params) =>
  postDataApi(URL_CONSTANTS.bookingstudent, params);

export const postBookingDemoClass = (params) =>
  postDataApi(URL_CONSTANTS.bookingDemoClass, params);

// message send student

// class Booking student
export const postStudentEmail = (params) =>
  postDataApi(URL_CONSTANTS.studentEmail, params);
export const postEmailToBatch = (params) =>
  postDataApi(URL_CONSTANTS.emailToBatch, params);
  export const postRental = (params) =>
  postDataApi(URL_CONSTANTS.rental, params);

// File Upload
export const uploadFile = (name, bucket, file) => {
  const formData = new FormData();
  formData.append("data", JSON.stringify({ name, bucket }));
  formData.append("file", file);
  return postFormDataApi(URL_CONSTANTS.fileUpload, formData);
};