import styled from "styled-components";


export const MainContainer = styled.div`
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
`;

export const LayoutContainer = styled.div`
  margin: 48px;
  margin-bottom: 200px;
`;

export const Logo = styled.div`
  height: 180px;
  width: 180px;
  min-width: 180px;
  border: 10px solid rgba(148,14,52,0.07);
    border-radius: 90px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 5px solid rgba(148,14,52,0.4);
    border-radius: 80px;
  }
`;

export const TuitionName = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #940E34;
  margin-top: 40px;
`;

export const Detail = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #012834;
  margin-top: 16px;
`;

// Videos Tab
export const VideoCard = styled.div`
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px;
`;

export const VideoThumb = styled.div`
  position: relative;

  img {
    width: 100%;
  }

  .video-thumb-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: rgba(0,0,0,0.2);
    }

    span {
      color: #ffffff;
      font-size: 36px;
    }
  }
`;

export const VideoTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #940E34;
  margin-top: 16px;
`;

export const VideoDate = styled.div`
  font-size: 12px;
  color: #012834;
  margin-top: 8px;
`;

// Notes Tab
export const NotesContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const NoteCard = styled.div`
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px;
  cursor: pointer;

  span {
    color: rgba(0,0,0,0.4);
    font-size: 60px;
  }
`;

export const NoteTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #940E34;
  margin-top: 16px;
`;

// Video Viewer
export const VideoPageContainer = styled.div`
  height: 100%;
  background-color: #333c4d;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VideoContent = styled.div`
  width: 60vw;
`;

export const VideoViewerTitle = styled.h3`
  margin-top: 20px;
  color: #ffffff;
`;

export const VideoViewerDate = styled.p`
  margin-bottom: 0;
  color: #ffffff;
`;