/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  STUDENT_PROFILE_SUCCESS,
  STUDENT_PROFILE_FAILURE,
  STUDENT_PROFILE_REQUEST,
} from "../types/studentProfilePageType";
import { updateStudentProfilePage } from "../../api/update";
import { getStudentProfileById } from "../../api/list";

export const StudentProfileRequest = () => ({
  type: STUDENT_PROFILE_REQUEST,
});
export const StudentProfileSuccess = (users) => ({
  type: STUDENT_PROFILE_SUCCESS,
  payload: users,
});
export const StudentProfileFailure = (error) => ({
  type: STUDENT_PROFILE_FAILURE,
  payload: error,
});

export const UpdateStudentProfileData = (data, userId) =>
  async function (dispatch) {
    dispatch(StudentProfileRequest());
    return updateStudentProfilePage(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(StudentProfileSuccess({ responseStatus: "success" }));
          //  toast.success("Student Profile Updated Successfully");
          return "success";
        }
        dispatch(StudentProfileFailure(res.error));
        return res.message || res.error;
      })
      .catch((err) => console.log("StudentProfile not Updated "));
  };

export const GetStudentProfileById = (userId, data) =>
  async function (dispatch) {
    dispatch(StudentProfileRequest());
    return getStudentProfileById(userId, data)
      .then((res) => {
        if (res) {
          dispatch(StudentProfileSuccess(res.records));
          return res;
        }
        dispatch(StudentProfileFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
