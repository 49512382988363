import styled from "styled-components";

import DownArrow from "../../assets/images/arrow-down.svg";

export const TutionDetailMain = styled.div``;

export const TutionBreadCrumbs = styled.div`
  background-color: #f7f7f7;
  padding: 23px 0px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  .breadcrumb {
    margin: 0;

    .breadcrumb-item {
      font-size: 14px;
      line-height: 120%;
      font-weight: 400;
      & + .breadcrumb-item {
        padding-left: 20px;
        margin-left: 20px;
        position: relative;
        &::before {
          background-image: url(${DownArrow});
          float: none;
          padding: 0;
          width: 14px;
          height: 8px;
          transform: rotate(-90deg);
          content: "";
          display: inline-block;
          left: -6px;
          top: 5px;
          position: absolute;
        }
      }
      a {
        color: #940e34;
        font-size: 14px;
        line-height: 120%;
        font-weight: 400;
        text-decoration: none;
      }
      &.active {
        color: rgba(1, 40, 52, 0.5);
      }
    }
  }
`;
export const TutionBreadWrap = styled.div`
  color: #940e34;
  font-size: 14px;
  line-height: 120%;
  font-weight: 400;
  Span {
    color: #012834;
    // opacity: 0.5;
    font-size: 14px;
    line-height: 120%;
    font-weight: 400;
  }
  img {
    transform: rotate(270deg);
    opacity: 0.2;
    padding: 0px 12px;
  }
`;
export const TutionProfilesWrap = styled.div`
  padding: 100px 0px;
  background-color: #f7f7f7;
  @media (max-width: 1680px) {
    padding: 90px 0px;
  }
  @media (max-width: 1440px) {
    padding: 70px 0px;
  }
  @media (max-width: 1366px) {
    padding: 50px 0px;
  }
  @media (max-width: 1280px) {
    padding: 40px 0px;
  }
`;

export const TutionProfilesDetails = styled.div``;
export const TutionDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  @media (max-width: 1440px) {
    margin-bottom: 20px;
  }
  @media (max-width: 1280px) {
    margin-bottom: 15px;
  }
`;
export const TutionImg = styled.div`
  flex: 100px;
  max-width: 100px;
  height: 100px;
  width: 100%;
  @media (max-width: 1366px) {
    flex: 90px;
    max-width: 90px;
    height: 90px;
  }
  img {
    height: 100%;
    width: 100%;
    border: 4px solid #ffffff;
    border-radius: 58px;
    box-shadow: 0px 10px 10px #7070700d;
    object-fit: cover;
  }
  @media (max-width: 500px) {
    flex: 50px;
    max-width: 50px;
    height: 50px;
  }
`;
export const TutionName = styled.div`
  flex: calc(100% - 100px);
  max-width: calc(100% - 100px);
  padding-left: 30px;
  @media (max-width: 1366px) {
    padding-left: 20px;
    flex: calc(100% - 90px);
    max-width: calc(100% - 90px);
  }
  h4 {
    font-size: 20px;
    line-height: 120%;
    color: #940e34;
    font-weight: 700;
    margin: 14px 0px 20px;
    @media (max-width: 1440px) {
      margin: 10px 0px 15px;
    }
    @media (max-width: 1366px) {
      font-size: 18px;
      margin: 10px 0px 10px;
    }
  }
  span {
    font-size: 14px;
    line-height: 130%;
    color: #012834;
    // opacity: 0.5;
    font-weight: 400;
  }
`;
export const TutionProfile = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0px -15px 52px;
  @media (max-width: 1440px) {
    margin: 0px -15px 42px;
  }
  @media (max-width: 1366px) {
    margin: 0px -15px 32px;
  }
  @media (max-width: 1280px) {
    margin: 0px -15px 22px;
  }
  P {
    font-size: 14px;
    line-height: 190%;
    color: #012834;
    opacity: 0.8;
    font-weight: 400;
    margin: 0px 15px 20px;
    @media (max-width: 1440px) {
      margin: 0px 15px 15px;
    }
    @media (max-width: 1366px) {
      margin: 0px 15px 10px;
    }
  }
`;
export const TutionProData = styled.div`
  background-color: #ffffff;
  padding: 15px 20px;
  border-radius: 20px;
  margin: 10px 15px;
  @media (max-width: 1366px) {
    min-width: 300px;
  }
`;
export const TutionIcon = styled.div`
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #940e34;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`;
export const TutionData = styled.div`
  span {
    font-size: 14px;
    line-height: 130%;
    color: #940e34;
    font-weight: 400;
  }
  h6 {
    font-size: 16px;
    line-height: 120%;
    color: #012834;
    font-weight: 400;
    margin-top: 10px;
    max-width: 240px;
    margin-bottom: 0px;
    @media (max-width: 1366px) {
      font-size: 15px;
    }
    @media (max-width: 1280px) {
      font-size: 14px;
      margin-top: 5px;
    }
  }
`;

export const TutionButton = styled.div`
  display: flex;
  flex-warp: warp;
  justify-content: space-between;
  align-items: center;
`;
export const TutionButtonSeats = styled.div`
  .btn-secondary-disabled {
    font-size: 16px;
    line-height: 120%;
    font-weight: 600;
    padding: 20px 36px 19px;
    width: 233px;
    background-color: #ffffff;
    pointer-events: none;
    border-radius: 0px;
  }
  .btn-secondary {
    font-size: 16px;
    line-height: 120%;
    font-weight: 600;
    padding: 20px 36px 19px;
    width: 233px;
    background-color: #ffffff;
    @media (max-width: 1366px) {
      font-size: 15px;
    }
    &:active {
      background-color: #940e34;
    }
    &:hover {
      background-color: #940e34;
    }
    span {
      font-size: 18px;
      line-height: 107%;
      font-weight: 700;
      @media (max-width: 1366px) {
        font-size: 16px;
      }
    }
  }
`;
export const TutionButtonBook = styled.div`
  padding: 5px 5px 5px;
  border-radius: 34px 0px 0px 34px;
  opacity: 0.99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  position: relative;
  @media (max-width: 1440px) {
    gap: 10px;
    margin-right: -35px;
  }
  @media (max-width: 1366px) {
    gap: 10px;
    margin-right: -29px;
  }
  .btn-primary {
    font-size: 16px;
    line-height: 120%;
    font-weight: 600;
    padding: 20px 46px 19px;
    width: 310px;
    @media (max-width: 1440px) {
      width: 290px;
      padding: 15px 40px 15px;
    }
    @media (max-width: 1366px) {
      width: 280px;
      font-size: 15px;
    }
    &:hover {
      background-color: #ffffff;
    }
  }
  .btn-secondary {
    font-size: 16px;
    line-height: 120%;
    font-weight: 600;
    padding: 20px 36px 19px;
    width: 290px;
    background-color: #ffffff;
    &:active {
      background-color: #940e34;
    }
    &:hover {
      background-color: #940e34;
    }
    &:focus-visible {
      color: #940e34;
      box-shadow: none;
      border-color: #940e34;
    }
    &:active:focus-visible {
      box-shadow: none;
      background-color: #ffffff;
    }
    &:hover:focus-visible {
      background-color: #ffffff;
    }
    @media (max-width: 1440px) {
      font-size: 14px;
      width: 280px;
    }
    @media (max-width: 1200px) {
      font-size: 14px;
      width: 200px;
    }
    @media (max-width: 991px) {
      font-size: 14px;
      width: 280px;
    }
    .btn-secondary-disabled {
      font-size: 16px;
      line-height: 120%;
      font-weight: 600;
      padding: 20px 36px 19px;
      width: 290px;
      background-color: #000;
      &:active {
        background-color: #940e34;
      }
      &:hover {
        background-color: #940e34;
      }
      &:focus-visible {
        color: #940e34;
        box-shadow: none;
        border-color: #940e34;
      }
      &:active:focus-visible {
        box-shadow: none;
        background-color: #ffffff;
      }
      &:hover:focus-visible {
        background-color: #ffffff;
      }
      @media (max-width: 1440px) {
        font-size: 14px;
        width: 280px;
      }
  }
`;

export const TutionAboutWrap = styled.div`
  margin: 100px 0px;
  @media (max-width: 1680px) {
    margin: 90px 0px;
  }
  @media (max-width: 1440px) {
    margin: 80px 0px;
  }
  @media (max-width: 1366px) {
    margin: 60px 0px;
  }
  @media (max-width: 1280px) {
    margin: 50px 0px;
  }
`;
export const TutionAboutUs = styled.div`
  h3 {
    font-size: 42px;
    line-height: 120%;
    font-weight: 800;
    margin-bottom: 15px;
    color: #012834;
    @media (max-width: 1440px) {
      font-size: 38px;
    }
    @media (max-width: 1366px) {
      font-size: 36px;
      margin-bottom: 10px;
    }
    @media (max-width: 1280px) {
      font-size: 32px;
    }
  }
  p {
    font-size: 16px;
    line-height: 190%;
    font-weight: 400;
    margin-bottom: 0px;
    color: #000000;
    max-width: 628px;
    @media (max-width: 1366px) {
      font-size: 15px;
    }
  }
`;

export const TutionSubject = styled.div`
  padding: 100px 0px;
  background: #f7f7f7;
  @media (max-width: 1680px) {
    padding: 90px 0;
  }
  @media (max-width: 1440px) {
    padding: 70px 0;
  }
  @media (max-width: 1366px) {
    padding: 60px 0;
  }
  @media (max-width: 1280px) {
    padding: 50px 0;
  }
`;
export const TutionSubTitle = styled.div`
  h3 {
    font-size: 42px;
    line-height: 131%;
    font-weight: 800;
    margin-bottom: 65px;
    color: #012834;
    opacity: 1;
    @media (max-width: 1440px) {
      font-size: 38px;
      margin-bottom: 50px;
    }
    @media (max-width: 1366px) {
      font-size: 36px;
      margin-bottom: 35px;
    }
    @media (max-width: 1280px) {
      font-size: 32px;
      margin-bottom: 25px;
    }
  }
`;

export const TutionOfferd = styled.ul`
  margin: 0 -15px;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 0px;
  .tution-list {
    border-radius: 30px;
    flex: 25%;
    max-width: 25%;
    margin: 0 0 30px;
    padding: 0 15px;
    @media (max-width: 1440px) {
      margin: 0 0 15px;
      padding: 0 10px;
    }
  }
`;

export const TutionListName = styled.div`
  padding: 20px;
  border-radius: 30px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  @media (max-width: 1440px) {
    margin: 20px 0 0 0;
    padding: 16px;
  }
  @media (max-width: 1366px) {
    font-size: 18px;
    line-height: 100%;
    padding: 15px;
    min-height: 180px;
    margin: 10px 0 0 0;
  }
`;
export const TutionImage = styled.div``;

export const TutionTitle = styled.h4`
  color: #012834;
  font-size: 20px;
  line-height: 120%;
  font-weight: 600;
  margin: 26px 0 0 0;
  text-align: center;
  @media (max-width: 1440px) {
    font-size: 18px;
    line-height: 120%;
    margin: 20px 0 0 0;
  }
  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 120%;
    margin: 20px 0 0 0;
  }
`;

export const TutionBatchWrap = styled.div`
  padding: 100px 0px 70px;
  @media (max-width: 1680px) {
    padding: 90px 0 60px;
  }
  @media (max-width: 1440px) {
    padding: 70px 0 50px;
  }
  @media (max-width: 1366px) {
    padding: 60px 0 40px;
  }
  @media (max-width: 1280px) {
    padding: 50px 0 30px;
  }
`;

export const BatchTitle = styled.h3`
  font-size: 42px;
  line-height: 131%;
  font-weight: 800;
  margin-bottom: 65px;
  color: #012834;
  @media (max-width: 1440px) {
    font-size: 38px;
    margin-bottom: 50px;
  }
  @media (max-width: 1366px) {
    font-size: 36px;
    margin-bottom: 35px;
  }
  @media (max-width: 1280px) {
    font-size: 32px;
    margin-bottom: 25px;
  }
`;
export const TutionBatch = styled.ul`
  margin: 0 -15px;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 0px;
  .batch-time {
    border-radius: 30px;
    margin: 0 0 30px;
    padding: 0 15px;
    color:#3B5971;
    @media (max-width: 1440px) {
      margin: 0 0 15px;
      padding: 0 10px;
    }
  }
`;
export const BatchTime = styled.div`
  background-color: #f7f7f7;
  padding: 32px;
  border-radius: 30px;
  font-size: 20px;
  line-height: 150%;
  color: #940e34;
  font-weight: 700;
  @media (max-width: 1440px) {
    padding: 25px;
    font-size: 18px;
  }
`;

export const TutionRattingReviewWrap = styled.div`
  padding: 100px 0px 140px;
  background-color: #f7f7f7;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  @media (max-width: 1680px) {
    padding: 90px 0 130px;
  }
  @media (max-width: 1440px) {
    padding: 70px 0 120px;
  }
  @media (max-width: 1366px) {
    padding: 60px 0 90px;
  }
  @media (max-width: 1280px) {
    padding: 50px 0 80px;
  }
`;
export const ReviewRatting = styled.div`
  flex: 0 0 10%;
  max-width: 10%;
`;
export const RattingReviewWrapTitle = styled.h3`
  font-size: 42px;
  line-height: 131%;
  font-weight: 800;
  margin-bottom: 65px;
  color: #012834;
  @media (max-width: 1440px) {
    font-size: 38px;
    margin-bottom: 35px;
  }
  @media (max-width: 1366px) {
    font-size: 36px;
    margin-bottom: 25px;
  }
  @media (max-width: 1280px) {
    font-size: 32px;
    margin-bottom: 20px;
  }
`;
export const RattingReviewWrap = styled.div`
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
`;
export const RattingReview = styled.h4`
  font-size: 24px;
  line-height: 172%;
  font-weight: 700;
  color: #012834;
  margin-bottom: 0;
  span {
    font-size: 42px;
    line-height: 131%;
    @media (max-width: 1440px) {
      font-size: 40px;
      line-height: 131%;
    }
    @media (max-width: 1366px) {
      font-size: 38px;
      line-height: 131%;
    }
    @media (max-width: 1280px) {
      font-size: 36px;
      line-height: 131%;
    }
  }
  @media (max-width: 1440px) {
    font-size: 22px;
    line-height: 172%;
  }
  @media (max-width: 1366px) {
    font-size: 20px;
    line-height: 172%;
  }
`;
export const RattingStars = styled.ul`
  display: flex;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
  li {
    padding-right: 10px;
    img {
      height: 22px;
      width: auto;
      margin-top: 12px;
      opacity: 0.4;
      @media (max-width: 1440px) {
        height: 20px;
        width: auto;
        margin-top: 10px;
      }
    }
    &.active {
      img {
        opacity: 1;
      }
    }
  }
`;

export const ReviewProgess = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding-left: 84px;
  @media (max-width: 1440px) {
    padding-left: 70px;
  }
  @media (max-width: 1366px) {
    padding-left: 60px;
  }
`;
export const ReviewProgessName = styled.h4`
  font-size: 20px;
  line-height: 100%;
  font-weight: 600;
  color: #012834;
  margin-right: 15px;
  @media (max-width: 1440px) {
    font-size: 18px;
    height: 18px;
    margin-bottom: 25px;
    margin-right: 10px;
    min-width: 180px;
  }
  @media (max-width: 1366px) {
    font-size: 16px;
    height: 16px;
    margin-bottom: 15px;
    margin-right: 10px;
  }
`;
export const RattingProgessBar = styled.div`
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  .progress {
    display: flex;
    height: 16px;
    width: 200px;
    overflow: hidden;
    background-color: rgba(255, 159, 69, 0.2);
    border-radius: 12px;
    @media (max-width: 1440px) {
      width: 180px;
    }
    @media (max-width: 1366px) {
      width: 170px;
    }
  }
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #ff9f45;
    text-align: center;
    white-space: nowrap;
    background-color: #ff9f45;
    transition: var(--bs-progress-bar-transition);
    border-radius: 12px;
  }
`;
export const ReviewLable = styled.h4`
  font-size: 20px;
  line-height: 100%;
  font-weight: 600;
  color: #012834;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
  @media (max-width: 1366px) {
    font-size: 16px;
  }
`;
export const RattingProgess = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`;

export const ProfileListWrap = styled.div`
  .form-group {
    margin-bottom: 30px;
  }
  .dropdown {
    text-align: right;
    .dropdown-toggle {
      border: 1px solid #940e34;
      border-radius: 30px;
      background: #ffffff;
      padding: 12px 48px 12px 24px;
      color: #940e34;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
      &:active {
        background-color: #ffffff !important;
      }
      &::after {
        background-image: url(${DownArrow});
        background-position: center;
        border: none;
        width: 14px;
        height: 8px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .dropdown-menu {
      box-shadow: 0px 30px 30px #7070700d;
      border: 1px solid #7070701a;
      border-radius: 10px;
      background: #ffffff;
      padding: 5px 15px;
      .dropdown-item {
        color: #012834;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        border-bottom: 1px solid rgba(112, 112, 112, 0.2);
        padding: 10px 0;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
`;

export const StudentsCard = styled.div`
  padding: 0 15px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -15px;
  gap: 30px;
`;

export const StudentsCardWrap = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 30px;
  height: 100%;
  @media (max-width: 1680px) {
    padding: 25px;
  }
`;

export const StudentsName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 1680px) {
    margin-bottom: 18px;
  }
  @media (max-width: 1440px) {
    margin-bottom: 15px;
  }
  @media (max-width: 1366px) {
    margin-bottom: 10px;
  }
`;
export const StudentsImg = styled.div`
  flex: 0 0 15.35%;
  max-width: 15.35%;
  height: 60px;
  width: 60px;
  @media (max-width: 1366px) {
    height: 50px;
    width: 50px;
  }
  img {
    height: 100%;
    width: 100%;
  }
`;
export const StudentsReview = styled.div`
  flex: 0 0 79.65%;
  max-width: 79.65%;
  height: 60px;
  width: 60px;
  h4 {
    font-size: 20px;
    line-height: 24px;
    padding-left: 5px;
    color: #940e34;
    font-weight: 700;
    margin-bottom: 0px;
    @media (max-width: 1440px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  h6 {
    font-size: 14px;
    line-height: 24px;
    padding-left: 5px;
    font-weight: 500;
    color: #012834;
    opacity: 0.5;
    margin-bottom: 0px;
  }
`;
export const StudentsDetails = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: #012834;
  font-weight: 400;
  margin-bottom: 45px;
  opacity: 0.8;
  @media (max-width: 1680px) {
    margin-bottom: 40px;
  }
  @media (max-width: 1440px) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
  }
  @media (max-width: 1366px) {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
  }
`;
export const StudentsStar = styled.div`
  span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #012834;
    // opacity: 0.5;
    margin-bottom: 0px;
  }
  ul {
    display: flex;
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0;
    li {
      padding-right: 10px;
      img {
        height: 22px;
        width: auto;
        margin-top: 15px;
        opacity: 0.4;
        @media (max-width: 1440px) {
          height: 20px;
          width: auto;
          margin-top: 10px;
        }
      }
      &.active {
        img {
          opacity: 1;
        }
      }
    }
  }
`;

export const TutionBookDemoClass = styled.div``;
export const BookDateTime = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BookPickTitle = styled.h4`
  font-size: 24px;
  line-height: 125%;
  font-weight: 800;
  margin-bottom: 20px;
  @media (max-width: 1440px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
  @media (max-width: 1366px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;
export const BookBatchTitle = styled.h4`
  font-size: 24px;
  line-height: 125%;
  font-weight: 800;
  margin-bottom: 20px;
  padding-left: 10px;
  @media (max-width: 1440px) {
    font-size: 22px;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  @media (max-width: 1366px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

export const PickCalender = styled.div`
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar {
    background: #e6e6e6;
    border: 1px solid #c4c4c459;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    position: relative;
  }
  .react-calendar__tile--now {
    border: 1px solid #940e34 !important;
    color: #940e34;
    background: #e6e6e6;
    &:hover {
      background: #e6e6e6;
    }
  }
  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd {
    background: #940e34 !important;
    color: #ffffff !important;
  }
  .react-calendar__tile--active {
    background: #e6e6e6;
    color: #000000;
    &:hover {
      background: #e6e6e6;
    }
    &:focus {
      background: #e6e6e6;
    }
  }
  .react-calendar__navigation__prev-button {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 0px;
    &::before {
      background-image: url(${DownArrow});
      background-repeat: no-repeat;
      padding: 0;
      width: 15px;
      height: 8px;
      -webkit-transform: rotate(85deg);
      -ms-transform: rotate(85deg);
      transform: rotate(85deg);
      content: "";
      display: inline-block;
      /* position: absolute; */
    }
  }
  .react-calendar__month-view__days__day--weekend {
    color: #000000;
    &:disabled {
      color: rgba(16, 16, 16, 0.3);
    }
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: rgba(16, 16, 16, 0.3);
  }
  .react-calendar__navigation button {
    min-width: 15px;
    background: none;
  }
  .react-calendar__month-view__weekdays__weekday {
    border: 1px solid #c4c4c459;
    font-size: 19px;
    line-height: 21px;
    -webkit-text-decoration: none;
    text-decoration: none;
    height: 48px;
    display: flex;
    align-items: center;
    @media (max-width: 1440px) {
      height: 40px;
    }
    abbr[title] {
      text-decoration: none;
      cursor: help;
      text-decoration-skip-ink: none;
      font-size: 18px;
      width: 18px;
      height: 16px;
      margin: 0 auto;
      overflow: hidden;
      display: block;
      color: #000000;
      font-weight: 500;
    }
  }
  .react-calendar__tile {
    font-size: 19px;
    line-height: 21px;
    border: 1px solid #c4c4c459;
    @media (max-width: 1440px) {
      font-size: 17px;
      line-height: 19px;
    }
    @media (max-width: 370px) {
      font-size: 10px;
      line-height: 19px;
    }
  }
  .react-calendar__navigation {
    display: flex;
    height: 48px;
    margin-bottom: 0;
    align-items: center;
    @media (max-width: 1440px) {
      height: 40px;
    }
  }
  .react-calendar__navigation__label {
    padding: 13px 20px;
    .react-calendar__navigation__label__labelText {
      font-size: 20px;
      font-weight: 700;
      display: flex;
      justify-content:center;   
      @media (max-width: 1440px) {
        font-size: 18px;
      }
      @media (max-width: 1440px) {
        font-size: 17px;
      }
    }
  }
  .react-calendar__navigation__next-button {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 0px;
    @media (max-width: 1440px) {
      right: 15px;
      top: 15px;
    }
    @media (max-width: 767px) {
      right: 15px;
      top: 20px;
    }
    &::before {
      background-image: url(${DownArrow});
      background-repeat: no-repeat;
      padding: 0;
      width: 15px;
      height: 8px;
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
      content: "";
      display: inline-block;

      /* position: absolute; */
    }
  }
`;
export const BookBatchTime = styled.div`
  padding-left: 10px;
`;

export const BookTime = styled.ul`
  margin: 0 px;
  list-style-type: none;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 10px;
  .batch-time {
    /* border-radius: 30px; */
    margin: 0 0 20px;
    padding: 0 15px;
    width: 100%;
    .form-check {
      background: #f7f7f7;
      /* border-radius: 30px; */
      padding: 0;
      position: relative;
    }
    input[type="radio"] {
      /* display: none; */
      border: 1px solid #f7f7f7;
      border-radius: 30px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      cursor: pointer;
      &:checked + label {
        border: 1px solid #940e34;
        color: #940e34;
        /* border-radius: 30px; */
      }
    }
    label {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #012834;
      padding: 24px 20px;
      width: 100%;
      border: 1px solid #f7f7f7;
      /* border-radius: 30px; */
      @media (max-width: 1440px) {
        font-size: 15px;
        line-height: 22px;
        padding: 18px 20px;
      }
    }
  }
  .e-btn {
    background-color: #f7f7f7;
    padding: 19px 24px;
    border-radius: 30px;
    color: #940e34;
    font-weight: 700;
    width: 100%;
    border: 1px solid #f7f7f7;
  }
`;

export const BookNowBtn = styled.div`
  text-align: right;
  margin-top: 75px;
  .btn {
    width: 214px;
    &:focus-visible {
      background: #940e34;
      color: #ffffff;
      border-color: #940e34;
      box-shadow: none;
    }
    &:active {
      background: #940e34;
      color: #ffffff;
      box-shadow: none;
    }
    @media (max-width: 1440px) {
      width: 208px;
    }
  }
`;

export const BookTitle = styled.div`
  font-size: 24px;
  line-height: 125%;
  font-weight: 800;
  margin-bottom: 30px;
  @media (max-width: 1440px) {
    font-size: 22px;
    line-height: 125%;
    margin-bottom: 20px;
  }
  @media (max-width: 1366px) {
    font-size: 20px;
    line-height: 125%;
    margin-bottom: 15px;
  }
`;
export const TutionBookedDetails = styled.div`
  padding: 20px 30px;
  background-color: #f7f7f7;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const TutionBookedLeft = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex: 0 0 50%;
  width: 50%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
`;
export const TutionBookedRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 50%;
  width: 50%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
`;
export const TeacherImg = styled.div`
  height: 50px;
  width: 50px;
  img {
    height: 100%;
    width: 100%;
    border: 4px solid #ffffff;
    border-radius: 50%;
    object-fit: cover;
  }
`;
export const TeacherName = styled.h4`
  font-size: 16px;
  line-height: 120%;
  font-weight: 800;
  padding-left: 15px;
  color: #012834;
`;

export const RattingIcon = styled.div`
  height: 50px;
  width: 50px;
  background-color: #ffffff;
  padding: 14px 15px 15px;
  border-radius: 50%;
`;
export const TeacherDate = styled.div`
  flex: 0 0 50%;
  width: 50%;
  font-size: 16px;
  line-height: 120%;
  font-weight: 800;
  padding-top: 20px;
  color: #012834;
  p {
    margin-bottom: 12px;
  }
  span {
    color: #940e34;
  }
`;
export const TuitionContact = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1000px;
  padding-left: 0px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    & + li {
      padding-left: 40px;
      margin-left: 40px;
      @media (max-width: 1440px) {
        padding-left: 30px;
        margin-left: 30px;
      }
      @media (max-width: 1366px) {
        padding-left: 20px;
        margin-left: 20px;
      }
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 20px;
        width: 1px;
        background-color: #707070;
        opacity: 0.5;
      }
    }
    img {
      height: 16px;
      width: 16px;
      margin-right: 10px;
    }
    span {
      font-size: 14px;
      line-height: 120%;
      color: #000;
      font-weight: 400;
      max-width: 205px;
    }
  }
`;

export const Line = styled.div`
  height: 25px;
  width: 1px;
  margin: 0px 5px 0px 5px;
  background-color: #9e9e9e;
  @media (max-width: 400px) {
    display: none;
  }
`;
export const TuitionContent = styled.div`
  margin-bottom: 40px;
  width: 100%;
  @media (max-width: 1440px) {
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    line-height: 180%;
    color: #012834;
    opacity: 0.8;
    font-weight: 400;
    margin: 0px 15px 20px;
    @media (max-width: 1440px) {
      margin: 0px 15px 15px;
    }
    @media (max-width: 1366px) {
      margin: 0px 15px 10px;
    }
  }
`;
export const UpdatePlanModal = styled.div`
  .btn-close {
    height: 20px;
    width: 20px;
    &:focus {
      box-shadow: none;
    }
  }
  .modalTitle {
    font-size: 30px;
    line-height: 165%;
    font-weight: 800;
    @media (max-width: 1440px) {
      font-size: 28px;
    }
    @media (max-width: 1366px) {
      font-size: 26px;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
    }
  }
  .modal-header {
    background: #f7f7f7;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 28px 40px;
    border-bottom: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    @media (max-width: 1440px) {
      padding: 20px 32px;
    }
    @media (max-width: 1366px) {
      padding: 18px 30px;
    }
  }
  .modal-body {
    padding: 40px 25px 55px;
    @media (max-width: 1440px) {
      padding: 30px 20px;
    }
    @media (max-width: 1280px) {
      padding: 20px 20px 25px;
    }
  }
`;
export const TutionBooked = styled.div`
  .btn-close {
    height: 20px;
    width: 20px;
    &:focus {
      box-shadow: none;
    }
  }
  .modalTitle {
    font-size: 30px;
    line-height: 165%;
    font-weight: 800;
    @media (max-width: 1440px) {
      font-size: 28px;
    }
    @media (max-width: 1366px) {
      font-size: 26px;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
    }
  }
  .modal-header {
    background: #f7f7f7;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 28px 40px;
    border-bottom: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    @media (max-width: 1440px) {
      padding: 20px 32px;
    }
    @media (max-width: 1366px) {
      padding: 18px 30px;
    }
  }
  .modal-body {
    padding: 40px 25px 55px;
    @media (max-width: 1440px) {
      padding: 30px 20px;
    }
    @media (max-width: 1280px) {
      padding: 20px 20px 25px;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: center;
    border-top: 0px;
    padding: 15px 0px 35px;
    @media (max-width: 1440px) {
      padding: 15px 0px 30px;
    }
    .btn {
      width: 214px;
      /* &:focus-visible {
        background: #940e34;
        color: #ffffff;
        border-color: #940e34;
        box-shadow: none;
      }
      &:active {
        background: #940e34;
        color: #ffffff;
        box-shadow: none;
      } */
    }
  }
  ${TeacherName} {
    margin-bottom: 0;
  }
  ${TutionBookedRight} {
    justify-content: flex-start;
  }
  ${TutionData} {
    padding-left: 15px;
    span {
      color: #707070;
    }
    h6 {
      font-weight: 700;
      margin-top: 8px;
    }
  }
`;
export const BookModalBody = styled.div`
  .modal-body {
    padding: 40px 40px;
    @media (max-width: 1440px) {
      padding: 30px 20px;
    }
    @media (max-width: 1280px) {
      padding: 20px 20px 25px;
    }
    .form-label {
      font-size: 14px;
      line-height: 130%;
      color: #707070;
      margin-bottom: 15px;
    }
    .form-control {
      font-size: 16px;
      line-height: 130%;
      font-weight: 500;
      padding: 19px;
      @media (max-width: 1440px) {
        padding: 14px 20px;
        font-size: 14px;
      }
      &::placeholder {
        color: #012834;
        &:focus {
          background-color: #d3d3d3;
        }
      }
    }
    .ant-select-selector {
      border-radius: 30px;
      background: #f7f7f7 !important;
      font-size: 16px;
      line-height: 120%;
      font-weight: 500;
      color: #012834;
      border: 1px solid #f7f7f7 !important;
      min-height: 60px;
      padding: 5px 20px;
      opacity: 0.8;
      @media (max-width: 1440px) {
        min-height: 48px;
      }

      .ant-select-selection-placeholder {
        inset-inline-start: 20px;
        inset-inline-end: 20px;
        color: #707070;
        font-family: "Montserrat", sans-serif;
        @media (max-width: 1366px) {
          font-size: 14px;
        }
      }
      .ant-select-selection-item {
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 20px;
        padding: 3px 5px;
        height: 26px;
        font-size: 16px;
        line-height: 120%;
        font-weight: 500;
        color: #012834;
        .anticon {
          vertical-align: middle;
          svg {
            fill: #940e34;
          }
        }
      }
    }
    .input-group {
      .form-control {
        border-radius: 30px !important;
        padding-right: 46px;
      }
      .icon-wrap {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        padding: 0;
        border: 0;
        background: border-box;
        &:active {
          background: transparent !important;
        }
        img {
          width: 20px;
          &.close-eye {
            width: 18px;
          }
        }
      }
    }
  }
`;

export const ModalFooter = styled.div`
  .modal-footer {
    border-top: 0px;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 20px;
    @media (max-width: 1440px) {
      padding: 0 0 15px;
    }
    .btn {
      width: 214px;
      padding: 20px;
      /* &:focus-visible {
        background: #940e34;
        color: #ffffff;
        border-color: #940e34;
        box-shadow: none;
      }
      &:active {
        background: #940e34;
        color: #ffffff;
        box-shadow: none;
      } */
      @media (max-width: 1440px) {
        padding: 15px;
      }
    }
  }
`;
export const BookTuitionHeader = styled.div`
  max-width: 782px;
  @media (max-width: 1440px) {
    max-width: 582px;
  }
  .btn-close {
    height: 20px;
    width: 20px;
  }
  .btn-close:focus {
    box-shadow: none;
  }
  .modal-header {
    background: #f7f7f7;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 32px 40px;
    border-bottom: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    @media (max-width: 1440px) {
      padding: 20px 32px;
    }
    @media (max-width: 1366px) {
      padding: 18px 30px;
    }
    .modal-title {
      font-size: 30px;
      line-height: 120%;
      font-weight: 800;
      @media (max-width: 1440px) {
        font-size: 28px;
      }
      @media (max-width: 1366px) {
        font-size: 26px;
      }
      @media (max-width: 1280px) {
        font-size: 24px;
      }
    }
  }
`;
export const SignUpText = styled.div`
  margin-bottom: 0;
  color: #012834;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  .signup {
    color: #940e34;
    font-size: 14px;
    line-height: 120%;
    font-weight: 600;
    text-decoration: none;
    margin-left: 5px;
    &:hover {
      color: #012834;
    }
  }
`;
export const SubjectMainTitle = styled.h3`
  font-size: 42px;
  line-height: 131%;
  font-weight: 800;
  margin-bottom: 65px;
  color: #012834;
  @media (max-width: 1440px) {
    font-size: 38px;
    margin-bottom: 50px;
  }
  @media (max-width: 1366px) {
    font-size: 36px;
    margin-bottom: 35px;
  }
  @media (max-width: 1280px) {
    font-size: 32px;
    margin-bottom: 25px;
  }
`;
