import React from "react";
import { Spin } from "antd";


type Props = {
  height?: number
}

const BlockSpinner: React.FC<Props> = ({ height = 200 }) => {
  return (
    <div style={{ height: height, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Spin size="default" />
    </div>
  );
};

export default BlockSpinner;