import { useState } from "react";
import { IoIosCamera } from "react-icons/io";
import styled from "styled-components";


const DynamicImageUploadRect = ({ onImageSelect, name, setImageData, url }) => {
  const [selectedImage, setSelectedImage] = useState(url);
  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Convert the selected image to a base64 string
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Image = event.target.result;
        setSelectedImage(URL.createObjectURL(file)); // Display the selected image
        onImageSelect(file.name, base64Image); // Call the provided callback with the File object's name and base64 image
        // setImageData(base64Image); // Set the base64 image in the component's state
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container>
      <ImagePreviewRect>
        {selectedImage ? (
          <img src={selectedImage} alt="Preview" />
        ) : (
          <Placeholder>Select image</Placeholder>
        )}
      </ImagePreviewRect>
      <FileInputWrapperRect>
        <FileInput
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          name={name}
        />
        <UploadIcon>
          <IoIosCamera size={23} />
        </UploadIcon>
      </FileInputWrapperRect>
    </Container>
  );
};


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
`;

const ImagePreview = styled.div`
  width: 100px;
  height: 100px;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 50%; /* Rounded corners */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  img {
    width: 100%;
    height: 100%;
    object-fit: fit;
    border-radius: 50%; /* Rounded corners for the image */
  }
`;
const ImagePreviewRect = styled.div`
width: 100%;
  height: 140px;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;
  /* Rounded corners */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  img {
    width: 100%;
    height: 100%;
    object-fit: fit;
  
  }
`;
const Placeholder = styled.p`
  margin: 0;
  font-size: 14px;
  text-align: center;
`;


const FileInputWrapperRect = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: -7%;
  right: -5%;
`;
const FileInput = styled.input`
  display: none;
`;

const UploadIcon = styled.span`
  font-size: 21px;
  width: 32px;
  height: 32px;
  color: rgb(255, 255, 255);
  background-color: rgb(148, 14, 52);
  padding: 0px 5px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
`;

export default DynamicImageUploadRect;
