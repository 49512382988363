import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import { GetMyBookingById, GetStudentReviews } from "../../redux/action";

import Header from "../../components/Header";
import PageSpinner from "../../components/PageSpinner";
import PageError from "../../components/PageError";
import Footer from "../../components/Footer";
import BookingCard from "./BookingCard";

import {
  MainContainer,
  ContentContainer,
  SectionHeading,
} from "./styledViews";


const MyClasses: React.FC<any> = () => {
  const user: any = JSON.parse(localStorage.getItem("loggedUsers") || "{}");
  const [isPageLoading, setPageLoading] = useState<boolean>(true);
  const [pageError, setPageError] = useState<string|boolean>(false);
  const [classes, setClasses] = useState<any>(null);
  const [reviews, setReviews] = useState<any>([]);
  const dispatch = useDispatch();

  const getClasses = async () => {
    if (!user?.id) {
      setPageLoading(false);
      setPageError("User not logged in.");
      return;
    }

    const reviewsRes = await GetStudentReviews(user.id);

    const res = await GetMyBookingById(user.id)(dispatch);
    if(res.error || !res.success) {
      setPageLoading(false);
      setPageError("Failed to fetch details.");
      return;
    }
    setClasses(res.records);
    if(reviewsRes.success) setReviews(reviewsRes.records);
    setPageLoading(false);
    console.log("##### res", res, reviewsRes);
  };

  useEffect(() => {
    getClasses();
  }, []);

  const updateReviews = async () => {
    const res = await GetStudentReviews(user.id);
    if(res.success) setReviews(res.records);
  };

  const bookedClasses = useMemo(() => _.filter(classes, c => !c.booking.is_demo), [classes]);
  const bookedDemos = useMemo(() => _.filter(classes, c => c.booking.is_demo), [classes]);

  return (
    <MainContainer>
      <Header />

      {(isPageLoading && <PageSpinner />) ||
      (pageError && <PageError title={_.isString(pageError) && pageError} />) ||
      (<>
        <ContentContainer>
          <Container>
            {!_.isEmpty(bookedClasses) && <>
              <SectionHeading>My Classes</SectionHeading>

              <Row>
                {bookedClasses.map(item => (
                  <Col lg={4} md={6} sm={12} xs={12}>
                    <BookingCard data={item} reviews={reviews} updateReviews={updateReviews} />
                  </Col>
                ))}
              </Row>
            </>}

            <div style={{ height: 60 }} />

            {!_.isEmpty(bookedDemos) && <>
              <SectionHeading>My Booked Demos</SectionHeading>

              <Row>
                {bookedDemos.map(item => (
                  <Col lg={4} md={6} sm={12} xs={12}>
                    <BookingCard data={item} />
                  </Col>
                ))}
              </Row>
            </>}
          </Container>
        </ContentContainer>

        <Footer />
      </>)}
    </MainContainer>
  );
};

export default MyClasses;