/** **************************** Import Types ****************************** */
import {
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  PAYMENT_REQUEST,
} from "../types/paymentType";

const initialState = {
  loading: false,
  paymentResponse: "",
  error: "",
};

const paymentReducer = (state = initialState, action) => {
  console.log("form badge api reducer", action.payload);
  switch (action.type) {
    case PAYMENT_SUCCESS:
      return {
        ...state,
        loading: true,
        paymentResponse: "",
      };
    case PAYMENT_FAILURE:
      return {
        loading: false,
        paymentResponse: action.payload.responseStatus,
        error: "",
      };
    case PAYMENT_REQUEST:
      return {
        loading: false,
        paymentResponse: "error",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default paymentReducer;
