import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Cookies } from "react-cookie";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Header from "../../../components/TuorPanel/Header";
import { Row, Col } from 'antd';
import nodata from "../../../assets/images/no-data.png";
import PieChart from "../../../components/TuorPanel/Piechart";
import CustomPreloader from "../../../components/TuorPanel/Preloader";
import { GetDashboardById } from "../../../redux/action/dashBoardAction";
import "./dashboardStyle.css";
import './index.scss';

const Dashboard = () => {
  const [value, onChange] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState();
  const [data, setData] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date()); // Step 1: State for selected date
  const today = new Date();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const initialUserData = () => {
    const localData = cookies.get("TeacherloggedUser");
    console.log(localData?.id, "idssssss dash");
    setUserID(localData?.id);
    dispatch(GetDashboardById(localData?.id)).then((result) => {
      console.log(result, "result data");
      setData(result?.records);
      setLoading(false);
    });
  };
  const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const handleDateChange = (newDate) => {
    // You can perform any custom logic here when the date changes
    console.log("Selected date:", newDate.toLocaleDateString());
    console.log("Selected date:", formatDateToYYYYMMDD(newDate));

    // Update the state with the new date
    const localData = cookies.get("TeacherloggedUser");

    onChange(newDate.toLocaleDateString());
    setUserID(localData?.id);
    setLoading(true);
    dispatch(
      GetDashboardById(localData?.id, formatDateToYYYYMMDD(newDate))
    ).then((result) => {
      setData(result?.records);
      setLoading(false);
    });
  };
  console.log(data, "data Dashboard");
  useEffect(() => {
    setLoading(true);
    initialUserData();
  }, []);
  // const filteredSchedule = loading
  //   ? []
  //   : data?.my_schedule[0]?.filter((item) => {
  //       const itemDate = new Date(item.created_at);
  //       return (
  //         itemDate.getDate() === selectedDate.getDate() &&
  //         itemDate.getMonth() === selectedDate.getMonth() &&
  //         itemDate.getFullYear() === selectedDate.getFullYear()
  //       );
  //     });
  // const filteredSchedule = loading
  //   ? []
  //   : data?.my_schedule[0]?.filter((item) => {
  //       const itemDate = new Date(item.created_at);
  //       return (
  //         itemDate.getDate() === selectedDate.getDate() &&
  //         itemDate.getMonth() === selectedDate.getMonth() &&
  //         itemDate.getFullYear() === selectedDate.getFullYear()
  //       );
  //     });

  const student = [
    {
      "7th Standard": 7,
      Chemistry: 9,
    },
  ];

  const payment = [
    {
      tuition_mitra: 8,
      card: 4,
      other: 3,
    },
  ];

  return (
    <div className="teacher-dashboard">
        <h5 className="teacher-dashboard-overview">Overview</h5>
        <div className="teacher-dashboard-chart">
          {data ? (
            <div className="teacher-dashboard-chart-container-valid">
              {!data?.student_level_data ? (
                <div className="teacher-dashboard-chart-data-notvalid">
                  <img src={nodata} alt="no-data" />
                </div>
              ) : (
                <Row gutter={[16, 16]} className="teacher-dashboard-chart-valid-data">
                  <Col xs={24} sm={24} md={24} lg={8}>
                  <PieChart
  data={Object.keys(data?.student_level_data?.[0]).length === 0
    ? {
        "student level data":0,
        
      }
    : data?.student_level_data?.[0]
  }
  title="Student Level Data"
/>

                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8}>
                  <PieChart data={data?.fee_status[0]} title="Fee Status" />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8}>
                  <PieChart
                    data={data?.payment_method[0]}
                    title="Payment Method"
                  />
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            <div className="teacher-dashboard-chart-container-notvalid">
            <img src={nodata} alt="no-data" />
            </div>
            // <CustomPreloader /> // Show loading indicator until data is fetched
          )}
        </div>
        <div className="teacher-dashboard-scheduler-time">
          <Row gutter={[16, 16]} className="teacher-dashboard-scheduler-time-container">
            <Col xs={24} sm={24} md={24} lg={15} className="teacher-dashboard-scheduler-time-container-scheduler">              
              <h5 className="teacher-dashboard-scheduler-time-container-scheduler-text">My Schedule</h5>
              <div className="teacher-dashboard-scheduler-time-container-card">
                <p className="mx-2">Today - {Date().slice(0, 16)}</p>
                {loading ? (
                  <div className="teacher-dashboard-scheduler-time-container-card-loader">
                    <CustomPreloader />
                  </div>
                ) : (
                  <div className="teacher-dashboard-scheduler-time-container-card-container">
                    {data?.my_schedule?.length === 0 ? (
                      <div className="teacher-dashboard-scheduler-time-container-card-container-img">
                        <img src={nodata} alt="no-data" />
                      </div>
                    ) : (
                      <>
                        {data&&data?.my_schedule?.map((item, index) => (
                          <Row gutter={[8, 8]} key={index} className="teacher-dashboard-scheduler-time-container-card-container-timing">
                            <Col xs={24} sm={24} md={24} lg={12} className="d-flex justify-content-center"><p className="mb-0 text-align-center">{item.timing}</p></Col>
                            <Col xs={24} sm={24} md={24} lg={12} className="d-flex justify-content-center">
                            <p className="prime-text fw-bold mb-0 text-align-center">
                              {item.subject}
                            </p>
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                  </div>
                )}              
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={9} className="teacher-dashboard-time-table">
              <h5 className="teacher-dashboard-time-table-calender">My Calendar</h5>
              <div className="teacher-dashboard-time-table-card">
                <Calendar
                  onChange={handleDateChange}
                  value={value}
                  maxDate={today}
                />
              </div>
            </Col>
          </Row>
        </div>
    </div>
  );
};

export default Dashboard;