import { TimePicker } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { BsThreeDotsVertical ,BsSave } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import { getLevels, getallSubjects } from "../../../api/list";
import nodata from "../../../assets/images/no-data.png";
import SelectBox from "../../../components/SelectBox/SelectBox";
import RoundedButton from "../../../components/TuorPanel/Button";
import DeleteModal from "../../../components/TuorPanel/DeleteModal";
import Header from "../../../components/TuorPanel/Header";
import StyledInput from "../../../components/TuorPanel/Input/input";
import CustomPreloader from "../../../components/TuorPanel/Preloader";
import "./badge.css";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import {
  CreateBadge,
  DeleteBadge,
  GetBadgeList,
  UpdateBadge,
} from "../../../redux/action";
import "./badge.css";
import { toast } from "react-toastify";
import Modal from "../../../components/TuorPanel/Modal/modal";
import {
  onlyNumberHandler,
  handleNumberPaste,
} from "../../../utils/validation";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postEmailToBatch, postStudentEmail } from "../../../api/create";
import { Formik } from "formik";
const format = "HH:mm";

const Badges = () => {
  // States
  const [selectedFunctionIndex, setSelectedFunctionIndex] = useState(null);

  const [badgeContainers, setBadgeContainers] = useState([]);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [toastMessages, setToastMessages] = useState([]);
  const [messages, setMessages] = useState("");
  const [level, setLevel] = useState([]);
  const [subject, setSubject] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [formData, setFormData] = useState({
    message: "",
  });

  const [cookieID, setCookieID] = useState(null); // Declare cookieID state
  const [deleteID, setDeleteID] = useState(null); // Declare deleteID state
  const [batchID, setBatchID] = useState(null); // Declare deleteID state
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();

  // Function to search badges
  // Handle search input change
  const currentKey = window.location.href.split("/")[3];
  const search = useSelector((state) => state.searchTution);
  const searchValue = get(search, "data", "");

  if (searchValue?.trim().length) {
    if (currentKey === "badges") {
      setSearchQuery(searchValue);
    }
  }

  useEffect(() => {
    dispatch({ type: "SEARCH_HANDLER", data: null });
  }, []);

  // Function to initialize user data
  const initialUserData = async () => {
    const localData = cookies.get("TeacherloggedUser");
    setSelectedFunctionIndex(null);
    const userId = localData;
    setCookieID(localData);
    dispatch(GetBadgeList(userId?.id, searchQuery)).then((res) => {
      console.log("Badges response", res.records);
      setBadgeContainers(res.records);
      setLoading(false);
    });
  };

  // Function to show toast message
  const showToastMessage = (index) => {
    setToastMessages((prevMessages) => [...prevMessages, index]);
    setTimeout(() => {
      hideToastMessage(index);
    }, 3000);
  };

  // Function to hide toast message
  const hideToastMessage = (index) => {
    setToastMessages((prevMessages) =>
      prevMessages.filter((messageIndex) => messageIndex !== index)
    );
  };

  // Function to add a new badge container
  const addBadgeContainer = () => {
    toggleTooltip(null);
    if (badgeContainers.filter((ev) => ev.subject === "").length === 0) {
      const newBadgeData = {
        batchId: "",
        subject: "",
        startTime: "",
        endTime: "",
        teacher_id: cookieID?.id,
        timing: "",
        seatsOccupied: "",
        availableSeats: "",
        location: "",
        isEditing: true,
        level: "",
        subject_id: "",
      };
      setBadgeContainers((prevContainers) => [...prevContainers, newBadgeData]);
      const badgeContainersLength = badgeContainers.length;
      setSelectedFunctionIndex(badgeContainersLength);
    }
  };

  // Function to handle editing a badge
  const handleEdit = (index) => {
    toggleTooltip(index);
    setSelectedFunctionIndex(index);
    setBadgeContainers((prevContainers) =>
      prevContainers.map((badge, i) =>
        i === index
          ? { ...badge, isEditing: true, originalData: { ...badge } }
          : badge
      )
    );
  };
  const validationSchema = Yup.object().shape({
    messages: Yup.string().required("Messages is required"),
  });
  //

  // Function to handle saving a badge
  const handleSave = async (index) => {
    const badgeToSave = badgeContainers[index];
    console.log(badgeToSave, "daved dataaaaaaaaaaaa");
    const seatsOccupied = parseInt(badgeToSave.seats_occupied, 10);
  const availableSeats = parseInt(badgeToSave.available_seats, 10);

  // Check if available seats are less than seats occupied
  const isSeatMismatch = availableSeats < seatsOccupied;
  if(isSeatMismatch){
    
    showToastMessage(index);
    setMessages("Seats occupied cannot be greater than total seats.");
    toggleTooltip(index);
  }
  else{
    if (badgeToSave.id) {
      // Update existing badge
      dispatch(UpdateBadge(badgeToSave, badgeToSave?.id)).then((result) => {
        initialUserData();
        showToastMessage(index);
        setSelectedFunctionIndex(null);
        setMessages("Record Updated");
      });
    } else {
      // Create new badge
      dispatch(CreateBadge(badgeToSave)).then((createdBadge) => {
        if (createdBadge) {
          setBadgeContainers((prevContainers) =>
            prevContainers.map((badge, i) =>
              i === index ? { ...badge, ...createdBadge } : badge
            )
          );
          toggleEditState(index);
          initialUserData();
          showToastMessage(index);
          setMessages("Record Created");
        }
      });
    }
    toggleTooltip(index);}
  };

  // Function to toggle tooltip
  const toggleTooltip = (index) => {
    if(index===null){
      setActiveTooltip(null);
    }
    else if (activeTooltip === index) {
      setActiveTooltip(null);
    } else {
      setActiveTooltip(index);
    }
  };

  // Function to handle input change
  const handleInputEdit = (e, index, field) => {
    const value = e.target.value;

    setBadgeContainers((prevContainers) =>
      prevContainers.map((badge, i) =>
        i === index ? { ...badge, [field]: value } : badge
      )
    );
  };
  // const handleInputSubjectEdit = (e, index, field) => {
  //   const value = e.target.value;
  //   const selectedOption = e.target.options[e.target.selectedIndex];
  // const selectedId = selectedOption.getAttribute('data-id');
  // const selectedSubject = selectedOption.getAttribute('data-subject');

  //   setBadgeContainers((prevContainers) =>
  //   prevContainers.map((badge, i) =>
  //     i === index
  //       ? { ...badge, subject_id: parseInt(selectedId), subject: selectedSubject, [field]: e.target.value }
  //       : badge
  //   )
  // );
  // };

  // Function to handle badge delete
  const handleBadgeDelete = (index) => {
    setDeleteModal(true);
    setDeleteID(index);
  };
  const handleMessage = (index, data) => {
    setMessageModal(true);
    setBatchID(index);
    setSelectedOption([data?.batch_id]);
  };

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: async (values) => {
            await postEmailToBatch(values);
    },
  });
  //  const formik = useFormik({
  //    enableReinitialize: true,
  //    initialValues: {
  //      messages: "",
  //    },
  //    validationSchema: validationSchema,
  //    onSubmit: async (values) => {
  //      const newData = {
  //        message: values.message,
  //      };

  //      await postEmailToBatch(newData);
  //    },
  //  });

  // Function to handle deleting a badge
  // const handleDelete = async (indexToDelete) => {
  //   const badgeToDelete = badgeContainers[indexToDelete];
  //   if (badgeToDelete.id) {
  //     dispatch(DeleteBadge(badgeToDelete.id)).then((deletedBadgeId) => {
  //       if (deletedBadgeId) {
  //         setBadgeContainers((prevContainers) =>
  //           prevContainers.filter((_, i) => i !== indexToDelete)
  //         );
  //       }
  //     });
  //     setDeleteModal(false);
  //   }
  //   setActiveTooltip(null);
  // };
  const handleDelete = async () => {
    if (deleteID !== null) {
      const badgeToDelete = badgeContainers[deleteID];
      if (badgeToDelete.id) {
        dispatch(DeleteBadge(badgeToDelete.id)).then((deletedBadgeId) => {
          if (deletedBadgeId) {
            // setLoading(true);
            // initialUserData();
            setLoading(true);
            console.log('Before setBadgeContainers:', badgeContainers);
setBadgeContainers((prevContainers) =>
  prevContainers.filter((_, i) => i !== deleteID)
);
setLoading(false);

console.log('After setBadgeContainers:', badgeContainers);
          // setBadgeContainers((prevContainers) =>
            //   prevContainers.filter((_, i) => i !== deleteID)
            // );
          }
        });
      }
    }
    setDeleteModal(false);
    setDeleteID(null); // Reset the deleteIndex state
    setActiveTooltip(null);
  };
  const handleMessages = async (index) => {
    const badgeToSave = badgeContainers[index];
   
  };
  // const handleMessages = async (indexToDelete) => {
  //   const badgeToDelete = badgeContainers[indexToDelete];
  //   console.log("====================================");
  //   console.log(2, indexToDelete);
  //   console.log("====================================");
  //   if (badgeToDelete.id) {
  //     dispatch(DeleteBadge(badgeToDelete.id)).then((deletedBadgeId) => {
  //       if (deletedBadgeId) {
  //         setBadgeContainers((prevContainers) =>
  //           prevContainers.filter((_, i) => i !== indexToDelete)
  //         );
  //       }
  //     });
  //     setDeleteModal(false);
  //   }
  //   setActiveTooltip(null);
  // };

  // Function to handle closing delete modal
  const handleClose = () => {
    setDeleteModal(false);
  };

  // Function to toggle edit state
  const toggleEditState = (index) => {
    setBadgeContainers((prevContainers) =>
      prevContainers.map((badge, i) =>
        i === index ? { ...badge, isEditing: false } : badge
      )
    );
  };

  useEffect(() => {
    setLoading(true);
    getallLevels();
    getAllSubjects();
    initialUserData();
  }, []);

  const getallLevels = () => {
    getLevels().then((res) => {
      if (res) {
        setLevel(res?.records);
      }
    });
  };
  const getAllSubjects = () => {
    getallSubjects().then((res) => {
      if (res) {
        setSubject(res?.records);
      }
    });
  };

  const subjectList = subject?.map((item, index) => (
    // console.log(item, "itemssss5678")
    <option
      key={item.id}
      value={item?.subject}
      data-id={item.id}
      data-subject={item.subject}
    >
      {item?.subject}
    </option>
  ));
  const stop = level?.map((item, index) => (
    // console.log(item, "itemssss5678")
    <option key={index} value={item?.level_name}>
      {item?.level_name}
    </option>
  ));

  const checkSeats = (e) => {
    console.log(e.target.value, "w345345374537456");
  };
  console.log(badgeContainers, "badgeContainers");

  const handleSubmitMessage = () => {
    const payload = {
      batch_id: selectedOption,
      message: message,
    };
    postEmailToBatch(payload).then((res) => {
      if (res) {
        setMessageModal(false);
        setSelectedOption([]);
        toast.success("Email sent");
        setActiveTooltip(null);
      }
      else{
        toast.error("Failed to send email");
        setActiveTooltip(null);
      }
    });
  };
  const validateFields = (badge) => {
    const requiredFields = [ 'subject', 'available_seats', 'seats_occupied', 'level', 'location'];
  
    for (const field of requiredFields) {
      if (!badge[field]) {
        return false;
      }
    }
  
    return true;
  };

  return (
      <div className="badge-page">
        <div className="d-flex justify-content-between justify-content-md-between justify-content-sm-center align-items-center mx-2">
          <h6 className="prime-text fw-bolder mb-0" style={{ fontSize: '20px' }}>Batch Summary</h6>
          <RoundedButton variant="primary" onClick={addBadgeContainer}>
            Add Batch
          </RoundedButton>
        </div>
        <div className="badge-container">
          {loading ? (
            <CustomPreloader />
          ) : badgeContainers?.length === 0 ? (
            <div className="no-data-found text-center my-4">
              <img src={nodata} alt="no-data" width={350} />
            </div>
          ) : (
            <>
              {badgeContainers?.map((badge, index) => {
                console.log(badge, "badge data");
                const [startTime, endTime] = badge.timing.split(" - ");
                return (
                  <div
                    className="badge-container-child bg-pink d-flex justify-content-around align-items-center pt-2 rounded mt-2"
                    key={index}
                  >
                    <div className="mb-2 p-1 ">
                      <label className="prime-text fw-bold mb-1">
                        Batch ID
                      </label>
                      <StyledInput
                        backgroundColor="#fff"
                        type="text"
                        value={badge.batch_id}
                        disabled
                      />
                    </div>
                    <div className="mb-2 p-1">
                      <label className="prime-text fw-bold mb-1">Subject</label>
                      <SelectBox
                        width="100%"
                        height="50px"
                        name="driver_id"
                        margin="0px 0px 0px 0px"
                        padding="0px 10px 0px 10px"
                        onChange={(e) => handleInputEdit(e, index, "subject")}
                        value={
                          badge.isEditing
                            ? badgeContainers[index]?.subject
                            : badge.subject
                        }
                      >
                        <option value="">Select</option>
                        {subjectList}
                      </SelectBox>
                    </div>

                    <div className="d-grid mb-2 p-1">
                      <label className="prime-text fw-bold mb-1">Timings</label>
                      <TimePicker.RangePicker
                        format={format}
                        style={{ width: "150px", height: "50px" }}
                        className="border-0"
                        minuteStep={15}
                        defaultValue={
                          badge.timing && badge?.timing.includes("-")
                            ? [
                                moment(startTime, format),
                                moment(endTime, format),
                              ]
                            : undefined
                        }
                        onChange={(timeRange, timeStrings) => {
                          const startTime = timeStrings[0];
                          const endTime = timeStrings[1];
                          const updatedBadgeContainers = badgeContainers.map(
                            (badge, idx) => {
                              if (idx === index) {
                                return {
                                  ...badge,
                                  startTime,
                                  endTime,
                                  timing: `${startTime} - ${endTime}`,
                                };
                              }
                              return badge;
                            }
                          );
                          setBadgeContainers(updatedBadgeContainers);
                        }}
                      />
                    </div>
                    <div className="mb-2 p-1">
                      <label className="prime-text fw-bold mb-1">
                        Total Seats
                      </label>
                      <StyledInput
                        backgroundColor="#fff"
                        type="number"
                        placeholder="Total Seats"
                        value={
                          badge.isEditing
                            ? badgeContainers[index]?.available_seats
                            : badge.available_seats
                        }
                        onChange={(e) =>
                          handleInputEdit(e, index, "available_seats")
                        }
                        onKeyPress={(e) => onlyNumberHandler(e)}
                        onPaste={handleNumberPaste}
                        disabled={!badge.isEditing}
                      />
                    </div>
                    <div className="mb-2 p-1">
                      <label className="prime-text fw-bold mb-1">
                        Seats Occupied
                      </label>
                      <StyledInput
                        backgroundColor="#fff"
                        type="number"
                        placeholder="Seats Occupied"
                        value={
                          badge.isEditing
                            ? badgeContainers[index]?.seats_occupied
                            : badge.seats_occupied
                        }
                        onChange={(e) => {
                          handleInputEdit(e, index, "seats_occupied");
                          checkSeats(e);
                        }}
                        onKeyPress={(e) => onlyNumberHandler(e)}
                        onPaste={handleNumberPaste}
                        disabled={!badge.isEditing}
                      />
                    </div>

                    <div className="mb-2 p-1">
                      <label className="prime-text fw-bold mb-1">Level</label>
                      <SelectBox
                        width="100%"
                        height="50px"
                        name="driver_id"
                        margin="0px 0px 0px 0px"
                        padding="0px 10px 0px 10px"
                        onChange={(e) => handleInputEdit(e, index, "level")}
                        value={
                          badge.isEditing
                            ? badgeContainers[index]?.level
                            : badge.level
                        }
                      >
                        <option value="">Select</option>
                        {stop}
                      </SelectBox>
                    </div>
                    <div className="mb-2 p-1">
                      <label className="prime-text fw-bold mb-1">
                        Location
                      </label>
                      <StyledInput
                        backgroundColor="#fff"
                        type="text"
                        placeholder="Location"
                        value={
                          badge.isEditing
                            ? badgeContainers[index]?.location
                            : badge.location
                        }
                        onChange={(e) => handleInputEdit(e, index, "location")}
                        disabled={!badge.isEditing}
                      />
                    </div>
                    <div className="position-relative">
  {selectedFunctionIndex === index ? (
    // Render "Save" button
    <div
      className="mx-2 action-btn bg-white rounded-circle prime-text d-flex align-items-center mt-3"
     
      onClick={() => {
        if (validateFields(badgeContainers[index])) {
          handleSave(index);
          setActiveTooltip(null);
        } else {
          // Handle validation error, show a message or perform some action
          
          showToastMessage(index);
    setMessages('Please fill in all fields.');
        }
      }}
    >
      <BsSave />
    </div>
  ) : (
    // Render three dots button
    <div
      className="mx-2 action-btn bg-white rounded-circle prime-text d-flex align-items-center mt-3"
      onClick={() => toggleTooltip(index)}
    >
      <BsThreeDotsVertical />
    </div>
  )}

  {activeTooltip === index && (
    <div className="tooltip">
      <p onClick={() => handleEdit(index)}>Edit</p>
      <p onClick={() => handleBadgeDelete(index)}>Delete</p>
      <p onClick={() => handleMessage(index, badge)}>Message</p>
      <p onClick={() => navigate(`/batches/${badge.batch_id}/study-materials`)}>Study Materials</p>
    </div>
  )}

  {toastMessages.includes(index) && (
    <div className="custom-toast custom-toast-row">
      <p className="custom-toast-message mb-0">{messages}</p>
    </div>
  )}
</div>

                    {/* <div className="position-relative">
                      <div
                        className="mx-2 action-btn bg-white rounded-circle prime-text d-flex align-items-center mt-3"
                        onClick={() => toggleTooltip(index)}
                      >
                        <BsThreeDotsVertical />
                      </div>
                      {activeTooltip === index && (
                        <div className="tooltip">
                          <p onClick={() => handleEdit(index)}>Edit</p>
                          <p onClick={() => handleSave(index)}>Save</p>
                          <p onClick={() => handleBadgeDelete(index)}>Delete</p>
                          <p onClick={() => handleMessage(index, badge)}>
                            Message
                          </p>
                        </div>
                      )}
                      {toastMessages.includes(index) && (
                        <div className="custom-toast custom-toast-row">
                          <p className="custom-toast-message mb-0">
                            {messages}
                          </p>
                        </div>
                      )}
                    </div> */}
                  </div>
                );
              })}
            </>
          )}

          {deleteModal ? (
            <DeleteModal
              hide={handleClose}
              isShowing={deleteModal}
              width="450px"
              handleDelete={() => handleDelete()}
              info="Are you sure to delete this Batch?"
            />
          ) : null}
          {messageModal ? (
            <Modal
              title="Send Message"
              hide={() => setMessageModal(false)}
              isShowing={messageModal}
              // handleMessages={() => handleMessages(batchID)}
              width="600px"
            >
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <div className="row p-3 pb-0">
                    <div className="col-12 w-100">
                      <StyledInput
                        width="100%"
                        id="message"
                        type="text"
                        placeholder="Message"
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                        value={message}
                      />
                    </div>
                    <div className="my-3 text-left d-flex justify-content-center">
                      <RoundedButton
                        variant="primary"
                        type="submit"
                        onClick={() => handleSubmitMessage()}
                      >
                        Send Message
                      </RoundedButton>
                    </div>
                  </div>
                </div>
              </form>
            </Modal>
          ) : null}
        </div>
      </div>
  );
};

export default Badges;