import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postLoginRequestData } from "../../api/create";
import {
  LOGIN_USERS_FALIURE,
  LOGIN_USERS_REQUEST,
  LOGIN_USERS_SUCCESS,
} from "../types/loginTypes";
import EmailVerifyModal from "../../components/EmailverifyModal";

const cookies = new Cookies(); // Create an instance of Cookies

export const LoginUsersRequest = () => ({
  type: LOGIN_USERS_REQUEST,
});
export const LoginUsersSuccess = (users) => ({
  type: LOGIN_USERS_SUCCESS,
  payload: users,
});

export const LoginUsersFailure = (error) => ({
  type: LOGIN_USERS_FALIURE,
  payload: error,
});

export const LoginUser = (data, status) =>
  async function (dispatch) {
    dispatch(LoginUsersRequest());
    return (
      postLoginRequestData(data)
        // ============================
        .then((res) => {
          console.log(res, "returnedresponce false");
          if (res?.success) {
            const loggedUser = res?.records;
            const token = res?.token.access_token;
            localStorage.setItem("loggedUsers", JSON.stringify(loggedUser));
            localStorage.setItem("token", token);
            dispatch(LoginUsersSuccess({ responseStatus: res }));
            // toast("Logged in Successfully");
            // toast.success("Login Successfully!", {
            //   position: "top-center",
            //   autoClose: 1000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: false,
            //   progress: undefined,
            //   theme: "light",
            // });
            // if (status) {
            //   setTimeout(() => {
            //     window.location.href = "/";
            //   }, 3000);
            // }
            return res;
          } else {
            dispatch(LoginUsersFailure({ responseStatus: res }));
            if (res?.success) {
              // toast.error("check your email or password", {
              //   position: "top-center",
              //   autoClose: 1000,
              //   hideProgressBar: true,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: false,
              //   progress: undefined,
              //   theme: "light",
              // });
            }
            // dispatch(LoginUsersSuccess({ responseStatus: res }));
            return res;
          }
        })
        .catch((err) =>
          toast.error("Error!", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          })
        )
    );
  };
