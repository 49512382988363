import React from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";
import styled from "styled-components";

import StarRating from "../../components/TuorPanel/Accordion/starRatings";

import {
  ListHeader
} from "./styledViews";


const RattingReview = styled.h4`
  font-size: 24px;
  line-height: 172%;
  font-weight: 700;
  color: #012834;
  margin-bottom: 0;
  span {
    font-size: 42px;
    line-height: 131%;
    @media (max-width: 1440px) {
      font-size: 40px;
      line-height: 131%;
    }
    @media (max-width: 1366px) {
      font-size: 38px;
      line-height: 131%;
    }
    @media (max-width: 1280px) {
      font-size: 36px;
      line-height: 131%;
    }
  }
  @media (max-width: 1440px) {
    font-size: 22px;
    line-height: 172%;
  }
  @media (max-width: 1366px) {
    font-size: 20px;
    line-height: 172%;
  }
`;
const RattingStars = styled.ul`
  display: flex;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
  li {
    padding-right: 10px;
    img {
      height: 22px;
      width: auto;
      margin-top: 12px;
      opacity: 0.4;
      @media (max-width: 1440px) {
        height: 20px;
        width: auto;
        margin-top: 10px;
      }
    }
    &.active {
      img {
        opacity: 1;
      }
    }
  }
`;
export const ReviewProgessName = styled.h4`
  font-size: 20px;
  line-height: 100%;
  font-weight: 600;
  color: #012834;
  margin-bottom: 30px;
  @media (max-width: 1440px) {
    font-size: 18px;
    height: 18px;
    margin-bottom: 25px;
    margin-right: 10px;
    min-width: 180px;
  }
  @media (max-width: 1366px) {
    font-size: 16px;
    height: 16px;
    margin-bottom: 15px;
    margin-right: 10px;
  }
`;
export const RattingProgessBar = styled.div`
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  .progress {
    display: flex;
    height: 16px;
    width: 200px;
    overflow: hidden;
    background-color: rgba(255, 159, 69, 0.2);
    border-radius: 12px;
    @media (max-width: 1440px) {
      width: 180px;
    }
    @media (max-width: 1366px) {
      width: 170px;
    }
  }
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #ff9f45;
    text-align: center;
    white-space: nowrap;
    background-color: #ff9f45;
    transition: var(--bs-progress-bar-transition);
    border-radius: 12px;
  }
`;
export const RattingProgess = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`;
export const ReviewLable = styled.h4`
  font-size: 20px;
  line-height: 100%;
  font-weight: 600;
  color: #012834;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
  @media (max-width: 1366px) {
    font-size: 16px;
  }
`;

const ProfileRatings: React.FC<any> = ({ reviewCount }) => {
  const calculateOverallReviewCount = (
    totalReviewStarCount: any,
    reviewStudentCount: any
  ) => {
    if (reviewStudentCount === 0) {
      return 0; // Return 0 when dividing by 0
    }
    if (totalReviewStarCount === 0) {
      return 0; // Return 0 when dividing by 0
    }
    if (isNaN(totalReviewStarCount) || isNaN(reviewStudentCount)) {
      return -0; // Return an error message for non-numeric inputs
    }
    const result = totalReviewStarCount / reviewStudentCount; // Calculate the result as a number
    const isInteger = result % 1 === 0;
    if (isInteger) {
      return result.toFixed(0);
    } else {
      return result.toFixed(1);
    }
  };

  return (
    <div style={{ marginTop: 100 }}>
      <ListHeader>Ratings</ListHeader>

      <Row className="d-flex justify-content-start">
        <Col xs={12} lg={3} style={{ marginBottom: 30 }}>
          <RattingReview>
            <span>
              {calculateOverallReviewCount(
                reviewCount?.total_start_count,
                reviewCount?.student_count
              )}
            </span>
            /5
          </RattingReview>
          <RattingStars>
            <StarRating
              rating={calculateOverallReviewCount(
                reviewCount?.total_start_count,
                reviewCount?.student_count
              )}
              totalStars={5}
              starSize={20}
            />
          </RattingStars>
        </Col>
        <Col xs={12} lg={5} style={{ marginBottom: 30 }}>
          <ReviewProgessName>Classroom Environment</ReviewProgessName>

          <RattingProgessBar>
            <RattingProgess>
              <ProgressBar
                now={
                  (parseFloat(
                    calculateOverallReviewCount(
                      reviewCount?.total_batch_star_count,
                      reviewCount?.student_count
                    ).toString()
                  ) /
                    5) *
                  100
                }
              />
              <ReviewLable>
                {" "}
                {calculateOverallReviewCount(
                  reviewCount?.total_batch_star_count,
                  reviewCount?.student_count
                )}
              </ReviewLable>
            </RattingProgess>
          </RattingProgessBar>
        </Col>
        <Col xs={12} lg={4} style={{ marginBottom: 30 }}>
          <ReviewProgessName>Overall Experience</ReviewProgessName>

          <RattingProgessBar>
            <RattingProgess>
              <ProgressBar
                now={
                  (parseFloat(
                    calculateOverallReviewCount(
                      reviewCount?.total_timing_star_count,
                      reviewCount?.student_count
                    ).toString()
                  ) /
                    5) *
                  100
                }
              />

              <ReviewLable>
                {calculateOverallReviewCount(
                  reviewCount?.total_timing_star_count,
                  reviewCount?.student_count
                )}
              </ReviewLable>
            </RattingProgess>
          </RattingProgessBar>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileRatings;