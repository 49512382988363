/** ****************************** Import libs *********************************** */

const URL_CONSTANTS = {
  login: "student-users/login",
  studentRegistration: "student-users",
  teacherLogin: "teacher-users/login",
  teacherRegistration: "teacher-users",
  teacherUsers: "teacher-users",
  batches: "batches",
  // tlist: "specific-tuition-details",
  review: "reviews",
  advertisement: "advertisements",
  studentsProfile: "class-booking",
  studentProfilePage: "student-users",
  mailValidation: "class-booking-validation",
  dashboard: "dashboard",
  filter: "class-booking-search",
  // tutionSearch: "teacher-users-search",
  tlist: "specific-tuition-batch-details",
  tutionSearch: "tuition-search",
  studentOTP: "student-users/otp",
  teacherOTP: "teacher-users/otp",
  studentValidateOTP: "student-users/otp-verify",
  teacherValidateOTP: "teacher-users/otp-verify",
  studentPassword: "student-users-change-password",
  teacherPassword: "teacher-users-change-password",
  myBooking: "class-student-booking",
  classBooking: "payments",
  bookingPayment: "payments",
  verifyPayment: "payment-verify",
  listReview: "reviews",
  studentReviews: "student-reviews",
  listAds: "advertisements",
  topAds: "top-advertisements",
  level: "level",
  bookingstudent: "class-booking-student",
  bookingDemoClass: "demo-class-booking",
  subjects: "subjects",
  topSubjects: "top-subjects",
  popularTution: "top-tuitions",
  location: "locations",
  areas: "areas",
  studentEmail: "student-email-sending",
  emailToBatch: "batch-email-sending",
  bookingValidation: "class-student-booking-validation",
  blogs: "blogs",
  rental: "rentals",
  fileUpload: "file-upload",
  studyMaterials: "study-materials",
  studyMaterialsItem: "study-materials/item",
};

export { URL_CONSTANTS };
