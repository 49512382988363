import React, { useState } from "react";
import StarRating from "./starRatings";

const AccordionItem = ({ title, content, tutionName, date, stars, avatar }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  let mobile = false;
  if(window.innerWidth > 768) {
    mobile = false;
  } else {
    mobile = true;
  }

  return (
    <div
      className={`accordion-item mt-4 border-0 rounded-3 p-2 ${
        isOpen ? "show" : ""
      }`}
    >
      <h2 className="m-0">
        <button
          class="accordion-button d-flex p-0"
          type="button"
          data-bs-toggle="collapse"
          aria-expanded="true"
          onClick={toggleAccordion}
          style={{ display: 'flex'}}
        >

          <img
            src={avatar}
            alt="avatar"
            className="rounded-circle me-3"
            style={{ width: '10%', height: '10%'}}
          />
          <div>
            <p className="m-0 fw-bolder text-black">{title}</p>
            <div className="d-flex accordian-star">
            <div className="d-flex align-items-center">
              <p className="m-0 me-2 small text-black align-items-center">{tutionName}</p>
            </div>
              {!mobile && <span className=" text-black">|</span>}
              <div className={!mobile && "ms-2"}>
                <StarRating rating={stars} totalStars={5} starSize={12} />
              </div>
            </div>
          </div>
        </button>
      </h2>

      {isOpen && (
        <div className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}>
          <hr />
          <div class="accordion-body">{content}</div>
          <hr />
          <p className="ms-3 prime-text fw-semibold">{date.slice(0, 10)}</p>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
