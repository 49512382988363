import { SEARCH_FAILURE, SEARCH_HANDLER, SEARCH_REQUEST, SEARCH_SUCCESS } from "../types/searchType";

const initialState = {
    data: null,
    error: ""
};

export const searchTutionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_REQUEST:
            return {
                ...state,
                data: "true"
            };
        case SEARCH_SUCCESS:
            return {
                ...state,
                data: action.payload,
                count: action.count,
                error: ""
            };
        case SEARCH_FAILURE:
            return {
                ...state,
                data: "error",
                error: action.payload,
            };
        case SEARCH_HANDLER:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }

}