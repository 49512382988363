import styled from "styled-components";

export const TabsContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;

  @media (max-width: 1400px) {
    width: 80%;
  }
  @media (max-width: 900px) {
    width: 100%;
  margin-top: 0;
    padding: 0 30px;
  }
`;

export const Footer = styled.div`
  margin-top: 30px;

  .login-link {
    color: #940e34;
    font-weight: 600;
    &:hover {
      color: #012834;
    }
  }
`;

export const SuccessMessage = styled.div`
  margin-top: 100px;
  padding: 0 10vw;
  text-align: center;
`;