import React, { useState, ReactNode } from "react";
import Sidebar from "../Sidebar/sidebar";
import { Row, Col } from "antd";
import "./layoutStyle.css";
import MobileSidebar from "../Sidebar/mobileSideBar";
interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {

  return (
    <Row>
      <Col xs={0} sm={0} md={24} lg={24} xl={24}>
      <Sidebar data={children} />
      </Col>
      <Col xs={24} sm={24} md={0} lg={0} xl={0}>
        <MobileSidebar data={children} />
      </Col>
    </Row>
  );
};

export default Layout;
