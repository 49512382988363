import { useState, useEffect } from "react";
import { Row, Col, Pagination, Select } from 'antd';

const CustomPagination = ({ total, currentPage, onPageChange }) => {
  const [pageSize, setPageSize] = useState(10);
  const [pageOptions, setPageOptions] = useState([]);

  useEffect(() => {
    // Generate page size options based on the total number of items
    const options = [];
    for (let i = 10; i <= total; i += 10) {
      options.push(i);
    }
    setPageOptions(options);
  }, [total]);

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    onPageChange(1, value);
  };

  const calculateTotalPages = () => {
    return Math.ceil(total / pageSize);
  };

  const adjustCurrentPage = (newPageSize) => {
    const totalPages = calculateTotalPages();
    const newPage = Math.min(currentPage, totalPages);
    onPageChange(newPage, newPageSize);
  };

  return (
      <Row className="d-flex justify-content-between align-items-center pagination-bar">
        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
        <Row gutter={[8, 8]} className="align-items-center">
          <Col xs={24} sm={24} md={24} lg={8}>
          <p className="m-0 text-gray">Items per page</p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
          <Select
            className="rounded pagination-select"
            value={pageSize}
            onChange={(value) => {
              setPageSize(value);
              adjustCurrentPage(value);
            }}
          >
            {pageOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
          <p className="m-0 text-gray">
            {`${(currentPage - 1) * pageSize + 1} - ${Math.min(
              currentPage * pageSize,
              total
            )} of ${total} Items`}
          </p>
          </Col>
        </Row>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={16} className="d-flex justify-content-end">
        <Pagination
          current={currentPage}
          total={total}
          pageSize={pageSize}
          onChange={(page, pageSize) => onPageChange(page, pageSize)}
        />
        </Col>
      </Row>
  );
};

export default CustomPagination;
