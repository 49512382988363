/** **************************** Import Types ****************************** */
import {
  BOOK_DEMO_REQUEST,
  BOOK_DEMO_SUCCESS,
  BOOK_DEMO_FAILURE,
} from "../types/bookDemoType";

const initialState = {
  loading: false,
  bookDemoResponse: "",
  error: "",
};

const bookDemoReducer = (state = initialState, action) => {
  console.log("form badge api reducer", action.payload);
  switch (action.type) {
    case BOOK_DEMO_REQUEST:
      return {
        ...state,
        loading: true,
        bookDemoResponse: "",
      };
    case BOOK_DEMO_SUCCESS:
      return {
        loading: false,
        bookDemoResponse: action.payload.responseStatus,
        error: "",
      };
    case BOOK_DEMO_FAILURE:
      return {
        loading: false,
        bookDemoResponse: "error",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default bookDemoReducer;
