import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Tab } from "react-bootstrap";

import { LoginUser } from "../../../redux/action/loginAction";
import { TeacherLoginUser } from "../../../redux/action/tloginAction";

import StyledTabs from "../../../components/StyledTabs";
import {
  MainContainer,
  TabsContainer,
  FormContainer,
  Footer
} from "./styledViews";
import LoginForm, { dataType } from "./Form";


const TAB = {
  student: "student",
  teacher: "teacher",
};
const ActionMap = {
  [TAB.student]: LoginUser,
  [TAB.teacher]: TeacherLoginUser,
};

const Login: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState<string>(TAB.student);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const dispatch: any = useDispatch();

  const handleTabSelect = (selectedTab: string | null) => selectedTab && setActiveTab(selectedTab);

  const handleSubmit = (values: dataType) => {
    setErrorMsg("");
    setLoading(true);

    const payload = {
      email_address: values.email,
      password: values.password,
    };

    const action = ActionMap[activeTab];
    dispatch(action(payload, true)).then((res: any) => {
      setLoading(false);

      if (!res) {
        setErrorMsg("Something went wrong, please try again later.");
        return;
      }
      if (res.detail?.credential_verified === false) {
        setErrorMsg("Incorrect Email Id or Password.");
      }
      else if (res.detail?.email_verified === false) {
        setErrorMsg("Please verify your email to get started.");
      }
      else if (res.success) {
        window.location.href = activeTab === TAB.student ? "/" : "/dashboard";
      }
      else {
        setErrorMsg("Something went wrong, please try again later.");
      }
    });
  };

  return (
    <MainContainer>
      <TabsContainer>
        <StyledTabs
          activeKey={activeTab}
          onSelect={handleTabSelect}
          className="login-tabs"
        >
          <Tab eventKey={TAB.student} title="Student Login" />
          <Tab eventKey={TAB.teacher} title="Teacher Login" />
        </StyledTabs>

        <FormContainer>
          <LoginForm onFormSubmit={handleSubmit} isLoading={isLoading} submitError={errorMsg} />
        </FormContainer>
      </TabsContainer>

      <Footer>
        <span>
          Don't have an account? &nbsp;
          <Link className="sign-up" to="/auth/register">
            Sign Up
          </Link>
        </span>
      </Footer>
    </MainContainer>
  );
};

export default Login;