import React, { useEffect, useState, useMemo } from 'react';
import _ from "lodash";
import { useParams } from "react-router-dom";
import { Tabs } from 'antd';
import moment from 'moment';

import { getStudyMaterialsForBatch } from '../../../api/list';
import { postStudyMaterial } from '../../../api/create';

import PageSpinner from '../../../components/PageSpinner';
import PageError from '../../../components/PageError';

import AddItem from './AddItem';
import ContentItem from './ContentItem';
import FormModal from './FormModal';
import {
  MainContainer,
  MainTitle,
  ItemListContainer,
} from './styledViews';

import { FileType, SMFile } from './types';


const StudyMaterials: React.FC = () => {
  const { batchId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string|null>(null);
  const [content, setContent] = useState<SMFile[]>([]);
  const [activeTab, setTab] = useState<FileType>(FileType.VIDEO);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const fetchData = () => {
    setIsLoading(true);

    getStudyMaterialsForBatch(batchId).then((res) => {
      if (!res.success) {
        setError("Failed to fetch content.");
        setIsLoading(false);
        return;
      }

      setContent(res.records);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = () => {
    setShowAddModal(true);
  };

  const handleSubmitAdd = (data: any) => {
    setShowAddModal(false);
    setIsLoading(true);
    
    const { title, file_url, thumb_url, file_size } = data;
    const payload = {
      batch_id: batchId,
      type: activeTab,
      title,
      file_url,
      thumb_url: thumb_url || undefined,
      file_size,
    };

    postStudyMaterial(payload).then(res => {
      if (!res.success) {
        setError("Failed to create entry.");
        setIsLoading(true);
        return;
      }

      fetchData();
    });
  };

  const tabItems = [
    {
      label: "Video Recordings",
      key: FileType.VIDEO,
      children: <ContentList dataList={content} type={FileType.VIDEO} onAdd={handleAdd} fetchData={fetchData} />,
    },
    {
      label: "Reference Notes",
      key: FileType.NOTE,
      children: <ContentList dataList={content} type={FileType.NOTE} onAdd={handleAdd} fetchData={fetchData} />,
    },
  ];

  return (
    <MainContainer>
      <MainTitle>Study Materials</MainTitle>

      {(isLoading && <PageSpinner />) ||
      (!!error && <PageError title={error} />) ||
      (
        <Tabs size="large" defaultActiveKey={activeTab} items={tabItems} onChange={(tab) => setTab(tab as FileType)} /> 
      )}

      {showAddModal && <FormModal
        show onHide={() => setShowAddModal(false)}
        type={activeTab}
        onSubmit={handleSubmitAdd}
      />}
    </MainContainer>
  );
};

const ContentList: React.FC<any> = ({ dataList, type, onAdd, fetchData }) => {
  const list = useMemo(() => {
    return _.chain(dataList)
      .filter((d) => d.type === type)
      .sortBy((d) => moment(d.created_at).unix())
      .value();
  }, [dataList, type]);

  return (
    <ItemListContainer>
      {_.map(list, item => (
        <ContentItem key={item.id} data={item} type={type} fetchData={fetchData} />
      ))}
      <AddItem onAdd={onAdd} />
    </ItemListContainer>
  );
};

export default StudyMaterials;