import React, { useState } from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100% !important;
  border: ${(props) => props.border || "none"};
`;

const Label = styled.label`
  margin-bottom: 8px;
`;

const Input = styled.input`
  padding: 13.5px;
  border: none;
  background-color: ${(props) => props.backgroundColor || "#f7f7f7"};
  border-radius: 5px;
  border: 1px solid transparent; /* Change to the desired focus color */
  font-size: 13px;
  width: ${(props) => props.width || "150px"};
  &:focus {
    outline: none;
    border: 1px solid #940e34; /* Change to the desired focus color */
  }
`;

const Select = styled.select`
  padding: 13.5px;
  border: none;
  background-color: ${(props) => props.backgroundColor || "#f7f7f7"};
  border-radius: 5px;
  font-size: 13px;
  color: #757575;
  &:focus {
    outline: none;
    border: 1px solid #940e34; /* Change to the desired focus color */
  }
`;

const TimeRangeContainer = styled.div`
  display: flex;
`;

const TimeInput = styled.input`
  flex: 1;
  padding: 10px;
  border: none;
  background-color: #f7f7f7;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;
  &:focus {
    outline: none;
    border: 1px solid #940e34; /* Change to the desired focus color */
  }
`;

const StyledInput = ({ type, defaultValue, options, ...rest }) => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timeRange, setTimeRange] = useState("");

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };
  const handleTimeChange = (event) => {
    if (rest.id === "startTime") {
      setStartTime(event.target.value);
    } else if (rest.id === "endTime") {
      setEndTime(event.target.value);
    }
  };

  return (
    <InputContainer>
      {type === "select" ? (
        <Select {...rest}>
          <option value="" disabled>
            {defaultValue}
          </option>
          {/* Default disabled option */}
          {options?.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Select>
      ) : type === "timerange" ? (
        <InputContainer>
          {type === "time-range" ? (
            <Input
              type="text"
              {...rest}
              value={timeRange}
              onChange={handleTimeRangeChange}
            />
          ) : (
            <Input type={type} {...rest} />
          )}
        </InputContainer>
      ) : (
        <Input {...rest} />
      )}
    </InputContainer>
  );
};
export default StyledInput;
