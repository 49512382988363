import { SelectProps } from "antd";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { GrDown } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import {
  getAreasList,
  getLevels,
  getLocationList,
  getSubject,
} from "../../api/list";
import { SearchTution } from "../../redux/action/searchAction";
import data, { SubBannerLocationType } from "./subBannerSearch";
import { BannerSearchBox, BannerWrap, SubbannerMain } from "./subbannerStyle";

const options: SelectProps["options"] = [];

for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

const ViewService: Function = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [level, setLevel] = useState<any>([]);
  const [citysearch, setCitysearch] = useState<any>([]);
  const [areasearch, setAreasearch] = useState<any>([]);
  const [subjectSearch, setSubjectsearch] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const [areaList, setAreaList] = useState<any>([]);
  const [subject, setsubject] = useState([]);
  const [filteredArea, setFilteredarea] = useState<any>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(true);

  const filteredOptions = data.filter((option: any) => {
    return option?.location?.toLowerCase().includes(searchTerm.toLowerCase());
  });
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    filterAreas();
  }, [citysearch]);
  const filterAreas = () => {
    if (citysearch?.length > 0) {
      const cityid = cityList?.find((c: any) => c?.label === citysearch)?.id;
      const belongedareas = areaList?.filter(
        (a: any) => a?.locationid == cityid
      );
      setFilteredarea(belongedareas);
    }
  };
  const submitForm = (values: any) => {
    const data = { ...values };
    data.subject_level = values.level;
    data.location = values.location;
    SearchTution({
      area: data?.selectArea,
      batch_id: "",
      city: data?.location,
      days: data.daysOfWeek ? parseInt(data.daysOfWeek) : null,
      level: data.subject_level,
      subject: data?.subject !== "undefined" ? data?.subject : "",
      timing: values.timing,
    });
    navigate(
      `${"/tuition-list"}?LOCATION_NAME=${data.location}&SELECT_AREA=${
        data.selectArea
      }&SELECT_LEVEL=${data.subject_level}&DAYS_OF_WEEK=${
        data.daysOfWeek
      }&TIME=${data.selectTiming}&SUBJECT_NAME=${data?.subject}`
    );
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const getallLevels = () => {
    getLevels().then((res) => {
      if (res?.records) {
        const UserData = res?.records?.map((item: any) => {
          return {
            value: item?.level_name,
            label: item?.level_name,
            id: item?.id,
          };
        });
        setLevel(UserData);
      }
    });
  };
  const getAllLocation = () => {
    getLocationList().then((res) => {
      if (res?.records) {
        const cityData = res?.records?.map((item: any) => ({
          value: item?.location,
          id: item?.id,
          label: item?.location,
        }));
        setCityList(cityData);
      }
    });
  };
  const getAllAreas = () => {
    getAreasList().then((res) => {
      if (res?.records) {
        const areaData = res?.records?.map((item: any) => ({
          value: item?.area,
          id: item?.id,
          label: item?.area,
          locationid: item?.location_id,
        }));
        setAreaList(areaData);
        // setFilteredarea(areaData);
      }
    });
  };
  const getallSubjects = () => {
    getSubject().then((res) => {
      if (res?.records) {
        const subjectData = res?.records?.map((item: any) => ({
          value: item?.subject,
          id: item?.id,
          label: item?.subject,
        }));
        setsubject(subjectData);
        console.log(subjectData);
      }
    });
    console.log(subject);
  };
  useEffect(() => {
    getallLevels();
    getallSubjects();
    getAllLocation();
    getAllAreas();

    // getLocationList();
    // if (params) {
    //   setLevel(
    //     params.SELECT_LEVEL?.split(",")[0] !== ""
    //       ? params.SELECT_LEVEL?.split(",")
    //       : []
    //   );
    //   setSearchTerm(params?.LOCATION_NAME);
    // }
  }, []);

  const handleInputChange = (e: any) => {
    if (e.target.value.length >= 3) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setSearchTerm(e.target.value);
  };

  const handleOptionClick = (
    option: SubBannerLocationType,
    setFieldValue: any
  ) => {
    setFieldValue("selectArea", option.area);
    setOpen(false);
    setSearchTerm(option.location);
  };

  const isValidInfo = (values: any) => {
    let isValid = false;
    if (values.location.length > 0) {
      isValid = true;
    } else if (values.subject.length > 0) {
      isValid = true;
    } else if (values.selectArea.length > 0) {
      isValid = true;
    } else if (values.daysOfWeek.length > 0) {
      isValid = true;
    } else if (values.selectTiming.length > 0) {
      isValid = true;
    }
    return isValid;
  };

  return (
    <>
      <SubbannerMain>
        <Container>
          <BannerWrap>
            <BannerSearchBox>
              <Formik
                enableReinitialize
                initialValues={{
                  location: params.LOCATION_NAME ?? "",
                  subject: params.SUBJECT_NAME ?? "",
                  selectArea: params.SELECT_AREA ?? "",
                  daysOfWeek: params.DAYS_OF_WEEK ?? "",
                  selectTiming: params.TIME ?? "",
                  level: params.SELECT_LEVEL ?? "",
                  // classLevel: params.CLASS_NAME ?? "",
                }}
                validate={(values: any) => {
                  let errors: any = {};
                  if (!values.location) {
                    errors.location = "Please enter your location";
                  }
                  return errors;
                }}
                onSubmit={submitForm}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  resetForm,
                  dirty,
                }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={3} style={{ width: "19% !important" }}>
                          <Form.Group className="form-group">
                            <Form.Select
                              id="dropdown-city"
                              title="Select City"
                              name="location"
                              value={values.location}
                              // filterOption={filterOption}

                              onChange={handleChange}
                            >
                              <option value="">Select City</option>
                              {cityList?.map((sub: any) => (
                                <option value={sub?.label}>{sub?.label}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col lg={3} style={{ width: "19% !important" }}>
                          <Form.Group className="form-group">
                            <Form.Select
                              id="dropdown-city"
                              title="Select City"
                              name="location"
                              value={values.location}
                              // filterOption={filterOption}

                              onChange={handleChange}
                            >
                              <option value="">Select City</option>
                              {cityList?.map((sub: any) => (
                                <option value={sub?.label}>{sub?.label}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col lg={3} style={{ width: "19% !important" }}>
                          <Form.Group className="form-group">
                            <Form.Select
                              id="dropdown-area"
                              title="Select Area"
                              name="selectArea"
                              value={values.selectArea}
                              onChange={handleChange}
                            >
                              <option value="">Select Area</option>
                              {areaList?.map((sub: any) => (
                                <option value={sub?.label}>{sub?.label}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          {/* <Form.Group className="form-group CloseBtn">
                            <Form.Control
                              type="text"
                              placeholder="Area"
                              id="selectArea"
                              autoComplete="off"
                              name="selectArea"
                              value={values.selectArea}
                              onChange={handleChange}
                            />
                            {values.selectArea && (
                              <AiOutlineCloseCircle
                                type="reset"
                                className="resetbtn"
                                onClick={() => setFieldValue("selectArea", "")}
                              >
                                close
                              </AiOutlineCloseCircle>
                            )}
                          </Form.Group> */}
                        </Col>
                        <Col lg={3} style={{ width: "19% !important" }}>
                          <Form.Group className="form-group">
                            <Form.Select
                              id="dropdown-subject"
                              title="Select Subject"
                              name="subject"
                              value={values.subject}
                              onChange={handleChange}
                            >
                              <option value="">Select Subject</option>
                              {subject?.map((sub: any) => (
                                <option value={sub?.label}>{sub?.label}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col lg={3}>
                          <Form.Group className="form-group btn-wrap">
                            <Button
                              type="submit"
                              variant={
                                isValidInfo(values)
                                  ? "secondary active"
                                  : "secondary"
                              }
                            >
                              Modify Search
                            </Button>
                            <Button
                              className={
                                toggle ? "more-field" : "more-field open-fields"
                              }
                              onClick={() => setToggle(!toggle)}
                            >
                              More <GrDown />
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>

                      {!toggle && (
                        <Row>
                          <Col lg={3}>
                            <Form.Group className="form-group">
                              <Form.Select
                                id="dropdown-level"
                                title="Select Level"
                                name="level"
                                value={values.level}
                                onChange={handleChange}
                              >
                                <option value="">Select Level</option>
                                {level?.map((lev: any) => (
                                  <option value={lev?.label}>
                                    {lev?.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col lg={3}>
                            <Form.Group className="form-group">
                              <Form.Select
                                id="dropdown-days"
                                title="Select days of the week"
                                value={values.daysOfWeek}
                                onChange={(e: any) =>
                                  setFieldValue("daysOfWeek", e.target.value)
                                }
                              >
                                <option value="">Select Days</option>
                                <option value="0">All days(Mon-Fri)</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednesday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>
                                <option value="7">Sunday</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col lg={3}>
                            <Form.Group className="form-group">
                              <Form.Select
                                id="selectTiming"
                                title="Select Timings"
                                name="selectTiming"
                                value={values.selectTiming}
                                onChange={(e: any) =>
                                  setFieldValue("selectTiming", e.target.value)
                                }
                              >
                                <option value="">Select Timing</option>
                                <option value="1">6AM to 9AM</option>
                                <option value="2">9AM to 1AM</option>
                                <option value="3">2PM to 6PM</option>
                                <option value="4">6PM to 9PM</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col lg={3}>
                            <Form.Group className="form-group">
                              <Form.Select
                                id="selectTiming"
                                title="Select Timings"
                                name="selectTiming"
                                value={values.selectTiming}
                                onChange={(e: any) =>
                                  setFieldValue("selectTiming", e.target.value)
                                }
                              >
                                <option value="">Select Timing</option>
                                <option value="1">6AM to 9AM</option>
                                <option value="2">9AM to 1AM</option>
                                <option value="3">2PM to 6PM</option>
                                <option value="4">6PM to 9PM</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    </form>
                  </>
                )}
              </Formik>
            </BannerSearchBox>
          </BannerWrap>
        </Container>
      </SubbannerMain>
    </>
  );
};

export default ViewService;
