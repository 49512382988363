import React from "react";
import {
  FaqSection,
  FaqTitle,
  FaqContent,
  FaqImg,
  FaqAccordion,
} from "../../pages/home/homeStyle";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import FaqImage from "../../assets/images/faq-img.png";
import FaqText from "./faqText";

export const FaqSectionList = () => {
  return (
    <>
      <FaqSection>
        <Container>
          <Row>
            <Col sm={12}>
              <FaqTitle>{FaqText?.heading}</FaqTitle>
              <FaqContent>{FaqText.faqContent}</FaqContent>
            </Col>
            <Col md={5}>
              <FaqImg>
                <img src={FaqImage} alt="FAQ" />
              </FaqImg>
            </Col>
            <Col md={7}>
              <FaqAccordion>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {FaqText.accordianHeading1}
                    </Accordion.Header>
                    <Accordion.Body>
                      {FaqText.accordianHeadingBody1}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {FaqText?.accordiaaHeading2}
                    </Accordion.Header>
                    <Accordion.Body>
                      {FaqText?.accordianHeadingBody2}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      {FaqText?.accordiaaHeading3}
                    </Accordion.Header>
                    <Accordion.Body>
                      {FaqText?.accordianHeadingBody3}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      {FaqText?.accordiaaHeading4}
                    </Accordion.Header>
                    <Accordion.Body>
                      {FaqText.accordianHeadingBody4}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </FaqAccordion>
            </Col>
          </Row>
        </Container>
      </FaqSection>
    </>
  );
};
export default FaqSectionList;
