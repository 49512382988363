/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  PAYMENT_REQUEST,
} from "../types/paymentType";
import { postClassBooking, postBookingPayment, postVerifyPayment } from "../../api/create";
import { updateBatches } from "../../api/update";
import { getBatchesList, getBatchesById } from "../../api/list";
import { deleteBadches } from "../../api/delete";
import store from "../store";
import { hostConfig } from "../../config";
import { URL_CONSTANTS } from "../../api/urls";
import axios from "axios";

export const paymentRequest = () => ({
  type: PAYMENT_SUCCESS,
});
export const paymentSuccess = (users) => ({
  type: PAYMENT_FAILURE,
  payload: users,
});
export const paymentFailure = (error) => ({
  type: PAYMENT_REQUEST,
  payload: error,
});

//Class Booking
export const CreateClassBooking = (data) =>
  async function (dispatch) {
    console.log(data, "shgdvsjfbskhfbdsffh");
    dispatch(paymentRequest());
    return postClassBooking(data)
      .then((res) => {
        if (res) {
          console.log("badge post response", res);
          dispatch(paymentSuccess({ responseStatus: res }));
          // toast.success("Badge created successfully");
          return res;
        }
        dispatch(paymentFailure(res.message));
        // toast.success(res.message);
        return res.message;
      })
      .catch((err) => console.log("Badge not Created "));
  };

export const CreateBookingPayment = (data) => postBookingPayment(data)
  .then(res => res)
  .catch(err => err);

export const VerifyBookingPayment = (data) => postVerifyPayment(data)
  .then(res => res)
  .catch(err => err);