/** **************************** Import Types ****************************** */
import {
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
    PROFILE_FAILURE,
} from "../types/profileType";

const initialState = {
    loading: false,
    profileResponse: "",
    error: "",
};

const profileReducer = (state = initialState, action) => {
    console.log("form Profile api reducer", action.payload);
    switch (action.type) {
        case PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                profileResponse: "",
            };
        case PROFILE_SUCCESS:
            return {
                loading: false,
                profileResponse: action.payload.responseStatus,
                error: "",
            };
        case PROFILE_FAILURE:
            return {
                loading: false,
                profileResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default profileReducer;
