const calculateOverallReviewCount=(totalReviewStarCount:any, reviewStudentCount:any)=> {
    if (reviewStudentCount === 0) {
      return 0; // Return 0 when dividing by 0
    }
    if (totalReviewStarCount === 0) {
      return 0; // Return 0 when dividing by 0
    }
    if (isNaN(totalReviewStarCount) || isNaN(reviewStudentCount)) {
      return -0; // Return an error message for non-numeric inputs
    }
    const result = totalReviewStarCount / reviewStudentCount; // Calculate the result as a number
    const isInteger = result % 1 === 0;
    if(isInteger){
      return result.toFixed(0)
    }
    else{
      return result.toFixed(1);
    }
  }

  export default calculateOverallReviewCount ;
