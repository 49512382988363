import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import ReactPlayer from "react-player";

import PageSpinner from "../../components/PageSpinner";

import {
  VideoPageContainer,
  VideoContent,
  VideoViewerTitle,
  VideoViewerDate,
} from "./styledViews";
import { getStudyMaterialById } from "../../api/list";
import { SMFile } from "../tutorPanel/StudyMaterials/types";


const VideoViewer: React.FC = () => {
  const { itemId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const video = useRef<any>(null);

  useEffect(() => {
    if (!itemId) return;
    getStudyMaterialById(itemId).then(res => {
      if (!res.success) return;
      video.current = res.records;
      setIsLoading(false);
    });
  }, []);

  return (
    <VideoPageContainer>
      {(isLoading && <PageSpinner />) ||
      (
        <VideoContent>
          <ReactPlayer
            controls playing
            url={video.current.file_url}
            width="100%" height="100%"
          />

          <VideoViewerTitle>{video.current.title}</VideoViewerTitle>
          <VideoViewerDate>
            {moment(video.current.created_at).format("MMM DD, YYYY")}
          </VideoViewerDate>
        </VideoContent>
      )}
      
    </VideoPageContainer>
  );
};

export default VideoViewer;