/** ****************************** Import libs *********************************** */
import { changePasswordDataApi, putDataApi, putDataByIdApi, putFormDataApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const updateUserData = (params, id) =>
  putDataByIdApi(URL_CONSTANTS.student, params, id);

export const updatePassword = (params, id, token) =>
  changePasswordDataApi(URL_CONSTANTS.users, params, id, token);

export const updateBatches = (params, id, token) =>
  putDataByIdApi(URL_CONSTANTS.batches, params, id, token);

export const updateStudyMaterial = (params) =>
  putDataApi(URL_CONSTANTS.studyMaterials, params);

export const updateProfile = (params, id, token) =>
  putDataByIdApi(URL_CONSTANTS.teacherRegistration, params, id, token);

export const updateStudentProfile = (params, id, token) =>
  putDataByIdApi(URL_CONSTANTS.studentsProfile, params, id, token);

export const updateStudentProfilePage = (params, id, token) =>
  putDataByIdApi(URL_CONSTANTS.studentRegistration, params, id, token);

export const updateStudent = (params, id, token) =>
  putDataByIdApi(URL_CONSTANTS.studentsProfile, params, id, token);

export const updateReview = (params, id, token) =>
  putDataByIdApi(URL_CONSTANTS.review, params, id, token);

export const updateAdvertisement = (params, id, token) =>
putFormDataApi(URL_CONSTANTS.advertisement, params, id, );
