/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MYBOOKING_SUCCESS,
  MYBOOKING_FAILURE,
  MYBOOKING_REQUEST,
} from "../types/myBookingTypes";
// import { updateStudentProfilePage } from "../../api/update";
import { getMyBookingId } from "../../api/list";
import { postBookingClass, postBookingDemoClass } from "../../api/create";

export const MyBookingRequest = () => ({
  type: MYBOOKING_REQUEST,
});
export const MyBookingSuccess = (users) => ({
  type: MYBOOKING_SUCCESS,
  payload: users,
});
export const MyBookingFailure = (error) => ({
  type: MYBOOKING_FAILURE,
  payload: error,
});

export const GetMyBookingById = (userId, data) =>
  async function (dispatch) {
    dispatch(MyBookingRequest());
    return getMyBookingId(userId, data)
      .then((res) => {
        if (res.success) {
          dispatch(MyBookingSuccess(res.records));
          return res;
        }
        dispatch(MyBookingFailure(res));
        return res;
      })
      .catch((err) =>  err.message);
  };

// class booking student

export const CreateBookingClass = (data) =>
  async function (dispatch) {
    dispatch(MyBookingRequest());
    return postBookingClass(data)
      .then((res) => {
        console.log("badge post response", res);
        if (res.success) {
          dispatch(MyBookingSuccess(res?.records));
          // toast.success("Batch created successfully");
          return res;
        }
        dispatch(MyBookingFailure(res.message));
        // toast.success("Batch created successfully");
        return res.message;
      })
      .catch((err) => console.log("Class not Created "));
  };

export const CreateBookingSeat = (data) => {
  return postBookingClass(data).then(res => {
    if (!res.success) {
      throw new Error(res?.detail?.error || "Something went wrong.");
    }
    return res;
  });
};

export const CreateBookingDemoClass = (data) => {
  return postBookingDemoClass(data).then(res => {
    if (!res.success) {
      throw new Error(res?.detail?.error || "Something went wrong.");
    }
    return res;
  });
};