export enum FileType {
  VIDEO = "video",
  NOTE = "note"
}

export type SMFile = {
  id: number
  batch_id: string
  type: FileType
  title: string
  file_url: string
  thumb_url?: string
  file_size: number
  is_deleted: boolean
  created_at: string
  updated_at: string
}