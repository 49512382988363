import styled from "styled-components";

import DropArrow from "../../assets/images/arrow-down.svg";

export const SubbannerMain = styled.div`
  background-color: #f7f7f7;
  padding: 30px 0px;
  min-height: 260px;
  display: flex;
  align-items: center;
  @media (max-width: 1440px) {
    min-height: 220px;
    padding: 25px 0;
  }
`;
export const BannerWrap = styled.div``;
export const BannerSearchBox = styled.div`
  box-shadow: 0px 0px 50px #0128341a;
  background: #940e34;
  padding: 20px 20px 0px;
  border-radius: 30px;
  .CloseBtn {
    position: relative;
    .resetbtn {
      position: absolute;
      top: 22px;
      right: 10px;
      height: 20px;
      width: 20px;
      color: #940e34;
      opacity: 0.6;
      @media (max-width: 1440px) {
        top: 14px;
      }
    }
  }
  @media (max-width: 1440px) {
    padding: 20px 20px 5px;
    border-radius: 30px;
  }
  .form-select {
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    @media (max-width: 1366px) {
      font-size: 14px;
    }
  }
  .form-control {
    /* border-radius: 30px; */
    background: #f7f7f7;
    border: none;
    padding: 20px;
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    color: #012834;
    opacity: 1;
    border: 1px solid #f7f7f7;
    @media (max-width: 1440px) {
      padding: 14px 20px;
    }
    @media (max-width: 1366px) {
      font-size: 14px;
    }
    ::placeholder {
      color: #012834;
      opacity: 1; /* Firefox */
    }
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-secondary {
      width: calc(100% - 110px);
      padding: 20px 20px;
      font-weight: 600;
      background: #ffffff;
      color: #5e0b22;
      &:hover {
        /* background: #5e0b22; */
        color: #000000;
      }
      &.active {
        &:hover {
          /* background: #5e0b22; */
          color: #000000;
        }
        background: #ffffff;
        color: #000000;
      }
      @media (max-width: 1440px) {
        padding: 14px 20px;
        width: calc(100% - 85px);
      }
      @media (max-width: 1366px) {
        font-size: 14px;
      }
    }
    .more-field {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      text-decoration: none;
      padding: 0;
      background: transparent;
      border: none;
      margin-right: 10px;
      &:active {
        background: transparent !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px;
        margin-right: 0;
      }
      svg {
        polyline {
          stroke: #ffffff;
        }
      }
      &.open-fields {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  .ant-select-selector {
    /* border-radius: 30px; */
    background-color: #f7f7f7 !important;
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    color: #012834;
    border: 1px solid #f7f7f7 !important;
    min-height: 60px;
    padding: 5px 36px 5px 20px;
    opacity: 1;
    @media (max-width: 1440px) {
      min-height: 48px;
    }
    .ant-select-selection-placeholder {
      inset-inline-start: 20px;
      inset-inline-end: 20px;
      color: #012834;
      @media (max-width: 1366px) {
        font-size: 14px;
      }
    }
    .ant-select-selection-item {
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 20px;
      padding: 3px 5px;
      height: 26px;
      font-size: 16px;
      line-height: 120%;
      font-weight: 500;
      color: #012834;
      .anticon {
        vertical-align: middle;
        svg {
          fill: #940e34;
        }
      }
    }
  }
`;
