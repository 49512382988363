import React from "react";
import Button from "@mui/material/Button";

const EmailVerification = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "40vh" }}>
      <h3>Your email is verified Successfully</h3>
      <div>
        <a href="/">
          <Button variant="contained">Back to Login</Button>
        </a>
      </div>
    </div>
  );
};

export default EmailVerification;
