
import React from "react";
import { useNavigate } from "react-router-dom";


const BlogCard = ({ id, title, subtitle, coverImage,body,postedAt }) => {
//   const history = useHistory();
const navigate = useNavigate();
const handleCardClick = () => {
  // Navigate to the details page with the blog ID
  // navigate(`/blogs-details`);
  navigate(
    `${"/blogs-details"}?ID=${
      id
    }`
  );
};;

  const cardStyle = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
    transition: "transform 0.2s ease-in-out",
    margin: "20px", // Add side margin (adjust the value according to your preference)
    cursor: "pointer", // Add a cursor pointer to indicate it's clickable
  };

  const coverImageStyle = {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  };

  const contentStyle = {
    padding: "16px",
    flex: 1, // Allow content to take remaining height
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Space between title/subtitle and date
  };
  const titleStyle = {
    fontSize: "1.2em",
    marginBottom: "8px",
  };

  const subtitleStyle = {
    fontSize: "0.9em",
    color: "#666",
  };
  const dateStyle = {
    fontSize: "0.8em",
    color: "#999",
    marginTop: "8px",
  };
  // Format the postedAt date
  const formattedDate = new Date(postedAt).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return (
    <div style={cardStyle} onClick={handleCardClick} >
      <img style={coverImageStyle} src={coverImage} alt="Blog Cover"   onError={(e) => {
                            e.target.src = "https://cdn4.wpbeginner.com/wp-content/uploads/2018/07/whatisblog.png"; // Set to default image
                            e.target.onerror = null; // Remove the event handler to prevent infinite loop
                          }} />
      <div style={contentStyle}>
        <h3 style={titleStyle}>{title}</h3>
        <p style={subtitleStyle}>{subtitle}</p>
        <p style={dateStyle}>{`Posted at: ${formattedDate}`}</p>
      </div>
    </div>
  );
};

export default BlogCard;
