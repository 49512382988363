import React, { FC, ReactNode } from "react";
import ReactDOM from "react-dom";
import { GrClose } from "react-icons/gr";
import {
  ModalOverlay,
  ModalWrapper,
  ModalContent,
  Card,
  CardHeader,
  ModalHeader,
  ModalCloseButton,
  UserName,
  TitleHeader,
  ModalBody,
  CloseBtn,
} from "./modalStyle"; // Adjust the import path based on your project structure

interface ModalProps {
  isShowing: boolean;
  hide: () => void;
  title: string;
  width?: string;
  marginTop?: string;
  marginBottom?: string;
  userName?: string;
  backgroundColor?: string;
  color?: string;
  iconColor?: string;
  children?: ReactNode;
}

const Modal: FC<ModalProps> = ({
  isShowing,
  hide,
  title,
  width,
  marginTop,
  marginBottom,
  userName,
  backgroundColor,
  color,
  iconColor,
  children,
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <ModalOverlay>
            <ModalWrapper>
              <ModalContent
                style={{
                  width: width,
                }}
              >
                <Card>
                  <CardHeader
                    style={{
                      marginTop: marginTop,
                      marginBottom: marginBottom,
                      backgroundColor: backgroundColor,
                      color: color,
                    }}
                  >
                    <ModalHeader>
                      <div>
                        <UserName>{userName}</UserName>
                        <TitleHeader>{title}</TitleHeader>
                      </div>
                      <ModalCloseButton type='reset' onClick={hide}>
                        <CloseBtn>
                          <GrClose />
                        </CloseBtn>
                      </ModalCloseButton>
                    </ModalHeader>
                  </CardHeader>
                  <ModalBody>{children}</ModalBody>
                </Card>
              </ModalContent>
            </ModalWrapper>
          </ModalOverlay>
        </>,
        document.body
      )
    : null;

export default Modal;
