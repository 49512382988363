import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  AboutBannerWrap,
  AboutBannerTitle,
} from "../../pages/AboutUs/aboutusStyle";

const AboutBanner: Function = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AboutBannerWrap>
        <Container>
          <Row>
            <Col sm={12}>
              <AboutBannerTitle>About Us</AboutBannerTitle>
            </Col>
          </Row>
        </Container>
      </AboutBannerWrap>
    </>
  );
};

export default AboutBanner;
