import React, { useRef } from "react";
import _ from "lodash";
import { Row, Col, Input, Button, Typography } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { CheckCircleOutlined } from "@ant-design/icons";

import { openInNewTab, REGEX } from "../../../utils/helper";

import BaseModal from "../../../components/Modal/BaseModal";
import FileUploader from "../../../components/FileUploader/Input";
import {
  InputLabel,
  SubmitContainer,
} from "./styledViews";

import { FileType, SMFile } from "./types";

const Text = Typography.Text;


const titleMap = {
  [FileType.VIDEO]: "Add a Video",
  [FileType.NOTE]: "Add a Note",
};
const editTitleMap = {
  [FileType.VIDEO]: "Update Video",
  [FileType.NOTE]: "Update Note",
};

const initialValues = {
  title: '',
  fileUrl: '',
  thumbUrl: '',
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  fileUrl: Yup.string()
    .required("Please upload a file")
    .matches(REGEX.url, "Must be a valid URL"),
  thumbUrl: Yup.string()
    .matches(REGEX.url, "Must be a valid URL"),
});

type Props = {
  show: boolean
  onHide: () => void
  type: FileType
  data?: SMFile
  onSubmit: (data: any) => void
}

const FormModal: React.FC<Props> = ({ show, onHide, type, data, onSubmit }) => {
  const fileSize = useRef<number>(data?.file_size || 0);

  const initValues = !data ? _.clone(initialValues) : {
    title: data.title,
    fileUrl: data.file_url,
    thumbUrl: data.thumb_url || "",
  };

  const handleFormSubmit = (values: any) => {
    const data = {
      title: values.title,
      file_url: values.fileUrl,
      thumb_url: values.thumbUrl,
      file_size: fileSize.current,
    };
    onSubmit(data);
  };

  const onChangeFile = (handleChange: (e: any) => void) => (event: any, size?: number) => {
    fileSize.current = size || 0;
    handleChange(event);
  };

  return (
    <BaseModal show={show} onHide={onHide} title={!data ? titleMap[type] : editTitleMap[type]}>
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row gutter={[24, 36]}>
              <Col span={24}>
                <InputLabel>Title *</InputLabel>
                <FormikInput
                  name="title"
                  value={values?.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched?.title}
                  error={errors?.title}
                />
              </Col>

              <Col xs={24} md={12}>
                <InputLabel>{type === FileType.VIDEO ? "Video" : "Notes"} *</InputLabel>
                <FileUploader
                  verifyFile
                  bucket={type === FileType.VIDEO ? "teacher_video" : "teacher_notes"}
                  name="fileUrl"
                  value={values?.fileUrl}
                  onChange={onChangeFile(handleChange)}
                  onBlur={handleBlur}
                  touched={touched?.fileUrl}
                  error={errors?.fileUrl}
                  onView={type === FileType.VIDEO ? (url => openInNewTab(url)) : undefined}
                  fileTypes={type === FileType.VIDEO ? ["video/mp4"] : ["application/pdf"]}
                  maxFileSize={25}
                />
              </Col>

              {type === FileType.VIDEO && (
                <Col xs={24} md={12}>
                  <InputLabel>Thumbnail</InputLabel>
                  <FileUploader
                    verifyFile
                    bucket="teacher_video"
                    name="thumbUrl"
                    value={values?.thumbUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched?.thumbUrl}
                    error={errors?.thumbUrl}
                    onView={(url => openInNewTab(url))}
                    fileTypes={["image/jpeg", "image/png"]}
                    maxFileSize={0.5}
                  />
                </Col>
              )}

              <Col span={24}>
                <SubmitContainer>
                  <Button
                    block
                    type="primary"
                    shape="round"
                    size="large"
                    icon={<CheckCircleOutlined />}
                    htmlType="submit"
                    disabled={!values.title || !_.isEmpty(errors)}
                  >
                    Submit
                  </Button>
                </SubmitContainer>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </BaseModal>
  );
};

const FormikInput: React.FC<any> = ({ name, value, onChange, onBlur, touched, error }) => {
  const status = (touched && error) ? "error" : undefined;

  return (
    <>
      <Input
        size="large"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        status={status}
      />

      {status && <Text type="danger">{error}</Text>}
    </>
  );
};

export default FormModal;