import styled from "styled-components";


export const FieldContainer = styled.div`
  .ant-btn {
    background: #940E34;
    &:hover {
      background: #d2144a !important;
    }
  }
`;

export const UploadContainer = styled.div`
  height: 400px;
  position: relative;

  .ant-btn {
    background: #940E34;
    &:hover {
      background: #d2144a !important;
    }
  }
`;

export const IconContainer = styled.div`
  font-size: 60px;
  color: #940E34;
  margin-bottom: 10px;
`;

export const MainText = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: rgba(0,0,0,0.3);
  margin-bottom: 10px;
`;

export const InfoText = styled.p`
  position:absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  margin: 0;
  color: rgba(0,0,0,0.3);
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 7px;
  right: 10px;
  font-size: 25px;
  cursor: pointer;
`;