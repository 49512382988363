import StripeCheckout from "react-stripe-checkout";

const TakeMoney = () => {
  const onToken = (token: any) => {
    fetch("/save-stripe-token", {
      method: "POST",
      body: JSON.stringify(token),
    }).then((response) => {
      response.json().then((data) => {
        alert(`We are in business, ${data.email}`);
      });
    });
  };

  return (
    <StripeCheckout
      token={onToken}
      stripeKey="pk_test_51MhUyOSJ51WgBsJeZjgcNHtVzYc97q1YD0i34pjGVBaxr0sUXsEw508hVICIty7RKzmNYwQ3ku8333H5DDM6PAgu007GHyglGL"
    />
  );
};

export default TakeMoney;
