import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 100%;
  overflow: auto;
  position: relative;
  padding-bottom: 80px;
`;

export const TabsContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  margin-top: 160px;

  @media (max-width: 1400px) {
    width: 75%;
  }
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 40px;
    padding: 0 30px;
  }
`;

export const FormContainer = styled.div`
  margin-top: 30px;

  .form-control {
    background: #ffffff;
    color: #012834;
    font-weight: 500;
  }
  .form-label {
    font-size: 14px;
  }
`;

export const SubmitContainer = styled.div`
  margin-top: 10px;

  .btn {
    width: 180px;
    font-weight: 500;
    &:disabled {
      background: #6c757d;
      border-color: #6c757d;
    }
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px 60px;
  @media (max-width: 900px) {
    padding: 30px;
  }

  .sign-up {
    color: #940e34;
    font-weight: 600;
    &:hover {
      color: #012834;
    }
  }
`;