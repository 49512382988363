/** **************************** Import Types ****************************** */
import { OTP_REQUEST, OTP_SUCCESS, OTP_FAILURE } from "../types/otpType";

const initialState = {
  loading: false,
  badgeResponse: "",
  error: "",
};

const otpReducer = (state = initialState, action) => {
  console.log("form OTP api reducer", action.payload);
  switch (action.type) {
    case OTP_REQUEST:
      return {
        ...state,
        loading: true,
        otpResponse: "",
      };
    case OTP_SUCCESS:
      return {
        loading: false,
        otpResponse: action.payload.responseStatus,
        error: "",
      };
    case OTP_FAILURE:
      return {
        loading: false,
        otpResponse: "error",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default otpReducer;
