/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ADVERTISEMENT_POST_SUCCESS,
  ADVERTISEMENT_POST_FAILURE,
  ADVERTISEMENT_POST_REQUEST,
} from "../types/advertisementType";
import { postAdvertisementData } from "../../api/create";
import { updateAdvertisement } from "../../api/update";
import { getAdvertisement, getAdvertisementById } from "../../api/list";
import { deleteAdvertisement } from "../../api/delete";

export const advertisementPostRequest = () => ({
  type: ADVERTISEMENT_POST_REQUEST,
});
export const advertisementPostSuccess = (users) => ({
  type: ADVERTISEMENT_POST_SUCCESS,
  payload: users,
});
export const advertisementPostFailure = (error) => ({
  type: ADVERTISEMENT_POST_FAILURE,
  payload: error,
});

export const CreateAdvertisementPost = (data) =>
  async function (dispatch) {
    dispatch(advertisementPostRequest());
    return postAdvertisementData(data)
      .then((res) => {
        if (!res) {
          dispatch(advertisementPostRequest({ responseStatus: "success" }));
          //toast.success("Advertisement created successfully");
          return "success";
        }
        dispatch(advertisementPostFailure(res.message));
        // toast.success("Advertisement created successfully");
        return res.message;
      })
      .catch((err) => console.log("Advertisement not Created "));
  };
export const UpdateAdvertisement = (data, userId) =>
  async function (dispatch) {
    dispatch(advertisementPostRequest());
    return updateAdvertisement(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(advertisementPostSuccess({ responseStatus: "success" }));
         
          return "success";
        }
        dispatch(advertisementPostFailure(res.error));
        return res.message || res.error;
      })
      .catch((err) => console.log("Badge not Updated "));
  };

export const GetAdvertisementList = (teacherId, searchValue, page, limit) =>
  async function (dispatch) {
    dispatch(advertisementPostRequest());
    const params = {
      teacher_id: teacherId,
      search_value: searchValue,
      page: page, // Include the page parameter
      rowsPerPage: limit, // Include the limit parameter as rowsPerPage
    };
    return getAdvertisement(params)
      .then((res) => {
        if (res) {
          //console.log("student profile action=========", res);
          dispatch(advertisementPostSuccess({ responseStatus: res }));
          return res;
        }
        dispatch(advertisementPostFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
// export const GetAdvertisementList = (params) =>
//   async function (dispatch) {
//     dispatch(advertisementPostRequest());
//     return getAdvertisement(params)
//       .then((res) => {
//         if (res) {
//           console.log("Advertisement action=========", res);
//           dispatch(advertisementPostSuccess({ responseStatus: res }));
//           return res;
//         }
//         dispatch(advertisementPostFailure(res.message));
//         return res.message;
//       })
//       .catch((err) => console.log("Catch Error:", err));
//   };
export const GetAdvertisementById = (params) =>
  async function (dispatch) {
    dispatch(advertisementPostRequest());
    return getAdvertisementById(params)
      .then((res) => {
        if (res.results) {
          dispatch(advertisementPostSuccess(res.results));
          return res;
        }

        dispatch(advertisementPostFailure(res.message));

        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
export const DeleteAdvertisement = (AdvertisementId) =>
  async function (dispatch) {
    dispatch(advertisementPostRequest());
    return deleteAdvertisement(AdvertisementId)
      .then((res) => {
        if (!res.error) {
          dispatch(advertisementPostSuccess({ responseStatus: "success" }));
          //  toast.success("Advertisement Deleted Successfully");
          return AdvertisementId; // Return the deleted Advertisement's ID
        }
        dispatch(advertisementPostFailure(res.error));
        return res.error;
      })
      .catch((err) => {
        console.log("Advertisement not Deleted");
        return err;
      });
  };
