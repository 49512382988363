import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DynamicTabs from "../../components/Tab";
import "./serviceStyle.css";
import CheckboxList from "../../components/Checkbox";
import ColorCheckboxGroup from "../../components/Checkbox";
import RoundedButton from "../../components/TuorPanel/Button";
import { IoLocation } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { FaChevronDown, FaCity } from "react-icons/fa";
import { BiBuildingHouse, BiBuildings } from "react-icons/bi";
import { AutoComplete, Select } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { getLocationList, getareaById,getSpecificRentals } from "../../api/list";
import { formatQueryArrayStringForSearch } from "../../utils/helper";

const Services = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState(0);
  const accommodation = [
    { id: "Single Occupancy", label: "Single Occupancy" },
    { id: "Double Sharing", label: "Double Sharing" },
    { id: "Triple Sharing", label: "Triple Sharing" },
    { id: "Sharing with more than 4", label: "Sharing with more than 4" },
    // Add more color options as needed
  ];
  const budget = [
    { id: "Less than 2000", label: "Less than 2000" },
    { id: "2000 to  3000", label: "2000 to  3000" },
    { id: "3000 to 5000", label: "3000 to 5000" },
    { id: "5000 and above", label: "5000 and above" },
    // Add more color options as needed
  ];
  const Amenities = [
    { id: "Wi Fi", label: "Wi Fi" },
    { id: "Bed", label: "Bed" },
    { id: "Table Chair", label: "Table Chair" },
    { id: "Meals", label: "Meals" },
    // Add more color options as needed
  ];
  const tabs = [
    {
      label: "Rental",
    },
    {
      label: "Meal",
    },
    {
      label: "Books",
    },
    {
      label: "Career Counselling",
    },
    {
      label: "Others",
    },
  ];

  const handleNavigate = () => {
    // console.log(autoCompleteLableValue?.city,autoCompleteLableValue?.area,"Change")
    const params = {
      city:autoCompleteLableValue?.city,
      area:autoCompleteLableValue?.area,
      accommodation_type: formatQueryArrayStringForSearch(selectedValues?.accommodation_type),
      property_type: formatQueryArrayStringForSearch(selectedValues?.budget),
      amenities: formatQueryArrayStringForSearch(selectedValues?.amenities),
    };
    console.log(params, "params"); 
    
    getSpecificRentals(params)
      .then((response) => {
        if(response?.detail?.success){
          navigate("/view-service", { state: {...autoCompleteLableValue, postYourRental:false }});
        } else {
          // alert(`${response?.detail?.error}, Error from api`)
          navigate("/view-service", { state: {...autoCompleteLableValue, postYourRental:false }});
        }
        // console.log(response, "response"); 
      })
      .catch((error) => {
        console.error("Error fetching rentals:", error);
      });
      //if the respons is success navigate to this page 
    
  };
  const handlePostService = () => {
    navigate("/view-service", { state: {...autoCompleteLableValue, postYourRental:true }});
  };

  const [citysearch, setCitysearch] = useState([]);
  const [areasearch, setAreasearch] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [filteredArea, setFilteredarea] = useState([]);
  const [autoCompleteLableValue, setAutoCompleteLableValue] = useState({
    city: "",
    area: "",
  });
  const [optionsList, setOptionsList] = useState([]);

  useEffect(() => {
    getLocationList().then((res) => {
      if (res?.records) {
        const cityData = res?.records?.map((item) => ({
          value: item?.location,
          id: item?.id,
          label: item?.location,
        }));
        setCityList(cityData);
      }
    });
  }, []);

  const handleSearchCity = (value) => {
    const filteredOptions = cityList?.filter((option) =>
      option?.label?.toLowerCase()?.includes(value.toLowerCase())
    );
    setOptionsList(filteredOptions);
  };

  const getCertainArea = (data) => {
    getareaById(data).then((res) => {
      if (res?.records) {
        const areaData = res?.records?.map((item) => ({
          value: item?.area,
          id: item?.id,
          label: item?.area,
        }));
        setAreaList(areaData);
        setFilteredarea(areaData);
      }
    });
  };
  const filterOption = (
    input,
    option
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterAreas = () => {
    if (citysearch?.length > 0) {
      const cityid = cityList?.find((c) => c?.label === citysearch)?.id;
      const belongedareas = areaList?.filter(
        (a) => a?.locationid == cityid
      );
      setFilteredarea(belongedareas);
    }
  };

  const [selectedValues, setSelectedValues] = useState({
    accommodation_type: [],
    budget: [],
    amenities: [],
  });



  const handleCheckboxChange = (type, id, checked) => {
    const newSelectedValues = { ...selectedValues };

    if (checked) {
      newSelectedValues[type].push(id);
    } else {
      newSelectedValues[type] = newSelectedValues[type].filter((itemId) => itemId !== id);
    }

    setSelectedValues(newSelectedValues);
  };


  return (
    <div className="bg-white">
      <Header />
      <div>
        <DynamicTabs
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
        />
        {/* <div className="dynamic-tab-content"> */}
        {activeTab === 0 && (
          <>
            <div className="dynamic-tab-content">
              <div className="container">
                <div className="row">
                  <div className="py-4 col-12 col-lg-8 col-md-12">
                    <div>
                      <h1 className="text-white fw-bolder py-3">
                        Find Hostel, PG, Flatmates
                      </h1>
                      <div className="row pt-3">
                        <div className="col-md-3 col-lg-4 col-sm-6">
                          {/* <input
                            type="text"
                            name="city"
                            placeholder="Enter City"
                            className="rounded-input form-control"
                          /> */}
                          <AutoComplete
                            style={{
                              width: "100%",
                              height: "50px"
                            }}
                            onSearch={handleSearchCity}
                            options={optionsList}
                            onChange={(e, value) => {
                              setCitysearch(e);
                              setAreasearch([]);
                              // errors.location = "";
                              getCertainArea(value?.id);
                              setAutoCompleteLableValue({
                                ...autoCompleteLableValue,
                                city: value.value,
                              });
                            }}
                            placeholder="Select City"
                          />
                        </div>
                        <div className="col-md-3 col-lg-4 col-sm-6">
                          <Select
                            style={{
                              width: "100%",
                              height: "50px"
                            }}
                            showSearch
                            placeholder="Select Area"
                            optionFilterProp="children"
                            onChange={(e) => {
                              setAreasearch(e);
                              console.log(e, "valuesssssss");
                              setAutoCompleteLableValue({
                                ...autoCompleteLableValue,
                                area: e,
                              });
                            }}
                            filterOption={filterOption}
                            value={areasearch}
                            options={filteredArea}
                          ></Select>
                          {areasearch?.length > 0 && (
                            <>
                              <AiOutlineCloseCircle
                                type="reset"
                                className="resetbtn"
                                onClick={() => {
                                  setAreasearch([]);
                                }}
                              >
                                close
                              </AiOutlineCloseCircle>
                            </>
                          )}
                          {/* <select
                            type="select"
                            name="city"
                            placeholder="Enter City"
                            className="rounded-input form-control"
                          >
                            <option disabled defaultValue="Choose Area">
                              Choose Area
                            </option>
                            <option>Ariyalur</option>
                            <option>Chennai</option>
                            <option>Trichy</option>
                          </select> */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="d-md-block d-lg-flex mt-2 d-sm-block">
                        <div style={{ flexGrow: 1 }}>
                          <h6
                            className="text-white text-center mt-4"
                            style={{ fontWeight: "700" }}
                          >
                            Accommodation Type
                          </h6>

                          <div
                            className="diff-card"
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: -2,
                                left: "15%",
                                right: "15%",
                                background: "#969696",
                                width: "190px",
                                height: "3px",
                              }}
                            />
                            <ColorCheckboxGroup accommodation={accommodation} type={"accommodation"} selectedValues={selectedValues} onCheckboxChange={handleCheckboxChange} />
                          </div>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <h6
                            className="text-white text-center mt-4"
                            style={{ fontWeight: "700" }}
                          >
                            Budget (₹)
                          </h6>
                          <div
                            className="diff-card"
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: -2,
                                left: "15%",
                                right: "15%",
                                background: "#969696",
                                width: "190px",
                                height: "3px",
                              }}
                            />
                            <ColorCheckboxGroup  budget={budget} type={"budget"} selectedValues={selectedValues} onCheckboxChange={handleCheckboxChange} />
                          </div>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <h6
                            className="text-white text-center mt-4"
                            style={{ fontWeight: "700" }}
                          >
                            Amenities
                          </h6>
                          <div
                            className="diff-card"
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: -2,
                                left: "15%",
                                right: "15%",
                                background: "#969696",
                                width: "190px",
                                height: "3px",
                              }}
                            />
                            <ColorCheckboxGroup amenities={Amenities} type={"amenities"} selectedValues={selectedValues} onCheckboxChange={handleCheckboxChange} />
                          </div>
                        </div>
                      </div>
                      <div className="search-field bg-white d-flex justify-content-center pb-4 custom-width">
                        <RoundedButton variant="secondary" width="30%">
                          Clear
                        </RoundedButton>
                        <RoundedButton
                          variant="secondary"
                          width="30%"
                          // disabled={autoCompleteLableValue?.city === "" ? true :autoCompleteLableValue?.area === "" ? true: autoCompleteLableValue?.city === "" &&  autoCompleteLableValue?.area === "" ? true : false}
                          disabled={autoCompleteLableValue?.city === ""}

                          onClick={() => handleNavigate()}
                        >
                          Search
                        </RoundedButton>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 col-md-12 d-none d-xl-flex justify-content-center align-items-center ">
                    <div style={{ position: "relative", width: "100%" }}>
                      <div
                        style={{ position: "absolute", top: -144, right: 0 }}
                      >
                        <div>
                          <button
                            className="d-flex justify-content-center align-items-center p-1 px-3"
                            style={{
                              background: "#fff",
                              borderRadius: 15,
                              color: "#940E34",
                              fontSize: 12,
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePostService()}
                          >
                            POST YOUR RENTAL
                          </button>
                        </div>
                      </div>
                      <div style={{ position: "absolute", top: 35, right: 0 }}>
                        <BiBuildingHouse
                          style={{ fontSize: 200, color: "#ffffff" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="container">
              <h2 className="text-center fw-bolder my-5">
                Latest Posting in your Area
              </h2>
              <div className="position-relative mb-5">
                <div className="posting-card">
                  <strong className="mb-2">
                    Available Three sharing PG - Rs.2000/month
                  </strong>
                  <p className="mb-0">
                    Bihar Sharif, near Bus Stand <IoLocation />
                  </p>
                  <p>
                    Three Sharing occupancy paying guest for Gents. This
                    accommodation is available on ready to occupancy basic at
                    2,000/- month per person.
                  </p>
                  <p>
                    <strong>Amenities :</strong> Shared toilet and bathroom.
                    Meals available on request for Rs.2000 per month - Dinner
                    only
                  </p>
                </div>
                <div className="container">
                  <div className="img-grid">
                    <img src="https://picsum.photos/190/190?1" />
                    <img src="https://picsum.photos/190/190?2" />
                    <img src="https://picsum.photos/190/190?3" />
                    <img src="https://picsum.photos/190/190?4" />
                    <img src="https://picsum.photos/190/190?5" />
                  </div>
                </div>
              </div>
              <div className="position-relative mb-5">
                <div className="posting-card">
                  <strong className="mb-2">
                    Available Three sharing PG - Rs.2000/month
                  </strong>
                  <p className="mb-0">
                    Bihar Sharif, near Bus Stand <IoLocation />
                  </p>
                  <p>
                    Three Sharing occupancy paying guest for Gents. This
                    accommodation is available on ready to occupancy basic at
                    2,000/- month per person.
                  </p>
                  <p>
                    <strong>Amenities :</strong> Shared toilet and bathroom.
                    Meals available on request for Rs.2000 per month - Dinner
                    only
                  </p>
                </div>
                <div className="container">
                  <div className="img-grid">
                    <img src="https://picsum.photos/190/190?1" />
                    <img src="https://picsum.photos/190/190?2" />
                    <img src="https://picsum.photos/190/190?3" />
                    <img src="https://picsum.photos/190/190?4" />
                    <img src="https://picsum.photos/190/190?5" />
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
        {activeTab === 1 && (
          <div>
            <h2>Meal Services</h2>
            <p>This is the content for Meal Services.</p>
            {/* Add your HTML code here */}
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <h2>Books Services</h2>
            <p>This is the content for Books Services.</p>
            {/* Add your HTML code here */}
          </div>
        )}
        {activeTab === 3 && (
          <div>
            <h2>Career Counselling Services</h2>
            <p>This is the content for Career Counselling Services.</p>
            {/* Add your HTML code here */}
          </div>
        )}
        {activeTab === 4 && (
          <div>
            <h2>Other Services</h2>
            <p>This is the content for Other Services.</p>
            {/* Add your HTML code here */}
          </div>
        )}
      </div>
      <div className="pt-5" style={{ background: "#f7f7f7" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Services;
