import React from 'react'
import ReactMarkdown from 'react-markdown'
import { getSpecificBlogs,  } from "../../api/list";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Banner from "../../components/Blog/banner";
import BlogHeading from "../../components/Blog/theHeadingSection";

import {
  CompanyWrap,
  Title,

} from "../../pages/AboutUs/aboutusStyle";
import { Container, Row, Col } from "react-bootstrap";
import { hostConfig } from "../../config";

function BlogDetails() {
  const coverImageStyle = {
    width: "100%",
    height: "300px",
    objectFit: "fill",
  };
    const urlSearchParams = new URLSearchParams(window.location.search);
    const viewProfileInfo = Object.fromEntries(urlSearchParams.entries());
    const markdownText = "**This** is a line with a line break.  \n\nThis is a new paragraph.";
   
    const [blogData, setBlogData] = useState([]);
    const getBlogList = () => {
      const selectedBlog={id:3};
      getSpecificBlogs(viewProfileInfo?.ID||1).then((res) => {
        console.log(res, "res from popular subjects");
        setBlogData(res?.records || []);
        console.log(blogData,"______________________________________++++")
      });
    };
    useEffect(() => {
      getBlogList();
     
    }, []);
   
  return (
    <>
     <Header />
     <Banner />
     <CompanyWrap>
        <Container>
          <Row>
            <Col sm={12}>
              <img  style={coverImageStyle} src={
                            blogData?.is_image
                              ? `${hostConfig.S3_BUCKET}${blogData?.image_file_name}?lastdate=` +
                                Date()
                              : "https://cdn4.wpbeginner.com/wp-content/uploads/2018/07/whatisblog.png"
                          }
                          onError={(e) => {
                            e.target.src = "https://cdn4.wpbeginner.com/wp-content/uploads/2018/07/whatisblog.png"; // Set to default image
                            e.target.onerror = null; // Remove the event handler to prevent infinite loop
                          }}
                          
                          
                          />
              <Title>{blogData?.title}</Title>
              <sm>{blogData?.sub_title}</sm>
             
            </Col>
          </Row>
        </Container>
      </CompanyWrap>
      
      <Container style={styles.container}>
    <ReactMarkdown>{blogData?.body}</ReactMarkdown>
  </Container>
        
       
       
        <Footer />
    </>
  )
}
const styles = {
  container: {
    // textAlign: 'center',
    // maxWidth: '800px', // Adjust the maximum width as needed
    margin: '20px auto 60px',
    padding: '20px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
};

export default BlogDetails