// ******import types *******//
import {
  TLOGIN_USERS_REQUEST,
  TLOGIN_USERS_SUCCESS,
  TLOGIN_USERS_FALIURE,
} from "../types/tlogingTypes";

const initialState = {
  storeLoginResponse: "",
  error: "",
};

export const teacherLoginReducer = (state = initialState, action) => {
  console.log(action, "Im Reducer");
  switch (action.type) {
    case TLOGIN_USERS_REQUEST:
      return {
        ...state,
        storeLoginResponse: "true",
      };
    case TLOGIN_USERS_SUCCESS:
      return {
        ...state,
        storeLoginResponse: action.payload.responseStatus,
        error: "",
      };
    case TLOGIN_USERS_FALIURE:
      return {
        ...state,
        storeLoginResponse: "error",
        error: action.payload,
      };
    default:
      return state;
  }
};
