import React, { useEffect, useState } from "react";
import { AutoComplete } from "antd";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import {
  BookTuitionHeader,
  BookModalBody,
  ModalFooter,
} from "../Header/headerStyle";
import { Formik } from "formik";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import LocationInfo, { LocationType } from "../../pages/home/locationInfo";
import { Select, SelectProps } from "antd";
import { CreateBadge, createDemo } from "../../redux/action/bookDemoAction";
import {
  getAreasList,
  getLevels,
  getLocationList,
  getSubject,
  getareaById,
} from "../../api/list";
import { SearchTution } from "../../redux/action/searchAction";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles({
//   inputRoot: {
//     "& .MuiOutlinedInput-notchedOutline": {
//       border: "none", // Remove the border
//     },
//     "&:hover .MuiOutlinedInput-notchedOutline": {
//       border: "none", // Remove the border on hover
//     },
//   },
// });
const BookADemoSearchPopup = (props: any) => {
  const [citysearch, setCitysearch] = useState<any>([]);
  const [areasearch, setAreasearch] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const [areaList, setAreaList] = useState<any>([]);
  const [filteredArea, setFilteredarea] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [level, setLevel] = useState<any>("");
  const [open, setOpen] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [dataObj, setDataObj] = useState<any>();
  const [selectedOption, setSelectedOption] = useState<any>("");
  const handleClose = () => setShow(false);
  const [subject, setsubject] = useState([]);
  const [subjectSearch, setSubjectsearch] = useState<any>("");
  const [autoCompleteLableValue, setAutoCompleteLableValue] = useState<any>({
    city: "",
    subject: "",
  });
  const [optionsList, setOptionsList] = useState<any>([]);
  const [optionsList1, setOptionsList1] = useState<any>([]);
  const navigate = useNavigate();

  const options: SelectProps["options"] = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);

    getLevels().then((res) => {
      if (res?.records) {
        const UserData = res?.records?.map((item: any) => {
          return {
            value: item?.level_name,
            label: item?.level_name,
            id: item?.id,
          };
        });
        setLevel(UserData);
      }
    });
    getallSubjects();
  }, []);
  const getallSubjects = () => {
    getSubject().then((res) => {
      if (res?.records) {
        const subjectData = res?.records?.map((item: any) => ({
          value: item?.subject,
          id: item?.id,
          label: item?.subject,
        }));
        setsubject(subjectData);
      }
    });
  };
  // const handlesetValue = (data: any) => {
  //   setSelectedOption(data);
  //   setSearchValue({
  //     ...searchValue,
  //     level: data,
  //   });
  // };

  const submitForm = (values: any, resetForm: any) => {
    const data: any = { ...values };
    setModalShow(false);
    handleClose();
    props.onHide();

    // data.subject_level = level;
    data.location = citysearch;

    SearchTution({
      area: areasearch?.length > 0 ? areasearch : "",
      batch_id: "",
      city: autoCompleteLableValue.city,
      days: 0,
      level: selectedOption,
      subject: autoCompleteLableValue.subject,
      // timing: data?.selectTiming,
    });
    navigate(
      `${"/tuition-list"}?LOCATION_NAME=${
        autoCompleteLableValue.city
      }&SELECT_AREA=${
        areasearch?.length > 0 ? areasearch : ""
      }&SELECT_LEVEL=${selectedOption}&DAYS_OF_WEEK=${0}&TIME=${""}&SUBJECT_NAME=${
        autoCompleteLableValue.subject
      }`
    );
    localStorage.setItem("search-page", data.location);
  };

  const handleInputChange = (e: any) => {
    if (e.target.value.length >= 3) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setSearchTerm(e.target.value);
  };

  const filteredOptions = LocationInfo.filter((option: any) => {
    return option?.location?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option: LocationType, setFieldValue: any) => {
    setFieldValue("selectArea", option.area);
    setOpen(false);
    setSearchTerm(option.location);
  };

  const isValidInfo = (values: any) => {
    let isValid = false;

    if (citysearch?.length > 0) {
      isValid = true;
    } else if (values.subject.length > 0) {
      isValid = true;
    } else if (values.selectArea.length > 0) {
      isValid = true;
    }
    return isValid;
  };

  function onClickContinue() {
    setModalShow(!modalShow);
    handleClose();
    props.onHide();
    const payload = {
      area: dataObj.area ? dataObj.area : "",
      batch_id: "",
      city: dataObj.city ? dataObj.city : "",
      days: "",
      level: level ? level : "",
      subject: dataObj.subject ? dataObj.subject : "",
    };
    createDemo(payload);
  }
  useEffect(() => {
    getLocationList().then((res) => {
      if (res?.records) {
        const cityData = res?.records?.map((item: any) => ({
          value: item?.location,
          id: item?.id,
          label: item?.location,
        }));
        setCityList(cityData);
      }
    });
  }, []);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    filterAreas();
  }, [citysearch]);
  const filterAreas = () => {
    if (citysearch?.length > 0) {
      const cityid = cityList?.find((c: any) => c?.label === citysearch)?.id;
      const belongedareas = areaList?.filter(
        (a: any) => a?.locationid == cityid
      );
      setFilteredarea(belongedareas);
    }
  };
  const handlesetValue = (data: any) => {
    setSelectedOption(data);
  };

  const handleSearchCity = (value: any) => {
    const filteredOptions = cityList?.filter((option: any) =>
      option?.label?.toLowerCase()?.includes(value.toLowerCase())
    );
    setOptionsList(filteredOptions);
  };

  const handleSearchSubject = (value: any) => {
    const filteredOptions = subject?.filter((option: any) =>
      option?.label?.toLowerCase()?.includes(value.toLowerCase())
    );
    setOptionsList1(filteredOptions);
  };
  const getCertainArea = (data: any) => {
    getareaById(data).then((res: any) => {
      if (res?.records) {
        let areaData = res?.records?.map((item: any) => ({
          value: item?.area,
          id: item?.id,
          label: item?.area,
        }));
        areaData = areaData.sort((a: any, b: any) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
        setAreaList(areaData);
        setFilteredarea(areaData);
      }
    });
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        className="bookDemo"
        centered
      >
        <style>
          {`
          .bookDemo .modal-dialog {
            max-width: calc(100% - 40px);
            width: 782px;
            letter-spacing: 0;
            @media (max-width: 575px) {
              max-width: calc(100% );
              margin: auto;
              padding: 10px;
            }
          }
          .bookDemo .modal-content {
            background: #FFFFFF;
            border-radius: 30px;
            border: none;
            box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
          }
        
        `}
        </style>
        <BookTuitionHeader>
          <Modal.Header closeButton>
            <Modal.Title>Book a Demo Class</Modal.Title>
          </Modal.Header>
        </BookTuitionHeader>
        <BookModalBody>
          <Modal.Body>
            <Formik
              initialValues={{
                location: "",
                subject: "",
                selectArea: "",
              }}
              validate={(values: any) => {
                let errors: any = {};

                if (citysearch?.length < 1) {
                  errors.location = "Please enter location";
                }

                return errors;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6}>
                       <Form.Group className="form-group CloseBtn">
                        <h6>City</h6>
                        <AutoComplete
                          style={{
                            width: "100%",
                          }}
                          onSearch={handleSearchCity}
                           value={citysearch}
                          options={optionsList}
                          onChange={(e: any, value: any) => {
                            setCitysearch(e);
                            setAreasearch([]);
                            errors.location = "";
                            getCertainArea(value?.id);
                            setAutoCompleteLableValue({
                              ...autoCompleteLableValue,
                              city: value.value,
                            });
                          }}
                          placeholder="Select City"
                        />
                        <div className="error p-3">
                          {errors.location ? errors.location : null}
                        </div>
                        {citysearch?.length > 0 && (
                            <>
                              <AiOutlineCloseCircle
                                type="reset"
                                className="resetbtn"
                                onClick={() => {
                                  setCitysearch('');
                                }}
                              >
                                close
                              </AiOutlineCloseCircle>
                            </>
                          )}
                        </Form.Group> 
                       
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group CloseBtn">
                          {/* <Form.Label>Area</Form.Label> */}
                          <h6>Area</h6>
                          <Select
                            style={{
                              width: "100%",
                            }}
                            showSearch
                            placeholder="Select Area"
                            optionFilterProp="children"
                            onChange={(e: any) => {
                              setAreasearch(e);
                            }}
                            filterOption={filterOption}
                            value={areasearch}
                            options={filteredArea}
                          ></Select>
                          {areasearch?.length > 0 && (
                            <>
                              <AiOutlineCloseCircle
                                type="reset"
                                className="resetbtn"
                                onClick={() => {
                                  setAreasearch([]);
                                }}
                              >
                                close
                              </AiOutlineCloseCircle>
                            </>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                      <Form.Group className="form-group CloseBtn">
                        <h6 className="mt-3">Subject</h6>
                        <AutoComplete
                          style={{
                            width: "100%",
                          }}
                          value={subjectSearch}
                          onSearch={handleSearchSubject}
                          options={optionsList1}
                          onChange={(e: any, value: any) => {
                            setSubjectsearch(e);
                            setAutoCompleteLableValue({
                              ...autoCompleteLableValue,
                              subject: value.value,
                            });
                          }}
                          placeholder="Select Subject"
                          // dropdownClassName="custom-autocomplete-dropdown"
                        />
                           {subjectSearch?.length > 0 && (
                            <>
                              <AiOutlineCloseCircle
                                type="reset"
                                className="resetbtn"
                                onClick={() => {
                                  setSubjectsearch('');
                                }}
                              >
                                close
                              </AiOutlineCloseCircle>
                            </>
                          )}
                           </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group CloseBtn">
                          {/* <Form.Label>Level</Form.Label> */}
                          <h6 className="mt-3">Level</h6>
                          <Form.Select
                            id="dropdown-level"
                            title="Select Level"
                            name="level"
                            value={selectedOption}
                            onChange={(e: any) => {
                              handlesetValue(e?.target?.value);
                            }}
                          >
                            <option value="">Select Level</option>
                            {Array.isArray(level) &&
                              level?.map((level: any) => (
                                <option value={level?.label}>
                                  {level?.label}
                                </option>
                              ))}
                          </Form.Select>
                          {selectedOption?.length > 0 && (
                            <>
                              <AiOutlineCloseCircle
                                type="reset"
                                className="resetbtn"
                                onClick={() => {
                                  setSelectedOption('');
                                }}
                              >
                                close
                              </AiOutlineCloseCircle>
                            </>
                          )}
                        </Form.Group>
                        {/* <Select
                              defaultValue={selectedOption}
                              onChange={(value) => handlesetValue(value)}
                              options={UserData}
                              // isMulti
                            /> */}
                      </Col>
                    </Row>
                    <ModalFooter>
                      <Modal.Footer>
                        <Button
                          type="submit"
                          variant={
                            isValidInfo(values)
                              ? "secondary active"
                              : "secondary"
                          }
                          // onClick={onClickContinue}
                        >
                          Continue
                        </Button>
                      </Modal.Footer>
                    </ModalFooter>
                  </form>
                </>
              )}
            </Formik>
          </Modal.Body>
        </BookModalBody>
      </Modal>
    </>
  );
};
export default BookADemoSearchPopup;
