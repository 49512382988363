import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import VisionMission from "../../assets/images/about-vision.svg";
import data from "./aboutUsText";
import {
  VissionMissionWrap,
  Title,
  Description,
  Image,
} from "../../pages/AboutUs/aboutusStyle";

const VissionMission: Function = () => {
  return (
    <>
      <VissionMissionWrap>
        <Container>
          <Row >
            <Col sm={6} style={{marginBottom: 35}}>
              <Title>Vision and Mission</Title>
              <Description>{data.visionAndMission}</Description>
            </Col>
            <Col sm={6} >
              <Image>
                <img src={VisionMission} alt="" />
              </Image>
            </Col>
          </Row>
        </Container>
      </VissionMissionWrap>
    </>
  );
};

export default VissionMission;
