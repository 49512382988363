import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import { Alert } from 'antd';
import { SyncOutlined } from "@ant-design/icons";

import PasswordInput from "../../../components/PasswordTextField";
import { SubmitContainer } from "../Login/styledViews";


export type dataType = {
  firstName: string
  lastName: string
  mobileNumber: string
  email: string
  city: string
  subject: string
  password: string
  confirmPassword: string
  termsAndConditions: boolean
}

const initialValues: dataType = {
  firstName: "",
  lastName: "",
  mobileNumber: "",
  email: "",
  city: "",
  subject: "",
  password: "",
  confirmPassword: "",
  termsAndConditions: false,
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Not a valid mobile number")
    .required("Mobile number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  city: Yup.string().required("City is required"),
  subject: Yup.string().required("Subject is required"),
  password: Yup.string()
    .min(6, "at least 6 characters long")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  termsAndConditions: Yup.boolean().oneOf([true], "You must accept the Terms and Policy to register an account"),
});

const labelStyle = { color: "black" };

const TeacherForm: React.FC<any> = ({ onFormSubmit, isLoading, submitError }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label style={labelStyle}>
                  First Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="John"
                  id="firstName"
                  name="firstName"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  value={values.firstName}
                  onBlur={handleBlur}
                  maxLength={150}
                />
                {touched.firstName && errors.firstName && (
                  <div className="error">{errors.firstName}</div>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label style={labelStyle}>
                  Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Doe"
                  id="lastName"
                  name="lastName"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  value={values.lastName}
                  onBlur={handleBlur}
                  maxLength={150}
                />
                {touched.lastName && errors.lastName && (
                  <div className="error">{errors.lastName}</div>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label style={labelStyle}>
                  Mobile Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="XXXXXXXXXX"
                  id="mobileNumber"
                  name="mobileNumber"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  value={values.mobileNumber}
                  onBlur={handleBlur}
                  maxLength={10}
                />
                {touched.mobileNumber && errors.mobileNumber && (
                  <div className="error">{errors.mobileNumber}</div>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label style={labelStyle}>
                  Email ID
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="my@email.com"
                  id="email"
                  name="email"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  value={values.email}
                  onBlur={handleBlur}
                />
                {touched.email && errors.email && (
                  <div className="error">{errors.email}</div>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label style={labelStyle}>
                  City
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="My City"
                  id="city"
                  name="city"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  value={values.city}
                  onBlur={handleBlur}
                />
                {touched.city && errors.city && (
                  <div className="error">{errors.city}</div>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label style={labelStyle}>
                  Education
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="My Education"
                  id="subject"
                  name="subject"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  value={values.subject}
                  onBlur={handleBlur}
                />
                {touched.subject && errors.subject && (
                  <div className="error">{errors.subject}</div>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <PasswordInput
                label="Create Password"
                name="password"
                value={values.password}
                onChange={(e: any) => handleChange(e)}
                onBlur={handleBlur}
                error={
                  touched.password && errors.password
                    ? errors.password
                    : ""
                }
              />
            </Col>

            <Col md={6}>
              <PasswordInput
                label="Confirm Password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={(e: any) => handleChange(e)}
                onBlur={handleBlur}
                error={
                  touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : ""
                }
              />
            </Col>

            <Col sm={12}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="terms"
                  name="termsAndConditions"
                  value="termsAndConditions"
                  onChange={() =>
                    setFieldValue(
                      "termsAndConditions",
                      !values.termsAndConditions
                    )
                  }
                  label={
                    <label>
                      By creating an account you agree to our{" "}
                      <Link to="/tc-for-student">Terms&nbsp;of&nbsp;Service</Link>{" "}
                      and <Link to="/privacy-policy">Privacy&nbsp;Policy</Link>.
                    </label>
                  }
                  onBlur={handleBlur}
                />
                {touched.termsAndConditions && errors.termsAndConditions && (
                  <div className="error">{errors.termsAndConditions}</div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <SubmitContainer>
            <Button
              type="submit"
              disabled={!values.email || !_.isEmpty(errors) || isLoading}
            >
              {isLoading ? <SyncOutlined spin /> : "Register"}
            </Button>

            {submitError && <Alert
              style={{ marginTop: 30 }}
              type="error"
              showIcon
              message={submitError}
            />}
          </SubmitContainer>
        </form>
      )}
    </Formik>
  );
};

export default TeacherForm;