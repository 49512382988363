/** **************************** Import Types ****************************** */
import {
  RENTAL_SUCCESS,
  RENTAL_FAILURE,
  RENTAL_REQUEST,
} from "../types/rentalType";

const initialState = {
  loading: false,
  rentalResponse: "",
  error: "",
};

const rentalReducer = (state = initialState, action) => {
  //   console.log("form badge api reducer", action.payload);
  switch (action.type) {
    case RENTAL_REQUEST:
      return {
        ...state,
        loading: true,
        rentalResponse: "",
      };
    case RENTAL_SUCCESS:
      return {
        loading: false,
        rentalResponse: action.payload.responseStatus,
        error: "",
      };
    case RENTAL_FAILURE:
      return {
        loading: false,
        rentalResponse: "error",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default rentalReducer;
