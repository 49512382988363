import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Ratting from "../../assets/images/rattings.svg";
import Education from "../../assets/images/education.svg";
import Avaibility from "../../assets/images/avaibility.svg";
import Rupees from "../../assets/images/rupee.svg";
import Location from "../../assets/images/location.svg";
import Phone from "../../assets/images/phone.svg";
import Email from "../../assets/images/mail.svg";
import Website from "../../assets/images/website.svg";
import InnerBanner from "../../components/SearchResult/searchResultBanner";
import "react-toastify/dist/ReactToastify.css";
import {
  TutionListMain,
  TutionListWrap,
  TutionDetails,
  TutionImg,
  TutionName,
  TutionProfile,
  TutionProData,
  TutionIcon,
  TutionData,
  TutionButton,
  PageTitle,
  LoadMore,
} from "./searchResultStyle";

import SearchResultList from "./searchResult";
import tuitionListText from "../tuitionList/tuitionListText";
import BookADemo from "../../components/Book a demo/index";
import moment from "moment";
import { useSelector } from "react-redux";
import LoginModal from "../../components/LoginModal/index_old";
import CongratulationModal from "../../components/CongratulationModal/index";

const SearchResult: Function = () => {
  const [show, setShow] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loadMoreValue, setLoadMoreValue] = useState(5);
  const [loadMore, setLoadMore] = useState(false);

  let dateInfo = moment(new Date()).format("MM-DD-YYYY");

  const [popUpValue, setPopUpValue] = useState({ date: dateInfo, radio: "" });
  const [validation, setValidation] = useState("");

  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const navigateToUserProfilePage = (item: any) => {
    navigate(
      `${"/tuition-details"}?SUBJECT_NAME=${params.SUBJECT}&TIME=${
        params.TIME
      }&LOCATION_NAME=${params.CITY_NAME}&DAYS=${
        params.DAYS_OF_WEEK
      }&CLASS_NAME=${item.className}&CITY_NAME=${item.cityName}&PHONE_NUMBER=${
        item.phoneNumber
      }&WEBSITE=${item.website}&EMAIL=${item.email}&COST=${
        item.cost
      }&AREA_NAME=${params.AREA_NAME}&SUBECT_LEVEL=${
        params.SUBJECT_LEVEL
      }&PHOTO=${item.photo}&INSTITUTE_NAME=${item.instituteName}&RATING=${
        item.rating
      }&SEARCH_LIST=${item.searchList}`
    );
  };
  const handleShow = () => {
    setShow(true);
    setPopUpValue({ date: dateInfo, radio: "" });
  };

  const handleChangeForRadio = (e: any) => {
    setPopUpValue({ ...popUpValue, [e.target.name]: e.target.value });
    setValidation("");
  };

  const onChangePopUp = (value: any) => {
    setPopUpValue({ ...popUpValue, date: moment(value).format("MM-DD-YYYY") });
  };
  const getLoginInfo = useSelector((state: any) => state?.login);
  const getData = localStorage.getItem("email-password");

  const handleClose1 = () => setModalShow(false);

  return (
    <>
      <Header />
      <InnerBanner />
      <TutionListMain>
        <Container>
          <Row>
            <Col sm={12}>
              <PageTitle>
                Demo classes available in {params?.CITY_NAME}
              </PageTitle>
            </Col>
          </Row>
          {SearchResultList?.slice(0, loadMoreValue)?.map((item: any) => {
            return (
              <>
                <TutionListWrap>
                  <TutionDetails>
                    <TutionImg>
                      <img src={item.photo} alt="Profile photo" />
                    </TutionImg>
                    <TutionName>
                      <h4>{item.instituteName}</h4>
                      <span>Tuition Address - {item.located}</span>
                    </TutionName>
                  </TutionDetails>
                  <TutionProfile>
                    <TutionProData>
                      <TutionIcon>
                        <img src={Ratting} alt="Ratting" />
                      </TutionIcon>
                      <TutionData>
                        <span>Ratings</span>
                        <h6>
                          {item.rating} / 5 | {item.review} reviews
                        </h6>
                      </TutionData>
                    </TutionProData>
                    <TutionProData>
                      <TutionIcon>
                        <img src={Education} alt="Education" />
                      </TutionIcon>
                      <TutionData>
                        <span>Education</span>
                        <h6>{params.SUBJECT}</h6>
                      </TutionData>
                    </TutionProData>
                    <TutionProData>
                      <TutionIcon>
                        <img src={Avaibility} alt="Avaibility" />
                      </TutionIcon>
                      <TutionData>
                        <span>Availability</span>
                        <h6>10:00 to 12:00 AM</h6>
                      </TutionData>
                    </TutionProData>
                    <TutionProData>
                      <TutionIcon>
                        <img src={Rupees} alt="Rupees" />
                      </TutionIcon>
                      <TutionData>
                        <span>Average fee per subject</span>
                        <h6>Rs. {item.cost} / -</h6>
                      </TutionData>
                    </TutionProData>
                    <TutionProData>
                      <TutionIcon>
                        <img src={Location} alt="Location" />
                      </TutionIcon>
                      <TutionData>
                        <span>Location</span>
                        <h6>{params.CITY_NAME}</h6>
                      </TutionData>
                    </TutionProData>
                    <TutionProData>
                      <TutionIcon>
                        <img src={Phone} alt="Phone" />
                      </TutionIcon>
                      <TutionData>
                        <span>Phone</span>
                        <h6>{item.phoneNumber}</h6>
                      </TutionData>
                    </TutionProData>
                    <TutionProData>
                      <TutionIcon>
                        <img src={Email} alt="Email" />
                      </TutionIcon>
                      <TutionData>
                        <span>Email</span>
                        <h6>{item.email}</h6>
                      </TutionData>
                    </TutionProData>
                    {item.website && (
                      <TutionProData>
                        <TutionIcon>
                          <img src={Website} alt="Website" />
                        </TutionIcon>
                        <TutionData>
                          <span>Website</span>
                          <h6>{item.website}</h6>
                        </TutionData>
                      </TutionProData>
                    )}
                  </TutionProfile>
                  <TutionButton>
                    <Button
                      variant="secondary"
                      type="submit"
                      onClick={() => navigateToUserProfilePage(item)}
                    >
                      View Profile
                    </Button>

                    <Button
                      type="submit"
                      variant="secondary"
                      onClick={handleShow}
                    >
                      Book a Demo Class
                    </Button>
                  </TutionButton>
                </TutionListWrap>
              </>
            );
          })}

          {loadMoreValue < SearchResultList.length && (
            <LoadMore
              onClick={() => {
                setLoadMore(!loadMore);
                setLoadMoreValue(loadMoreValue + 2);
              }}
            >
              <span>Load More</span>
            </LoadMore>
          )}

          <BookADemo
            onChangePopUp={onChangePopUp}
            handleChangeForRadio={handleChangeForRadio}
            setModalShow={setModalShow}
            setLoginModal={setLoginModal}
            getLoginInfo={getLoginInfo}
            show={show}
            setShow={setShow}
            validation={validation}
            setValidation={setValidation}
            popUpValue={popUpValue}
          />

          {(!getLoginInfo?.data || getData) && (
            <LoginModal
              loginModal={loginModal}
              setLoginModal={setLoginModal}
              modalShow={modalShow}
              setModalShow={setModalShow}
            />
          )}
          <CongratulationModal
            modalShow={modalShow}
            onHide={handleClose1}
            popUpValue={popUpValue}
            setModalShow={setModalShow}
          />
        </Container>
      </TutionListMain>
      <Footer />
    </>
  );
};

export default SearchResult;
