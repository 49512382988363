import React from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { message, Upload, Button } from 'antd';
import { InboxOutlined, FileAddOutlined, CloseCircleFilled } from '@ant-design/icons';

import {
  UploadContainer,
  IconContainer,
  MainText,
  InfoText,
  CloseButton,
} from "./styledViews";

const { Dragger } = Upload;


type Props = {
  show: boolean
  onHide: () => void
  info?: string
  onUpload: (file: File) => void
  fileTypes?: string[]
  maxFileSize?: number // specify in MBs
}

const UploaderModal: React.FC<Props> = ({ show, onHide, info, onUpload, fileTypes, maxFileSize }) => {
  const handleUpload = (file: any) => {
    if (maxFileSize && (file.size/1024/1024) > maxFileSize) {
      message.error("File size exceeded.");
      return Upload.LIST_IGNORE;
    }

    onUpload(file);
    return Upload.LIST_IGNORE;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
    >
      <Modal.Body>
        <UploadContainer>
          <Dragger
            multiple={false}
            maxCount={1}
            accept={fileTypes ? fileTypes.join(", ") : undefined}
            beforeUpload={handleUpload}
            customRequest={() => {}}
          >
            <IconContainer>
              <InboxOutlined />
            </IconContainer>

            <MainText>
              Drag a file here to upload
            </MainText>

            <Button type="primary" shape="round" icon={<FileAddOutlined />} size="large">
              Browse Files
            </Button>

            <InfoText>{getInfo(info, fileTypes, maxFileSize)}</InfoText>
          </Dragger>

          <CloseButton>
            <CloseCircleFilled onClick={onHide} />
          </CloseButton>
        </UploadContainer>
      </Modal.Body>
    </Modal>
  );
}

function getInfo(propsInfo?: string, fileTypes?: string[], maxFileSize?: number) {
  const infoList = [];

  if (propsInfo) return propsInfo;
  if (_.isEmpty(fileTypes) && !maxFileSize) return "All file types are allowed";

  if (!_.isEmpty(fileTypes)) infoList.push(`Allowed file types: ${fileTypes?.join(", ")}`);
  if (maxFileSize) infoList.push(`Max file size: ${maxFileSize}MB`);
  
  return infoList.join(" | ");
}

export default UploaderModal;