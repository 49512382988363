import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Select, SelectProps } from "antd";
import {
  SubbannerMain,
  BannerWrap,
  BannerSearchBox,
} from "../../pages/searchResult/searchResultStyle";
import { Formik } from "formik";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import LocationInfo, { LocationType } from "../../pages/home/locationInfo";

const options: SelectProps["options"] = [];

for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

const SearchResultBanner = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [level, setLevel] = useState<any>([]);
  const [open, setOpen] = useState<any>(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [place, setPlace] = useState("");

  const navigate = useNavigate();

  const filteredOptions = LocationInfo.filter((option: any) => {
    return option?.location?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const submitForm = (values: any) => {
    const data = { ...values };
    data.subject_level = level;
    data.location = searchTerm;
    navigate(
      `${"/search-result"}?CITY_NAME=${data.location}&AREA_NAME=${
        data.selectArea
      }&SUBJECT_LEVEL=${data.subject_level}&SUBJECT=${data?.subject}`
    );
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (params) {
      setLevel(
        params.SUBJECT_LEVEL?.split(",")[0] !== ""
          ? params.SUBJECT_LEVEL?.split(",")
          : []
      );

      setSearchTerm(params?.CITY_NAME);
    }
  }, []);

  const handleInputChange = (e: any) => {
    if (e.target.value.length >= 3) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setSearchTerm(e.target.value);
  };

  const handleOptionClick = (option: LocationType, setFieldValue: any) => {
    setFieldValue("selectArea", option.area);
    setOpen(false);
    setSearchTerm(option.location);
  };

  const isValidInfo = (values: any) => {
    let isValid = false;

    if (values.location.length > 0) {
      isValid = true;
    } else if (values.subject.length > 0) {
      isValid = true;
    } else if (values.selectArea.length > 0) {
      isValid = true;
    }
    return isValid;
  };

  return (
    <>
      <SubbannerMain>
        <Container>
          <BannerWrap>
            <BannerSearchBox>
              <Formik
                enableReinitialize
                initialValues={{
                  location: params.CITY_NAME ?? "",
                  subject: params.SUBJECT ?? "",
                  selectArea: params.AREA_NAME ?? "",
                }}
                validate={(values: any) => {
                  let errors: any = {};
                  if (!values.location) {
                    errors.location = "Please enter your location";
                  }
                  return errors;
                }}
                onSubmit={submitForm}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  resetForm,
                  dirty,
                }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <Row className="justify-content-center">
                        <Col lg={3}>
                          <Form.Group className="form-group CloseBtn">
                            <Form.Control
                              type="text"
                              placeholder="Enter City"
                              id="location"
                              name="location"
                              value={searchTerm}
                              autoComplete="off"
                              onChange={(e: any) => {
                                handleChange(e);
                                handleInputChange(e);
                              }}
                            />
                            {filteredOptions.length > 0 ? (
                              <ul
                                className="search-group"
                                style={{ display: open ? "" : "none" }}
                              >
                                {filteredOptions.map(
                                  (option: LocationType, index: any) => {
                                    return (
                                      <li
                                        key={index}
                                        onClick={() =>
                                          handleOptionClick(
                                            option,
                                            setFieldValue
                                          )
                                        }
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              option.location?.replace(
                                                searchTerm,
                                                `${searchTerm}<b>`
                                              ) + "</b>",
                                          }}
                                        ></span>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            ) : (
                              <p className="error">No options found.</p>
                            )}
                            <div className="error">
                              {errors.location ? errors.location : null}
                            </div>
                            {searchTerm.length >= 3 && (
                              <>
                                <AiOutlineCloseCircle
                                  type="reset"
                                  className="resetbtn"
                                  onClick={() => {
                                    setSearchTerm("");
                                    setFieldValue("location", "");
                                    setOpen(false);
                                  }}
                                >
                                  close
                                </AiOutlineCloseCircle>
                              </>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={3}>
                          <Form.Group className="form-group CloseBtn">
                            <Form.Control
                              type="text"
                              placeholder="Area"
                              id="selectArea"
                              name="selectArea"
                              value={values.selectArea}
                              onChange={handleChange}
                            />
                            {values.selectArea && (
                              <AiOutlineCloseCircle
                                type="reset"
                                className="resetbtn"
                                onClick={() => setFieldValue("selectArea", "")}
                              >
                                close
                              </AiOutlineCloseCircle>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={3}>
                          <Form.Group className="form-group CloseBtn">
                            <Form.Control
                              type="text"
                              placeholder="Enter Subject"
                              id="subject"
                              name="subject"
                              value={values.subject}
                              onChange={handleChange}
                            />
                            {values.subject && (
                              <AiOutlineCloseCircle
                                type="reset"
                                className="resetbtn"
                                onClick={() => setFieldValue("subject", "")}
                              >
                                close
                              </AiOutlineCloseCircle>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={3}>
                          <Form.Group className="form-group">
                            <Select
                              mode="tags"
                              style={{
                                width: "100%",
                              }}
                              placeholder="Select Level"
                              value={level}
                              onChange={(e: any) => {
                                setLevel(e);
                              }}
                              options={options}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={3}>
                          <Form.Group className="form-group btn-wrap">
                            <Button
                              type="submit"
                              variant={
                                isValidInfo(values)
                                  ? "primary active"
                                  : "primary"
                              }
                            >
                              Search
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </form>
                  </>
                )}
              </Formik>
            </BannerSearchBox>
          </BannerWrap>
        </Container>
      </SubbannerMain>
    </>
  );
};

export default SearchResultBanner;
