import avatar from "../../../assets/images/no-profile.png";
import { hostConfig } from "../../../config";
import AccordionItem from "./accordionItem";
import { Row, Col } from 'antd';
import "./accordionStyle.css";

const Accordion = ({ items }) => {
  console.log(items, "items");
  const rows = [];
  for (let i = 0; i < items.length; i += 2) {
    rows.push(items.slice(i, i + 2));
  }

  return (
    <div className="accordion">
      {rows.map((row, rowIndex) => (
        <Row gutter={[16, 16]} key={rowIndex}>
          {row.map((item, index) => (
            <Col xs={24} sm={24} md={12} lg={12} key={index}>
              <AccordionItem
                title={`${item?.student_details?.student_first_name} ${item?.student_details?.student_last_name}`}
                content={item.review.reviewed_data}
                name={item.review.student_name}
                stars={item.review.start_count}
                tutionName={
                  item.teacher_details.tution_name
                    ? item.teacher_details.tution_name
                    : "Mihtra Tuition"
                }
                date={item.review.created_at}
                
                avatar={
                  item?.student_details?.profile_name === null
                    ? avatar
                    : `${hostConfig.S3_BUCKET}${item?.student_details?.profile_name}`
                }
                index={index} // Pass the index as a prop
              />
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default Accordion;
