/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Button, Modal } from "react-bootstrap";
import {
  TutionData,
  TutionBooked,
  BookTitle,
  TutionBookedDetails,
  TutionBookedLeft,
  TeacherImg,
  TeacherName,
  TutionBookedRight,
  RattingIcon,
  TeacherDate,
} from "../../pages/tuitionList/tuitionlistStyle";
import Ratting from "../../assets/images/rattings.svg";
import ProfileOne from "../../assets/images/no-profile.png";
import { hostConfig } from "../../config";
import moment from "moment";

const CongratulationModal: Function = (props: any) => {
  const { bookedDetails,title } = props;
  // console.log(bookedDetails, "from props booking details");
  const calculateOverallReviewCount=(totalReviewStarCount:any, reviewStudentCount:any)=> {
    if (reviewStudentCount === 0) {
      return 0; // Return 0 when dividing by 0
    }
    if (totalReviewStarCount === 0) {
      return 0; // Return 0 when dividing by 0
    }
    if (isNaN(totalReviewStarCount) || isNaN(reviewStudentCount)) {
      return -0; // Return an error message for non-numeric inputs
    }
    const result = totalReviewStarCount / reviewStudentCount; // Calculate the result as a number
    const isInteger = result % 1 === 0;
    if(isInteger){
      return result.toFixed(0)
    }
    else{
      return result.toFixed(1);
    }
  }
  return (
    <>
      <Modal
        show={props.modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="congratulationModel"
        onHide={props.onHide}
      >
        <style>
          {`
            .congratulationModel .modal-dialog {
              max-width: calc(100% - 40px);
              width: 782px;
              letter-spacing: 0;
              margin: auto;
            }
            .congratulationModel .modal-content {
              background: #FFFFFF;
              border-radius: 30px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
            @media (max-width: 1440px) {
              .congratulationModel .modal-dialog {
                max-width: calc(100% - 40px);
                width: 700px;
              }
            }
          `}
        </style>
        <TutionBooked>
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="modalTitle"
            >
              Congratulation!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BookTitle>{title?title:"Your class demo booked"}</BookTitle>
            <TutionBookedDetails>
              <TutionBookedLeft>
                <TeacherImg>
                  <img
                    src={
                      bookedDetails !== undefined
                        ? `${hostConfig.S3_BUCKET}${bookedDetails?.profile_filename}`
                        : ProfileOne
                    }
                    alt="Profile photo"
                  />
                </TeacherImg>
                <TeacherName>{`${
                  bookedDetails?.teacher_first_name
                    ? bookedDetails?.teacher_first_name
                    : "Teacher"
                } ${
                  bookedDetails?.teacher_last_name
                    ? bookedDetails?.teacher_last_name
                    : "Name"
                }`}</TeacherName>
              </TutionBookedLeft>
              <TutionBookedRight>
                <RattingIcon>
                  <img src={Ratting} alt="Ratting" />
                </RattingIcon>
                <TutionData>
                  <span>Ratings</span>
                  {bookedDetails !== undefined?<h6>{calculateOverallReviewCount(bookedDetails?.overall_review_status?.total_start_count,bookedDetails?.overall_review_status?.student_count)} / 5 | {bookedDetails?.overall_review_status?.student_count} reviews</h6>:<h6>0 / 0 | 0 reviews</h6>}
                  
                </TutionData>
              </TutionBookedRight>
              <TeacherDate>
                <p>Date</p>
                <span>
                  {moment(bookedDetails?.created_at).format("DD-MM-YYYY")}
                </span>
              </TeacherDate>
              <TeacherDate>
                <p>Batch</p>
                <span>{bookedDetails?.batch_timing}</span>
              </TeacherDate>
            </TutionBookedDetails>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              onClick={() => {
                props?.setModalShow(true);
                props?.onHide();
                props?.resetField?.();
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }}
            >
              Okay
            </Button>
          </Modal.Footer>
        </TutionBooked>
      </Modal>
    </>
  );
};

export default CongratulationModal;
