import React, { useMemo } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { PlayCircleFilled } from "@ant-design/icons";

import { FileType } from "../tutorPanel/StudyMaterials/types";

import {
  VideoCard,
  VideoThumb,
  VideoTitle,
  VideoDate,
} from "./styledViews";

import defaultThumb from "../../assets/images/default_thumbnail.jpeg";


const VideosTab: React.FC<any> = ({ dataList }) => {
  const navigate = useNavigate();

  const videos = useMemo(() => {
    return _.chain(dataList)
      .filter((d) => d.type === FileType.VIDEO)
      .sortBy((d) => moment(d.created_at).unix())
      .reverse()
      .value();
  }, [dataList]);

  const openVideo = (id: string) => () => {
    navigate("/class-content/video/" + id);
  };

  return (
    <Row>
      {_.map(videos, video => (
        <Col key={video.id} lg={3} md={4} sm={6}>
          <VideoCard>
            <VideoThumb onClick={openVideo(video.id)}>
              <img src={video.thumb_url || defaultThumb} alt="Video Recording" />
              <div className="video-thumb-overlay" onClick={() => {}}><PlayCircleFilled /></div>
            </VideoThumb>

            <VideoTitle>{video.title}</VideoTitle>
            <VideoDate>{moment(video.created_at).format("MMM DD, YYYY")}</VideoDate>
          </VideoCard>
        </Col>
      ))}
    </Row>
  );
};

export default VideosTab;