import React, { useEffect, useRef, useState } from "react";
import { MapContainer as Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { getSpecificRentals } from "../../api/list";

const ServiceLocations = ({ rentalList }) => {
  const [center, setCenter] = useState({
    lat: 11.1085,
    lng: 77.3411,
  });
  const ZOOM_LEVEL = 9;
  const mapRef = useRef();

  const MarkerIcon = new L.Icon({
    iconUrl: require("../../assets/images/marker_map_icon.png"),
    iconSize: [35, 45],
    iconAnchor: [17, 46],
    popupAnchor: [3, -46],
  });

  return (
    <>
      <h4 style={{ textAlign: "center", marginTop: "50px" }}> Locations</h4>
      <div style={{ zIndex: -1 }}>
        <Map center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
          <TileLayer url="https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=zMR1aQCzLlxpM3ZcsQ3z" />
          {rentalList?.map((item, idx) => (
            <Marker
              position={[item?.latitude, item?.longitude]}
              icon={MarkerIcon}
              key={idx}
            >
              <Popup>{item?.budget}/month</Popup>
            </Marker>
          ))}
        </Map>
      </div>
    </>
  );
};

export default ServiceLocations;
