import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  DifferenceWrap,
  Title,
  DifferenceList,
} from "../../pages/AboutUs/aboutusStyle";
import data from "./aboutUsText";

const Difference: Function = () => {
  return (
    <>
      <DifferenceWrap>
        <Container>
          <Row>
            <Col sm={12}>
              <Title>How Do We Make a Difference</Title>
              <DifferenceList>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="noun-check-45888"
                      d="M82,0A12,12,0,1,0,94,12,12,12,0,0,0,82,0Zm4.5,6.891L88.609,9l-7.332,7.332-.777.777L75.391,12,77.5,9.891l3,3Z"
                      transform="translate(-70)"
                      fill="#940e34"
                    />
                  </svg>
                  {data.makeADifferenceFirst}
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="noun-check-45888"
                      d="M82,0A12,12,0,1,0,94,12,12,12,0,0,0,82,0Zm4.5,6.891L88.609,9l-7.332,7.332-.777.777L75.391,12,77.5,9.891l3,3Z"
                      transform="translate(-70)"
                      fill="#940e34"
                    />
                  </svg>
                  {data.makeADifferenceSecond}
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="noun-check-45888"
                      d="M82,0A12,12,0,1,0,94,12,12,12,0,0,0,82,0Zm4.5,6.891L88.609,9l-7.332,7.332-.777.777L75.391,12,77.5,9.891l3,3Z"
                      transform="translate(-70)"
                      fill="#940e34"
                    />
                  </svg>
                  {data.makeADifferenceThird}
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="noun-check-45888"
                      d="M82,0A12,12,0,1,0,94,12,12,12,0,0,0,82,0Zm4.5,6.891L88.609,9l-7.332,7.332-.777.777L75.391,12,77.5,9.891l3,3Z"
                      transform="translate(-70)"
                      fill="#940e34"
                    />
                  </svg>
                  {data.makeADifferenceFourth}
                </li>
              </DifferenceList>
            </Col>
          </Row>
        </Container>
      </DifferenceWrap>
    </>
  );
};

export default Difference;
