import React, { ElementType, useEffect, useRef } from "react";
import _ from "lodash";


const defaultSelectors = "input, textarea, select";

type Props = {
    Child: ElementType
    childProps?: any
    selectors?: string
}

const ControlFocus: React.FC<Props> = ({ Child, childProps = {}, selectors = defaultSelectors }) => {
    const ref = useRef<HTMLDivElement>(null);
    const elements = useRef<HTMLElement[]>([]);

    const listElements = () => {
        if (!ref.current) return;
        elements.current = getTabElements(ref.current, selectors);
    };
    const dispatchTab = (cycle?: boolean) => {
        const activeElement = document.activeElement as HTMLElement;
        if (!activeElement) return null;
        if (activeElement.tabIndex === -1) {
            elements.current[0].focus();
            return;
        }
        const currentIndex = elements.current.findIndex(e => e === activeElement);
        let nextIndex = (currentIndex + 1) % elements.current.length;
        if (nextIndex === 0 && !cycle) nextIndex = currentIndex;
        elements.current[nextIndex].focus();
    };

    useEffect(() => {
        listElements();
    }, []);

    return (
        <div ref={ref}>
            <Child {...childProps} listElements={listElements} dispatchTab={dispatchTab} />
        </div>
    );
};

function getTabElements(parent: HTMLDivElement, selectors: string) {
    return _.chain(parent.querySelectorAll(selectors) as NodeListOf<HTMLElement>)
        .filter(ele => ele.tabIndex > -1)
        .reduce((prev, next) => {
            return next.tabIndex > 0
            ? [[...prev[0], next].sort((a, b) => a.tabIndex > b.tabIndex ? -1 : 1), prev[1]]
            : [prev[0], [...prev[1], next]];
        }, [[], []] as HTMLElement[][])
        .flatten()
        .value();
}

export default ControlFocus;