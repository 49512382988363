import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Nodata from "../../assets/images/no-data.png";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { postRental } from "../../api/create";
import { toast } from "react-toastify";
import PostRentalModel from "../../components/PostRentalModel";

const PropertyDetails = ({ data,setActive,state }) => {
  const userData = JSON.parse(localStorage.getItem("loggedUsers"));
  const location = useLocation();
  const formData = location.state;
  const [value, onChange] = useState(new Date());
  console.log(userData, "userData");
  const [isCheck, setIsCheck] = useState(false);

  // Example usage of formData:
  console.log(formData, 7788977);
  const validationSchema = Yup.object().shape({
    // expectedRent: Yup.string().required("Expected Rent is required"),
    // deposit: Yup.string().required("Deposit is required"),
    // availFrom: Yup.string().required("Avail From date is required"),
    // Define other fields validation
  });
  const blobToBase64 = (blobUrl) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function (error) {
        reject(error);
      };
      xhr.open("GET", blobUrl);
      xhr.responseType = "blob";
      xhr.send();
    });
  };
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  console.log(latitude, longitude, 77999);
  useEffect(() => {
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            console.log("Latitude:", latitude);
            console.log("Longitude:", longitude);
            setLatitude(latitude);
            setLongitude(longitude);
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              console.error("User denied geolocation access.");
              // Handle the denial gracefully (e.g., display a message to the user)
            } else {
              console.error("Error getting geolocation:", error);
            }
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    fetchLocation();
  }, []);

  console.log(data, "data from props");
  // Handle form submission
  const handleSubmitpost = async (values) => {
    try {
      console.log("values...>",values);
      // const base64Image = await blobToBase64(selectedImage);
      console.log("Form values: ====>>>>", values);
      console.log("selectedImages",selectedImage)
      const data = {
        accomdation_type: values?.occupancy || "",
        address: values?.area || "",
        amenities: values?.amenities || "",
        area: values?.area || "",
        base64_image: selectedImage.map(img => img.replace(/^data:image\/[a-z]+;base64,/, "")),
        bathroom: values?.bathrooms || "",
        bedroom: values?.bedrooms.toString() || "",
        budget: values?.expectedRent || "",
        city: values?.city || "",
        contract_length: "10",
        description: "",
        email_id: values?.email_id || "",
        gender: values?.gender || "",
        i_want_to: values?.i_want_to || "",
        iam: values?.iam || "",
        latitude: latitude?.toString() || "",
        longitude: longitude?.toString() || "",
        mobile_number: values?.mobile_number || "",
        name: `${userData?.first_name} ${userData?.last_name}` || "",
        property_type: values?.propertyType || "",
        security_deposit: values?.deposit || "",
      };


      console.log("Payload data:", data);
      const response = await postRental(data);
      console.log("Response from rental:", response);

      if (response?.success === true) {
        toast.success(response?.message.toUpperCase());
        setOpen(true);
      } else {
        toast.error("Error occurred while posting rental.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("An unexpected error occurred.");
    }
  };

  const handleChangeOccupancy = (e, setFieldValue) => {
    const { value, checked } = e.target;
    if (checked) {
      setFieldValue("occupancy", value);
    } else {
      setFieldValue("occupancy", "");
    }
  };
  const handleChangeAmenities = (e, setFieldValue,amenities) => {
    const { value, checked } = e.target;
    console.log(value, "value",checked, "checked",amenities,"amenities")
    if (checked) {
      amenities ? 
      setFieldValue("amenities", [...amenities,value]) :  setFieldValue("amenities", [value])
    } else {
      setFieldValue("amenities", amenities.filter((item) => item !== value));
    }
  };

  const [selectedImage, setSelectedImage] = useState(null);
  console.log(selectedImage, 899887);
  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const newImages = [];
    console.log("--->> test");
    console.log("----->> len se:", selectedImage?.length);
    if (
      (selectedImage?.length ? selectedImage?.length : 0) + files.length >
      4
    ) {
      toast.error("Maximum 4 images can upload");
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      const file = files[i];

      reader.onloadend = () => {
        newImages.push(reader.result);

        // If all files have been processed, update the state
        if (newImages.length === files.length) {
          if (selectedImage) {
            setSelectedImage([...selectedImage, ...newImages]);
          } else {
            setSelectedImage([...newImages]);
          }
        }
      };

      reader.readAsDataURL(file);
    }
  };

  console.log("--->>> selectedImage : ", selectedImage);

  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log();
  return (
    <>
      {/* <Header /> */}
      <div className="container">
        <div>
          <h3 style={{ fontWeight: 700 }}>Property Details</h3>
        </div>
        <Formik
          initialValues={{
            expectedRent: "",
            deposit: "",
            availFor: "", // Single field for selected option
            availFrom: "",
            occupancy: "",
            amenities: "",
            location: {
              location1: "",
              location2: "",
              location3: "",
              location4: "",
            },
            city: data?.city ? data?.city : "",
            area: data?.area ? data?.area : "",
            bedrooms: data?.bedrooms ? data?.bedrooms : "",
            bathrooms: data?.bathrooms ? data?.bathrooms : "",
            propertyType: data?.propertyType ? data?.propertyType : "",
            gender: data?.gender ? data?.gender : "",
            maxPrice: data?.maxPrice ? data?.maxPrice : "",
            i_want_to: data?.i_want_to ? data?.i_want_to : "",
            iam: data?.iam ? data?.iam : "",
            mobile_number: data?.mobile_number ? data?.mobile_number : "",
            email_id: data?.email_id ? data?.email_id : "",
            // Define other form fields
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmitpost}
        >
          {({ errors, touched, values, handleChange, setFieldValue , handleSubmit}) => (
            console.log(errors, 78987),
            (
              <Form>
                <div>
                  {/* // first row */}
                  <div className="row mt-3">
                    <div className="col-md-2">
                      <span>
                        <h5 style={{ fontWeight: 700 }}>Expected Rent</h5>
                      </span>
                      <span>
                        <input
                          type="text"
                          className="txt-feild"
                          placeholder="Rs"
                          name="expectedRent"
                          value={values?.expectedRent}
                          onChange={handleChange}
                        />
                      </span>
                    </div>
                    <div className="col-md-2">
                      <span>
                        <h5 style={{ fontWeight: 700 }}>Deposit</h5>
                      </span>
                      <span>
                        <input
                          type="text"
                          className="txt-feild"
                          placeholder="Rs"
                          name="deposit"
                          value={values?.deposit}
                          onChange={handleChange}
                        />
                      </span>
                    </div>
                    <div className="col-md-6">
                      <span>
                        <h5 style={{ fontWeight: 700 }}>Avail For</h5>
                      </span>
                      <div className="row">
                        <div className="col-md-4">
                          <span style={{ padding: "2px" }}>
                            <input
                              type="radio"
                              placeholder="Rs"
                              name="availFor"
                              value="Male"
                              checked={values.availFor === "Male"}
                              onChange={handleChange}
                            />
                          </span>
                          <span>
                            <input
                              type="text"
                              className="txt-feild"
                              placeholder="Male"
                              disabled={true}
                            />
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span style={{ padding: "2px" }}>
                            <input
                              type="radio"
                              placeholder="Rs"
                              name="availFor"
                              value="Female"
                              checked={values.availFor === "Female"}
                              onChange={handleChange}
                            />
                          </span>
                          <span>
                            <input
                              type="text"
                              className="txt-feild"
                              placeholder="Female"
                              disabled={true}
                            />
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span style={{ padding: "2px" }}>
                            <input
                              type="radio"
                              placeholder="Rs"
                              name="availFor"
                              value="Any"
                              checked={values.availFor === "Any"}
                              onChange={handleChange}
                            />
                          </span>
                          <span>
                            <input
                              type="text"
                              className="txt-feild"
                              placeholder="Any"
                              disabled={true}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <span>
                        <h5 style={{ fontWeight: 700 }}>Avail From</h5>
                      </span>
                      <span>
                        <input
                          type="date"
                          className="txt-feild"
                          name="availFrom"
                          value={values.availFrom}
                          onChange={handleChange}
                        />
                      </span>
                    </div>
                  </div>
                  {/* // second row */}
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <h5 style={{ fontWeight: 700 }}>Occupancy</h5>
                    </div>
                    <div className="col-md-4 grid-post">
                      <h5 style={{ fontWeight: 700 }}>Amenities</h5>
                    </div>
                    <div className="col-md-6">
                      <h5 style={{ fontWeight: 700 }}>Location</h5>
                    </div>
                  </div>
                  <div className="row ">
                    {/* // Occupancy */}
                    <div className="col-md-2">
                      <div>
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="occupancy"
                            value="Single"
                            onChange={(e) =>
                              handleChangeOccupancy(e, setFieldValue)
                            }
                            checked={values.occupancy === "Single"}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="Single"
                            disabled={true}
                            value="Single"
                          />
                        </span>
                      </div>
                      <div className="mt-2">
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="occupancy"
                            value="Double"
                            onChange={(e) =>
                              handleChangeOccupancy(e, setFieldValue)
                            }
                            checked={values.occupancy === "Double"}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="Double"
                            disabled={true}
                          />
                        </span>
                      </div>
                      <div className="mt-2">
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="occupancy"
                            value="Triple"
                            onChange={(e) =>
                              handleChangeOccupancy(e, setFieldValue)
                            }
                            checked={values.occupancy === "Triple"}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="Triple"
                            disabled={true}
                          />
                        </span>
                      </div>
                      <div className="mt-2">
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="occupancy"
                            value="4 and above"
                            onChange={(e) =>
                              handleChangeOccupancy(e, setFieldValue)
                            }
                            checked={values.occupancy === "4 and above"}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="4 and above"
                            disabled={true}
                          />
                        </span>
                      </div>
                    </div>
                    {/* // Ametities */}
                    <div className="col-md-2">
                      <div>
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="amenities"
                            value="Bedding"
                            onChange={(e) =>
                              handleChangeAmenities(e, setFieldValue,values.amenities)
                            }
                            checked={values.amenities.includes("Bedding")}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="Bedding"
                            disabled={true}
                          />
                        </span>
                      </div>
                      <div className="mt-2">
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="amenities"
                            value="Table/Chair"
                            onChange={(e) =>
                              handleChangeAmenities(e, setFieldValue,values.amenities)
                            }
                            checked={values.amenities.includes("Table/Chair")}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="Table/Chair"
                            disabled={true}
                          />
                        </span>
                      </div>
                      <div className="mt-2">
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="amenities"
                            value="CupBoard"
                            onChange={(e) =>
                              handleChangeAmenities(e, setFieldValue,values.amenities)
                            }
                            checked={values.amenities.includes("CupBoard")}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="CupBoard"
                            disabled={true}
                          />
                        </span>
                      </div>
                      <div className="mt-2">
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="amenities"
                            value="Geyser"
                            onChange={(e) =>
                              handleChangeAmenities(e, setFieldValue,values.amenities)
                            }
                            checked={values.amenities.includes("Geyser")}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="Geyser"
                            disabled={true}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div>
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="amenities"
                            value="Attached Toilet"
                            onChange={(e) =>
                              handleChangeAmenities(e, setFieldValue,values.amenities)
                            }
                            checked={values.amenities.includes("Attached Toilet")}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="Attached Toilet"
                            disabled={true}
                          />
                        </span>
                      </div>
                      <div className="mt-2">
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="amenities"
                            value="TV"
                            onChange={(e) =>
                              handleChangeAmenities(e, setFieldValue,values.amenities)
                            }
                            checked={values.amenities.includes("TV")}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="TV"
                            disabled={true}
                          />
                        </span>
                      </div>
                      <div className="mt-2">
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="amenities"
                            value="Wi-Fi"
                            onChange={(e) =>
                              handleChangeAmenities(e, setFieldValue,values.amenities)
                            }
                            checked={values.amenities.includes("Wi-Fi")}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="Wi-Fi"
                            disabled={true}
                          />
                        </span>
                      </div>
                      <div className="mt-2">
                        <span style={{ padding: "2px" }}>
                          <input
                            type="checkbox"
                            placeholder="Rs"
                            name="amenities"
                            value="Elec Bill"
                            onChange={(e) =>
                              handleChangeAmenities(e, setFieldValue,values.amenities)
                            }
                            checked={values.amenities.includes("Elec Bill")}
                          />
                        </span>
                        <span>
                          <input
                            type="text"
                            className="txt-feild"
                            placeholder="Elec Bill"
                            disabled={true}
                          />
                        </span>
                      </div>
                    </div>
                    {/* // Location */}
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div>
                            <input
                              type="text"
                              className="location-txt-feild"
                              placeholder="House Number,Building Name"
                            />
                          </div>
                          <div className="mt-3">
                            <input
                              type="text"
                              className="location-txt-feild"
                              placeholder="Landmark"
                            />
                          </div>{" "}
                          <div className="mt-3">
                            <input
                              type="text"
                              className="location-txt-feild"
                              placeholder="Pincode"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <input
                              type="text"
                              className="location-txt-feild"
                              placeholder="Street Name,Locality"
                              name="area"
                              value={values?.area}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mt-3">
                            <input
                              type="text"
                              className="location-txt-feild"
                              name="city"
                              placeholder="City"
                              value={values?.city}
                              onChange={handleChange}
                            />
                          </div>{" "}
                          <div className="mt-3">
                            <input
                              type="text"
                              className="location-txt-feild"
                              placeholder={`Locate`}
                              value={`${latitude} ${longitude}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* // Third Row */}
                  <div className="row mt-3">
                    <div className="col-md-2">
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                        ref={fileInputRef}
                      />
                      <button
                        type="button"
                        style={{
                          width: "100%",
                          padding: "10px",
                          color: "white",
                          background: "#940E34",
                        }}
                        onClick={() => fileInputRef.current.click()}
                      >
                        Add Photos
                      </button>
                      <div>
                        <p style={{ fontWeight: 600 }} className="mt-2 mb-0">
                          Photo Guidelines
                        </p>
                      </div>
                      <div>
                        <p className="mt-2">
                          - Preferred photos resolution 1024px x 680px
                        </p>
                      </div>
                      <div>
                        <p className="mt-2">
                          - Upload genuine and declutered property photos
                        </p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="card">
                        {selectedImage?.[0] ? (
                          <img
                            className="card-img-top"
                            src={selectedImage[0]}
                            alt="Selected Image"
                          />
                        ) : (
                          <img
                            className="card-img-top"
                            src={Nodata}
                            alt="Default Image"
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div class="card">
                        {selectedImage?.[1] ? (
                          <img
                            className="card-img-top"
                            src={selectedImage[1]}
                            alt="Selected Image"
                          />
                        ) : (
                          <img
                            className="card-img-top"
                            src={Nodata}
                            alt="Default Image"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="card">
                        {selectedImage?.[2] ? (
                          <img
                            className="card-img-top"
                            src={selectedImage[2]}
                            alt="Selected Image"
                          />
                        ) : (
                          <img
                            className="card-img-top"
                            src={Nodata}
                            alt="Default Image"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="card">
                        {selectedImage?.[3] ? (
                          <img
                            className="card-img-top"
                            src={selectedImage[3]}
                            alt="Selected Image"
                          />
                        ) : (
                          <img
                            className="card-img-top"
                            src={Nodata}
                            alt="Default Image"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* // Fourth Row */}
                  <div className="row mt-4 mb-5">
                    <div
                      className="col-md-1 p-0"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <input
                        type="checkbox"
                        onChange={(e) => setIsCheck(e.target?.checked)}
                        checked={isCheck}
                      />
                    </div>
                    <div className="col-md-11">
                      <p style={{ fontWeight: 600 }} className="mb-0">
                        I agree to the <a href="">terms and condtions</a>
                      </p>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-md-4" />
                    <div className="col-md-3">
                      <button
                        type="submit"
                        style={{
                          width: "100%",
                          padding: "10px",
                          borderRadius: "20px",
                          color: "white",
                          background: "#940E34",
                        }}
                        onSubmit={handleSubmit}
                        disabled={
                          values?.expectedRent === "" ||
                          values?.amenities === "" ||
                          values?.availFor === "" ||
                          values?.availFrom === "" ||
                          !isCheck
                        }
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                          className="mb-0"
                        >
                          Post
                        </p>
                      </button>
                    </div>
                    <div className="col-md-4" />
                  </div>
                </div>
              </Form>
            )
          )}
        </Formik>
        {open && <PostRentalModel open={open} handleClose={handleClose} setActive={setActive} state={state}/>}
      </div>
    </>
  );
};

export default PropertyDetails;
