import React from "react";
import { Container, Breadcrumb } from "react-bootstrap";
import {
  TutionBreadCrumbs,
  TutionBreadWrap,
} from "../../pages/tuitionDetails/tuitionDetailsStyle";
import { Link, useNavigate } from "react-router-dom";
import TeacherInfo from "../../pages/tuitionList/index";

const PageBreadcrumb = () => {
  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  console.log(params, "paramsmsms");

  const navigateToList = (item: any) => {
    // navigate("/tuition-list");

    navigate(
      `${"/tuition-list"}?SUBJECT_NAME=${params.SUBJECT_NAME}&TIME=${
        params.TIME
      }&LOCATION_NAME=${params.LOCATION_NAME}&DAYS=${params.SUBECT_LEVEL}`
    );
  };

  const localData = localStorage.getItem("tuition-list");

  // const localSearchPageData = localStorage.getItem("search-page");

  const navigateToSearchList = () => {
    navigate(
      `${"/search-result"}?CITY_NAME=${params.LOCATION_NAME}&AREA_NAME=${
        params.AREA_NAME
      }&SUBJECT_LEVEL=${params.SUBJECT}&SUBJECT=${params.SUBECT_LEVEL}`
    );
  };

  return (
    <>
      <TutionBreadCrumbs>
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            {/* {localData ? ( */}
            <Breadcrumb.Item href="#" onClick={navigateToList}>
              List of Tuitions
            </Breadcrumb.Item>
            {/* ) : ( */}
            {/* <Breadcrumb.Item onClick={navigateToSearchList}>
                Search Result
              </Breadcrumb.Item> */}
            {/* )} */}

            <Breadcrumb.Item href="#" active>
            {params?.INSTITUTE_NAME ? params.INSTITUTE_NAME : `Tuition's Name`}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </TutionBreadCrumbs>
    </>
  );
};

export default PageBreadcrumb;
