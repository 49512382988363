import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { RiFilter2Fill } from "react-icons/ri";
import styled from "styled-components";
import * as Yup from "yup";
import { getValidationApi } from "../../../api/actions";
import { getLevels, getSubject } from "../../../api/list";
import SelectBox from "../../../components/SelectBox/SelectBox";
import RoundedButton from "../../../components/TuorPanel/Button";
import DeleteModal from "../../../components/TuorPanel/DeleteModal";
import Header from "../../../components/TuorPanel/Header";
import CustomPagination from "../../../components/TuorPanel/Pagination";
import StyledInput from "../../../components/TuorPanel/Input/input";
import Modal from "../../../components/TuorPanel/Modal/modal";
import CustomPreloader from "../../../components/TuorPanel/Preloader";
import Table from "../../../components/TuorPanel/Table/customTable";
import TableTop from "../../../components/TuorPanel/TableTop";
import { useDispatch, useSelector } from "react-redux";
import { get } from 'lodash';
import "./studentStyle.scss";
import {
  CreateStudentProfile,
  DeleteStudentProfile,
  GetBadgeList,
  GetStudentProfileList,
  UpdateStudentProfileData,
  filterProfile,
} from "../../../redux/action";
import { postStudentEmail } from "../../../api/create";
import { TbEdit } from "react-icons/tb";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

const ErrorMessage = styled.div`
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.2rem;
`;

const StudentInfo = () => {
  const [deleteData, setDeleteData] = useState();
  const [studentProfileData, setStudentProfileData] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [modal, setModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBatchId, setSelectedBatchId] = useState("");
  const [selectedFilterBatchId, setSelectedFilterBatchId] = useState("");
  const [selectedFilterBatchTiming, setSelectedFilterBatchTiming] =
    useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [batchTimingOptions, setBatchTimingOptions] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [resetForm, setResetForm] = useState({});
  const [selectedTiming, setSelectedTiming] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [level, setLevel] = useState([]);
  const [subject, setsubject] = useState([]);

  const [selectedOption, setSelectedOption] = useState([]);
  const [userId, setUserId] = useState([]);
  const [disableData, setdisableData] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const currentKey = window.location.href.split("/")[3];

  const search = useSelector(state => state.searchTution);
  const searchValue = get(search, 'data', '');

  if(searchValue?.trim().length){
    if(currentKey === 'student-info') {
      setSearchQuery(searchValue)
    }
  }
  
  useEffect(() => {
    dispatch({ type: 'SEARCH_HANDLER', data: null });
  }, [])
  

  console.log(searchQuery, "filteredData");
  const theadData = [
    { header: "Student ID", field: "student_id" },
    { header: "Batch ID", field: "batch_id" },
    { header: "Name", field: "first_name" },
    { header: "City", field: "city" },
    { header: "Subjects", field: "subject" },
    { header: "Level", field: "level" },
    { header: "Fee Status", field: "fee_status" },
    { header: "Batch Timing", field: "date" },
    { header: "Comments", field: "comments" },
  ];

  const cookies = new Cookies();
  // filterProfile()
  const initialUserData = async () => {

    const userData = cookies.get("TeacherloggedUser");
    const userID = userData;
    const requestData = userID?.id;
    console.log(searchQuery, "searchQuery");
    setLoading(true);
    const studentProfileResponse = await dispatch(
      GetStudentProfileList(requestData, searchQuery, currentPage, itemsPerPage)
    );
    console.log("Student Profile Data response", currentPage, itemsPerPage);
    setStudentProfileData(studentProfileResponse?.records);
    setLoading(false);
    setTotalItems(studentProfileResponse?.total_records || 0);
    const badgeListResponse = await dispatch(GetBadgeList(requestData));
    setBatchData(badgeListResponse);
    const timingData =
      badgeListResponse.records?.map((record) => record.timing) || [];
    console.log("Timing Data:", timingData);
    setBatchTimingOptions(timingData);
  };

  const handleEdit = (userData, index) => {
    setEditIndex(index);
    setSelectedUserData(userData);
    setModal(true);
    console.log(userData, "indexes");
  };
  const handleMessage = (userData, index) => {
    console.log("DDD");
    setUserId([userData.student_id]);
    
    console.log(userData);
    console.log("DDD");
    setMessageModal(true);
    // setEditIndex(index);
    // setSelectedUserData(userData);
    // setModal(true);
    // console.log(userData, "indexes");
  };
  
  const handleDeactive = async (userData, index) => {
    setLoading(true);
    setEditIndex(index);
    const payload = {
      ...userData,
      is_active: false,
    };
    await dispatch(UpdateStudentProfileData(payload, userData?.id)); // Replace with your Redux action
    initialUserData();
    setLoading(false);
    window.location.reload();
  };

  const handleActive = async (userData, index) => {
    setLoading(true);
    setEditIndex(index);
    const payload = {
      ...userData,
      is_active: true,
    };
    await dispatch(UpdateStudentProfileData(payload, userData?.id)); // Replace with your Redux action
    initialUserData();
    setLoading(false);
    window.location.reload();
  };
  const handleTimingChange = (selectedTiming) => {
    setSelectedTiming(selectedTiming);
    console.log(selectedTiming, "selecteds");
    // Find the batch ID corresponding to the selected timing
    const batch = batchData?.records?.find(
      (batch) => batch.timing === selectedTiming
    );
    const newBatchId = batch.batch_id; // Use a default value if not found
    console.log(newBatchId, "newBatchId");
    setSelectedBatchId(newBatchId);
  };

  const handleBatchIdUpdate = (selectedTiming) => {
    console.log(selectedTiming, "selected");
    // Find the batch ID corresponding to the selected timing
    const batch = batchData?.records?.find(
      (batch) => batch.timing === selectedTiming
    );
    setSelectedFilterBatchId(batch?.batch_id);
    console.log(batch?.batch_id, "batch select");
  };
  const handleFilter = () => {
    setFilterModal(true);
    console.log("clicked");
    setFilterData({
      batch_id: "",
      city: "",
      email_id: "",
      fee_status: true,
      level: "",
      student_id: "",
      student_name: "",
      subject: "",
      timing: "",
      teacher_id: teacherID?.id,
    });
  };

  console.log(selectedFilterBatchId, "selectFilter");
  const handleFaqDelete = (key) => {
    console.log(key, "delete ID");
    setDeleteModal(true);
    setDeleteData(key);
  };

  const handleEditCLose = () => {
    setModal(false);
    setSelectedUserData([]); // Reset selectedUserData
    setSelectedOption([]);
  };
  const handleDeleteClose = () => {
    setDeleteModal(false);
  };
  const teacherId = cookies.get("TeacherloggedUser");
  const teacherID = teacherId;

  const handleDelete = async (index) => {
    console.log(index, "index for delete");
    const classToDelete = deleteData;
    console.log(classToDelete, "deleteID");
    if (classToDelete.id) {
      // Delete badge from the server
      dispatch(DeleteStudentProfile(classToDelete.id)).then(
        (deletedBadgeId) => {
          if (deletedBadgeId) {
            // Update Redux store immediately by removing the deleted badge
            setStudentProfileData((prevContainers) =>
              prevContainers.filter((_, i) => i !== index)
            );
          }
          setDeleteModal(false);
          initialUserData();
        }
      );
    }
  };
  const handlePageChange = (page, pageSize) => {
    console.log(page, pageSize, "pagesize");
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };

  console.log(totalItems, "totalItems");
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    // student_name: Yup.string().required("Student Name is required"),
    mobile_number: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0-9]+$/, "Must be only numbers")
      .matches(/^[0-9]{10}$/, "Must be a 10-digit mobile number")
      .test(
        "not-starts-with-1-to-5",
        "Enter a valid mobile number.",
        (value) => {
          // Check if the first digit of the mobile number is not in the range 1-6
          const firstDigit = parseInt(value.charAt(0), 10);
          return ![1, 2, 3, 4, 5].includes(firstDigit);
        }
      ),
    city: Yup.string().required("City is required"),
    // comments: Yup.string()
    //   .required("Comments is required")
    //   .max(150, "Comments must be at most 150 characters"),
    subject: Yup.string().required("Subject is required"),
    level: Yup.string().required("Level is required"),
    date: Yup.string().required("Timing is required"),
    email_id: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .test("unique-email", "Email already exists", async function (value) {
        if (!value) return true; // Skip validation if value is empty

        try {
          const response = await getValidationApi(value);

          // Check if the email already exists
          if (!response.message) {
            // Email already exists in records, return an error
            return this.createError({
              path: "email_id",
              message: "Student mail id not found",
            });
          }
        } catch (error) {
          // Handle API request error (e.g., log the error)
          console.error("API request error:", error);
          // You can choose to return true to assume validation passed or
          // return an error message depending on your preference.
          // Example: return this.createError({ path: "email_id", message: "Validation failed" });
          return true;
        }

        return true; // Validation passed if email is unique
      }),
  });
  console.log(selectedBatchId, "selectedBatchId");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: selectedUserData ? selectedUserData.first_name : "",
      last_name: selectedUserData ? selectedUserData.last_name : "",
      mobile_number: selectedUserData ? selectedUserData.mobile_number : "",
      email_id: selectedUserData ? selectedUserData.email_id : "",
      city: selectedUserData ? selectedUserData.city : "",
      subject: selectedUserData ? selectedUserData.subject : "",
      level: selectedUserData ? selectedUserData.level : "",
      date: selectedUserData ? selectedUserData.date : "",
      comments: selectedUserData ? selectedUserData.comments : "",
      fee_status: selectedUserData ? selectedUserData.fee_status : false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("executed", values);
      console.log(selectedBatchId, "form values"); // For debugging purposes
      const newData = {
        first_name: values.first_name,
        last_name: values.last_name,
        mobile_number: values.mobile_number,
        email_id: values.email_id,
        city: values.city,
        subject: values.subject,
        level: values.level ? values.level : "",
        batch_timing: values.date,
        comments: values.comments,
        teacher_id: teacherID?.id,
        date: selectedTiming || values.date, // Use selectedTiming if available, else use form value
        batch_id: selectedBatchId, // Include selected batch ID in the data sent to the API
        fee_status: values.fee_status == "true" ? true : false,
      };

      console.log(newData, "newData");
      if (editIndex !== -1) {
        // Update existing data
        await dispatch(UpdateStudentProfileData(newData, selectedUserData?.id)); // Replace with your Redux action
        // initialUserData();
      } else {
        // Create new data
        await dispatch(CreateStudentProfile(newData));
        // initialUserData();
      }
      initialUserData();
      // window.location.reload();
      setModal(false);
      resetForm();
      setEditIndex(-1); // Reset editIndex
    },
    onValuesChange: (newValues) => {
      console.log("Values changed:", newValues);
      if (!modal) {
        setResetForm(newValues);
        // setSelectedOption([]);
      }
    },
  });
  const handleFormReset = () => {
    formik.resetForm(resetForm);
    setEditIndex(-1);
    setModal(!modal);
    setSelectedUserData({
      first_name: "",
      last_name: "",
      mobile_number: "",
      email_id: "",
      city: "",
      subject: "",
      level: "",
      batch_timing: "",
      comments: "",
      teacher_id: "",
      batch_id: "",
      date: "",
      selectedTiming: "",
      selectedBatchId: "",
    });
    setSelectedOption([]);
  };
  const handleResetFilter = () => {
    setFilterModal(false);
    setFilterData({
      batch_id: "",
      city: "",
      email_id: "",
      fee_status: "",
      level: "",
      student_id: "",
      student_name: "",
      subject: "",
      teacher_id: "",
    });

    setSelectedFilterBatchId("");
    setSelectedFilterBatchTiming("");
  };
  console.log(selectedBatchId, "selectinitl");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const [filterData, setFilterData] = useState({
    batch_id: "",
    city: "",
    email_id: "",
    fee_status: "",
    level: "",
    student_id: "",
    student_name: "",
    subject: "",
    teacher_id: teacherID?.id, // Assuming you want to set a default teacher_id
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });

    // Clear the corresponding error message when the value changes
    setErrors({ ...errors, [name]: "" });
  };
  const [searchValues, setsearchValues] = useState({
    batch_id: "",
    city: "",
    email_id: "",
    fee_status: true,
    level: "",
    student_id: "",
    student_name: "",
    subject: "",
    // teacher_id: teacherID?.id, // Assuming you want to set a default teacher_id
  });
  const handleSearch = async () => {
    // Update filterData with the selected batch ID and timing
    const updatedSearchData = {
      ...searchValues,
      teacher_id: teacherID?.id,
    };
    // Set the filterData and call fetchFilteredData
    setsearchValues(updatedSearchData);
    setLoading(true);
    const response = await dispatch(filterProfile(updatedSearchData));
   

    if (response?.success) {
      // toast.success("Filter applied successfully");
      setStudentProfileData(response?.records);
    } else {
      // toast.error("An error occurred while applying the filter");
    }
    setLoading(false);
  };

  const handleClear = () => {
    setsearchValues({
      batch_id: "",
      city: "",
      email_id: "",
      fee_status: true,
      level: "",
      student_id: "",
      student_name: "",
      subject: "",
      teacher_id: "", // Assuming you want to set a default teacher_id
    });
    setLoading(true);
    initialUserData();
    setLoading(false);
  };
  const validateForm = () => {
    const errors = {};

    // if (!filterData.subject) {
    //   errors.subject = "Subject is required";
    // }

    // if (!filterData.city) {
    //   errors.city = "City is required";
    // }

    // if (!selectedFilterBatchTiming) {
    //   errors.timing = "Batch Timing is required";
    // }

    // if (!filterData.level) {
    //   errors.level = "Level is required";
    // }

    setErrors(errors);

    return errors;
  };
  const handleFilterApply = async () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      // Only clear the errors and submit if there are no validation errors
      setErrors({});
      setSubmitted(true);

      const updatedFilterData = {
        ...filterData,
        batch_id: selectedFilterBatchId===undefined?"":selectedFilterBatchId,
        timing: selectedFilterBatchTiming,
      };
      setLoading(true);
      const response = await dispatch(filterProfile(updatedFilterData));
      if (response?.success) {
        
        setStudentProfileData(response?.records);
        setSelectedFilterBatchTiming("");
      }

      setFilterModal(false);
    } else {
      // There are validation errors, do not submit
      setSubmitted(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    handleBatchIdUpdate();
    initialUserData().catch((err) => {
      setError(err.message); // Handle API or network errors
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [searchQuery, currentPage, itemsPerPage]);

  useEffect(() => {
    getallLevels();
    getallSubjects();
  }, []);

  const getallLevels = () => {
    getLevels().then((res) => {
      setLevel(res?.records);
    });
  };
  const getallSubjects = () => {
    getSubject().then((res) => {
      setsubject(res?.records);
    });
  };
  const UserData = level?.map((item) => {
    return {
      value: item?.level_name,
      label: item?.level_name,
      id: item?.id,
    };
  });
  console.log(selectedOption, "selectedOption");

  const handlesetValue = (data) => {
    setSelectedOption(data);
    // setSearchValue({
    //   ...searchValue,
    //   level: data,
    // });
  };

  const stop = level?.map((item, index) => (
    // console.log(item, "itemssss5678")
    <option key={index} value={item?.level_name}>
      {item?.level_name}
    </option>
  ));

  const subjectsLits = subject?.map((item, index) => (
    // console.log(item, "itemssss5678")
    <option key={index} value={item?.subject}>
      {item?.subject}
    </option>
  ));
  const hideMessageModal = () => {
    setMessageModal(false);
    setMessage("");
  };

  const handleSubmitMessage = () => {
    const payload = {
      class_booking_id: userId,
      message: message,
    };
    setEditIndex(-1);
    postStudentEmail(payload)
      .then((res) => {
        if (res) {
          setMessageModal(false);
          setUserId([]);
          toast.success("Email sent");
        } else {
          // Handle the case where res is falsy (e.g., an error occurred)
          // You can show an error toast or handle it in a way that makes sense for your application
          toast.error("Failed to send email");
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error sending email:", error);
        toast.error("An error occurred while sending email");
      });
      // window.location.reload();
  };

  return (
    <div className="student-info">
      <div className="student-info-fluid">
          <TableTop onClear={handleClear} onSearch={handleSearch}>
            <StyledInput
              width="100%"
              type="text"
              placeholder="Enter Student name"
              name="student_name"
              value={searchValues?.student_name}
              onChange={(e) =>
                setsearchValues({
                  ...searchValues,
                  student_name: e.target.value,
                })
              }
            />
            <StyledInput
              width="100%"
              type="text"
              placeholder="Enter Student ID"
              name="student_id"
              value={searchValues?.student_id}
              onChange={(e) =>
                setsearchValues({ ...searchValues, student_id: e.target.value })
              }
            />
            <StyledInput
              width="100%"
              type="text"
              placeholder="Enter Email ID"
              name="email_id"
              value={searchValues?.email_id}
              onChange={(e) =>
                setsearchValues({ ...searchValues, email_id: e.target.value })
              }
            />
            <StyledInput
              width="100%"
              type="text"
              placeholder="Enter Batch ID"
              name="batch_id"
              value={searchValues?.batch_id}
              onChange={(e) =>
                setsearchValues({ ...searchValues, batch_id: e.target.value })
              }
            />
          </TableTop>
          {/* Modal */}
          {error ? (
            <ErrorMessage>{error}</ErrorMessage>
          ) : (
            <div className="student-info-fluid-container">
              <div className="student-info-fluid-container-details d-md-flex justify-content-between mt-3 mb-3 align-items-center d-sm-block">
                <h6 style={{ fontSize: '20px'}} className="prime-text fw-bolder mb-0 text-sm-center text-lg-start">
                  Student Details
                </h6>
                <div className="d-sm-grid d-md-flex student-info-fluid-container-details-filter">
                  {disableData ? (
                    <RoundedButton
                      variant="primary"
                      onClick={() => {
                        setMessageModal(true);
                      }}
                    >
                      Send Message
                    </RoundedButton>
                  ) : null}
                 {disableData?null: <RoundedButton
                    variant="secondary"
                    onClick={() => handleFilter()}
                    icon={<RiFilter2Fill />}
                    disabled={disableData}
                  >
                    Filter
                  </RoundedButton>}
                  {disableData?null:<RoundedButton
                    variant="primary"
                    onClick={() => {
                      handleFormReset();
                    }}
                    disabled={disableData}
                  >
                    Create Student Profile
                  </RoundedButton>}
                </div>
              </div>
              {loading ? (
                <CustomPreloader />
                // ''
              ) : (
                <Table
                  data={studentProfileData}
                  columns={theadData}
                  handleEdit={handleEdit}
                  handleMessage={handleMessage}
                  handleDelete={handleFaqDelete}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  handleDeactive={handleDeactive}
                  handleActive={handleActive}
                  id={userId}
                  setId={setUserId}
                  setdisableData={setdisableData}
                />
              )}           
              <div className="student-info-fluid-container-pagination">
                <CustomPagination
                  total={totalItems}
                  currentPage={currentPage}
                  onPageChange={handlePageChange} // Pass handlePageChange here
                  itemsPerPage={itemsPerPage}
                />
              </div>
            </div>
          )}
          {modal ? (
            <Modal
              title={
                editIndex !== -1
                  ? "Edit Student Profile"
                  : "Create Student Profile"
              }
              hide={() => handleEditCLose()}
              isShowing={modal}
              width="600px"
            >
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <div className="row p-3 pb-0">
                    <div className="col-6 mb-3">
                      <StyledInput
                        type="text"
                        placeholder="Student First name"
                        width="100%"
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.first_name &&
                          formik.touched.first_name &&
                          "error-border"
                        }
                      />
                      {formik.errors.first_name &&
                        formik.touched.first_name && (
                          <div className="error">
                            {formik.errors.first_name}
                          </div>
                        )}
                    </div>
                    <div className="col-6 mb-3">
                      <StyledInput
                        type="text"
                        placeholder="Student Last name"
                        width="100%"
                        name="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.last_name &&
                          formik.touched.last_name &&
                          "error-border"
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.last_name && formik.touched.last_name && (
                        <div className="error">{formik.errors.last_name}</div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <StyledInput
                        type="number"
                        placeholder="Mobile Number"
                        width="100%"
                        maxLength="10" // Set maximum length to 10 characters
                        name="mobile_number"
                        value={formik.values.mobile_number}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.mobile_number &&
                          formik.touched.mobile_number &&
                          "error-border"
                        }
                        disabled={editIndex !== -1} // Disable for update operation
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.mobile_number &&
                        formik.touched.mobile_number && (
                          <div className="error">
                            {formik.errors.mobile_number}
                          </div>
                        )}
                    </div>
                    <div className="col-6 mb-3">
                      <StyledInput
                        type="email_id"
                        placeholder="Email ID"
                        width="100%"
                        name="email_id"
                        value={formik.values.email_id}
                        className={
                          formik.errors.email_id &&
                          formik.touched.email_id &&
                          "error-border"
                        }
                        disabled={editIndex !== -1} // Disable for update operation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.email_id && formik.touched.email_id && (
                        <div className="error">{formik.errors.email_id}</div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <StyledInput
                        type="city"
                        placeholder="City"
                        width="100%"
                        name="city"
                        value={formik.values.city}
                        className={
                          formik.errors.city &&
                          formik.touched.city &&
                          "error-border"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.city && formik.touched.city && (
                        <div className="error">{formik.errors.city}</div>
                      )}
                    </div>

                    <div className="col-6 mb-3">
                      <SelectBox
                        width="100%"
                        height="38px"
                        name="subject"
                        margin="0px 0px 0px 0px"
                        padding="0px 10px 0px 10px"
                        onChange={formik.handleChange}
                        value={formik.values.subject}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.subject &&
                          formik.touched.subject &&
                          "error-border"
                        }
                      >
                        <option value="">Select Subject</option>
                        {subjectsLits}
                      </SelectBox>
                      {formik.errors.subject && formik.touched.subject && (
                        <div className="error">{formik.errors.subject}</div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <SelectBox
                        width="100%"
                        height="38px"
                        name="level"
                        margin="0px 0px 0px 0px"
                        padding="0px 10px 0px 10px"
                        onChange={formik.handleChange}
                        value={formik.values.level}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Level</option>
                        {stop}
                      </SelectBox>
                    </div>
                    <div className="col-6 mb-3">
                      <StyledInput
                        type="select"
                        defaultValue={"Batch Timing"}
                        options={[...batchTimingOptions]}
                        placeholder="Select option"
                        value={formik.values.date}
                        className={
                          formik.errors.date &&
                          formik.touched.date &&
                          "error-border"
                        }
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleInputChange(e);
                          handleTimingChange(e.target.value); // Call the handleTimingChange function
                        }}
                        onBlur={formik.handleBlur}
                        name="date"
                      />
                      {formik.errors.date && formik.touched.date && (
                        <div className="error">{formik.errors.date}</div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <SelectBox
                        width="100%"
                        height="38px"
                        name="fee_status"
                        margin="0px 0px 0px 0px"
                        padding="0px 10px 0px 10px"
                        onChange={formik.handleChange}
                        value={formik.values.fee_status}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select fee</option>
                        <option value="true">Paid</option>
                        <option value="false">Unpaid</option>
                      </SelectBox>
                    </div>
                    <div className="col-12 w-100">
                      <StyledInput
                        type="text"
                        placeholder="Comments"
                        width="100%"
                        name="comments"
                        className={
                          formik.errors.comments &&
                          formik.touched.comments &&
                          "error-border"
                        }
                        value={formik.values.comments}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.comments && formik.touched.comments && (
                        <div className="error">{formik.errors.comments}</div>
                      )}
                    </div>
                  </div>
                  <div className="my-3 text-left d-flex justify-content-center">
                    <RoundedButton
                      variant="secondery"
                      type="reset"
                      onClick={() => setModal(false)}
                    >
                      Cancel
                    </RoundedButton>
                    <RoundedButton variant="primary" type="submit">
                      {editIndex !== -1 ? "Update" : "Create"}
                    </RoundedButton>
                  </div>
                </div>
              </form>
            </Modal>
          ) : null}
          {deleteModal ? (
            <DeleteModal
              hide={() => handleDeleteClose()}
              isShowing={deleteModal}
              width="450px"
              handleDelete={() => handleDelete()}
              info="Are you sure to delete this profile ?"
            />
          ) : null}
          {filterModal ? (
            <Modal
              title="Filter"
              hide={() => setFilterModal(false)}
              isShowing={filterModal}
              width="600px"
            >
              <div className="row p-3 pb-0">
                <div className="col-6 mb-3">
                  <SelectBox
                    width="100%"
                    height="38px"
                    name="subject"
                    margin="0px 0px 0px 0px"
                    padding="0px 10px 0px 10px"
                    value={filterData?.subject}
                    onChange={handleInputChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.errors.subject &&
                      formik.touched.subject &&
                      "error-border"
                    }
                  >
                    <option value="">Select Subject</option>
                    {subjectsLits}
                  </SelectBox>
                </div>
                <div className="col-6 mb-3">
                  <StyledInput
                    type="text"
                    placeholder="City "
                    width="100%"
                    name="city"
                    value={filterData?.city}
                    onChange={handleInputChange}
                  />
                  {submitted && errors.city && (
                    <div className="eerror">{errors.city}</div>
                  )}
                </div>
                <div className="col-6 mb-3">
                  <StyledInput
                    type="select"
                    defaultValue="Batch Timing"
                    options={[...batchTimingOptions]}
                    placeholder="Select option"
                   
                    width="100%"
                    name="timing"
                    // value={filterData?.timing}
                    // onChange={handleInputChange}
                    value={selectedFilterBatchTiming}
                    onChange={(e) => {
                      const selectedTiming = e.target.value;
                      setSelectedFilterBatchTiming(selectedTiming);
                      handleBatchIdUpdate(selectedTiming);
                      // Clear the corresponding error message when the value changes
                      setErrors({ ...errors, timing: "" });
                    }}
                  />
                  {submitted && errors.timing && (
                    <div className="eerror">{errors.timing}</div>
                  )}
                </div>
                <div className="col-6 mb-3">
                  <SelectBox
                    width="100%"
                    height="38px"
                    name="level"
                    margin="0px 0px 0px 0px"
                    padding="0px 10px 0px 10px"
                    value={filterData?.level}
                    onChange={handleInputChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select Level</option>
                    {stop}
                  </SelectBox>
                  {submitted && errors.level && (
                    <div className="eerror">{errors.level}</div>
                  )}
                </div>
                <div className="my-3 text-left d-flex justify-content-center">
                  <RoundedButton
                    variant="secondery"
                    type="reset"
                    onClick={() => handleResetFilter()}
                  >
                    Cancel
                  </RoundedButton>
                  <RoundedButton variant="primary" onClick={handleFilterApply}>
                    Filter
                  </RoundedButton>
                </div>
              </div>
            </Modal>
          ) : null}
      </div>
      <div className="student-info-message">
      {messageModal ? (
        <Modal
          title="Send Message"
          hide={() => hideMessageModal()}
          isShowing={messageModal}
          // handleMessages={() => handleMessages(batchID)}
          width="600px"
        >
          {/* <form onSubmit={formik.handleSubmit}> */}
          <div>
            <div className="row p-3 pb-0">
              <div className="col-12 w-100">
                <StyledInput
                  width="100%"
                  id="message"
                  type="text"
                  placeholder="Message"
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  value={message}
                />
              </div>
              <div className="my-3 text-left d-flex justify-content-center">
                <RoundedButton
                  variant="primary"
                  type="submit"
                  onClick={() => handleSubmitMessage()}
                >
                  Send Message
                </RoundedButton>
              </div>
            </div>
          </div>
          {/* </form> */}
        </Modal>
      ) : null}
      </div> 
    </div>
  );
};

export default StudentInfo;
