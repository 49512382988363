import styled from "styled-components";

export const AuthContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const SideImage = styled.img`
  width: 40%;
  object-fit: cover;
  object-position: center;

  @media (max-width: 1199px) {
    width: 0;
  }
`;

const headerHeight = 120;
export const Header = styled.div`
  height: ${headerHeight}px;

  div {
    height: ${headerHeight/4}px;
    margin: ${(headerHeight - headerHeight/4)/2}px 0;
    margin-left: ${headerHeight/2}px;
    @media (max-width: 900px) {
      margin-left: 30px;
    }
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

export const Logo = styled.img`
  height: 100%;
`;