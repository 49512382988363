import React from "react";
import { Modal } from "react-bootstrap";

import "./styles.scss";


type Props = {
  show: boolean
  onHide: () => void
  title: string
  children?: React.ReactNode
  small?: boolean
}

const BaseModal: React.FC<Props> = ({ show, onHide, title, small, children }) => {
  const classes = ['base-modal', small ? 'small' : ''].join(' ');

  return (
    <Modal
      className={classes}
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default BaseModal;