/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BADGE_SUCCESS,
  BADGE_FAILURE,
  BADGE_REQUEST,
} from "../types/badgeType";
import { postBatchesData } from "../../api/create";
import { updateBatches } from "../../api/update";
import { getBatchesList, getBatchesById } from "../../api/list";
import { deleteBadches } from "../../api/delete";

export const BadgeRequest = () => ({
  type: BADGE_REQUEST,
});
export const BadgeSuccess = (users) => ({
  type: BADGE_SUCCESS,
  payload: users,
});
export const BadgeFailure = (error) => ({
  type: BADGE_FAILURE,
  payload: error,
});

export const CreateBadge = (data) =>
  async function (dispatch) {
    dispatch(BadgeRequest());
    return postBatchesData(data)
      .then((res) => {
        if (!res) {
          console.log("badge post response", res);
          dispatch(BadgeSuccess({ responseStatus: "success" }));
          // toast.success("Batch created successfully");
          toast.success("Batch created successfully!", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          return "success";
        }
        dispatch(BadgeFailure({ responseStatus: res }));
        // toast.success("Batch created successfully");
        toast.error("Already batch allocated on same time!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        return res.message;
      })
      .catch((err) => console.log("Batch not Created "));
  };

export const UpdateBadge = (data, userId) =>
  async function (dispatch) {
    dispatch(BadgeRequest());
    return updateBatches(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(BadgeSuccess({ responseStatus: "success" }));
          toast.success("Batch update successfully!", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          return "success";
        }
        dispatch(BadgeFailure(res.error));
        toast.error("Batch not updated!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        return res.message || res.error;
      })
      .catch((err) => console.log("Batch not Updated "));
  };
export const GetBadgeList = (teacherId, searchValue) =>
  async function (dispatch) {
    dispatch(BadgeRequest()); // Dispatch the request action
    try {
      const params = {
        teacher_id: teacherId,
        search_value: searchValue, // Include the searchValue parameter
      };
      const res = await getBatchesList(params);
      if (res) {
        console.log("badge action", res);
        dispatch(BadgeSuccess({ responseStatus: res })); // Dispatch the success action
        return res;
      } else {
        dispatch(BadgeFailure("Error message")); // Dispatch the failure action with an error message
        return "Error message";
      }
    } catch (err) {
      console.log("Catch Error:", err);
      dispatch(BadgeFailure(err.message)); // Dispatch the failure action with the error message from the catch block
      return err.message;
    }
  };
export const GetBadgeById = (params) =>
  async function (dispatch) {
    dispatch(BadgeRequest());
    return getBatchesById(params)
      .then((res) => {
        if (res.results) {
          dispatch(BadgeSuccess(res.results));
          return res;
        }

        dispatch(BadgeFailure(res.message));

        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
// UpdateBadge action remains unchanged

export const DeleteBadge = (badgeId) =>
  async function (dispatch) {
    dispatch(BadgeRequest());
    return deleteBadches(badgeId)
      .then((res) => {
        if (!res.error) {
          dispatch(BadgeSuccess({ responseStatus: "success" }));
          // toast.success("Batch Deleted Successfully");
          return badgeId; // Return the deleted badge's ID
        }
        dispatch(BadgeFailure(res.error));
        return res.error;
      })
      .catch((err) => {
        // toast.error("Batch not Deleted");
        return err;
      });
  };
