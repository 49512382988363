import { IoStarSharp } from "react-icons/io5";
import styled from "styled-components";
import './accordionStyle.css';
const StarContainer = styled.div`
  display: flex;
`;

const StarRating = ({ rating, totalStars, starSize }) => {
  const roundedRating = Math.round(rating * 2) / 2;
  const adjustedStarSize = starSize === null ?'20px':starSize===12?'20px': starSize;


  return (
    <StarContainer>
      {[...Array(totalStars)].map((_, index) => {
        const filled = index < roundedRating;
        return (
          <IoStarSharp
            className="stars"
            key={index}
            size={adjustedStarSize}
            color={filled ? "#ff9f45" : "#CBB49F"}
          />
        );
      })}
    </StarContainer>
  );
};

export default StarRating;
