import styled from "styled-components";


export const MainContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export const ContentContainer = styled.div`
  padding: 80px 0px 160px;
  background-color: #ffffff;

  @media (max-width: 1400px) {
    padding: 70px 0px 80px;
  }
  @media (max-width: 1199px) {
    padding: 50px 0px 80px;
  }
  @media (max-width: 991px) {
    padding: 40px 0px 80px;
  }
`;

export const SectionHeading = styled.h5`
  font-weight: bold;
  color: #940E34;
  margin-bottom: 30px;
`;

export const CardContainer = styled.div`
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px;

  .btn:active {
    background-color: #940e34;
  }
`;

export const Logo = styled.div`
  height: 80px;
  width: 80px;
  min-width: 80px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 4px solid #ffffff;
    border-radius: 40px;
  }
`;

export const TuitionName = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #940E34;
`;

export const TuitionNameCard = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #940E34;
  margin-top: 20px;
`;

export const Detail = styled.div`
  font-size: 16px;
  color: #012834;
  margin-top: 16px;
`;

export const RatingsContainer = styled.div`
  margin-top: 16px;
`;

export const TextAreaWrapper = styled.div`
  margin-top: 30px;
  
  textarea {
    padding: 20px;
    font-size: 16px;
  }
`;

export const RatingsWrapper = styled.div`
  margin-top: 10px;
  font-size: 16px;
`;

export const RatingsCard = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(0,0,0,0.02);
  border-radius: 20px;

  .label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

export const SubmitWrapper = styled.div`
  margin-top: 30px;

  .btn {
    width: 180px;
    font-weight: 500;
    &:active {
      background: #940E34;
    }
    &:disabled {
      background: #6c757d;
      border-color: #6c757d;
    }
  }
`;