import React, { useState, useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Tabs } from 'antd';
import { PlayCircleFilled, FileTextOutlined } from "@ant-design/icons";

import { getBatchByBatchId, getTeacherById, getStudyMaterialsForBatch } from "../../api/list";
import { hostConfig } from "../../config";
import { convertRailwayTimeRangeToStandardTimeRange } from "../../utils/helper";
import { FileType } from "../tutorPanel/StudyMaterials/types";

import Header from "../../components/Header";
import PageSpinner from "../../components/PageSpinner";
import PageError from "../../components/PageError";
import Footer from "../../components/Footer";
import VideosTab from "./VideosTab";
import NotesTab from "./NotesTab";

import {
  MainContainer,
  LayoutContainer,
  Logo,
  TuitionName,
  Detail,
  VideoDate,
  NotesContainer,
  NoteCard,
  NoteTitle,
} from "./styledViews";



const ClassContent: React.FC<any> = () => {
  const { batchId } = useParams();
  const [isPageLoading, setPageLoading] = useState<boolean>(true);
  const [pageError, setPageError] = useState<string|boolean>(false);

  const batch = useRef<any>(null);
  const teacher = useRef<any>(null);
  const materials = useRef<any>(null);

  const timing = useMemo(() => {
    if (!batch.current) return "-";
    return convertRailwayTimeRangeToStandardTimeRange(batch.current.timing);
  }, [isPageLoading]);

  const tabItems = [
    { label: "Video Recordings", key: FileType.VIDEO, children: <VideosTab dataList={materials.current} /> },
    { label: "Reference Notes", key: FileType.NOTE, children: <NotesTab dataList={materials.current} /> },
  ];

  const fetchBatch = async () => {
    if (!batchId) return;
    const res = await getBatchByBatchId(batchId);
    if (!res.success) {
      setPageError("Failed to fetch batch details.");
      setPageLoading(false);
      return false;
    }
    batch.current = res.records;
    return true;
  };

  const fetchTeacher = async (id: number) => {
    const res = await getTeacherById(id);
    if (!res.success) {
      setPageError("Failed to fetch tution details.");
      setPageLoading(false);
      return false;
    }
    teacher.current = res.records;
    return true;
  };

  const fetchStudyMaterials = async () => {
    const res = await getStudyMaterialsForBatch(batchId);
    if (!res.success) {
      setPageError("Failed to fetch study materials.");
      setPageLoading(false);
      return false;
    }
    materials.current = res.records;
    return true;
  };

  const initialize = async () => {
    (await fetchBatch()) && 
    (await fetchTeacher(batch.current.teacher_id)) &&
    (await fetchStudyMaterials()) &&
    setPageLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <MainContainer>
      <Header />

      {(isPageLoading && <PageSpinner />) ||
      (pageError && <PageError title={_.isString(pageError) && pageError} />) ||
      (<>
        <LayoutContainer>
          <Container fluid>
            <Row>
              <Col lg={3} md={4}>
                <Logo><img src={`${hostConfig.S3_BUCKET}${teacher.current.profile_filename}`} alt="Tuition Logo" /></Logo>
                <TuitionName>{teacher.current.tuition_name}</TuitionName>
                <Detail>Subject: {batch.current.subject}</Detail>
                <Detail>Timing: {timing}</Detail>
              </Col>

              <Col lg={9} md={8}>
                <Tabs size="large" defaultActiveKey={FileType.VIDEO} items={tabItems} />
              </Col>
            </Row>
          </Container>
        </LayoutContainer>
      </>)}

      <Footer />
    </MainContainer>
  );
};

export default ClassContent;