import React, { useState } from "react";
import "./tabStyle.css";

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface DynamicTabsProps {
  tabs: Tab[];
  activeTab: number; // Prop to manage the active tab index
  onTabChange: (index: number) => void; // Prop to handle tab changes
}

const DynamicTabs: React.FC<DynamicTabsProps> = ({
  tabs,
  activeTab,
  onTabChange,
}) => {
  const handleTabClick = (index: number) => {
    onTabChange(index); // Call the onTabChange prop to update the active tab
  };

  return (
    <div className="container-fluid mb-0 p-0">
      <div className="tab bg-white">
        <ul role="button" className="tab-container serviceTab">
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={`tab-item  ${activeTab === index ? "active-tab" : ""}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
      <div>{tabs[activeTab].content}</div>
    </div>
  );
};

export default DynamicTabs;
