import React, { useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { FileTextOutlined } from "@ant-design/icons";

import { FileType } from "../tutorPanel/StudyMaterials/types";

import {
  NotesContainer,
  NoteCard,
  NoteTitle,
  VideoDate,
} from "./styledViews";


const NotesTab: React.FC<any> = ({ dataList }) => {
  const notes = useMemo(() => {
    return _.chain(dataList)
      .filter((d) => d.type === FileType.NOTE)
      .sortBy((d) => moment(d.created_at).unix())
      .reverse()
      .value();
  }, [dataList]);

  const downloadFile = (url: string) => () => {
    
  };

  return (
    <NotesContainer>
      {_.map(notes, note => (
        <NoteCard key={note.id} onClick={downloadFile(note.file_url)}>
          <FileTextOutlined />
          <NoteTitle>{note.title}</NoteTitle>
          <VideoDate>{moment(note.created_at).format("MMM DD, YYYY")}</VideoDate>
        </NoteCard>
      ))}
    </NotesContainer>
  );
};

export default NotesTab;