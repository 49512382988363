import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Select } from "antd";

import ControlFocus from "../../components/ControlFocus";

import { getareaById } from "../../api/list";


const daysList = [
  { label: "All days (Mon-Fri)", value: "0" },
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
  { label: "Sunday", value: "7" },
];
const timingList = [
  { label: "6AM to 9AM", value: "0" },
  { label: "9AM to 1PM", value: "1" },
  { label: "2PM to 6PM", value: "2" },
  { label: "6PM to 9PM", value: "3" },
];

export type dataType = {
  location: string
  area: string
  subject: string
  daysOfWeek: string
  timing: string
  level: string
}

const initialValues: dataType = {
  location: "",
  area: "",
  subject: "",
  daysOfWeek: "",
  timing: "",
  level: "",
};

const validationSchema = Yup.object().shape({
  location: Yup.string().required("Please select a City")
});

type InputProps = {
  name: string
  placeholder: string
  value?: string
  error: string | undefined
  options: {
    label?: string
    value: string
  }[]
  disabled?: boolean
  loading?: boolean
  onSelect: (event: any) => void
  onBlur: React.FocusEventHandler<HTMLElement>
}
const Input: React.FC<InputProps> = (
  { name, placeholder, value, error, options, disabled, loading, onSelect, onBlur }
) => {
  const handleSelect = (value: string) => onSelect({ target: { name, value: value || "" } });

  return (
    <Form.Item
      validateStatus={error && "error"}
      help={error}
    >
      <Select
        variant="filled"
        showSearch allowClear
        optionFilterProp="label"
        disabled={disabled}
        loading={loading}
        style={{ width: "100%" }}
        options={options}
        placeholder={placeholder}
        value={value || (_.isString(value) ? null : undefined)}
        onChange={handleSelect}
        onBlur={onBlur}
        filterOption={(inputValue, option) => {
          return option?.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }}
      />
    </Form.Item>
  );
};

const SelectArea: React.FC<any> = ({ city, cityList, value, onSelect, onBlur, error }) => {
  const [areaList, setAreaList] = useState<any[]|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if(!city) {
      setAreaList(null);
      return;
    }

    setIsLoading(true);
    const data = _.find(cityList, c => c.value === city);
    getareaById(data.id).then((res) => {
      if (res?.records) {
        const areaData = _.chain(res.records)
          .map((item: any) => ({ label: item.area, value: item.area }))
          .sortBy(["label"])
          .value();
          setAreaList(areaData);
          setIsLoading(false);
      }
    });
  }, [city, cityList]);

  return (
    <Input
      name="area"
      placeholder="Select Area"
      value={value}
      disabled={!areaList}
      loading={isLoading}
      error={error}
      options={areaList || []}
      onSelect={onSelect}
      onBlur={onBlur}
    />
  );
};

const SearchForm: React.FC<any> = ({ cityList, subjectList, levelsList, onSubmit, dispatchTab }) => {
  const handleSelect = (handler: (e: any) => void) => (e: any, delay?: boolean) => {
    handler(e);
    if (!e.target.value) return;
    setTimeout(() => dispatchTab(), !delay ? 100 : 500);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        setValues
      }) => (
        <Form onFinish={handleSubmit}>
          {/* {(() => {console.log("### Formik", values, errors); return null;})()} */}
          <Row>
            <Col md={6}>
              <Input
                name="location"
                placeholder="Select City *"
                value={values.location}
                error={errors.location}
                options={cityList}
                onSelect={(e) => {
                  setFieldValue("area", "");
                  handleSelect(handleChange)(e, true);
                }}
                onBlur={handleBlur}
              />
            </Col>

            <Col md={6}>
              <SelectArea
                city={values.location}
                cityList={cityList}
                value={values.area}
                error={errors.area}
                onSelect={handleSelect(handleChange)}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Input
                name="subject"
                placeholder="Select Subject"
                value={values.subject}
                error={errors.subject}
                options={subjectList}
                onSelect={handleSelect(handleChange)}
                onBlur={handleBlur}
              />
            </Col>
            
            <Col md={6}>
              <Input
                name="level"
                placeholder="Select Level"
                value={values.level}
                error={errors.level}
                options={levelsList}
                onSelect={handleSelect(handleChange)}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Input
                name="daysOfWeek"
                placeholder="Select Days"
                value={values.daysOfWeek}
                error={errors.daysOfWeek}
                options={daysList}
                onSelect={handleSelect(handleChange)}
                onBlur={handleBlur}
              />
            </Col>
            
            <Col md={6}>
              <Input
                name="timing"
                placeholder="Select Timings"
                value={values.timing}
                error={errors.timing}
                options={timingList}
                onSelect={handleSelect(handleChange)}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Button variant="secondary" onClick={() => setValues(_.clone(initialValues))}>
                Clear
              </Button>
            </Col>

            <Col md={6}>
              <Button
                type="submit"
                disabled={!_.isEmpty(errors)}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

const WithControlFocus: React.FC<any> = (props) => {
  return (
    <ControlFocus Child={SearchForm} childProps={props} />
  );
};

export default WithControlFocus