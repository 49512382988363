import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { get } from 'lodash';
import { Card, Button, Modal, Row, Col } from 'antd';
import * as Yup from "yup";
import nodata from "../../../assets/images/no-data.png";
import RoundedButton from "../../../components/TuorPanel/Button";
// import Card from "../../../components/TuorPanel/Card";
import Header from "../../../components/TuorPanel/Header";
import DynamicImageUploadRect from "../../../components/TuorPanel/ImageInputRect";
import StyledInput from "../../../components/TuorPanel/Input/input";
// import Modal from "../../../components/TuorPanel/Modal/modal";
import CustomPagination from "../../../components/TuorPanel/Pagination";
import CustomPreloader from "../../../components/TuorPanel/Preloader";
import { hostConfig } from "../../../config";
import {
  CreateAdvertisementPost,
  GetAdvertisementList,
  UpdateAdvertisement,
} from "../../../redux/action/advertisementAction";
import LoginImg from '../../../assets/images/login.png';
import './index.scss';

const { Meta } = Card;

const Post = () => {
  const dispatch = useDispatch();
  const [advPost, setAdvPost] = useState();
  const [editAdvPost, setEditAdvPost] = useState();
  const [base64, setBase64] = useState("");
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [userID, setUserID] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const cookies = new Cookies();
  const [searchQuery, setSearchQuery] = useState();
  const [view, setView] = useState();
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    console.log(event.target.value, "eeeeeeeeee");
  };
  const currentKey = window.location.href.split("/")[3];

  const search = useSelector(state => state.searchTution);
  const searchValue = get(search, 'data', '');

  if(searchValue?.trim().length){
    if(currentKey === 'post') {
      setSearchQuery(searchValue)
    }
  }
  
  useEffect(() => {
    dispatch({ type: 'SEARCH_HANDLER', data: null });
  }, [])
  
  console.log(imageData, "imagedata");
  const handlePageChange = (page, pageSize) => {
    console.log(page, pageSize, "pagesize");
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };
  const handleImageSelect = (file, base64ImagData) => {
    if (file) {
      setImageData(file);
      setBase64(base64ImagData);
    }
  };
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();

    // Pad single-digit day and month with leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Format: DD-MM-YYYY

    console.log(`${formattedDay}-${formattedMonth}-${year}`);
    return `${formattedDay}-${formattedMonth}-${year}`;
  };
  const handleOpen = () => {
    setModal(true);
    setIsEdit(false);
    setImageData(null);
    formik.setErrors({}); // Clear any existing errors
    formik.resetForm(); // Reset the form's validation state
  };
  const handleEditCLose = () => {
    setModal(false);
    setIsEdit(false);
    setImageData(null);
    formik.setErrors({}); // Clear any existing errors
    formik.resetForm(); // Reset the form's validation state
  };
  const handleEditModalCLose = () => {
    setModal(false);
    setImageData(null);
    formik.setErrors({}); // Clear any existing errors
    formik.resetForm(); // Reset the form's validation state
  };
  const initialUserData = async () => {
    const localData = cookies.get("TeacherloggedUser");
    console.log(localData?.id, "idssssss");
    setUserID(localData?.id);
    dispatch(
      GetAdvertisementList(
        localData?.id,
        searchQuery,
        currentPage,
        itemsPerPage
      )
    ).then((result) => {
      setAdvPost(result?.records);
      setTotalItems(result?.total_records || 0);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    initialUserData();
  }, [imageData, searchQuery, currentPage, itemsPerPage]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    sub_title: Yup.string().required("Sub Title is required"),
    short_description: Yup.string().required("Short Description is required"),
    image_file:
      imageData === null ? Yup.string().required("Image is required") : null,
    date: Yup.string()
      .required("Date is required")
      .test("is-future-date", "Date must be in the future", function (value) {
        // Parse the selected date and the current date
        const selectedDate = new Date(value);
        const currentDate = new Date();

        // Compare the selected date with the current date
        if (selectedDate > currentDate) {
          return true; // Date is in the future, validation passes
        }

        return false; // Date is not in the future, validation fails
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      sub_title: "",
      short_description: "",
      image_file: "",
      teacher_id: userID,
      date: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData(); // Create a new FormData instance
      console.log(values, "values");
      formData.append("title", values.title);
      formData.append("sub_title", values.sub_title);
      formData.append("short_description", values.short_description);
      formData.append("valid_date", formatDate(values.date));
      // if (imageData) {
      //   // Append the image data (Blob) to the form data
      //   formData.append("image_file", imageData, "image.png"); // Use "image.png" or a unique name for the image file
      // }
      if (base64 != "") {
        var strImage = base64.replace(/^data:image\/[a-z]+;base64,/, "");
        formData.append("image_file", strImage);
      }
      formData.append("teacher_id", userID); // Append other fields if needed
      console.log(formData, "formdata");
      console.log("Form Data Entries:");
      for (const pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }
      if (isEdit === false) {
        dispatch(CreateAdvertisementPost(formData)).then(() => {
          // Dispatch GetAdvertisementList after creating the post
          dispatch(GetAdvertisementList(userID, searchQuery)).then((result) => {
            setAdvPost(result?.records);
            initialUserData();
          });
          setModal(false);
          resetForm();
          setImageData(null);
        });
      } else {
        dispatch(UpdateAdvertisement(formData, editAdvPost.id)).then(() => {
          // Dispatch GetAdvertisementList after creating the post
          dispatch(GetAdvertisementList(userID, searchQuery)).then((result) => {
            setAdvPost(result?.records);
            initialUserData();
          });
          setModal(false);
          resetForm();
          setImageData(null);
        });
      }
    },

    onValuesChange: (newValues) => {
      console.log("Values changed:", newValues);
      if (!modal) {
        setImageData(null);
      }
    },
  });
  const content = (title, date) => {
    return(
    <div className="d-flex justify-content-between align-items-center">
      <p className="m-0 fw-bold" style={{ color: "rgb(148, 14, 52)", fontSize: '18px' }}>{title}</p>
      <p className="m-0 text-gray" style={{ fontSize: '14px' }}>{date}</p>
    </div>
  )}

  const buttonHandler = (item) => {
    // Handle the RoundedButton click event
    formik.setValues({
      title: item.title,
      sub_title: item.sub_title,
      short_description: item.short_description,
      image_file: item?.image_file_name,
      date: moment(item.validated_date).format("YYYY-MM-DD"),
    });

    setStartDate(
      moment(item.validated_date).format("MM/DD/YYYY")
    );
    console.log(startDate);
    setImageData(
      item.image_file_name
        ? `${hostConfig.S3_BUCKET}${item?.image_file_name}`
        : null
    );
    setBase64("");
    setEditAdvPost(item);
    setModal(true);
    setIsEdit(true);
  }

  const showHandler = (index) => {
    setView(index);
    setShow(!show);
  }
   
  console.log(advPost, "adv");
  return (
    <div className="w-100">
      <div className="post-page">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="prime-text fw-bolder mb-0" style={{ fontSize: '20px' }}>Post Your Adv</h6>
          <RoundedButton variant="primary" onClick={handleOpen}>
            Post Adv
          </RoundedButton>
        </div>
        {loading ? (
          <CustomPreloader />
        ) : advPost?.length === 0 ? (
          <div className="no-data-found text-center my-4">
            <img src={nodata} alt="no-data" width={350} />
          </div>
        ) : (
          <Row gutter={[16, 16]} className="post-page-card">
            {advPost?.map(
              (item, index) => (
                (
                  <Col xs={24} sm={24} md={24} lg={12} xl={8} className="post-page-card-child">
                    <Card className="post-page-card-child-card"
                      cover={
                        <div className="post-page-card-child-card-img">
                        <img
                          alt={item.title}
                          src={
                            item.is_image
                              ? `${hostConfig.S3_BUCKET}${item?.image_file_name}?lastdate=` +
                              Date()
                              : 
                              LoginImg
                          }
                        />
                        </div>
                      }
                      style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}}
                    >
                      
                      <Meta
                        title={content(item.title, item.created_at.slice(0, 10))}
                        description={<div className="fw-bold" style={{ color: '#000000'}}>{item.sub_title}</div>}
                      />
                      <div>
                      <button style={{border: 'none', float: 'right'}} onClick={() => showHandler(index)}>{(view === index && show) ? 'Hide' : 'More'}</button>
                      <p title={item.short_description} className="my-3" style={(view === index && show) ? {} : { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item.short_description}</p>
                      </div>
                      <div style={{ textAlign: 'right'}}>
                      <p className="mb-1 text-gray">Valid Till :{
                        item.validated_date != null
                          ? item.validated_date.slice(0, 10)
                          : ""
                      }</p>
                      </div>
                      <Button className="w-100 text-white" style={{ background: 'rgb(148, 14, 52)'}} shape="round" size="large" onClick={() => buttonHandler(item)}>View Ad</Button>
                    </Card>
                  </Col>
                )
              )
            )}
          </Row>
        )}
        {modal && (
          <Modal open={modal} onCancel={handleEditCLose}
            title={isEdit ? "Edit  Advertisement" : "Post  Advertisement"}
            width="600px"
          >
            <form>
              <div className="post-image">
              <DynamicImageUploadRect
                url={
                  isEdit
                    ? imageData
                    : null
                }
                onImageSelect={handleImageSelect}
                name="image_file"
              /></div>
              {formik.errors.image_file && formik.touched.image_file && (
                <div className="error text-center">
                  {formik.errors.image_file}
                </div>
              )}

              <StyledInput
                type="text"
                className="mt-3"
                placeholder="Title"
                width="100%"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
              />
              {formik.errors.title && formik.touched.title && (
                <div className="error">{formik.errors.title}</div>
              )}
              <StyledInput
                type="text"
                className="mt-3"
                placeholder="Sub Title"
                width="100%"
                name="sub_title"
                value={formik.values.sub_title}
                onChange={formik.handleChange}
              />
              {formik.errors.sub_title && formik.touched.sub_title && (
                <div className="error">{formik.errors.sub_title}</div>
              )}
              <StyledInput
                type="text"
                className="mt-3"
                placeholder="Short Description"
                width="100%"
                name="short_description"
                value={formik.values.short_description}
                onChange={formik.handleChange}
              />
              {formik.errors.short_description &&
                formik.touched.short_description && (
                  <div className="error">{formik.errors.short_description}</div>
                )}
              <div className="d-flex justify-content-center mt-3">
                <input
                  type="date"
                  // value={startDate}
                  defaultValue={startDate}
                  name="date"
                  value={formik.values.date}
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  // onChange={(e) => console.log(e.target.value, "sdghvshgdsvdh")}
                  className="form-control form-control-sm form-control-solid"
                  autoComplete="off"
                />
                {formik.errors.date && formik.touched.date && (
                  <div className="error">{formik.errors.date}</div>
                )}
              </div>
              {/* </Col> */}
              <div className="my-3 text-left d-flex justify-content-center">
                <RoundedButton
                  variant="secondery"
                  type="reset"
                  onClick={() => setModal(false)}
                >
                  Cancel
                </RoundedButton>
                <RoundedButton variant="primary" onClick={formik.handleSubmit}>
                  {isEdit ? "Update" : "Create"}
                </RoundedButton>
              </div>
            </form>
          </Modal>
        )}
      </div>
      <div className="post-pagination">
        <CustomPagination
          total={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange} // Pass handlePageChange here
          itemsPerPage={itemsPerPage}
        />
      </div>
    </div>
  );
};

export default Post;
