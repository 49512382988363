import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tab } from "react-bootstrap";
import { Result } from "antd";

import { postStudentRegRequestData, postTeacherRegisterRequestData } from "../../../api/create";

import StyledTabs from "../../../components/StyledTabs";

import {
  MainContainer,
  FormContainer
} from "../Login/styledViews";
import { TabsContainer, Footer, SuccessMessage } from "./styledViews";
import StudentForm, { dataType as studentDataType } from "./StudentForm";
import TeacherForm, { dataType as teacherDataType } from "./TeacherForm";


const TAB = {
  student: "student",
  teacher: "teacher",
};

const Register: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState<string>(TAB.student);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [regSuccess, setRegSuccess] = useState<boolean>(false);

  const handleTabSelect = (selectedTab: string | null) => {
    if (isLoading) return;
    setErrorMsg("");
    selectedTab && setActiveTab(selectedTab);
  }

  const handleStudentSubmit = (values: studentDataType) => {
    setErrorMsg("");
    setLoading(true);

    const payload = {
      email_id: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      mobile_number: values.mobileNumber,
      password: values.password,
    };

    postStudentRegRequestData(payload).then((res: any) => {
      setLoading(false);

      if (res.success) setRegSuccess(true);
      else {
        console.log("Registration Error", res.detail?.error);
        if (res.detail?.error === "student email id already in use") {
          setErrorMsg("Specified Email ID is already in use.");
        }
        else if (res.detail?.error === "student mobile number already in use") {
          setErrorMsg("Specified Mobile Number is already in use.");
        }
        else setErrorMsg("Something went wrong, please try again later.");
      }
    }).catch((err) => {
      setLoading(false);

      console.log("Registration Error", err);
      setErrorMsg("Something went wrong, please try again later.");
    });
  };

  const handleTeacherSubmit = (values: teacherDataType) => {
    setErrorMsg("");
    setLoading(true);

    const payload = {
      email_id: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      mobile_number: values.mobileNumber,
      city: values.city,
      education: values.subject,
      password: values.password,
    };

    postTeacherRegisterRequestData(payload).then((res: any) => {
      setLoading(false);

      if (res.success) setRegSuccess(true);
      else {
        console.log("Registration Error", res.detail?.error);
        if (res.detail?.error === "teacher email id already in use") {
          setErrorMsg("Specified Email ID is already in use.");
        }
        else if (res.detail?.error === "teacher mobile number already in use") {
          setErrorMsg("Specified Mobile Number is already in use.");
        }
        else setErrorMsg("Something went wrong, please try again later.");
      }
    }).catch((err) => {
      setLoading(false);

      console.log("Registration Error", err);
      setErrorMsg("Something went wrong, please try again later.");
    });
  };

  return (
    <MainContainer>
      {!regSuccess ? (
        <TabsContainer>
          <StyledTabs
            activeKey={activeTab}
            onSelect={handleTabSelect}
            className="login-tabs"
          >
            <Tab eventKey={TAB.student} title="Student Registration">
              <FormContainer>
                <StudentForm onFormSubmit={handleStudentSubmit} isLoading={isLoading} submitError={errorMsg} />
              </FormContainer>
            </Tab>
            <Tab eventKey={TAB.teacher} title="Teacher Registration">
              <FormContainer>
                <TeacherForm onFormSubmit={handleTeacherSubmit} isLoading={isLoading} submitError={errorMsg} />
              </FormContainer>
            </Tab>
          </StyledTabs>

          <Footer>
            <span>
              Already have an account? &nbsp;
              <Link className="login-link" to="/auth/login">
                Login
              </Link>
            </span>
          </Footer>
        </TabsContainer>
      ) : (
        <Result
          status="success"
          title="Thank you for registering your account with Tuition Mitra!"
          subTitle="Before we get started, lets get your Email ID verified. Please check your email account for the verification link."
        />
      )}
    </MainContainer>
  );
};

export default Register;