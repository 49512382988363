import React from "react";
import StyledInput from "../Input/input";
import RoundedButton from "../Button";
import "./tableTop.css";

const TableTop = ({ onClear, onSearch, children }) => {
  return (
    <div className="card border-0 grid-container">
      <div className="grid-wrapper">
      {children}
      </div>
      <div className="d-flex justify-content-center">
        <RoundedButton
          variant="secondary"
          className="me-2"
          onClick={onClear}
        >
          Clear
        </RoundedButton>
        <RoundedButton variant="primary" onClick={onSearch}>
          Search
        </RoundedButton>
      </div>
    </div>
  );
};

export default TableTop;
