import React from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { Alert } from 'antd';
import { SyncOutlined } from "@ant-design/icons";

import PasswordInput from "../../../components/PasswordTextField";
import { SubmitContainer } from "./styledViews";


export type dataType = {
  email: string
  password: string
}

const initialValues: dataType = {
  email: '',
  password: '',
};

function validate(values: dataType) {
  let errors: any = {};

  if (!values.email) {
    errors.email = "Please enter your Email Id";
  }

  if (!values.password) {
    errors.password = "Please enter your Password";
  }

  return errors;
}

const LoginForm: React.FC<any> = ({ onFormSubmit, isLoading, submitError }) => {
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label style={{ color: "black" }}>
                  Email ID
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="my@email.com"
                  id="email"
                  name="email"
                  autoFocus
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  value={values.email}
                  onBlur={handleBlur}
                />
                {touched.email && errors.email && (
                  <div className="error">{errors.email}</div>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <PasswordInput
                label="Password"
                name="password"
                value={values.password}
                onChange={(e: any) => handleChange(e)}
                onBlur={handleBlur}
                error={
                  touched.password && errors.password
                    ? errors.password
                    : ""
                }
              />
            </Col>
          </Row>

          <SubmitContainer>
            <Button
              type="submit"
              disabled={!values.email || !_.isEmpty(errors) || isLoading}
            >
              {isLoading ? <SyncOutlined spin /> : "Submit"}
            </Button>

            {submitError && <Alert
              style={{ marginTop: 30 }}
              type="error"
              showIcon
              message={submitError}
            />}
          </SubmitContainer>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;