import styled from "styled-components";

export const Title = styled.h1`
  font-family: "Montserrat";
  font-size: 42px;
  line-height: 120%;
  font-weight: 800;
  letter-spacing: 0px;
  color: #012834;
  margin-top:20px;
  padding-bottom: 24px;
  margin-bottom: 0px;
  @media (max-width: 1680px) {
    font-size: 38px;
  }
  @media (max-width: 1440px) {
    font-size: 36px;
    padding-bottom: 15px;
  }
  @media (max-width: 1366px) {
    font-size: 34px;
  }
  @media (max-width: 1280px) {
    font-size: 30px;
  }
`;
export const Description = styled.p`
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 30px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #012834;
  padding-bottom: 0px;
  margin-bottom: 0px;
  @media (max-width: 1366px) {
    font-size: 15px;
    line-height: 28px;
  }
`;

export const CompanyWrap = styled.div`
  background: #f0f0f0;
  padding: 100px 0px;
  text-align: center;
  @media (max-width: 1680px) {
    padding: 90px 0;
  }
  @media (max-width: 1440px) {
    padding: 70px 0;
  }
  @media (max-width: 1366px) {
    padding: 60px 0;
  }
  @media (max-width: 1280px) {
    padding: 40px 0;
  }
  */ ${Title} {
    text-align: center;
  }
  ${Description} {
    max-width: 1050px;
    margin: 0 auto;
    text-align: center;
  }
`;

export const VissionMissionWrap = styled.div`
  padding: 100px 0px;
  @media (max-width: 1680px) {
    padding: 90px 0;
  }
  @media (max-width: 1440px) {
    padding: 70px 0;
  }
  @media (max-width: 1366px) {
    padding: 60px 0;
  }
  @media (max-width: 1280px) {
    padding: 40px 0;
  }
  ${Description} {
    max-width: 580px;
  }
  .row {
    align-items: center;
  }
`;

export const Image = styled.div``;
export const MeetTheTeamWrap = styled.div`
  background: #f0f0f0;
  padding: 100px 0px;
  @media (max-width: 1680px) {
    padding: 90px 0;
  }
  @media (max-width: 1440px) {
    padding: 70px 0;
  }
  @media (max-width: 1366px) {
    padding: 60px 0;
  }
  @media (max-width: 1280px) {
    padding: 40px 0;
  }
  ${Description} {
    max-width: 580px;
  }
  .row {
    align-items: center;
  }
`;
export const TeamRight = styled.div`
  padding-left: 132px;
  @media (max-width: 1680px) {
    padding-left: 120px;
  }
  @media (max-width: 1440px) {
    padding-left: 100px;
  }
  @media (max-width: 1366px) {
    padding-left: 80px;
  }
  @media (max-width: 1280px) {
    padding-left: 60px;
  }
  @media (max-width: 767px) {
    padding-left: 0px;
  }
`;
export const DifferenceWrap = styled.div`
  padding: 100px 0px;
  @media (max-width: 1680px) {
    padding: 90px 0;
  }
  @media (max-width: 1440px) {
    padding: 70px 0;
  }
  @media (max-width: 1366px) {
    padding: 60px 0;
  }
  @media (max-width: 1280px) {
    padding: 50px 0;
  }
`;

export const DifferenceList = styled.ul`
  padding-left: 0px;
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 50px;
  li {
    font-family: "Montserrat";
    font-size: 16px;
    line-height: 30px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #000000;
    flex: 0 0 47%;
    max-width: 47%;
    display: flex;
    padding-bottom: 20px;
    @media (max-width: 1366px) {
      font-size: 15px;
      line-height: 28px;
      padding-bottom: 10px;
    }
    @media (max-width: 991px) {
      flex-wrap: nowrap;
      flex: 100%;
      max-width: 100%;
      line-height: 28px;
      padding-bottom: 22px;
    }
    svg {
      flex: 0 0 4%;
      max-width: 4%;
      margin-right: 15px;
    }
  }
`;

export const AboutBannerWrap = styled.div`
  background-color: #940e34;
  text-align: center;
  padding: 49px 0 48px;
  @media (max-width: 1680px) {
    padding: 45px 0;
  }
  @media (max-width: 1440px) {
    padding: 40px 0;
  }
  @media (max-width: 1366px) {
    padding: 35px 0;
  }
  @media (max-width: 1280px) {
    padding: 30px 0;
  }
`;
export const AboutBannerTitle = styled.h2`
  font-size: 60px;
  line-height: 122%;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 0px;
  @media (max-width: 1680px) {
    font-size: 55px;
  }
  @media (max-width: 1440px) {
    font-size: 45px;
  }
  @media (max-width: 1366px) {
    font-size: 45px;
  }
  @media (max-width: 1280px) {
    font-size: 40px;
  }
`;
