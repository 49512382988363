import { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { gettopSubjects, getallTutions } from "../../api/list";
import { hostConfig } from "../../config";
import ProfileOne from "../../assets/images/no-image-2.png";
import { useNavigate } from "react-router-dom";
import {
  PopularSection,
  TutionGroup,
  TutionImage,
  TutionListWrap,
  TutionTitle,
} from "../../pages/home/homeStyle";
import styled from "styled-components";
import { SearchTution } from "../../redux/action/searchAction";

const TutionCard = styled.li`
  list-style: none;
  border-radius: 8px; /* Optional: Add rounded corners for a more card-like appearance */
  overflow: hidden; /* Ensure the content doesn't overflow the card */
`;
const TutionCardWrapper = styled.div`
  cursor: pointer; /* Add a pointer cursor to indicate clickability */
`;

const PopularTutionSubjects = () => {
  const navigate = useNavigate();

  const [tutionData, setTutionData] = useState<any>([]);
  const [subjectData, setSubjectData] = useState<any>([]);
  
  const getPopularTutions = () => {
    const selectedCity = { city: "Patna" };
    getallTutions(selectedCity).then((res) => {
      // console.log(res, "res from popular tutions");
      setTutionData(res?.records);
    });
  };

  const getPopularSubjects = () => {
    gettopSubjects().then((res) => {
      // console.log(res, "res from popular subjects");
      setSubjectData(res?.records);
    });
  };
  const handleTuitionCardClick = (data: any) => {
    console.log(data);
    navigateToUserProfilePage(data);
    // SearchTution({
    //   area:  "",
    //   batch_id: "",
    //   city:  "",
    //   level:"",

    //   subject: "",
    //   tuition_name:data?.tuition_name // timing: data?.selectTiming,
    // });

    // navigate(
    //   `${"/tuition-list"}?LOCATION_NAME=${
    //     ""
    //   }&SELECT_AREA=${
    //     ""
    //   }&SELECT_LEVEL=${""}&DAYS_OF_WEEK=${
    //      0
    //   }&TIME=${""}&SUBJECT_NAME=${
    //     ""}&CITY_ID=${""
    //   }&TUITION_NAME=${
    //     data?.tuition_name}`
    // );
  };
  const navigateToUserProfilePage = (item: any) => {
    navigate(
      `${"/tuition-details"}?SUBJECT_NAME=${
        item?.subjectName
      }&TIME=${""}&LOCATION_NAME=${item?.location}&DAYS=${
        item?.day
      }&CLASS_NAME=${item?.className}&CITY_NAME=${
        item?.cityName
      }&PHONE_NUMBER=${item?.mobile_number}&WEBSITE=${item?.website}&EMAIL=${
        item?.email_id
      }&COST=${item?.cost}&RATING=${item?.rating}&REVIEW=${
        item?.review
      }&SEARCH_LIST=${item?.searchList}&INSTITUTE_NAME=${
        item?.tuition_name
      }&PHONE_NUMBER=${item?.mobile_number}&PHOTO=${item?.photo}&SEATS=${
        item?.availableSeats
      }&TEACHER_ID=${item?.id}&BATCH_ID=${item?.batchId}&BATCH_TIME=${
        item?.batchTime
      }`
    );
    localStorage.setItem("tuition-list", item?.location);
  };
  const handleSubjectCardClick = (data: any) => {
    console.log(data);
    SearchTution({
      area: "",
      batch_id: "",
      city: "",
      level: "",

      subject: data?.subject, // timing: data?.selectTiming,
    });
    navigate(
      `${"/tuition-list"}?LOCATION_NAME=${""}&SELECT_AREA=${""}&SELECT_LEVEL=${""}&DAYS_OF_WEEK=${0}&TIME=${""}&SUBJECT_NAME=${
        data?.subject
      }&CITY_ID=${""}`
    );
  };

  useEffect(() => {
    getPopularTutions();
    getPopularSubjects();
  }, []);
  return (
    <>
      <PopularSection id="popular-tuitions">
        <Container>
          <Row>
            <Col sm={12}>
              <Tabs defaultActiveKey="tuitions" justify>
                <Tab eventKey="tuitions" title="Popular Tuitions">
                  <TutionGroup>
                    {tutionData?.map((item: any, index: number) => {
                      return (
                        <li className="tution-list">
                          <TutionCardWrapper
                            key={index}
                            onClick={() => handleTuitionCardClick(item)}
                          >
                            <TutionCard>
                              <TutionListWrap key={item.id}>
                                <TutionImage>
                                  <img
                                    src={`${hostConfig.S3_BUCKET}${item?.profile_filename}`}
                                    alt={item?.tuition_name}
                                    style={{
                                      height: "80px",
                                      objectFit: "contain"
                                    }}
                                    onError={(
                                      e: React.SyntheticEvent<
                                        HTMLImageElement,
                                        Event
                                      >
                                    ) => {
                                      const target =
                                        e.target as HTMLImageElement;
                                      target.src = ProfileOne; // Replace with the path to your placeholder image
                                      target.alt = "img"; // Replace with your placeholder alt text
                                    }}
                                    // onError={(e) => {
                                    //   e.target.src = ProfileOne; // Replace with the path to your placeholder image
                                    //   // e.target.alt = {item?.tuition_name}; // Replace with your placeholder alt text
                                    // }}
                                  />
                                </TutionImage>
                                <TutionTitle>{item?.tuition_name}</TutionTitle>
                              </TutionListWrap>
                            </TutionCard>
                          </TutionCardWrapper>
                        </li>
                      );
                    })}
                  </TutionGroup>
                </Tab>
                <Tab eventKey="subjects" title="Popular Subjects">
                  <TutionGroup>
                    {subjectData?.map((item: any, index: number) => {
                      return (
                        <li className="tution-list">
                          <TutionCardWrapper
                            key={index}
                            onClick={() => handleSubjectCardClick(item)}
                          >
                            <TutionCard>
                              <TutionListWrap>
                                <TutionImage>
                                  <img
                                    src={`${hostConfig.S3_BUCKET}${item?.image_file_name}`}
                                    alt={item?.subject}
                                    style={{
                                      height: "80px",
                                      objectFit: "contain"
                                    }}
                                  />
                                </TutionImage>
                                <TutionTitle>{item?.subject}</TutionTitle>
                              </TutionListWrap>
                            </TutionCard>
                          </TutionCardWrapper>
                        </li>
                      );
                    })}
                  </TutionGroup>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </PopularSection>
    </>
  );
};

export default PopularTutionSubjects;
