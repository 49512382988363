import styled from "styled-components";

export const HeaderLogo = styled.a`
  display: block;
  max-width: 215px;
  position: relative;
  top: -60px;
`;

export const LoginImageWrap = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40%;
  @media (max-width: 1199px) {
    display: none;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
export const LoginContent = styled.div`
  .header-logo {
    max-width: 215px;
    margin-bottom: 35px;
    position: absolute;
    top: 30px;
    display: inline-block;
    @media (max-width: 1199px) {
      max-width: 185px;
    }
    max-width: 250px;
  }
  
  .nav-tabs {
    border-radius: 0;
    margin: 30px -15px 50px;
    border: none;
    @media (max-width: 1680px) {
      margin-bottom: 50px;
    }
    @media (max-width: 1440px) {
      margin-bottom: 40px;
    }
    @media (max-width: 1366px) {
      margin-bottom: 30px;
    }
    .nav-item {
      background: transparent;
      width: calc(50% - 30px);
      margin: 0 15px;
      white-space: nowrap;
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
      @media (max-width: 500px) {
        width: 100%;
        margin: 10px;
      }
      .nav-link {
        border: none;
        border-radius: 0;
        border-bottom: 2px solid rgba(112, 112, 112, 0.2);
        color: #012834;
        font-size: 20px;
        line-height: 120%;
        font-weight: 400;
        width: 100%;
        @media (max-width: 1366px) {
          font-size: 18px;
        }
        @media (max-width: 820px) {
          font-size: 17px;
        }
        &.active {
          background: transparent;
          color: #940e34;
          border-bottom: 2px solid #940e34;
          font-weight: 700;
        }
      }
    }
  }
  .form-control {
    background: #ffffff;
    color: #012834;
    font-weight: 500;
  }
  .form-check-label {
    color: #707070;
  }
`;
export const BtnWrap = styled.div`
  margin-top: 30px;
  .btn {
    width: 180px;
    font-weight: 500;
    &:focus-visible {
      background: #940e34;
      color: #ffffff;
      border-color: #940e34;
      box-shadow: none;
    }
    &:active {
      background: #940e34;
      color: #ffffff;
    }
  }
`;
export const RegisterFooter = styled.div`
  p {
    margin-bottom: 15;
    color: #012834;
    font-size: 14px;
    line-height: 120%;
    .sign-up {
      color: #940e34;
      font-size: 14px;
      line-height: 120%;
      font-weight: 600;
      text-decoration: none;
      margin-left: 5px;
      &:hover {
        color: #012834;
      }
    }
  }
`;

// export const RegisterFooter = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center; /* Center horizontally */
//   position: fixed;
//   bottom: 30px;
//   max-width: 700px;
//   width: 100%;
//   @media (max-width: 1366px) {
//     max-width: 600px;
//   }
//   &.register-footer {
//     position: static;
//     margin-top: 20px;
//   }
//   p {
//     margin-bottom: 0;
//     color: #012834;
//     font-size: 14px;
//     line-height: 120%;
//     .sign-up {
//       color: #940e34;
//       font-size: 14px;
//       line-height: 120%;
//       font-weight: 600;
//       text-decoration: none;
//       margin-left: 5px;
//       &:hover {
//         color: #012834;
//       }
//     }
//   }
// `;

export const LoginFooter = styled.div`
  display: flex;
  align-items: end;
  position: absolute;
  justify-content: space-between;
  bottom: 0px;
  width: 44%;
  margin: 40px 0px;
  gap: 10px;
  @media (max-width: 450px) {
    position: relative;
    margin-top: 20px;
    display: block;
  }
  @media (max-height: 400px) {
    position: relative;
  }
  @media (max-width: 1200px) {
    width: 87%;
  }
  &.register-footer {
    position: static;
    margin-top: 70px;
  }
  p {
    margin-bottom: 0;
    color: #012834;
    font-size: 14px;
    line-height: 120%;
    .sign-up {
      color: #940e34;
      font-size: 14px;
      line-height: 120%;
      font-weight: 600;
      text-decoration: none;
      margin-left: 5px;
      &:hover {
        color: #012834;
      }
    }
  }
  .forgot-pass {
    color: #012834;
    font-size: 14px;
    line-height: 120%;
    text-decoration: none;
    &:hover {
      color: #940e34;
    }
  }
`;

export const LoginWith = styled.div`
  margin-top: 20px;
  .btn-login-with {
    border: 1px solid #70707033;
    border-radius: 30px;
    background: #f7f7f7;
    width: 180px;
    display: inline-block;
    padding: 12px 20px;
    color: #012834;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    svg {
      vertical-align: -7px;
      margin-right: 15px;
    }
    & + .btn-login-with {
      margin-left: 30px;
    }
    &:hover {
      background-color: #ffffff;
    }
  }
`;
export const TeacherWelcomeText = styled.p`
  text-align: center;
  margin-bottom: 30px;
  span {
    color: #940e34;
  }
`;
export const LoginMain = styled.div`
  padding: 0px 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  min-height: 75vh;
  @media (max-width: 370px) {
    min-height: 85vh;
  }
  background: #f7f7f7;
  &.register-main {
    align-items: inherit;
    ${LoginContent} {
      .header-logo {
        position: static;
      }
    }
    .ant-divider {
    }
  }
`;
