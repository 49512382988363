import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

const useAuth = () => {
  const user = localStorage.getItem("token");
  if (user) {
    return true;
  } else {
    return false;
  }
};
const PublicRoutes = (props: any) => {
  const auth = useAuth();
  return auth ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoutes;
