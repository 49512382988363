import { FC, MouseEvent, useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import TeacherHeader from "../../Header/teacherHeader";
import Searchbar from "../Searchbar/searchbar";
import { FaBell } from "react-icons/fa";
import avatar from "../../../assets/images/profile1.png";
import { MenuOutlined } from "@ant-design/icons";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Cookies } from "react-cookie";
import UserImage from "../../../assets/images/no-profile.png";
import { hostConfig } from "../../../config";
import "../../Header/teacherHeader.scss";
import {
  NotificationBadge,
  NotificationContainer,
  StyledBellIcon,
} from "./header"; // Import your styled components
interface HeaderProps {
  setOpen: any;
  data: any;
}

const Header: FC<HeaderProps> = (props) => {
  const { data, setOpen } = props;
  const cookies = new Cookies();
  const isWeb = window.innerWidth > 760;
  const currentKey = window.location.href.split("/")[3];
  const navigate: any = useNavigate();

  let searchBar = false;
  if (["post", "student-info", "badges", "reviews"].includes(currentKey))
    searchBar = true;
  const selectLoginInfo = useSelector((state: any) => state?.login);
  const teacherLoginInfo = useSelector((state: any) => state?.teacherLogin);
  const [items, setItems] = useState<any>({});

  useEffect(() => {
    // Fetch and update items only if it's not already set
    if (!items || Object.keys(items).length === 0) {
      const item = JSON.parse(localStorage.getItem("loggedUsers") || "{}");
      setItems(item);
    }
  }, [items]);

  const handleClearLocalStorage = () => {
    localStorage.clear();
    cookies.remove("TeacherloggedUser", { path: "/" });
    localStorage.removeItem("token");
    window.location.href = "/";
  };
  const handleBack = () => {
    navigate("/");
  };
  return (
    <>
      {isWeb ? (
        <div className="teacher-page-web">
          <div className="teacher-page-header-content-left">
            {searchBar ? (
              <></>
            ) : // <Searchbar />
            items?.is_student ? (
              <IoIosArrowBack
                size={30}
                color="#940e34"
                style={{ cursor: "pointer" }}
                onClick={handleBack}
              />
            ) : (
              <div />
            )}
          </div>

          <div className="teacher-page-header-content-right">
            <div className="teacher-page-header-content-right-bell">
              <div className="position-relative">
                <StyledBellIcon className="notification-icon" />
                <NotificationBadge>3</NotificationBadge>
              </div>
            </div>
            <div className="teacher-page-header-content-right-user">
              <div className="teacher-page-header-content-right-dropdown">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <div className="teacher-page-header-content-right-img">
                      <img
                        src={
                          items?.is_profile == true
                            ? `${hostConfig.S3_BUCKET}${items?.profile_filename}?lastdate=` +
                              Date()
                            : UserImage
                        }
                        //   src={UserImage}
                        alt="UserImage"
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <h3>{selectLoginInfo?.data ?? teacherLoginInfo?.data}</h3>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <span className="teacher-page-header-content-right-user-category">
                        {`${items?.first_name} ${items?.last_name}`}
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href={items?.is_student ? "student-profile" : "profile"}
                    >
                      My Profile
                    </Dropdown.Item>
                    {items?.is_student && (
                      <Dropdown.Item href="/my-bookings">
                        My Bookings
                      </Dropdown.Item>
                    )}
                    <hr></hr>
                    <Dropdown.Item href="" onClick={handleClearLocalStorage}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="teacher-page-mobile">
          <div className="teacher-page-header-content-left">
            <MenuOutlined
              className="teacher-page-header-content-left-icon"
              onClick={() => setOpen(true)}
              rev={undefined}
            />
            {searchBar ? <Searchbar /> : <div />}
          </div>
          <div className="teacher-page-header-content-right">
            <div className="teacher-page-header-content-right-bell">
              <div className="position-relative ">
                <StyledBellIcon className="notification-icon" />
                <NotificationBadge>3</NotificationBadge>
              </div>
            </div>
            <div className="teacher-page-header-content-right-user">
              <div className="teacher-page-header-content-right-dropdown">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <div className="teacher-page-header-content-right-img">
                      <img
                        src={
                          items?.is_profile == true
                            ? `${hostConfig.S3_BUCKET}${items?.profile_filename}?lastdate=` +
                              Date()
                            : UserImage
                        }
                        //   src={UserImage}
                        alt="UserImage"
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <h3>{selectLoginInfo?.data ?? teacherLoginInfo?.data}</h3>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <span className="teacher-page-header-content-right-user-category">
                        {`${items?.first_name} ${items?.last_name}`}
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href={items?.is_student ? "student-profile" : "profile"}
                    >
                      My Profile
                    </Dropdown.Item>

                    <Dropdown.Item href="/my-bookings">
                      My Bookings
                    </Dropdown.Item>
                    <hr></hr>
                    <Dropdown.Item href="" onClick={handleClearLocalStorage}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
