export type SubBannerLocationType = {
  location: string;
  area: string;
};

const data: SubBannerLocationType[] = [
  {
    location: "ahmedabad",
    area: "memnagar",
  },
  {
    location: "patna",
    area: "bihar-patna",
  },
  {
    location: "rajkot",
    area: "palace-road",
  },
  {
    location: "Usa",
    area: "san fransisco",
  },
  {
    location: "africa",
    area: "uganda",
  },
];
export default data;
