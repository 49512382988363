/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  STUDENT_PROFILE_SUCCESS,
  STUDENT_PROFILE_FAILURE,
  STUDENT_PROFILE_REQUEST,
} from "../types/studentProfile";
import { postFilterData, postStudentProfileData } from "../../api/create";
import { updateStudentProfile } from "../../api/update";
import { getStudentProfileList, getStudentProfileById } from "../../api/list";
import { deleteStudentProfile } from "../../api/delete";

export const StudentProfileRequest = () => ({
  type: STUDENT_PROFILE_REQUEST,
});
export const StudentProfileSuccess = (users) => ({
  type: STUDENT_PROFILE_SUCCESS,
  payload: users,
});
export const StudentProfileFailure = (error) => ({
  type: STUDENT_PROFILE_FAILURE,
  payload: error,
});

export const CreateStudentProfile = (data) =>
  async function (dispatch) {
    dispatch(StudentProfileRequest());
    try {
      const res = await postStudentProfileData(data);

      if (res) {
        dispatch(StudentProfileSuccess({ responseStatus: "success" }));
        //  toast.success("Student profile created successfully");
        toast.success("Student profile created Successfully", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        return "success";
      }
      dispatch(StudentProfileFailure(res.message));
      //  toast.success("Student profile created successfully");
      return res.message;
    } catch (err) {
      dispatch(StudentProfileFailure(err.message));
      toast.success("An error occurred while creating the student profile", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      console.log("An error occurred while creating the student profile");
      return err.message;
    }
  };

let isToastShown = false; // Add this flag

export const filterProfile = (data) =>
  async function (dispatch) {
    dispatch(StudentProfileRequest());
    try {
      const res = await postFilterData(data);

      if (res) {
        dispatch(StudentProfileSuccess({ responseStatus: "success" }));
        return res;
      }
      dispatch(StudentProfileFailure(res.message));
      if (!isToastShown) {
        isToastShown = true; // Set the flag to true
      }
      return res.message;
    } catch (err) {
      dispatch(StudentProfileFailure(err.message));
      if (!isToastShown) {
        isToastShown = true; // Set the flag to true
      }
      return err.message;
    }
  };

export const UpdateStudentProfileData = (data, userId) =>
  async function (dispatch) {
    dispatch(StudentProfileRequest());
    return updateStudentProfile(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(StudentProfileSuccess({ responseStatus: "success" }));
          //  toast.success("Student Profile Updated Successfully");
          toast.success("Student Profile Updated Successfully", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          return "success";
        }
        dispatch(StudentProfileFailure(res.error));
        return res.message || res.error;
      })
      .catch((err) => console.log("StudentProfile not Updated "));
  };
export const GetStudentProfileList = (teacherId, searchValue, page, limit) =>
  async function (dispatch) {
    dispatch(StudentProfileRequest());
    const params = {
      teacher_id: teacherId,
      search_value: searchValue,
      page: page, // Include the page parameter
      rowsPerPage: limit, // Include the limit parameter as rowsPerPage
    };
    return getStudentProfileList(params)
      .then((res) => {
        if (res) {
          console.log("student_profile_action", res);
          dispatch(StudentProfileSuccess({ responseStatus: res }));
          return res;
        }
        dispatch(StudentProfileFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };

export const GetStudentProfileById = (userId, data) =>
  async function (dispatch) {
    dispatch(StudentProfileRequest());
    return getStudentProfileById(userId, data)
      .then((res) => {
        if (res) {
          dispatch(StudentProfileSuccess(res.records));
          return res;
        }
        dispatch(StudentProfileFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
// UpdateStudentProfile action remains unchanged

export const DeleteStudentProfile = (studentProfileId) =>
  async function (dispatch) {
    dispatch(StudentProfileRequest());
    return deleteStudentProfile(studentProfileId)
      .then((res) => {
        if (!res.error) {
          dispatch(StudentProfileSuccess({ responseStatus: "success" }));
          // toast.success("Student Profile Deleted Successfully");
          toast.success("Student Profile Deleted Successfully", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          return studentProfileId; // Return the deleted studentProfile's ID
        }
        dispatch(StudentProfileFailure(res.error));
        return res.error;
      })
      .catch((err) => {
        // toast.error("StudentProfile not Deleted");
        return err;
      });
  };
