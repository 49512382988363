
import React, { useState,useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container, Row, Col } from "react-bootstrap";

import {
  CompanyWrap,
  Title,
  Description,
} from "../../pages/AboutUs/aboutusStyle";

const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
         <Header/>
    <Container>
      <Row>
        <Col sm={12}>
          <Title style={{textAlign:"center"}}>Cookies</Title>
          {/* <Description>{data.contactUs}</Description> */}
         

<p>We employ the use of cookies. By accessing Tuition Mitra, you agreed to use cookies in agreement
with the EthosITy solutions’ Privacy Policy.
Most interactive websites use cookies to let us retrieve the user&#39;s details for each visit. Cookies are
used by our website to enable the functionality of certain areas to make it easier for people visiting
our website. Some of our affiliate/advertising partners may also use cookies.</p>



        </Col>
      </Row>
    </Container>
  <Footer/>
 

</>
  );
};

export default CookiePolicy;



