import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Calendar from "react-calendar";
import { useDispatch } from "react-redux";
import {
  BatchTime,
  BookBatchTime,
  BookBatchTitle,
  BookNowBtn,
  BookPickTitle,
  BookTime,
  PickCalender,
  UpdatePlanModal,
} from "../../pages/tuitionDetails/tuitionDetailsStyle";
import { CreateBookingClass, GetMyBookingById } from "../../redux/action";
import { CreateClassBooking } from "../../redux/action/paymentAction";
import CongratulationModal from "../CongratulationModal";

const Index = (props) => {
  console.log(props, "propfordemobooking");
  const handleClose = () => props.setShow(false);
  const [items, setItems] = useState();
  const [selectedItems, setSelectedItems] = useState();
  const [payload, setPayload] = useState({
    id: props.batchId,
    value: props.batchTime,
  });
  const [congratsModal, setCongratsModal] = useState(false);
  let dateInfo = moment(new Date()).format("MM-DD-YYYY");
  const usertoken = localStorage.getItem("token");
  const [popUpValue, setPopUpValue] = useState({ date: dateInfo, radio: "" });
  const [paymentId, setPaymentId] = useState("");
  const [bookingDetails, setBookingDetails] = useState();
  const [bookedList, setBookedList] = useState([]);
  const dispatch = useDispatch();
  const bookingList = async () => {
    const items = JSON.parse(localStorage.getItem("loggedUsers"));
    const studentID = items;
    const id = studentID?.id;
    
    const result = await dispatch(GetMyBookingById(id));
    if(result?.records){
      setBookedList(result?.records)
    }
 

  

  };
  const handleShow = () => {
    setCongratsModal(true);
    setPopUpValue({ date: dateInfo, radio: "" });
  };
  const handleClose1 = () => setCongratsModal(false);
  const resetField = () => {
    setPopUpValue({ date: "", radio: "" });
  };
  const submitForm1 = (values) => {
    console.log(values, "values Info for book a demo popup");
    if (props?.getLoginInfo?.data) {
      props?.setModalShow(true);
    } else {
      props?.setLoginModal(true);
    }
    handleClose();
  };

  const isValidInfo = (values) => {
    let isValid = false;

    if (props.popUpValue.radio && selectedItems?.batch_id) {
      isValid = true;
    }
    console.log(isValid, "isValid");
    console.log(props.popUpValue, "values");

    return isValid;
  };

  useEffect(() => {
    bookingList()
    const items = JSON.parse(localStorage.getItem("loggedUsers") || "{}");
    if (items) {
      setItems(items);
    }
  }, []);
  const handleRadioChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);
    setSelectedItems(selectedValue);
  };
  const handleBooking = async () => {
    const data = {
      amount: "10000",
      batch_id: selectedItems?.batch_id,
      student_id: items.id,
      payment_type: "card",
      teacher_id: Number(props.teacherId),
    };
    console.log(data, "dataaasfsdfhgfh");
    dispatch(CreateClassBooking(data)).then((res) => {
      console.log(res?.records, "res from class booking");
      setPaymentId(res?.records?.id);
      if (res?.order_details?.id) {
        if(props?.isdemo){
          handleBookingClass()
        }else{
          displayRazorpay(res?.order_details?.id, res?.records?.id);
        }
 
      }
    });
  };
  const handleBookingClass = () => {
    const data = {
      batch_id: selectedItems.batch_id,
      student_id: items.id,
      teacher_id: Number(props.teacherId),
    };
    dispatch(CreateBookingClass(data)).then((res) => {
      console.log(res, "res sucesssssssssssssssssssssss");
      setBookingDetails(res?.records);
      handleShow();
    });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(id, paymentId) {
    handleClose();
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    console.log(paymentId, "keykeykeykeykeykeykeykey");
    const options = {
      key: "rzp_test_jJVStcdBt9Ti7C",
      amount: "300000",
      currency: "INR",
      name: "Akham",
      description: "Test Transaction",
      order_id: id,
      handler: async function (response) {
        console.log(response, "_--------------________------------>>>");
        const datanew = {
          payment_id: paymentId,
          razorpay_order_id: response?.razorpay_order_id,
          razorpay_payment_id: response?.razorpay_payment_id,
          razorpay_signature: response?.razorpay_signature,
        };
        console.log(datanew, "datanewdatanewdatanew");
        try {
          // loaderStart();
          await fetch("https://api.studentsathi.com/api/v1/payment-verify", {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${usertoken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datanew),
          })
            .then((response) => console.log(response, "cdgcvdgvcdgcdgcvdgcg"))
            .then((res) => {
              console.log(res, "_________________ call back url responce");
              // if (res?.error) {
              //   console.log(res?.error, "");
              // }
              // if (res?.success) {
              //   toast.error("Your Payment is Successfully completed");
              // } else {
              //   toast.error("Oops your Payment is Failed!!!");
              //   return;
              // }
            });
        } catch (error) {
          console.log(error, ":errorr");
        }
        if (response.razorpay_order_id) {
        }
        if (response) {
          handleBookingClass();
        }
        const data = {
          orderCreationId: response.order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
      },

      theme: {
        color: "#3B5971",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
const isbooked =(id)=>{
if(bookedList?.length >=1){
 const data = bookedList?.find(bkditem=>{

    return bkditem?.booking?.batch_id == id
  })
  if(data){
return true
  }else{
return false
  }

  
}

}
  return (
    <>
      <Modal
        className="updatePlanModal"
        show={props?.show}
        onHide={handleClose}
        centered
      >
        <style>
          {`
            .updatePlanModal .modal-dialog {
              max-width: calc(100% - 40px);
              width: 914px;
              letter-spacing: 0;
            }
            .updatePlanModal .modal-content {
              background: #FFFFFF;
              border-radius: 30px;
              border: none;
              box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
            }
            @media (max-width: 1440px) {
              .updatePlanModal .modal-dialog {
                max-width: calc(100% - 40px);
                  width: 880px;
              }
            }
          `}
        </style>
        <UpdatePlanModal>
          <Modal.Header closeButton>
            <Modal.Title className="modalTitle">Book a Demo Class</Modal.Title>
          </Modal.Header>

          <Modal.Body className="show-grid">
            <Container>
              <Formik
                initialValues={{
                  selectedOption: "",
                  // calenderValue: new Date(),
                }}
                validate={(values) => {
                  let errors = {};

                  if (!values.selectedOption) {
                    errors.radio = "Please select one batch time";
                    props.setValidation("Please select one batch time");
                  }
                  return errors;
                }}
                onSubmit={submitForm1}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  dirty,
                }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col
                          sm={12}
                          md={5}
                          style={{ marginTop: 20, marginBottom: 20 }}
                        >
                          <BookPickTitle>Pick a Date</BookPickTitle>
                          <PickCalender>
                            <Calendar
                              className={["c1", "c2"]}
                              onChange={props.onChangePopUp}
                              minDate={new Date()}
                              value={
                                props.popUpValue.date &&
                                moment(props.popUpValue.date).toDate()
                              }
                            />
                          </PickCalender>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={7}
                          style={{ marginTop: 20, marginBottom: 20 }}
                        >
                          <Row>
                            <Col xs={12} sm={12}>
                              <BookBatchTime>
                                <BookBatchTitle>Pick a Batch</BookBatchTitle>
                              </BookBatchTime>
                            </Col>

                            <>
                              {props.batchTime?.map((item) => {
                                isbooked(item?.batch_id)
                             
                                return (
                                  <Col xs={12} sm={6} md={6}>
                                    <BookTime  className="batch-time">
                                      <li className="batch-time">
                                        <Form.Check
                                          label={item.timing}
                                          name="selectedOption"
                                          type="radio"
                                          id={item.batch_id}
                                          disabled={  isbooked(item?.batch_id) ? true:false}
                                          value={JSON.stringify(item)}
                                          onChange={(e: any) => {
                                            handleRadioChange(e);
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                          style={{ textAlign: "center" }}
                                        />
                                      </li>
                                    </BookTime>
                                  </Col>
                                );
                              })}
                              {/* <li className="batch-time">
                                <Form.Check
                                  label="9:30 AM to 11:00 AM"
                                  name="radio"
                                  type="radio"
                                  id="batchTwo"
                                  value="9:30 AM to 11:00 AM"
                                  onChange={props.handleChangeForRadio}
                                  onBlur={handleBlur}
                                />
                              </li>
                              <li className="batch-time">
                                <Form.Check
                                  label="11:30 AM to 1:00 PM"
                                  name="radio"
                                  type="radio"
                                  id="batchThree"
                                  value="11:30 AM to 1:00 PM"
                                  onChange={props.handleChangeForRadio}
                                  onBlur={handleBlur}
                                />
                              </li>
                              <li className="batch-time">
                                <Form.Check
                                  label="1:30 AM to 3:00 PM"
                                  name="radio"
                                  type="radio"
                                  id="batchFour"
                                  value="1:30 AM to 3:00 PM"
                                  onChange={props.handleChangeForRadio}
                                  onBlur={handleBlur}
                                />
                              </li> */}
                              <div className="error">
                                {touched.radio && props.validation && (
                                  <p>{props.validation}</p>
                                )}
                              </div>
                            </>
                            <Col xs={12} sm={12}>
                              <BookNowBtn
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                disabled={ !selectedItems?.batch_id}
                                  type="submit"
                                  // variant="etttte"
                                  variant={
                                    isValidInfo(values)
                                      ? "secondary active"
                                      : "secondary"
                                  }
                                  onClick={handleBooking}
                                >
                                  Book
                                </Button>
                              </BookNowBtn>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </form>
                  </>
                )}
              </Formik>
            </Container>
          </Modal.Body>
        </UpdatePlanModal>
      </Modal>
      {congratsModal ? (
        <CongratulationModal
          modalShow={congratsModal}
          bookedDetails={bookingDetails}
          onHide={handleClose1}
          popUpValue={popUpValue}
          setModalShow={setCongratsModal}
          resetField={resetField}
          title={props?.title}
        />
      ) : null}
    </>
  );
};
export default Index;
