import styled from 'styled-components';



export const DescriptionBlog = styled.p`
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 30px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #012834;
  padding-bottom: 0px;
  margin-bottom: 0px;
  text-align: left;
  @media (max-width: 1366px) {
    font-size: 15px;
    line-height: 28px;
  }
`;

export const CompanyWrap = styled.div`
  background: #f0f0f0;
  padding: 100px 0px;
  text-align: center;
  @media (max-width: 1680px) {
    padding: 90px 0;
  }
  @media (max-width: 1440px) {
    padding: 70px 0;
  }
  @media (max-width: 1366px) {
    padding: 60px 0;
  }
  @media (max-width: 1280px) {
    padding: 40px 0;
  }
 
  ${DescriptionBlog} {
    max-width: 1050px;
    margin: 0 auto;
    text-align: left;
  }
`;